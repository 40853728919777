import types from './types';

const INITIAL_STATE = {
  loading: false,
  list: [],
  loadingActionDetail: false,
  actionDetail: {},
  buttonLoading: false,
  loadingActionResults: false,
  actionResults: {
    participations: [],
  },
  actionsTotal: 0,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'ACTIVE_ACTIONS_LOAD':
      return { ...state, loading: action.payload };
    case 'ACTIVE_ACTIONS_FETCHED':
      return { ...state, list: action.payload, loading: false };
    case 'ACTION_RESULTS_LOAD':
      return { ...state, loadingActionResults: action.payload };
    case 'ACTION_RESULTS_FETCHED':
      return {
        ...state,
        actionResults: action.payload,
        loadingActionResults: false,
      };
    case 'ACTIVE_ACTIONS_TOTAL_LOAD':
      return { ...state, loadingActionResults: action.payload };
    case 'ACTIVE_ACTIONS_TOTAL_FETCHED':
      return {
        ...state,
        actionsTotal: action.payload,
        loadingActionResults: false,
      };
    case types.DETAIL_LOAD:
      return { ...state, loadingActionDetail: action.payload };
    case types.DETAIL_FETCHED:
      return {
        ...state,
        actionDetail: action.payload,
        loadingActionDetail: false,
      };
    case types.BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    default:
      return state;
  }
};
