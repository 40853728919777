import { String2Currency } from 'helpers/dataTransformers';
import React, { useCallback, useMemo, useState } from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import { connect } from 'react-redux';
import {
  Container,
  FinalPrice,
  InfoContainer,
  ItemImage,
  Prices,
  Quantity,
  SeeMore,
  Title,
} from './styles';

let ItemCard = function({ data }) {
  const [items, setItems] = useState(1);

  const price = useMemo(() => {
    return String2Currency(data.value);
  }, [data.value]);

  const availableStock = useMemo(() => {
    return data.stock;
  }, [data.stock]);

  const discountPrice = useMemo(() => {
    return String2Currency(data.value - data.discount_value);
  }, [data.discount_value, data.value]);

  const finalPrice = useMemo(() => {
    return ((data.value - data.discount_value) * items)
      .toFixed(2)
      .replace('.', ',');
  }, [data.value, data.discount_value, items]);

  const onAdd = useCallback(() => {
    if (items < data.limit && items < availableStock) {
      setItems(item => item + 1);
    }
  }, [availableStock, data.limit, items]);

  const onSubtract = useCallback(() => {
    if (items > 0) {
      setItems(item => item - 1);
    }
  }, [items]);

  return (
    <>
      <Container>
        <ItemImage image={data.image} />
        <InfoContainer>
          <Title>{data.name}</Title>
          <SeeMore>Ver mais</SeeMore>
          <section>
            <Prices>
              <span>{discountPrice}</span>
              {data.discount_value > 0 && <span>{price}</span>}
            </Prices>
            <Quantity>
              <FiMinus onClick={() => onSubtract()} size={14} />
              <p>{items}</p>
              <FiPlus onClick={() => onAdd()} size={14} />
            </Quantity>
            <FinalPrice>
              R$ <span>{finalPrice}</span>
            </FinalPrice>
          </section>
        </InfoContainer>
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ItemCard);
