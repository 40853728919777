import React from 'react';
import { IndexRoute, Route } from 'react-router';
import Contacts from './contacts';
import ImportContacts from './importContacts';
import InviteOwner from './inviteOwner';

export default (
  <Route path="contacts">
    <IndexRoute component={props => <Contacts {...props} />} />
    <Route path="import" component={props => <ImportContacts {...props} />} />
    <Route path="gestor" component={props => <InviteOwner {...props} />} />
  </Route>
);
