import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import React, { useEffect } from 'react';
import { MessageBox } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getStationActionOfferMessages,
  onClickAction,
  remove,
} from './actionOfferMessagesActions';
import moment from 'moment';

function ActionOfferMessages(props) {
  const content = props.actionOfferMessages.list;

  useEffect(() => {
    props.getStationActionOfferMessages(props.router.params.id);
  }, []);

  function onDialogClose() {
    if (props.location.query.from === 'arquivadas') {
      props.router.push('/owner/acoes/minhas-atividades/?to=closed');
    } else {
      props.router.push('/owner/acoes/minhas-atividades/?to=drafts');
    }
  }

  function renderEmptyScreen() {
    return (
      <section
        id="actionOfferMessages"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não fez propostas de atividades para estações.
                  </h2>
                  <p className="">
                    Atividades são campanhas que você poderá distribuir para o
                    público das estações.
                  </p>

                  <PrimaryButton
                    onClick={() => props.router.push('/owner/acoes')}
                    color="primary"
                  >
                    Criar atividade
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração Cupons"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.actionOfferMessages.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <AlertDialogSlide
      title="Mensagens da proposta"
      visible={true}
      height="95vh"
      onClose={onDialogClose}
      maxWidth={isMobile ? 'xl' : 'md'}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {props.actionOfferMessages.list.map(message => {
          return (
            <div
              style={{
                maxWidth: 300,
                alignSelf:
                  message.from_establishment_id ===
                  props.auth.user.establishments[0].id
                    ? 'flex-end'
                    : 'flex-start',
              }}
            >
              <Typography
                style={{
                  textAlign:
                    message.from_establishment_id ===
                    props.auth.user.establishments[0].id
                      ? 'right'
                      : 'left',
                  margin: 10,
                }}
                variant="subtitle2"
              >
                {message.from_establishment_id ===
                props.auth.user.establishments[0].id
                  ? 'Você'
                  : message.from_establishment.name}
              </Typography>
              <MessageBox
                position={
                  message.from_establishment_id ===
                  props.auth.user.establishments[0].id
                    ? 'right'
                    : 'left'
                }
                type={'text'}
                dateString={moment(message.createdAt).format('DD/MM')}
                text={message.message}
                data={{
                  uri: 'https://facebook.github.io/react/img/logo.svg',
                  status: {
                    click: false,
                    loading: 0,
                  },
                }}
              />
            </div>
          );
        })}
      </div>
    </AlertDialogSlide>
  );
}

const mapStateToProps = state => {
  return {
    actionOfferMessages: state.actionOfferMessages,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getStationActionOfferMessages, remove, onClickAction },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActionOfferMessages);
