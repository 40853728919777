import { Box, Typography, IconButton, useTheme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getValidatedList,
  setSubscriptionRewardLoading,
  setSubscriptionRewardModal,
  setSubscriptionCancelModal,
  rewardPresentialCourse,
  cancelPresentialCourseSubscription,
} from './subscriptionsActions';
import AlertDialogSlide from '../../../../common/components/dialog/dialog';
import PrimaryButton from '../../../../common/components/buttons/primaryButton';
import SecondaryButton from '../../../../common/components/buttons/secondaryButton';
import Table from 'common/components/table/table-material';
import { isMobile } from 'react-device-detect';

function SubscriptionsValidated(props) {
  const { auth } = props;
  const { content } = props.subscriptions.list;
  const { router } = props;
  const theme = useTheme();
  const [acceptData, setAcceptData] = useState({});
  const [rejectData, setRejectData] = useState({});

  function handleOpenAcceptModal(rowData) {
    setAcceptData(rowData);
    props.setSubscriptionRewardModal(true);
  }

  function handleOpenRejectModal(rowData) {
    setRejectData(rowData);
    props.setSubscriptionCancelModal(true);
  }

  function handleCloseAcceptModal() {
    props.setSubscriptionRewardModal(false);
  }

  function handleCloseRejectModal() {
    props.setSubscriptionCancelModal(false);
  }

  function handleReward() {
    props.rewardPresentialCourse(acceptData);
  }

  function handleCancel() {
    props.cancelPresentialCourseSubscription(rejectData);
  }

  const [columns] = useState([
    {
      title: 'Nome',
      field: 'user.name',

      render: rowData => (rowData.user?.name ? rowData.user?.name : '-'),
    },
    {
      title: 'CPF',
      field: 'user.cpf',
      render: rowData => (rowData.user?.cpf ? rowData.user?.cpf : '-'),
    },
    { title: 'Validado', field: 'validated' },
    {
      title: 'Atividade',
      field: 'presential_course.action.name',

      render: rowData =>
        rowData.presential_course?.action?.name
          ? rowData.presential_course?.action?.name
          : '-',
    },
    { title: 'Data da inscrição', field: 'createdAt' },
    {
      title: 'Premiação',
      field: 'validated',
      render: rowData => {
        return (
          <div>
            <IconButton
              style={{ color: theme.palette.success.main }}
              aria-label="Aceitar"
              onClick={() => handleOpenAcceptModal(rowData)}
            >
              <CheckIcon />
            </IconButton>
            <IconButton
              style={{ color: theme.palette.error.main }}
              aria-label="Rejeitar"
              onClick={() => handleOpenRejectModal(rowData)}
            >
              <ClearIcon />
            </IconButton>
          </div>
        );
      },
    },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    props.getValidatedList(auth.user.establishments[0].id);
  }, []);
  useEffect(() => {
    setData(props.subscriptions.list.content);
  }, [props.subscriptions.list.content]);

  function renderEmptyScreen() {
    return (
      <section id="subscriptions" style={{ marginTop: '4.3em' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui ninguém validado.
                  </h2>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.subscriptions.loading) {
    return (
      <Box display="flex" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <Table
            columns={columns}
            rows={data}
            router={router}
            hasToolbar
            toolbarSearch
            title="Lista de validados"
            dataType={'subscriptions'}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>
      {props.subscriptions.isSubscriptionRewardModalOpen ? (
        <AlertDialogSlide
          maxWidth="sm"
          title="Tem certeza?"
          onClose={handleCloseAcceptModal}
          visible={props.subscriptions.isSubscriptionRewardModalOpen}
        >
          <div>
            <Typography>
              Você tem certeza que deseja premiar o seguinte participante?
            </Typography>
            <Typography variant="h6">Nome:</Typography>
            <Typography>{acceptData.user.name}</Typography>
            <Typography variant="h6">CPF:</Typography>
            <Typography>{acceptData.user?.cpf}</Typography>
            <Typography variant="h6">Atividade:</Typography>
            <Typography>{acceptData.presential_course.action.name}</Typography>
            <PrimaryButton
              noMargin
              onClick={handleReward}
              disabled={props.subscriptions.subscriptionRewardLoading}
            >
              Premiar
            </PrimaryButton>
            <SecondaryButton onClick={handleCloseAcceptModal}>
              Cancelar
            </SecondaryButton>
          </div>
        </AlertDialogSlide>
      ) : null}
      {props.subscriptions.isSubscriptionCancelModalOpen ? (
        <AlertDialogSlide
          maxWidth="sm"
          title="Tem certeza?"
          onClose={handleCloseRejectModal}
          visible={props.subscriptions.isSubscriptionCancelModalOpen}
        >
          <div>
            <Typography>
              Você tem certeza que deseja rejeitar o seguinte participante?
            </Typography>
            <Typography variant="h6">Nome:</Typography>
            <Typography>{rejectData.user.name}</Typography>
            <Typography variant="h6">CPF:</Typography>
            <Typography>{rejectData.user?.cpf}</Typography>
            <Typography variant="h6">Atividade:</Typography>
            <Typography>{rejectData.presential_course.action.name}</Typography>
            <PrimaryButton
              noMargin
              onClick={handleCancel}
              disabled={props.subscriptions.subscriptionRewardLoading}
            >
              Rejeitar
            </PrimaryButton>
            <SecondaryButton onClick={handleCloseRejectModal}>
              Cancelar
            </SecondaryButton>
          </div>
        </AlertDialogSlide>
      ) : null}
    </section>
  );
}

const mapStateToProps = state => {
  return {
    subscriptions: state.subscriptions,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getValidatedList,
      setSubscriptionRewardLoading,
      setSubscriptionRewardModal,
      setSubscriptionCancelModal,
      rewardPresentialCourse,
      cancelPresentialCourseSubscription,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsValidated);
