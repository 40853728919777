import { Box, Typography } from '@material-ui/core';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { BASE_S3 } from 'config/consts';
import React from 'react';
import './cardActionInfo.css';
import moment from 'moment';

export default function CardActionNew(props) {
  return (
    <GeneralGrid
      container
      justify="space-between"
      spacing={3}
      style={{
        backgroundColor: '#ffff',
        marginBottom: '2.5em',
        height: 190,
      }}
    >
      <GeneralGrid item sm={7} md={6} lg={8}>
        <GeneralGrid container spacing={3} style={{ height: '100%' }}>
          <GeneralGrid
            item
            sm={6}
            md={5}
            style={{
              height: 190,
              padding: 0,
              background: `url(${BASE_S3}/${props.data.image})`,
              backgroundSize: 'cover',
              backgroundPosition: `center`,
            }}
          />
          <GeneralGrid
            item
            sm={6}
            md={7}
            style={{ padding: '12px 12px 12px 35px' }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              flexDirection="column"
              height="100%"
            >
              <Box>
                <Typography variant={'h6'}>{props.data.name}</Typography>
                <Typography style={{ color: '#959595' }} variant={'subtitle1'}>
                  {`${props.data.summary}`}
                </Typography>
              </Box>
              <Box style={{ width: '100%', marginLeft: '-10px' }}>
                {props.draft ? (
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" justifyContent="space-between">
                      <Typography
                        variant={'body1'}
                        color={'primary'}
                        onClick={props.onClickEdit}
                        className="links"
                      >
                        Editar
                      </Typography>
                      <Box style={{ width: 'fit-content' }}>
                        <Typography
                          variant={'body1'}
                          color={'primary'}
                          onClick={props.onClickDelete}
                          className="links"
                        >
                          Excluir
                        </Typography>
                      </Box>
                    </Box>
                    {props.data.action_offers ? (
                      props.data.action_offers.length > 0 ? (
                        props.data.action_offers[0].messages.length > 0 ? (
                          <Typography
                            variant={'body1'}
                            color={'primary'}
                            onClick={() =>
                              props.router.push(
                                `/owner/mensagens-proposta/${props.data.action_offers[0].id}`
                              )
                            }
                            className="links"
                          >
                            Mensagens
                          </Typography>
                        ) : null
                      ) : null
                    ) : null}
                    <Typography
                      variant={'body1'}
                      color={'primary'}
                      onClick={props.onClickShare}
                      className="links"
                    >
                      Submeter a aprovação
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    style={{
                      width: 'fit-content',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    {props.data.type_id === 3 ? (
                      <Typography
                        variant={'body1'}
                        color={'primary'}
                        onClick={props.onClickEdit}
                        className="links"
                      >
                        Editar
                      </Typography>
                    ) : null}
                    <Typography
                      variant={'body1'}
                      color={'primary'}
                      onClick={props.onClickResults}
                      className="links"
                    >
                      Resultados
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid item sm={5} md={4} lg={3}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ height: '100%' }}
        >
          <Box display="flex" justifyContent="flex-end">
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <p
                style={{
                  margin: 0,
                  width: 'fit-content',
                  fontSize: 14,
                  color: '#ffff',
                  backgroundColor:
                    props.condition && props.data.station_id
                      ? '#169f53'
                      : '#707070',
                  padding: '5px 10px',
                  marginBottom: 4,
                }}
              >
                {props.condition && props.data.station_id
                  ? 'ONLINE'
                  : props.data.action_offers
                  ? props.data.action_offers.length > 0
                    ? props.data.action_offers[0].refused
                      ? 'RECUSADA'
                      : 'OFFLINE'
                    : 'OFFLINE'
                  : 'OFFLINE'}
              </p>
              {props.draft ? (
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    width: 'fit-content',
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    color: 'orange',
                  }}
                >
                  {props.data.action_offers.length > 0
                    ? props.data.action_offers[0].refused
                      ? 'Você pode fazer o reenvio'
                      : null
                    : null}
                </p>
              ) : (
                <p
                  style={{
                    margin: 0,
                    padding: 0,
                    width: 'fit-content',
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    color: '#2D95EF',
                  }}
                >
                  {props.data.station_id
                    ? 'Aprovada!'
                    : 'Aguardando aprovação...'}
                </p>
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Box display="flex" justifyContent="center" alignItems="center">
              <SupervisorAccountIcon
                color={'primary'}
                fontSize="small"
                style={{ marginRight: 10 }}
              />
              <Typography component="span" variant={'h5'} color={'primary'}>
                {`${' '}${props.data.userActions.length}`}
              </Typography>
              <Typography
                style={{ color: '#959595' }}
                component="span"
                variant={'h5'}
              >
                {`/${props.data.limit}`}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <Box display="flex" justifyContent="flex-end">
              <Typography
                style={{ color: '#707070' }}
                component="span"
                variant="subtitle1"
              >
                início:{'  '}
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="primary"
                >
                  {props.data.start !== 'Invalid date' && props.reactivate
                    ? `${props.data.start.slice(
                        0,
                        10
                      )} às ${props.data.start.slice(11, 16)}`
                    : props.data.start && props.data.start !== 'Invalid date'
                    ? `${moment(props.data.start).format(
                        'DD/MM/YYYY'
                      )} às ${moment(props.data.start).format('HH:mm')}`
                    : 'a definir'}
                </Typography>
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Typography
                style={{ color: '#707070' }}
                component="span"
                variant="subtitle1"
              >
                fim:{'  '}
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="primary"
                >
                  {props.data.end !== 'Invalid date' && props.reactivate
                    ? `${props.data.end.slice(0, 10)} às ${props.data.end.slice(
                        11,
                        16
                      )}`
                    : props.data.end && props.data.end !== 'Invalid date'
                    ? `${moment(props.data.end).format(
                        'DD/MM/YYYY'
                      )} às ${moment(props.data.end).format('HH:mm')}`
                    : 'a definir'}
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Box>
      </GeneralGrid>
    </GeneralGrid>
  );
}
