import { Dialog } from 'primereact/dialog';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { postCsvFileToContacts } from './contactsActions';

class ImportContacts extends Component {
  render() {
    return (
      <section className="modules">
        <div className="container-fluid">
          <div>
            <Dialog
              header="Importe Seus Contatos"
              visible={true}
              style={{ width: '50vw' }}
              modal={true}
              onHide={() => this.props.router.push('/owner/contacts')}
            >
              <Dropzone
                onDrop={acceptedFiles =>
                  this.props.postCsvFileToContacts(
                    acceptedFiles[0],
                    this.props.auth.user.establishments[0].id,
                    this.props.router
                  )
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>
                        Arraste (ou clique aqui) seu Arquivo .csv com os as
                        colunas "Nome" e "Email", para que seus contatos sejam
                        importados
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </Dialog>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ postCsvFileToContacts }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportContacts);
