import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import PrimaryButton from 'common/components/buttons/primaryButton';
import Content from 'common/components/content/content';
import {
  InputLabelOutlined,
  SelectLabel,
} from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { cepMask, cnpjMask } from 'helpers/masks';
import { validateCNPJ } from 'helpers/utils';
import { FORM_RULES } from 'helpers/validations';
import { signupCompanyOnly } from 'pages/auth/authActions.js';
import React, { Component } from 'react';
import { Card } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { getCitiesList, getStatesList } from '../addresses/addressesActions';
import { changeField, getAdditionalData } from './signupCompanyActions.js';

class SignupCompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDialogVisible: false,
    };
    props.getStatesList();
    props.getAdditionalData();
  }
  componentWillMount() {}

  async componentDidUpdate(prevProps) {
    if (this.props.formValues) {
      if (this.props.formValues.state && prevProps.formValues === undefined) {
        this.props.getCitiesList(
          this.props.addresses.statesList.find(
            item => this.props.formValues.state === item.sigla
          ).id
        );
      } else if (
        this.props.formValues.state &&
        this.props.formValues.state !== prevProps.formValues.state
      ) {
        this.props.getCitiesList(
          this.props.addresses.statesList.find(
            item => this.props.formValues.state === item.sigla
          ).id
        );
      }

      if (this.props.formValues.zip_code) {
        if (
          this.props.formValues.zip_code.length === 8 &&
          this.props.formValues.zip_code !== prevProps.formValues.zip_code
        ) {
          const response = await axios.get(
            `https://viacep.com.br/ws/${this.props.formValues.zip_code}/json/`
          );

          this.props.changeField(
            'SignupCompanyForm',
            'line_1',
            response.data.logradouro
          );
          this.props.changeField(
            'SignupCompanyForm',
            'state',
            this.props.addresses.statesList.find(
              item => response.data.uf === item.sigla
            ).sigla
          );
        }
      }
    }
  }

  handleClickOpen = () => {
    this.setState({ isDialogVisible: true });
  };

  handleClose = () => {
    this.setState({ isDialogVisible: false });
  };

  onSubmit = values => {
    // Validação de CNPJ

    const isCNPJValid = validateCNPJ(values.cnpj);

    if (!isCNPJValid) {
      this.setState({ isDialogVisible: true });
      return;
    }

    //

    const newValues = {
      ...values,
      country: 'BR',
      user_id: this.props.auth.user.id,
      owner: this.props.auth.user.id,
      type: 4,
    };

    const { router, auth } = this.props;
    values['type'] = 4;
    values['owner'] = this.props.auth.user.id;
    this.props.signupCompanyOnly(newValues, router, auth);
  };

  render() {
    const { handleSubmit } = this.props;

    const statesList = this.props.addresses.statesList
      .map(state => ({
        value: state.sigla,
        label: state.nome,
      }))
      .sort((a, b) => {
        if (a.label.toUpperCase() > b.label.toUpperCase()) {
          return 1;
        } else {
          return -1;
        }
      });
    const citiesList = this.props.addresses.citiesList.map(city => ({
      value: city.nome,
      label: city.nome,
    }));

    if (this.props.signupCompany.loading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <LoadingProgress />
        </div>
      );
    }
    return (
      <Content>
        <Card style={{ marginTop: 20 }}>
          <div className="container-fluid">
            <div className="col-auto modal-signup-text my-4">
              Cadastro comercial.
            </div>
            <form onSubmit={handleSubmit(this.onSubmit)} noValidate>
              <GeneralGrid container spacing={3}>
                <GeneralGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="Nome fantasia"
                    name="name"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </GeneralGrid>
                <GeneralGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="Razão social resumida"
                    name="social_name"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </GeneralGrid>
                <GeneralGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="Razão social completa (para os Termos de Uso)"
                    name="full_social_name"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                  />
                </GeneralGrid>
                <GeneralGrid item xs={12} md={6}>
                  <Field
                    component={InputLabelOutlined}
                    label="CNPJ"
                    name="cnpj"
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                    {...cnpjMask}
                  />
                </GeneralGrid>

                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <div className="col-auto modal-signup-text my-4">
                      Informações de Endereço
                    </div>
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="CEP"
                      name="zip_code"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                      {...cepMask}
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Linha 1"
                      name="line_1"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Número/Complemento/Referência"
                      name="line_2"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>

                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={SelectLabel}
                      label="Estado"
                      name="state"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                      options={statesList}
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={SelectLabel}
                      label="Cidade"
                      name="city"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                      options={citiesList}
                    />
                  </GeneralGrid>
                </GeneralGrid>
              </GeneralGrid>

              <div className="row justify-content-start mb-3 mt-3">
                <div className="col">
                  <PrimaryButton
                    router={this.props.router}
                    type="submit"
                    color="primary"
                  >
                    Enviar
                  </PrimaryButton>
                </div>
              </div>
            </form>
          </div>
        </Card>
        <Dialog
          open={this.state.isDialogVisible}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">CNPJ inválido</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Verifique o CNPJ informado e tente novamente.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Content>
    );
  }
}

SignupCompanyForm = reduxForm({ form: 'SignupCompanyForm' })(SignupCompanyForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    signupCompany: state.signupCompany,
    formValues: getFormValues('SignupCompanyForm')(state),
    addresses: state.addresses,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      signupCompanyOnly,
      getCitiesList,
      getStatesList,
      getAdditionalData,
      changeField,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupCompanyForm);
