import React from 'react';

export function FullButton(props) {
  const { background, text, border, fontSize, classNameProp } = props;
  return (
    <button
      className={`${classNameProp}`}
      style={{
        background: background,
        color: text,
        border: border,
        fontSize: fontSize,
        width: '100%',
        borderRadius: 4,
      }}
      {...props}
    >
      {props.children}
    </button>
  );
}
