import Box from '@material-ui/core/Box';
import LoadingProgress from 'common/components/progress/loading';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getValidatedList,
  getList as getSubscriptionsList,
} from '../subscriptions/subscriptionsActions';
import { getPartnerData } from './dashboardOwnerActions';
import MaterialGrid from '@material-ui/core/Grid';
import { isMobile } from 'react-device-detect';
import UsersIcon from './users';
import ActiveUsers from './activeUsers';
import PropTypes from 'prop-types';
import Table from 'common/components/table/table-material';
import {
  ActionNameElement,
  ColumnsBelowSearch,
} from 'common/components/table/tableComponents';
import {
  getList as getDonationsList,
  remove,
} from '../donations/donationsActions';
import { getList as getSalesList } from '../sales/salesActions';
import { getOrdersWithId } from '../sales/productOrders/productOrdersActions';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined';
import LocalActivityOutlinedIcon from '@material-ui/icons/LocalActivityOutlined';

function Dashboard(props) {
  const { auth } = props;
  const [data, setData] = useState([]);
  const [setVisibleFirst] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    props.location.query.to ? props.location.query.to : 'sales'
  );

  useEffect(() => {
    if (!props.auth.user.briefingDone) {
      props.router.push('/owner/principal');
    }
    if (props.auth.user.establishments) {
      if (props.auth.user.establishments.length > 0) {
        props.getPartnerData(props.auth.user.establishments[0].id);
        props.getValidatedList(props.auth.user.establishments[0].id);
      }
    }

    if (props.location.query.first_time) {
      setVisibleFirst({ visibleFirst: true });
    }
  }, []);

  const defaultColumnsMobile = [
    {
      title: '',
      field: 'action.name',
      cellStyle: {
        padding: 0,
        whiteSpace: 'nowrap',
      },
      render: rowData => (
        <ActionNameElement
          singleColum={false}
          actionName={rowData['name']}
          image={rowData['image']}
        />
      ),
    },
    {
      field: 'actions',
      sorting: false,
      headerStyle: {
        fontWeight: 500,
        fontSize: 13,
        color: 'blue',
        whiteSpace: 'nowrap',
      },
      cellStyle: {
        whiteSpace: 'nowrap',
        border: 0,
        padding: 0,
      },
      title: '',
      render: rowData => {
        return (
          <ColumnsBelowSearch
            buttons={
              selectedOption === 'online'
                ? {
                    namedButtons: [],
                    iconButtons: [
                      {
                        name: 'options',
                        onClick: () => {},
                        menuIconOptions: [
                          {
                            label: 'Editar',
                            onClickMenuItem: () => {
                              // props.onClickAction(
                              //   rowData,
                              //   props.router,
                              //   'online'
                              // );
                            },
                          },
                          {
                            label: 'Encerrar',
                            onClickMenuItem: () => {
                              // props.suspendActivity(
                              //   rowData.id,
                              //   props.router,
                              //   true
                              // );
                            },
                          },
                        ],
                      },
                    ],
                  }
                : selectedOption === 'drafts'
                ? {
                    namedButtons: [],
                    iconButtons: [
                      {
                        name: 'delete',
                        onClick: () => {
                          // setOpenConfirmation(true);
                          // setItem(rowData.id);
                        },
                        color: '#E34848',
                      },
                    ],
                  }
                : selectedOption === 'scheduled'
                ? {
                    namedButtons: [],
                    iconButtons: [
                      {
                        name: 'options',
                        onClick: () => {},
                        menuIconOptions: [
                          // {
                          //   label: 'Prorrogar',
                          //   onClickMenuItem: () => {
                          //     console.log('Aqui Estoy');
                          //   },
                          // },
                          {
                            label: 'Editar',
                            onClickMenuItem: () => {
                              // props.onClickAction(
                              //   rowData,
                              //   props.router,
                              //   'scheduled'
                              // );
                            },
                          },
                          {
                            label: 'Encerrar',
                            onClickMenuItem: () => {
                              // props.suspendActivity(rowData.id, props.router);
                            },
                          },
                        ],
                      },
                    ],
                  }
                : selectedOption === 'closed'
                ? {
                    namedButtons: [],
                    iconButtons: [
                      {
                        name: 'delete',
                        onClick: () => {
                          // setOpenConfirmation(true);
                          // setItemHistory(rowData.id);
                        },
                        color: '#E34848',
                      },
                    ],
                  }
                : null
            }
          />
        );
      },
    },
  ];

  function renderFullPrice(item) {
    return (
      <div>
        R${' '}
        {parseFloat(item)
          .toFixed(2)
          .replace('.', ',')}
      </div>
    );
  }

  const vouchersColumns = [
    {
      title: 'Nome',
      field: 'user.name',
      editable: 'never',
      render: rowData => rowData.user.name,
    },
    {
      title: 'Telefone',
      field: 'user.tel',
      editable: 'never',
      render: rowData => rowData.user.tel,
    },
    {
      title: 'E-mail',
      field: 'user.email',
      editable: 'never',
      render: rowData => rowData.user.email,
    },
    {
      title: 'Deseja contato',
      render: rowData => (rowData.should_contact ? 'Sim' : 'Não'),
    },
    { title: 'Validado', field: 'validated' },
    { title: 'Atividade', field: 'presential_course.action.name' },
    { title: 'Data da inscrição', field: 'createdAt' },
  ];

  const salesColumns = [
    {
      field: 'purchase_id',
      title: 'ID',
      editable: 'never',
    },
    {
      field: 'gateway_charge.external_id',
      title: 'ID Pagar.me',
      editable: 'never',
      render: rowData => rowData.gateway_charge?.external_id,
    },
    {
      field: 'createdAt',
      title: 'Data',
      editable: 'never',
    },
    {
      field: 'user.name',
      title: 'Nome',
      editable: 'never',
      render: rowData => rowData.user?.name,
    },
    {
      field: 'user.cpf',
      title: 'CPF',
      editable: 'never',
      render: rowData => rowData.user?.cpf,
    },
    {
      field: 'fullAddresses',
      title: 'Endereços de Entrega',
      editable: 'never',
    },
    {
      title: 'Valor pago da carteira',
      field: 'wallet_value',
      render: rowData => {
        return renderFullPrice(rowData.wallet_value);
      },
      editable: 'never',
      export: true,
    },
    {
      title: 'Valor líquido da carteira',
      render: rowData => {
        if (rowData.status === 'Pagamento recusado') {
          return '0,00';
        }
        if (rowData.totalWalletNetValue) {
          return renderFullPrice(rowData.totalWalletNetValue);
        }
        return '0,00';
      },
      editable: 'never',
    },
    {
      title: 'Valor pago no cartão',
      field: 'total_credit_card_value',
      render: rowData => {
        return rowData.total_credit_card_value
          ? renderFullPrice(rowData.total_credit_card_value)
          : '';
      },
      editable: 'never',
    },
    {
      title: 'Valor líquido do cartão',
      render: rowData => {
        if (rowData.status === 'Pagamento recusado') {
          return '0,00';
        }
        if (rowData.totalCardNetValue) {
          return renderFullPrice(rowData.totalCardNetValue);
        }
        return '0,00';
      },
      editable: 'never',
    },
    {
      title: 'Valor Final',
      field: 'final_value',
      render: rowData => {
        return renderFullPrice(rowData.final_value);
      },
      editable: 'never',
    },
    {
      title: 'Status',
      field: 'status',
      editable: 'never',
    },
  ];

  const donationsColumns = [
    {
      title: 'Nome',
      field: 'user.name',
      editable: 'never',
      render: rowData => rowData.user?.name,
    },
    {
      title: 'CPF',
      field: 'user.cpf',
      editable: 'never',
      render: rowData => rowData.user?.cpf,
     },
    { title: 'Atividade', field: 'action.name' },
    {
      title: 'Valor',
      render: rowData => {
        return (
          'R$' +
          parseFloat(rowData.value)
            .toFixed(2)
            .replace('.', ',')
        );
      },
    },
    { title: 'Data da doação', field: 'createdAt', type: 'datetime' },
  ];

  let filteredDefaultColums;

  filteredDefaultColums = isMobile
    ? defaultColumnsMobile
    : selectedOption === 'sales'
    ? salesColumns
    : selectedOption === 'donations'
    ? donationsColumns
    : vouchersColumns;
  const [columns, setColumns] = useState(filteredDefaultColums);

  useEffect(() => {
    setColumns(filteredDefaultColums);
  }, [selectedOption]);

  const options = [
    { label: 'Vendas', value: 'sales' },
    { label: 'Doações', value: 'donations' },
    { label: 'Vouchers', value: 'vouchers' },
  ];

  const optionsMobile = [
    { label: 'Vendas', value: 'sales' },
    { label: 'Doações', value: 'donations' },
    { label: 'Vouchers', value: 'vouchers' },
  ];

  function handleDeliveryLocation(productOrder) {
    const {
      address,
      reference,
      zipcode,
      action,
      delivery_location,
    } = productOrder;
    if (action.digital_product) {
      return 'Produto digital';
    } else if (delivery_location.delivery) {
      return `${address}, ${reference}, ${zipcode}`;
    } else {
      const {
        zipcode: deliveryLocationZipcode,
        street,
        street_number,
      } = delivery_location;
      return `${street}, ${street_number}, ${deliveryLocationZipcode}`;
    }
  }

  useEffect(() => {
    if (selectedOption === 'sales') {
      const samePurchaseArray = [];
      const formattedCSVData = [
        [
          'ID',
          'ID Pagar.me',
          'Data',
          'Nome',
          'CPF',
          'Endereços de entrega',
          'Valor pago da carteira',
          'Valor pago no cartão',
          'Valor final',
        ],
      ];
      for (let i = 0; i < props.productOrders.list.length; i += 1) {
        const item = props.productOrders.list[i];
        const index = samePurchaseArray.findIndex(secondItem => {
          return secondItem.purchase_id === item.purchase_id;
        });
        const futureTransactionCard = item.future_transactions.find(
          item => item.gateway_or_wallet
        );
        const futureTransactionWallet = item.future_transactions.find(
          item => !item.gateway_or_wallet
        );
        let totalCardNetValue = 0;
        let totalWalletNetValue = 0;
        if (futureTransactionCard) {
          totalCardNetValue = parseFloat(futureTransactionCard.value);
        }
        if (futureTransactionWallet) {
          totalWalletNetValue = parseFloat(futureTransactionWallet.value);
        }
        if (index === -1) {
          samePurchaseArray.push({
            ...item,
            fullAddresses: handleDeliveryLocation(item),
            totalCardNetValue,
            totalWalletNetValue,
          });
          formattedCSVData.push([
            item.purchase_id,
            item.gateway_charge ? item.gateway_charge.external_id : '',
            item.createdAt,
            item.user?.name || '',
            item.user?.cpf || '',
            handleDeliveryLocation(item),
            item.wallet_value,
            item.total_credit_card_value,
            item.final_value,
          ]);
        } else {
          samePurchaseArray[index] = {
            ...samePurchaseArray[index],
            fullAddresses: `${
              samePurchaseArray[index].fullAddresses
            },${handleDeliveryLocation(item)}`,
            wallet_value: parseFloat(
              (
                parseFloat(samePurchaseArray[index].wallet_value) +
                parseFloat(item.wallet_value)
              ).toFixed(2)
            ),
            final_value: parseFloat(
              (
                parseFloat(samePurchaseArray[index].final_value) +
                parseFloat(item.final_value)
              ).toFixed(2)
            ),
            totalCardNetValue:
              samePurchaseArray[index].totalCardNetValue + totalCardNetValue,
            totalWalletNetValue:
              samePurchaseArray[index].totalWalletNetValue +
              totalWalletNetValue,
          };
          formattedCSVData.push([
            samePurchaseArray[index].purchase_id,
            samePurchaseArray[index].gateway_charge
              ? samePurchaseArray[index].gateway_charge?.external_id
              : '',
            samePurchaseArray[index].createdAt,
            samePurchaseArray[index].user?.name,
            samePurchaseArray[index].user?.cpf,
            `${samePurchaseArray[index].fullAddresses},${handleDeliveryLocation(
              item
            )}`,
            parseFloat(
              (
                parseFloat(samePurchaseArray[index].wallet_value) +
                parseFloat(item.wallet_value)
              ).toFixed(2)
            ),
            samePurchaseArray[index].total_credit_card_value,
            parseFloat(
              (
                parseFloat(samePurchaseArray[index].final_value) +
                parseFloat(item.final_value)
              ).toFixed(2)
            ),
          ]);
        }
      }

      setData(samePurchaseArray);
    }
    if (selectedOption === 'donations') {
      setData(props.donations.list.content);
    }
    if (selectedOption === 'vouchers') {
      setData(props.subscriptions.list.content);
    }
  }, [
    props.subscriptions.list,
    props.donations.list,
    props.productOrders.list,
  ]);

  useEffect(() => {
    if (selectedOption === 'sales') {
      props.getOrdersWithId(auth.user.establishments[0].id);
    }
    if (selectedOption === 'donations') {
      props.getDonationsList(auth.user.establishments[0].id);
    }
    if (selectedOption === 'vouchers') {
      props.getSubscriptionsList(auth.user.establishments[0].id);
    }
  }, [selectedOption]);

  if (props.dashboardOwner.dataLoading) {
    return (
      <Box
        display="flex"
        height="100vh"
        width="90vw"
        justifyContent="center"
        mt={7}
      >
        <LoadingProgress />
      </Box>
    );
  }

  function handleChangeOption(option) {
    setSelectedOption(option);
  }

  function CardContainer(props) {
    return (
      <div
        style={{
          width: isMobile ? 180 : 270,
          height: isMobile ? 167 : 194,
          background: 'white',
          borderRadius: 8,
          border: '1px solid lightgray',
          marginBottom: 20,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        {props.children}
      </div>
    );
  }

  function CardTitle(props) {
    return (
      <span
        style={{
          fontFamily: 'Lato !important',
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: isMobile ? '12px' : '16px',
          lineHeight: '160%',
          letterSpacing: '0.25px',
          color: '#000000',
        }}
      >
        {props.children}
      </span>
    );
  }

  function MainInfo(props) {
    return (
      <span
        style={{
          fontFamily: 'Lato !important',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: isMobile ? '32px' : '48px',
          lineHeight: '58px',
          letterSpacing: '0.25px',
          color: '#000000',
        }}
      >
        {props.children}
      </span>
    );
  }

  function AdditionalInfo(props) {
    return (
      <span
        style={{
          fontFamily: 'Lato !important',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: isMobile ? '12px' : '16px',
          lineHeight: '160%',
          letterSpacing: '0.25px',
          color: '#000000',
        }}
      >
        {props.children}
      </span>
    );
  }

  function ActionLink(props) {
    return (
      <span
        onClick={props.onClick}
        style={{
          fontFamily: 'Lato !important',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: isMobile ? '12px' : '16px',
          lineHeight: '160%',
          letterSpacing: '0.25px',
          color: '#5759FB',
          cursor: 'pointer',
        }}
      >
        {props.children}
      </span>
    );
  }

  function GeneralActivityCard(props) {
    const {
      title,
      mainInfo,
      additionalInfo,
      actionLink,
      actionLinkClick,
      about,
    } = props;
    return (
      <MaterialGrid item container xs={10} md={3}>
        <CardContainer>
          <div
            className="d-flex flex-row w-100 justify-content-between"
            style={{
              marginLeft: 24,
              marginRight: 24,
              marginBottom: 5,
              marginTop: 10,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CardTitle>{title}</CardTitle>
            {about === 'participantes' ? (
              <UsersIcon
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : about === 'atividadesOnline' ? (
              <LocalActivityOutlinedIcon
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : about === 'vendas' ? (
              <LocalAtmOutlinedIcon
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : about === 'usuariosAtivos' ? (
              <ActiveUsers
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : about === 'creditos' ? (
              <MonetizationOnOutlinedIcon
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : about === 'vouchers' ? (
              <ConfirmationNumberOutlinedIcon
                width={isMobile ? 17 : 25}
                height={isMobile ? 18 : 26}
                color="primary"
              />
            ) : null}
          </div>
          <div
            className="d-flex w-100 justify-content-center"
            style={{ marginBottom: 5 }}
          >
            <MainInfo>{mainInfo}</MainInfo>
          </div>
          <div
            className="d-flex w-100 justify-content-center"
            style={{ marginBottom: 5 }}
          >
            <AdditionalInfo>{additionalInfo}</AdditionalInfo>
          </div>
          <div
            className="d-flex w-100 justify-content-center"
            style={{ marginBottom: 5 }}
          >
            <ActionLink onClick={actionLinkClick}>{actionLink}</ActionLink>
          </div>
        </CardContainer>
      </MaterialGrid>
    );
  }

  return (
    <div>
      <GridContainer
        justify="center"
        style={{
          paddingTop: '2rem',
          maxWidth: '89vw',
        }}
      >
        <MaterialGrid
          container
          xs={12}
          spacing={2}
          style={{
            flexDirection: 'row',
            flexWrap: 'nowrap',
            overflowX: isMobile ? 'scroll' : null,
            marginBottom: 20,
            justifyContent: 'space-between',
            maxWidth: '90vw',
          }}
        >
          {/* Atividades online */}
          <GeneralActivityCard
            about={'atividadesOnline'}
            title={'Atividades online'}
            mainInfo={props.dashboardOwner.data.actions.length}
            additionalInfo={
              parseInt(props.dashboardOwner.data.actionsExpire7days) > 1
                ? `${props.dashboardOwner.data.actionsExpire7days} vencerão em 7 dias`
                : parseInt(props.dashboardOwner.data.actionsExpire7days) === 1
                ? `${props.dashboardOwner.data.actionsExpire7days} vencerá em 7 dias`
                : 'Nenhuma vencerá em 7 dias'
            }
            actionLink={'Ver atividades'}
            actionLinkClick={() =>
              props.router.push('/owner/acoes/minhas-atividades/?to=online')
            }
          />
          {/* Vendas */}
          <GeneralActivityCard
            about={'vendas'}
            title={'Vendas'}
            titleIcon=""
            mainInfo={props.dashboardOwner.data.establishmentSales}
            additionalInfo={`R$${props.dashboardOwner.data.finalValue} nos últimos 7 dias`}
            actionLink={'Ver pedidos'}
            actionLinkClick={() => props.router.push('/owner/sales')}
          />
          {/* Créditos */}
          <GeneralActivityCard
            about={'creditos'}
            title={'Créditos'}
            titleIcon=""
            mainInfo={(
              parseFloat(props.dashboardOwner.data.dec_points).toFixed(2) -
              parseFloat(
                props.dashboardOwner.data.dec_allocated_points
              ).toFixed(2)
            )
              .toFixed(2)
              .replace('.', ',')}
            additionalInfo={`
              Créditos alocados ${parseFloat(
                props.dashboardOwner.data.dec_allocated_points
              )
                .toFixed(2)
                .replace('.', ',')}
              `}
            actionLink={'Ver créditos'}
            actionLinkClick={() => props.router.push('/owner/points')}
          />
          {/* Vouchers */}
          <GeneralActivityCard
            about={'vouchers'}
            title={'Vouchers gerados'}
            mainInfo={
              props.subscriptions.list.content.filter(sub => sub.validated)
                .length
            }
            additionalInfo={`${props.subscriptions.list.content.length}
              ${
                props.subscriptions.list.content.length === 1
                  ? 'voucher utilizado'
                  : 'vouchers utilizados'
              }`}
            actionLink={'Ver vouchers'}
            actionLinkClick={() =>
              props.router.push('/owner/inscricoes/validados')
            }
          />
        </MaterialGrid>
        <MaterialGrid
          container
          style={{
            maxWidth: isMobile ? '89vw' : '89vw',
            marginTop: isMobile ? 0 : 15,
            marginBottom: isMobile ? 0 : 15,
          }}
        >
          {props.donations.loading ||
          props.subscriptions.loading ||
          props.productOrders.loading ? (
            <Box
              display="flex"
              height="100vh"
              width="90vw"
              justifyContent="center"
              mt={7}
            >
              <LoadingProgress />
            </Box>
          ) : (
            <Table
              searchPlaceholder={'Pesquisar por nome'}
              hasToolbar={true}
              defaultSelected={selectedOption}
              title="Últimas transações"
              columns={columns}
              rows={data}
              toolbarSearch
              onChangeOption={handleChangeOption}
              mobileTable={isMobile}
              mobileDropdown
              selectOptions={isMobile ? optionsMobile : options}
              hasBorder={!isMobile}
            />
          )}
        </MaterialGrid>
        {/* Cartões com as propostas feitas e aceitas */}
      </GridContainer>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    dashboardOwner: state.dashboardOwner,
    donations: state.donations,
    subscriptions: state.subscriptions,
    sales: state.sales,
    productOrders: state.productOrders,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getPartnerData,
      getValidatedList,
      getDonationsList,
      getSalesList,
      remove,
      getSubscriptionsList,
      getOrdersWithId,
    },
    dispatch
  );
};

Dashboard.propTypes = {
  auth: PropTypes.objectOf(PropTypes.any).isRequired,
  dashboardOwner: PropTypes.objectOf(PropTypes.any).isRequired,
  getPartnerData: PropTypes.func.isRequired,
  getValidatedList: PropTypes.func.isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  params: PropTypes.objectOf(PropTypes.any).isRequired,
  route: PropTypes.objectOf(PropTypes.any).isRequired,
  routeParams: PropTypes.objectOf(PropTypes.any).isRequired,
  router: PropTypes.objectOf(PropTypes.any).isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  subscriptions: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
