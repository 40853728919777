import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import types from './types';

const URL_DELETE_DRAFT = `${BASE_API}/draft-action`;
const URL_ACTION_PAGE = `${BASE_API}/actions-list-table`;
const URL_ACTION_TOTAL_PAGE = `${BASE_API}/actions/total`;


export const getStationActionsDrafts = id => {
  return dispatch => {
    dispatch({ type: 'DRAFT_ACTIONS_LOAD', payload: true });
    axios
      .get(
        `${URL_ACTION_PAGE}/?where[active]=0&&where[partner_id]=${id}&&where[is_draft]=1`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(response => {
        for (let i in response.data) {
          response.data[i]['createdAtDate'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:mm:ss');
          response.data[i]['startDate'] = moment(
            response.data[i].start
          ).format('DD/MM/YYYY HH:mm:ss');
          response.data[i]['endDate'] = moment(
            response.data[i].end
          ).format('DD/MM/YYYY HH:mm:ss');
        }
        dispatch([
          {
            type: 'DRAFT_ACTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getLazyStationActionsDrafts = (id, lazy) => {
  return (dispatch) => {
    console.log('lazy', lazy);
    dispatch({ type: 'DRAFT_ACTIONS_LOAD', payload: true });
    axios
      .get(
        `${URL_ACTION_PAGE}/?where[active]=0&&where[partner_id]=${id}&&where[is_draft]=1\
        &&where[limit]=${lazy.rows}&&where[offset]=${lazy.page}\
        &&where[order]=${lazy.sortField} ${lazy.sortOrder}\
        &&where[filters]=${JSON.stringify(lazy.filters)}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        for (let i in response.data) {
          response.data[i]['createdAtDate'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:mm:ss');
          response.data[i]['startDate'] = moment(response.data[i].start).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i]['endDate'] = moment(response.data[i].end).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        dispatch([
          {
            type: 'DRAFT_ACTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getTotalNumberActionsDrafts = (id, lazy) => {
  return (dispatch) => {
    dispatch({ type: 'DRAFT_ACTIONS_TOTAL_LOAD', payload: true });
    axios
      .get(
        `${URL_ACTION_TOTAL_PAGE}/?where[active]=0&&where[partner_id]=${id}&&where[is_draft]=1\
        &&where[filters]=${JSON.stringify(lazy.filters)}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: 'DRAFT_ACTIONS_TOTAL_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const onClickDraftAction = (action, router = undefined) => {
  return dispatch => {
    if (action.type_id === 7 || action.type_id === 8 || action.type_id === 6) {
      if (!_.isUndefined(router)) {
        router.push(
          `/owner/acoes/aquisicao-clientes/pesquisa/${action.id}/rascunho`
        );
      }
    }
    if (action.type_id === 4) {
      if (!_.isUndefined(router)) {
        router.push(`/owner/acoes/curso-presencial/${action.id}/rascunho`);
      }
    } else if (action.type_id === 14) {
      if (!_.isUndefined(router)) {
        router.push(`/owner/acoes/entregar-produto/${action.id}/rascunho`);
      }
    } else if (action.type_id === 3) {
      router.push(`/owner/acoes/cupons/${action.id}/rascunho`);
    } else if (action.type_id === 2) {
      router.push(`/owner/acoes/signup-earn/${action.id}/rascunho`);
    } else if (action.type_id === 1) {
      router.push(`/owner/acoes/member-get-member/${action.id}/rascunho`);
    } else if (action.type_id === 5) {
      router.push(`/owner/acoes/compartilhe/${action.id}/rascunho`);
    } else if (action.type_id === 16) {
      router.push(`/owner/acoes/conteudo-s-i/${action.id}/rascunho`);
    } else if (action.type_id === 17) {
      router.push(`/owner/acoes/atividade-doacao/${action.id}/rascunho`);
    } else if (action.type_id === 18) {
      router.push(`/owner/acoes/video-playlist/${action.id}/rascunho`);
    }
  };
};

export const onClickDraftShare = (action, router = undefined) => {
  return dispatch => {
    if (action.type_id === 7 || action.type_id === 8 || action.type_id === 6) {
      if (!_.isUndefined(router)) {
        router.push(
          `/owner/acoes/aquisicao-clientes/pesquisa/${action.id}/rascunho?share=true`
        );
      }
    }
    if (action.type_id === 4) {
      if (!_.isUndefined(router)) {
        router.push(
          `/owner/acoes/curso-presencial/${action.id}/rascunho?share=true`
        );
      }
    } else if (action.type_id === 14) {
      if (!_.isUndefined(router)) {
        router.push(
          `/owner/acoes/entregar-produto/${action.id}/rascunho?share=true`
        );
      }
    } else if (action.type_id === 3) {
      router.push(`/owner/acoes/cupons/${action.id}/rascunho?share=true`);
    } else if (action.type_id === 2) {
      router.push(`/owner/acoes/signup-earn/${action.id}/rascunho?share=true`);
    } else if (action.type_id === 1) {
      router.push(
        `/owner/acoes/member-get-member/${action.id}/rascunho?share=true`
      );
    } else if (action.type_id === 5) {
      router.push(`/owner/acoes/compartilhe/${action.id}/rascunho?share=true`);
    } else if (action.type_id === 16) {
      router.push(`/owner/acoes/conteudo-s-i/${action.id}/rascunho`);
    } else if (action.type_id === 17) {
      router.push(
        `/owner/acoes/atividade-doacao/${action.id}/rascunho?share=true`
      );
    } else if (action.type_id === 18) {
      router.push(
        `/owner/acoes/video-playlist/${action.id}/rascunho?share=true`
      );
    }
  };
};

export const deleteActionDraft = id => {
  return dispatch => {
    dispatch([
      { type: types.DELETE_TEMPLATE_BUTTON_LOAD, payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .delete(`${URL_DELETE_DRAFT}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          { type: types.DELETE_TEMPLATE_BUTTON_LOAD, payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
        ]);
      })
      .catch(error => {
        try {
          if (error.response.data.error) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${error.response.data.error}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro interno do servidor`,
              variant: 'error',
              open: true,
            },
          });
        }
      })
      .finally(() => {
        dispatch({ type: types.DELETE_TEMPLATE_BUTTON_LOAD, payload: false });
        window.location.reload();
      });
  };
};
