/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import { InputLabelOutlined } from 'common/components/fields/fields';
import { currencyMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { Field } from 'redux-form';
import ActivityButton from '../buttons/activityButton';
import { DomPurifyContentContainer } from './styles';

function ActivityDonation(props) {
  return (
    <Grid
      item
      container
      alignItems="center"
      xs={12}
    >
      {/* DOM PURIFY CONTENT */}
      <Grid
        item
        container
        alignItems="center"
        xs={12}
      >
        <DomPurifyContentContainer
          className={props.className}
          dangerouslySetInnerHTML={{
            __html: props.formValues.description,
          }}
        />
      </Grid>
      <Grid
        item
        container
        justify="center"
        xs={12}
      >
        <Field
          component={InputLabelOutlined}
          type="tel"
          label={'Quantidade em BRL'}
          name="points"
          required
          onChange={() => console.log('ok')}
          validate={[FORM_RULES.required]}
          margin="normal"
          {...currencyMask}
        />
      </Grid>
      <Grid
        item
        container
        justify="center"
        xs={12}
      >
        <ActivityButton color={'#2D95EF'} label={'Doar'} />
      </Grid>
    </Grid>
  );
}

export default ActivityDonation;
