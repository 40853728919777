import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';

const URL_ACTION_PAGE = `${BASE_API}/archived-actions`;
const URL_ACTION_TOTAL_PAGE = `${BASE_API}/actions/total`;

export const getStationHistory = id => {
  return dispatch => {
    dispatch({ type: 'ACTION_HISTORY_LOAD', payload: true });
    axios
      .get(`${URL_ACTION_PAGE}/?where[partner_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        for (let i in response.data) {
          response.data[i]['createdAtDate'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:mm:ss');
          response.data[i]['start'] = moment(response.data[i].start).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i]['end'] = moment(response.data[i].end).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        dispatch([
          {
            type: 'ACTION_HISTORY_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};


export const getLazyStationHistory = (id, lazy) => {
  return (dispatch) => {
    dispatch({ type: 'ACTION_HISTORY_LOAD', payload: true });
    axios
      .get(`${URL_ACTION_PAGE}/?where[partner_id]=${id}\
        &&where[limit]=${lazy.rows}&&where[offset]=${lazy.page}\
        &&where[order]=${lazy.sortField} ${lazy.sortOrder}\
        &&where[filters]=${JSON.stringify(lazy.filters)}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        for (let i in response.data) {
          response.data[i]['createdAtDate'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:mm:ss');
          response.data[i]['start'] = moment(response.data[i].start).format(
            'DD/MM/YYYY HH:mm:ss'
          );
          response.data[i]['end'] = moment(response.data[i].end).format(
            'DD/MM/YYYY HH:mm:ss'
          );
        }
        dispatch([
          {
            type: 'ACTION_HISTORY_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getTotalArchivedActions = (id, lazy) => {
  return (dispatch) => {
    dispatch({ type: 'ACTION_HISTORY_TOTAL_LOAD', payload: true });
    axios
      .get(
        `${URL_ACTION_TOTAL_PAGE}/?where[active]=0&&where[partner_id]=${id}&&where[is_draft]=0\
        &&where[station_id]=notnull&&where[filters]=${JSON.stringify(lazy.filters)}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: 'ACTION_HISTORY_TOTAL_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};