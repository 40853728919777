import Content from 'common/components/content/content';
import React, { Component } from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
// import { bindActionCreators } from "redux";
import { inviteContacts } from './inviteContactsActions';

class InviteContacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }
  componentWillMount() {}

  invite = () => {
    let values = {};
    values['establishment'] = this.props.auth.user.establishments[0].id;
    this.props.inviteContacts(values, this.props.router);
  };

  render() {
    return (
      <Content>
        <Card>
          <CardTitle title="Convidar seus contatos para a plataforma" />
          <CardText>
            <div className="container-fluid">
              <center>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={() => this.invite()}
                >
                  Convidar meus contatos
                </button>
              </center>
            </div>
          </CardText>
        </Card>
      </Content>
    );
  }
}

InviteContacts = reduxForm({ form: 'InviteContactsForm' })(InviteContacts);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    formValues: getFormValues('InviteContactsForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ inviteContacts }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(InviteContacts);
