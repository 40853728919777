import styled from "styled-components";

export const ActivityImageContainer = styled.div `
  background-image: ${(props) =>
    `url( ${props.imagePath} )`
  };
  width: 100%;
  max-width: 100%;
  min-height: 200;
  padding: 0 0 56.25% 0;
  border-radius: 0;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  margin: 1rem 0;
  border-radius: ${(props) =>
    props.xs
    ? null
    : '8px'
  };
`;

export const DomPurifyContentContainer = styled.div `
  width: 100%;
  line-height: 2rem;
  font-size: 1.15rem;
  text-align: none !important;
  margin: .3rem 0;

  > p {
    font-size: 1rem;
  }

  > ul, ol {
    padding-left: 3rem;
    font-size: 1rem;
  }
`;
