import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';

const URL_QUIZZES = `${BASE_API}/quizzes/establishment`;
const URL_ACTIONS_POST = `${BASE_API}/actions`;
const URL_ACTIONS_POST_FILE = `${BASE_API}/actions/file`;

export const getQuizzes = id => {
  return dispatch => {
    dispatch([
      {
        type: 'QUIZZES_LOAD',
        payload: true,
      },
    ]);

    axios
      .get(`${URL_QUIZZES}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'QUIZZES_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const postAction = (values, router = undefined) => {
  return dispatch => {
    values['type'] = 2;

    if (values.contentType === 'file') {
      values['fileName'] = values.file.name;
      values['fileType'] = values.file.type;

      var reader = new FileReader();
      reader.readAsDataURL(values.file);
      reader.onload = function() {
        values['file'] = reader.result;
        axios
          .post(`${URL_ACTIONS_POST_FILE}`, values, {
            headers: {
              Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
            },
          })
          .then(response => {
            if (!_.isUndefined(router)) {
              router.push('/owner');
            }
          });
      };
      reader.onerror = function(error) {
        console.log('Error: ', error);
      };
    } else {
      axios
        .post(`${URL_ACTIONS_POST}`, values, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then(response => {
          if (!_.isUndefined(router)) {
            router.push('/owner');
          }
        });
    }
  };
};
