import generalReducer from './generalReducer';
import actionOffersReducer from './pages/actions/actionOffers/actionOffersReducer';
import actionReducers from './pages/actions/actionReducers';
import actionsDraftsReducer from './pages/actions/actionsDrafts/actionsDraftsReducer';
import actionsHistoryReducer from './pages/actions/actionsHistory/actionsHistoryReducer';
import activeActionsReducer from './pages/actions/activeActions/activeActionsReducer';
import couponsReducer from './pages/actions/coupons/couponsReducer';
import cupomReducers from './pages/actions/cupom/cupomReducers';
import cupomOffersReducer from './pages/actions/cupomOffers/cupomOffersReducer';
import engajeReadingReducer from './pages/actions/engajeReading/engajeReadingReducer';
import inviteContactsReducer from './pages/actions/inviteContacts/inviteContactsReducer';
import presentialCourseReducers from './pages/actions/presentialCourse/presentialCoursesReducers';
import contactsSurveysReducer from './pages/actions/research/researchReducers';
import categoriesReducer from './pages/categories/categoriesReducer';
import contactsReducers from './pages/contacts/contactsReducers';
import dashboardOwnerReducer from './pages/dashboard/dashboardOwnerReducer';
import lottiesReducers from './pages/lotties/lottiesReducers';
import ownerUsersReducers from './pages/ownerUsers/ownerUsersReducers';
import partnerCuponsReducer from './pages/partnerCupons/partnerCuponsReducer';
import statementBuyPointsReducers from './pages/points/pages/statementBuyPoints/statementBuyPointsReducers';
import transferRequestsReducers from './pages/points/pages/transferRequests/transferRequestsReducers';
import userEarnedPointsReducers from './pages/points/pages/userEarnedPoints/userEarnedPointsReducers';
import pointsReducers from './pages/points/pointsReducers';
import principalReducer from './pages/principal/principalReducer';
import questionsReducer from './pages/questions/questionsReducer';
import sectionsReducer from './pages/sections/sectionsReducer';
import signupCompanyReducer from './pages/signupCompany/signupCompanyReducer';
import subscriptionsReducer from './pages/subscriptions/subscriptionsReducer';
import validationsReducer from './pages/validations/validationsReducer';
import websiteReducers from './pages/website/websiteReducers';
import surveysReducer from './surveys/surveysReducer';
import validationsCupomReducer from './pages/validationsCupom/validationsCupomReducer';
import productDeliveryReducers from './pages/actions/productDelivery/productDeliveryReducers';
import productOrdersReducer from './pages/sales/productOrders/productOrdersReducer';
import salesReducer from './pages/sales/salesReducer';
import addressesReducer from './pages/addresses/addressesReducer';
import donationsReducer from './pages/donations/donationsReducer';
import donationActionReducers from './pages/actions/donationAction/donationActionReducers';
import profileReducer from './pages/profile/profileReducer';
import actionOfferMessagesReducer from './pages/actions/actionOfferMessages/actionOfferMessagesReducer';
import rawContentReducer from './pages/actions/rawContent/rawContentReducer';
import videoPlaylistReducer from './pages/actions/videoPlaylist/videoPlaylistReducer';
import validatorsReducer from './pages/validators/validatorsReducer';

export default {
  profile: profileReducer,
  dashboardOwner: dashboardOwnerReducer,
  ownerUsers: ownerUsersReducers,
  points: pointsReducers,
  statementBuyPoints: statementBuyPointsReducers,
  userEarnedPoints: userEarnedPointsReducers,
  contacts: contactsReducers,
  engajeReading: engajeReadingReducer,
  inviteContacts: inviteContactsReducer,
  sections: sectionsReducer,
  questions: questionsReducer,
  website: websiteReducers,
  coupons: couponsReducer,
  contactsSurveys: contactsSurveysReducer,
  surveys: surveysReducer,
  principal: principalReducer,
  signupCompany: signupCompanyReducer,
  cupomOffer: cupomOffersReducer,
  general: generalReducer,
  presentialCourses: presentialCourseReducers,
  validations: validationsReducer,
  subscriptions: subscriptionsReducer,
  cupom: cupomReducers,
  categories: categoriesReducer,
  actionOffers: actionOffersReducer,
  activeActions: activeActionsReducer,
  actionsDrafts: actionsDraftsReducer,
  actionsHistory: actionsHistoryReducer,
  actions: actionReducers,
  lotties: lottiesReducers,
  research: contactsSurveysReducer,
  partnerCupons: partnerCuponsReducer,
  transferRequests: transferRequestsReducers,
  validationsCupom: validationsCupomReducer,
  productDelivery: productDeliveryReducers,
  productOrders: productOrdersReducer,
  sales: salesReducer,
  addresses: addressesReducer,
  donations: donationsReducer,
  donationAction: donationActionReducers,
  actionOfferMessages: actionOfferMessagesReducer,
  rawContent: rawContentReducer,
  videoPlaylist: videoPlaylistReducer,
  validatorCode: validatorsReducer
};
