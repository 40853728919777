import { io } from 'socket.io-client';

const socket = io(
  process.env.REACT_APP_API_KEY === 'production'
    ? 'https://apihub.rilato.app'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'http://localhost:8080'
    : 'http://localhost:8080',
  {
    auth: {
      token: 'd81f696d067acabe3bcaac790ebf81be',
    },
    autoConnect: false,
  }
);

function connect({ establishment_id }) {
  socket.io.opts.query = { establishment_id };
  console.log('tentou conectar', socket.io.opts.query);
  socket.connect();
}

function disconnect() {
  if (socket.connected) {
    socket.disconnect();
  }
}

export { connect, disconnect, socket };
