/* eslint-disable no-useless-constructor */
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialGrid from '@material-ui/core/Grid';
import React from 'react';
import Activities from '../activities/activity';
import GeneralTabs from '../navigation/actionsTab/tabsActivity';
import Loading from '../progress/loading';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DefaultCard from '../cards/DefaultCard';
import { isMobile } from 'react-device-detect';

class DialogAction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleClose = () => {
    this.props.onClose();
  };

  renderActivities() {
    return <Activities formValues={this.props.form} />;
  }

  renderCard() {
    return (
      <DefaultCard
        actionName={this.props.form.name}
        imgCloudSource={this.props.form.image}
        labelClick={this.props.form.click_me_label || 'Participe'}
      />
    );
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.visible}
          keepMounted={true}
          PaperProps={{
            style: { minHeight: this.props.height || null },
          }}
          onClose={this.props.onClose}
          fullWidth={true}
          maxWidth={'xl'}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullScreen={isMobile ? true : false}
        >
          <DialogContent
            fullWidth={true}
            style={{ overflow: 'hidden', padding: 0 }}
          >
            <div
              style={{
                background: '#323232',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  paddingLeft: 30,
                  paddingTop: isMobile ? 10 : 20,
                  paddingBottom: isMobile ? 10 : 20,
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: isMobile ? '20px' : '32px',
                  lineHeight: isMobile ? '20px' : '38px',
                  letterSpacing: '0.25px',
                  color: '#FFFFFF',
                }}
              >
                {'Gerenciador de atividades'}
              </span>
              <IconButton
                onClick={() => {
                  this.handleClose();
                }}
              >
                <CloseIcon
                  style={{ fontSize: 30, color: '#FFFFFF', marginRight: 20 }}
                />
              </IconButton>
            </div>
            <div
              ref={node => {
                this.dialogContent = node;
              }}
            >
              <DialogContent
                style={{
                  background: 'white',
                  padding: 0,
                  overflow: 'hidden',
                }}
              >
                {/*
                  Presentation
                */}
                <MaterialGrid container xs={12} justify="space-between">
                  <MaterialGrid
                    style={{
                      padding: 0,
                      background: '#ffffff',
                    }}
                    item
                    xs={12}
                    md={5}
                  >
                    {this.props.children}
                  </MaterialGrid>
                  {/*
                    Preveiw
                  */}
                  <MaterialGrid
                    item
                    xs={7}
                    style={{
                      borderLeft: '3px solid #f7f7f7',
                      backgroundColor: 'white',
                      display: isMobile ? 'none' : null,
                    }}
                  >
                    {this.props.form ? (
                      <>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                          style={{
                            padding: '25px',
                            paddingLeft: '20px',
                          }}
                        >
                          <Typography
                            style={{
                              fontFamily: 'Roboto',
                              fontStyle: 'normal',
                              fontWeight: 'bold',
                              fontSize: '24px',
                              lineHeight: '29px',
                              letterSpacing: '0.25px',
                              color: '#000000',
                              paddingLeft: 20,
                            }}
                          >
                            Preview
                          </Typography>
                          <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            width="100%"
                            style={{
                              paddingRight: 10,
                              height: 35,
                            }}
                          >
                            {this.props.activeActions.buttonLoading ? (
                              <CircularProgress size={30} />
                            ) : null}
                          </Box>
                        </Box>
                        <GeneralTabs
                          formValues={this.props.form}
                          typeAction={this.props.form.type_action_id}
                          activity={this.renderActivities()}
                          card={this.renderCard()}
                          step={this.props.value}
                        />
                      </>
                    ) : (
                      <Loading />
                    )}
                  </MaterialGrid>
                </MaterialGrid>
              </DialogContent>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state, { form }) => {
  return {
    activeActions: state.activeActions,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogAction);
