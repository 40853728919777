import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { initialize } from 'redux-form';

const URL_USERS = `${BASE_API}/establishment-users/users/all`;

export const getUsersByEstablishmentId = id => {
  return dispatch => {
    dispatch([
      {
        type: 'OWNER_USERS_LOAD',
        payload: true,
      },
    ]);

    axios
      .get(`${URL_USERS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        for (let i in response.data) {
          response.data[i]['createdAt'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY');
        }
        dispatch([
          {
            type: 'OWNER_USERS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const remove = (id, router = undefined) => {
  return dispatch => {
    axios
      .delete(`${BASE_API}/quiz/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        toastr.success('Quiz deletado com sucesso');
        if (!_.isUndefined(router)) {
          router.push('/owner/manage-quiz/manage');
        }
        dispatch([getUsersByEstablishmentId()]);
      })
      .catch(e => {
        toastr.error('Erro interno no sistema');
      });
  };
};

export const initializeForm = values => {
  return initialize('quizForm', values);
};

export const getDetail = id => {
  return dispatch => {
    axios
      .get(`${BASE_API}/quiz/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const initialValues = {
          ...response.data[0],
          questions: JSON.parse(response.data[0].questions),
        };
        dispatch([initializeForm(initialValues)]);
      });
  };
};

export const setDetail = values => {
  return dispatch => {
    const initialValues = {
      ...values,
      questions: JSON.parse(values.questions),
    };
    dispatch([initializeForm(initialValues)]);
  };
};
