import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardActionInfo from '../../../../../common/components/cards/cardActionInfo/cardActionInfo';
import {
  getStationActiveActions,
  onClickActionResults,
  onClickActionShare,
  onClickAction,
} from './activeActionsScripts';

function ActiveActions(props) {
  const { auth } = props;
  const content = props.activeActions.list;

  useEffect(() => {
    props.getStationActiveActions(auth.user.establishments[0].id);
  }, []);

  function renderEmptyScreen() {
    return (
      <section id="activeActions" style={{ marginTop: '4.3em' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui Atividades Online
                  </h2>
                  <p className="">
                    Atividades são campanhas que você poderá distribuir para
                    seus associados.
                  </p>

                  <PrimaryButton
                    onClick={() => props.router.push('/owner/acoes')}
                    color="primary"
                  >
                    Criar Atividade
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração Cupons"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.activeActions.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (content.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <section style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item sm={12} style={{ padding: '0 2em' }}>
          <GeneralGrid
            container
            spacing={3}
            justify={'space-between'}
            style={{ padding: '2.6em 0 2.6em 0' }}
          >
            <GeneralGrid
              item
              sm={8}
              md={6}
              lg={4}
              style={{ padding: '12px 12px 12px 5px' }}
            >
              <Box
                height="100%"
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: 24,
                    textAlign: 'left',
                    textJustify: 'center',
                    backgroundColor: '#f7f7f7',
                  }}
                >
                  Estas são as atividades online em sua estação.
                </Typography>
              </Box>
            </GeneralGrid>
            <GeneralGrid
              item
              sm={4}
              md={6}
              lg={8}
              style={{ padding: '12px 0 12px 12px' }}
            >
              <Box
                height="100%"
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
              >
                <PrimaryButton
                  style={{
                    height: '4.3em',
                    width: '14em',
                    margin: '8px 0 8px 8px',
                  }}
                  onClick={() => props.router.push('/owner/acoes')}
                >
                  NOVA ATIVIDADE
                </PrimaryButton>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          {content
            .slice(0)
            .reverse()
            .map((item, index) => (
              <CardActionInfo
                index={item.id}
                router={props.router}
                data={item}
                onClickEdit={() => props.onClickAction(item, props.router)}
                summary={item.summary}
                condition={moment(new Date()).isBetween(item.start, item.end)}
                onClickResults={() =>
                  props.onClickActionResults(item, props.router)
                }
              />
            ))}
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    activeActions: state.activeActions,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStationActiveActions,
      onClickActionResults,
      onClickActionShare,
      onClickAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveActions);
