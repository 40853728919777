import MomentUtils from '@date-io/moment';
import {
  Box,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Switch,
  Typography,
  ListItemText,
  Backdrop,
  CircularProgress,
  Tooltip,
  Icon,
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
//
import Select from '@material-ui/core/Select';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import clsx from 'clsx';
//WYSIWYG
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'icheck/skins/all.css';
import _ from 'lodash';
import moment from 'moment';
import { Calendar } from 'primereact/calendar';
import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Editor } from 'react-draft-wysiwyg';
import Dropzone from 'react-dropzone';
import { FontIcon } from 'react-md';
import { SelectList } from 'react-widgets';
import { appColors } from 'styles/colors.jsx';
import { stylesheets } from 'styles/stylesheets';
import Grid from '../layout/grid/grid';
import Row from '../layout/grid/row';
import IF from '../operador/if';
import './fields.css';
import LottieControl from 'common/components/lottie/lottie';
import ReactCrop from 'react-image-crop';
import { BASE_S3 } from 'config/consts';
import { uploadImageToS3 } from 'modules/owner/pages/actions/actionsFile';
import InputImage from './inputImage/index';

export class InputAuth extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <IF test={!this.props.hide}>
        <Grid cols={this.props.cols} style={this.props.style}>
          <div className={`form-group ${touched && error && 'has-error'}`}>
            <TextField
              {...this.props.input}
              id={this.props.id}
              label={this.props.label}
              type={this.props.type}
              lineDirection="center"
              name={this.props.name}
              floating={true}
              className={this.props.className}
            />
            {touched && error && <span className="help-block">{error}</span>}
          </div>
        </Grid>
      </IF>
    );
  }
}

export class DatePickerComponent extends React.Component {
  constructor(props) {
    super(props);
    let dates = new Date(this.props.disabledDates);
    this.state = {
      invalidDates: [dates],
      date: null,
    };
  }

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    const pt = {
      firstDayOfWeek: 1,
      dayNames: [
        'domingo',
        'segunda-feira',
        'terça-feira',
        'quarta-feira',
        'quinta-feira',
        'sexta-feira',
        'sábado',
      ],
      dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
      dayNamesMin: ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'],
      monthNames: [
        'janeiro',
        'fevereiro',
        'março',
        'abril',
        'maio',
        'junho',
        'julho',
        'agosto',
        'setembro',
        'outubro',
        'novembro',
        'dezembro',
      ],
      monthNamesShort: [
        'jan',
        'fev',
        'mar',
        'abr',
        'mai',
        'jun',
        'jul',
        'ago',
        'set',
        'out',
        'nov',
        'dez',
      ],
    };
    return (
      <Grid cols={this.props.cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">{this.props.label}</label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <div className={`p-col-12 p-md-12`}>
            <Calendar
              inputStyle={{
                border: `1px solid ${appColors.field.primary} `,
                fontFamily: 'Roboto',
                fontSize: 16,
                borderRadius: 0,
                fontWeight: 400,
                padding: '7px 10px',
              }}
              value={this.state.date}
              locale={pt}
              showIcon={true}
              onChange={e => {
                this.props.input.onChange(
                  moment(e.value).format('YYYY-MM-DD HH:mm:ss')
                );
                this.setState({ date: e.value });
              }}
              disabledDates={this.props.disabledDates}
              touchUI={true}
              disabledDays={[0]}
              disabled={this.props.readOnly}
              onSelect={this.props.onSelect}
              dateFormat="dd/mm/yy"
              readonlyInput={true}
              monthNavigator={true}
              yearNavigator={true}
              yearRange={
                moment().format('YYYY') +
                ':' +
                moment()
                  .add(2, 'year')
                  .format('YYYY')
              }
            />
          </div>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export class InputLabelNatural extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <div className="md-text-field-container md-full-width md-text-field-container--input md-cell md-cell--bottom">
            <input
              {...this.props.input}
              id={this.props.id}
              className={this.props.className}
              placeholder={this.props.placeholder}
              readOnly={this.props.readOnly}
              type={this.props.type}
              name={this.props.name}
            />
            <hr class="md-divider md-divider--text-field md-divider--expand-from-center" />
          </div>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export class InputLabel2 extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    const {
      cols,
      id,
      label,
      placeholder,
      type,
      name,
      style,
      className,
    } = this.props;
    return (
      <Grid cols={cols}>
        <div class={`form-group ${touched && error && 'has-error'}`}>
          <label for={id}>{label}</label>
          <input
            className={`form-control ${className} ${touched &&
              error &&
              'is-invalid'}`}
            aria-describedby="emailHelp"
            {...this.props.input}
            id={this.props.id}
            placeholder={placeholder}
            readOnly={this.props.readOnly}
            type={type}
            name={name}
            style={style}
          />
          {touched && error && <div className="invalid-feedback">{error}</div>}
        </div>
      </Grid>
    );
  }
}

export class InputLabelControl extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}

          <input
            {...this.props.input}
            id={this.props.id}
            className="form-control"
            placeholder={this.props.placeholder}
            readOnly={this.props.readOnly}
            type={this.props.type}
            name={this.props.name}
          />
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export class TextLabel extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <textarea
            {...this.props.input}
            className="form-control"
            rows={this.props.rows}
            readOnly={this.props.readOnly}
            placeholder={this.props.placeholder}
            style={this.props.style}
          />
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

const useStylesSelectLabel = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
  inputLabel: {
    color: appColors.primary,
  },
  formControl: {
    width: '100%',
    marginTop: 16,
    marginBottom: 8,
    minWidth: 120,
  },
  selectEmpty: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export const InputImageWithProcessing = props => {
  return <InputImage {...props} />;
};

export function SelectLabel(props) {
  const classes = useStylesSelectLabel();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const {
    meta: { touched, error },
    required,
    helperText,
    options,
  } = props;

  function renderOptions() {
    return options.map((option, index) => (
      <MenuItem key={index} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  }

  return (
    <div className={classes.root}>
      <FormControl
        variant="outlined"
        color="primary"
        style={{ width: '100%' }}
        className={classes.formControl}
        error={touched && error ? true : false}
        required={required}
      >
        <InputLabel
          ref={inputLabel}
          className={classes.inputLabel}
          htmlFor="select-label"
        >
          {props.label}
        </InputLabel>
        <Select
          disabled={props.disabled}
          {...props.input}
          input={
            <OutlinedInput
              name={props.name}
              labelWidth={labelWidth}
              id="select-label"
            />
          }
        >
          {renderOptions()}
        </Select>
        <FormHelperText>{(touched && error) || helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}

export function SelectMultiple(props) {
  const classes = useStylesSelectLabel();

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const [option, setOption] = React.useState([]);
  function handleChange(event) {
    setOption(event.target.value);
  }

  useEffect(() => {
    if (props.input.value.length > 0) {
      setOption(props.input.value);
    }
  }, [props.input.value]);

  const {
    meta: { touched, error },
  } = props;

  return (
    <div className={classes.root}>
      <FormControl
        aria-label="Tooltip do Formulário"
        variant="outlined"
        color="primary"
        className={classes.formControl}
        disabled={props.disabled}
        required={props.required}
      >
        <InputLabel
          ref={inputLabel}
          className={classes.inputLabel}
          htmlFor="age-customized-select"
        >
          {props.label}
        </InputLabel>
        <Select
          {...props.input}
          margin={props.margin}
          error={touched && error ? true : false}
          value={option}
          onChange={handleChange}
          multiple
          renderValue={(selected, a) => {
            const selectedLabelsList = props.options
              .filter(i => selected.includes(i.value))
              .map(i => i.label);
            return selectedLabelsList.join(', ');
          }}
          input={
            <OutlinedInput
              name={props.name}
              labelWidth={labelWidth}
              id="age-customized-select"
            />
          }
        >
          <MenuItem disabled value="">
            <em>Selecione uma ou mais opções. Clique fora para fechar.</em>
          </MenuItem>

          {props.options.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              <Checkbox color="primary" checked={option.includes(item.value)} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
        {touched && error ? (
          <FormHelperText style={{ color: '#FF5A52' }}>
            {touched && error}
          </FormHelperText>
        ) : (
          <FormHelperText>
            Você pode selecionar mais de uma opção
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
}

export class SelectLabel2 extends React.PureComponent {
  renderOptions() {
    const { options } = this.props;

    return options.map((option, index) => (
      <option key={index} value={option.value}>
        {option.label}
      </option>
    ));
  }

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          <select
            {...this.props.input}
            name={this.props.name}
            className="form-control"
            placeholder={this.props.placeholder}
            disabled={this.props.readOnly}
            style={this.props.style}
          >
            {this.renderOptions()}
          </select>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

const CssTextField = withStyles({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
      fontSize: 16,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 19.5,
      paddingBottom: 19.5,
    },
    '& .MuiOutlinedInput-multiline': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
})(TextField);

const CssDateTimeField = withStyles({
  root: {
    flexGrow: 1,
    '& label': {
      color: appColors.field.normal,
    },
    '& label.Mui-focused': {
      color: appColors.field.primary,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: appColors.field.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: appColors.field.normal,
      },
      '&:hover fieldset': {
        borderColor: appColors.field.hover,
      },
      '&.Mui-focused fieldset': {
        borderColor: appColors.field.primary,
      },
    },
  },
})(DateTimePicker);

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export function InputLabelOutlined(props) {
  const [selectedDate, setSelectedDate] = React.useState(
    props.input.value || null
  );
  const classes = useStyles();
  function handleDateChange(date) {
    setSelectedDate(date);
    props.input.onChange(date);
  }

  // Inicializa o input com a data atual

  // useEffect(() => {
  //   setSelectedDate(props.input.value);
  // }, [props.input.value]);

  const {
    meta: { touched, error },
    input,
    className,
    required,
    label,
    type,
    multiline,
    rowsMax,
    rows,
    margin,
    helperText,
  } = props;

  if (type === 'datetime-local') {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <div className={classes.container}>
          <CssDateTimeField
            error={touched && error ? true : false}
            label={label}
            InputLabelProps={{
              shrink: true,
            }}
            value={selectedDate}
            onChange={handleDateChange}
            disabled={props.disabled}
            inputVariant="outlined"
            disablePast
            format={'DD/MM/YYYY HH:mm'}
            ampm={false}
            autoOk
            required={required}
            helperText={(touched && error) || helperText}
            invalidDateMessage="Inválida"
            minDateMessage="Não é possível selecionar data antes de hoje"
            margin={margin}
          />
        </div>
      </MuiPickersUtilsProvider>
    );
  }
  return (
    <div className={classes.container}>
      <CssTextField
        error={touched && error ? true : false}
        label={label}
        className={clsx(className)}
        variant="outlined"
        disabled={props.disabled}
        helperText={(touched && error) || helperText}
        type={type}
        multiline={multiline}
        InputLabelProps={props.inputlabelprops}
        rowsMax={rowsMax}
        rows={rows}
        {...input}
        margin={margin}
      />
    </div>
  );
}

export function InputAnyFile(props) {
  const [files, setFiles] = useState(null);
  const theme = useTheme();

  function renderAccepted(acceptedFiles) {
    setFiles(acceptedFiles[0]);
    props.input.onChange(acceptedFiles[0]);
  }
  const {
    meta: { touched, error },
  } = props;
  return (
    <div>
      <Dropzone onDrop={acceptedFiles => renderAccepted(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <div
            style={{
              height: 'auto',
              maxWidth: '100%',
              borderWidth: 2,
              borderColor:
                touched && error ? appColors.error : appColors.dark.level3,
              borderStyle: 'dashed',
              borderRadius: 5,
              margin: 'auto',
              cursor: 'pointer',
              backgroundColor: '#F7F7F7',
            }}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="row h-100 justify-content-center">
              <div className="col-auto text-center align-self-center">
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      borderRadius: '50%',
                      backgroundColor: `${appColors.light.level1}`,
                      height: 70,
                      width: 70,
                    }}
                  >
                    <CloudUploadIcon
                      style={{
                        fontSize: theme.spacing(4),
                        color:
                          touched && error
                            ? appColors.error
                            : 'rgb(102, 102, 102)',
                      }}
                      className=""
                    />
                  </Box>

                  <Typography
                    variant="caption"
                    style={{
                      color:
                        touched && error
                          ? appColors.error
                          : 'rgb(102, 102, 102)',
                    }}
                  >
                    {files !== null ? files.name : props.placeholder}
                  </Typography>
                </Box>
              </div>
            </div>
          </div>
        )}
      </Dropzone>

      {touched && error && (
        <Typography variant="caption" style={{ color: appColors.error }}>
          {error}
        </Typography>
      )}
    </div>
  );
}

export class CheckboxWithoutLabel extends React.PureComponent {
  render() {
    const {
      option,
      input,
      labelStyle,
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          <Row style={{ marginLeft: 0 }}>
            <Checkbox
              checkboxClass="icheckbox_square-green"
              name={input.name}
              value={option.value}
              cursor={false}
              checked={input.value}
              onChange={event => input.onChange(!event.target.checked)}
            />
            &nbsp;&nbsp;
            <label htmlFor={option.label} style={labelStyle}>
              {option.label}
            </label>
          </Row>
          <Row style={{ marginLeft: 0 }}>
            {touched && error && <span className="help-block">{error}</span>}
          </Row>
        </div>
      </Grid>
    );
  }
}

export class CustomSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detail: false,
      fuelChoosen: this.props.initialValue,
    };
  }

  showDetail = () => {
    this.setState({ detail: true });
  };

  hideDetail = () => {
    this.setState({ detail: false });
  };

  selectOption = id => {
    this.setState({ fuelChoosen: id });
    this.props.input.onChange(this.state.fuelChoosen);
  };

  renderOptions = () => {
    const { fuelChoosen } = this.state;
    const { options } = this.props;
    return options.map((item, index) => (
      <div
        style={{
          height: '2em',
          borderRadius: 20,
          background: item.value === fuelChoosen ? '#10b0ae' : '#e5e8ea',
          margin: 10,
          color: item.value === fuelChoosen ? '#ffffff' : 'black',
          display: 'flex',
          textTransform: 'uppercase',
          alignItems: 'center',
        }}
        key={index}
        onClick={() => this.selectOption(item.value)}
      >
        <Grid cols="12 12 12 12">{item.label}</Grid>
      </div>
    ));
  };

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          <div
            style={{
              height: '2em',
              borderRadius: 20,
              background: '#e5e8ea',
              margin: 10,
              display: 'flex',
              ...stylesheets.flexBoxAlignCenter,
            }}
          >
            <Grid cols="8 8 8 8">
              <span
                style={{
                  fontSize: '1em',
                  color: '#acaeaf',
                  textTransform: 'uppercase',
                }}
              >
                SELECIONE
              </span>
            </Grid>
            <Grid
              cols="4 4 4 4"
              style={{
                paddingRight: 0,
              }}
            >
              <FontIcon
                onClick={() => this.showDetail()}
                className="history-icon-arrow"
                style={{
                  position: 'relative',
                  cssFloat: 'right',
                  fontSize: '2em',
                  display: !this.state.detail ? 'block' : 'none',
                }}
              >
                arrow_drop_down_circle
              </FontIcon>
              <FontIcon
                onClick={() => this.hideDetail()}
                className="history-icon-arrow"
                style={{
                  position: 'relative',
                  cssFloat: 'right',
                  fontSize: '2em',
                  display: this.state.detail ? 'block' : 'none',
                  transform: 'rotate(180deg)',
                }}
              >
                arrow_drop_down_circle
              </FontIcon>
            </Grid>
          </div>
          <div
            style={{
              display: this.state.detail ? 'block' : 'none',
            }}
          >
            {this.renderOptions()}
          </div>
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export function InputFile(props) {
  let {
    input,
    label,
    meta: { touched, error },
  } = props;

  const [lottieFile, setLottie] = useState(undefined);
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [imageRef, setImageRef] = useState({});
  const [croppedImgBase64] = useState(undefined);
  const theme = useTheme();

  function onDrop(imageFiles) {
    let {
      input: { onChange, onBlur },
    } = props;

    if (props.lottie) {
      let newReader = new FileReader();

      newReader.readAsBinaryString(imageFiles[0]);
      newReader.onload = function () {
        setLottie(JSON.parse(newReader.result));
      };
    }

    let reader = new FileReader();
    reader.readAsDataURL(imageFiles[0]);
    reader.onload = function () {
      onBlur({ base64: reader.result, name: imageFiles[0] });
      onChange({
        base64: reader.result,
        imagem: imageFiles[0],
        preview: URL.createObjectURL(imageFiles[0]),
      });
    };
    reader.onerror = function (error) {
      ////console.log("Error: ", error);
    };
  }

  function handleOnCropChange(crop) {
    setCrop(crop);
  }

  function handleImageLoaded(image) {
    setImageRef(image);
    return false;
  }

  function handleOnCropComplete(crop, pixelCrop) {
    makeClientCrop(crop);
  }

  function makeClientCrop(crop) {
    if (imageRef && crop.width && crop.height) {
      console.log(imageRef, crop, 'aa', imageRef.width, imageRef.height);
      console.log(
        imageRef.width,
        imageRef.height,
        imageRef.naturalWidth,
        imageRef.naturalHeight
      );
    }
  }

  let dropzoneProps = {
    accept: props.accept || 'image/jpeg, image/png',
    multiple: false,
    onDropAccepted: onDrop,
    maxSize: 2000000,
    onDropRejected: () =>
      props.meta.dispatch({
        type: 'SNACKBAR',
        payload: {
          message: 'Erro: Imagem maior que 2MB',
          variant: 'error',
          open: true,
        },
      }),
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={`${touched && error && 'has-error'}`}
    >
      {props.input.value ? (
        <Typography
          align="left"
          variant="caption"
          style={{
            color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
            textAlign: 'left',
          }}
        >
          {label}
        </Typography>
      ) : null}
      <input type="hidden" disabled {...input} />
      <Box display="flex">
        <Dropzone {...dropzoneProps}>
          {({ getRootProps, getInputProps }) => (
            <Box
              display="flex"
              flexGrow={1}
              {...getRootProps()}
              style={{
                height: 'auto',
                width: '100%',
                borderWidth: 1,
                borderColor:
                  touched && error ? appColors.error : appColors.dark.level3,
                borderStyle: 'dashed',
                borderRadius: 5,
                cursor: 'pointer',
                backgroundColor: 'white',
              }}
            >
              {props.input.value ? (
                props.accept === 'application/json' || props.lottie ? (
                  <>
                    <input {...getInputProps()} />
                    <Box
                      display="flex"
                      alignItems={'center'}
                      justifyContent="center"
                    >
                      {lottieFile ? (
                        <LottieControl
                          width={100}
                          hasReplayButton={false}
                          height={100}
                          loop={false}
                          animation={lottieFile}
                        />
                      ) : props.input.value ? (
                        <LottieControl
                          width={100}
                          hasReplayButton={false}
                          height={100}
                          loop={false}
                          animation={props.input.value}
                        />
                      ) : null}
                    </Box>
                  </>
                ) : (
                  <Box display="flex">
                    <input {...getInputProps()} />
                    <ReactCrop
                      src={
                        props.input.value.base64 ||
                        `${BASE_S3}/${props.input.value}`
                      }
                      onChange={handleOnCropChange}
                      imageStyle={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                      }}
                      crop={crop}
                      style={{
                        width: '100%',
                        height: 'auto',
                      }}
                      onImageLoaded={handleImageLoaded}
                      onComplete={handleOnCropComplete}
                    />
                  </Box>
                )
              ) : (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      borderRadius: '50%',
                      backgroundColor: `${appColors.light.level1}`,
                      height: 70,
                      width: 70,
                    }}
                  >
                    <CloudUploadIcon
                      style={{
                        fontSize: theme.spacing(4),
                        color:
                          touched && error
                            ? appColors.error
                            : 'rgb(102, 102, 102)',
                      }}
                      className=""
                    />
                  </Box>

                  <Typography
                    variant="caption"
                    align="left"
                    style={{
                      textAlign: 'left',
                      color:
                        touched && error
                          ? appColors.error
                          : 'rgb(102, 102, 102)',
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Dropzone>
      </Box>
      {touched && error && (
        <Box display="flex" mt={1} ml={1}>
          <Typography
            align="left"
            variant="caption"
            style={{ color: appColors.error }}
          >
            {error}
          </Typography>
        </Box>
      )}
      {croppedImgBase64 && (
        <img alt="Crop" style={{ maxWidth: '100%' }} src={croppedImgBase64} />
      )}
    </Box>
  );
}

export class ColorPickerInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPicker: false,
      color: '#fff',
    };

    this.toggleColor = this.toggleColor.bind(this);
    this.handleChangeComplete = this.handleChangeComplete.bind(this);
  }

  componentWillMount() {
    if (!_.isUndefined(this.props.input.value)) {
      this.setState({ color: this.props.input.value });
    }
  }

  toggleColor(e) {
    e.preventDefault();

    this.setState({
      showPicker: !this.state.showPicker,
    });
  }

  handleChangeComplete(color, event) {
    const { onColorChange } = this.props;
    this.setState({ color: color.hex });
    onColorChange(color.hex);
  }

  render() {
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <div className={`form-group ${touched && error && 'has-error'}`}>
        {touched && error ? (
          <label className="control-label">{this.props.label}</label>
        ) : (
          <label htmlFor={this.props.name}>{this.props.label}</label>
        )}
        <center>
          {
            <div>
              <SketchPicker
                color={this.state.color}
                onChangeComplete={this.handleChangeComplete}
                disableAlpha={true}
                width={250}
              />
            </div>
          }
        </center>
        {touched && error && <span className="help-block">{error}</span>}
      </div>
    );
  }
}

export class InputCheckbox extends React.PureComponent {
  render() {
    const {
      meta: { touched, error },
      options,
    } = this.props;
    return (
      <Grid cols={this.props.cols} style={this.props.style}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          {options.map((item, index) => {
            return (
              <div class="form-check form-control">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={item.value}
                  id={`checkbox-${item.label}`}
                />

                {touched && error ? (
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-${item.label}`}
                    style={{ marginLeft: 17 }}
                  >
                    <i className="fa fa-times-circle-o" />
                    &nbsp;
                    {item.label}
                  </label>
                ) : (
                  <label
                    className="form-check-label"
                    htmlFor={`checkbox-${item.label}`}
                    style={{ marginLeft: 17 }}
                  >
                    {item.label}
                  </label>
                )}
              </div>
            );
          })}
          {touched && error && <span className="help-block">{error}</span>}
        </div>
      </Grid>
    );
  }
}

export class InputRadio extends React.PureComponent {
  render() {
    const {
      input,
      meta: { touched, error },
      options,
    } = this.props;
    return (
      <Grid cols={this.props.cols}>
        <div className={`form-group ${touched && error && 'has-error'}`}>
          {touched && error ? (
            <label className="control-label">
              <i className="fa fa-times-circle-o" />
              &nbsp;
              {this.props.label}
            </label>
          ) : (
            <label htmlFor={this.props.name}>{this.props.label}</label>
          )}
          {options.map((item, index) => {
            return (
              <div
                class="form-check"
                style={this.props.style}
                key={`radio-${item.label}${index}`}
              >
                <input
                  {...this.props.input}
                  className="form-check-input"
                  type="radio"
                  value="valor"
                  name={`radio${this.props.name}`}
                  id={`radio-${item.label}${index}`}
                />

                {touched && error ? (
                  <label
                    className="form-check-label"
                    htmlFor={`radio-${item.label}${index}`}
                    style={{ marginLeft: 17 }}
                  >
                    <i className="fa fa-times-circle-o" />
                    &nbsp;
                    {item.label}
                  </label>
                ) : (
                  <label
                    className="form-check-label"
                    htmlFor={`radio-${item.label}${index}`}
                    style={{ marginLeft: 17 }}
                  >
                    {item.label}
                  </label>
                )}
              </div>
            );
          })}
        </div>
      </Grid>
    );
  }
}

export const renderPalletesSelectList = ({
  input,
  options,
  style,
  cols,
  meta: { touched, error },
  label,
  name,
}) => {
  /* let ListItem = ({ item }) => {
        for (let prop in item.colors) {
            return (
                <span>
                    <div style={{ backgroundColor: `${item.colors[prop]}`, borderRadius: "50%", width: 20, height: 20, display: "inline-block" }} />
                </span>
            );
        }
    }; */
  let ListItem = ({ item }) => (
    <div>
      <span>
        <div
          style={{
            backgroundColor: `${item.colors.dark_color}`,
            borderRadius: '50%',
            width: 20,
            height: 20,
            display: 'inline-block',
          }}
        />
      </span>
      <span>
        <div
          style={{
            backgroundColor: `${item.colors.light_color}`,
            borderRadius: '50%',
            width: 20,
            height: 20,
            display: 'inline-block',
          }}
        />
      </span>
      <span>
        <div
          style={{
            backgroundColor: `${item.colors.dark_font_color}`,
            borderRadius: '50%',
            width: 20,
            height: 20,
            display: 'inline-block',
          }}
        />
      </span>
      <span>
        <div
          style={{
            backgroundColor: `${item.colors.light_font_color}`,
            borderRadius: '50%',
            width: 20,
            height: 20,
            display: 'inline-block',
          }}
        />
      </span>
    </div>
  );

  return (
    <Grid cols={cols}>
      {touched && error ? (
        <label className="control-label">
          <i className="fa fa-times-circle-o" />
          &nbsp;
          {label}
        </label>
      ) : (
        <label htmlFor={name}>{label}</label>
      )}
      <SelectList
        {...input}
        style={{
          border: '1px solid #00000099',
          backgroundColor: 'transparent',
        }}
        data={options}
        itemComponent={ListItem}
        valueField="value"
        textField="label"
        onBlur={() => input.onBlur()}
      />
      {touched && error && <span className="help-block">{error}</span>}
    </Grid>
  );
};

export function InputSwitchGrey(props) {
  const [state, setState] = useState(false);

  const { input, label, disabled, tooltipMessage } = props;

  function handleChange(e) {
    setState(e.target.checked);
    input.onChange(e.target.checked);
  }

  useEffect(() => {
    if (input.value === '' || input.value === undefined) {
      setState(false);
      input.onChange(false);
    } else {
      setState(input.value);
    }
  }, [input.value]);

  return (
    <Box
      style={{ ...props.boxStyle }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle2">{label}</Typography>
          {tooltipMessage ? (
            <Tooltip
              style={{ marginLeft: 10 }}
              title={tooltipMessage}
              placement="top-start"
            >
              <Icon color="default">help</Icon>
            </Tooltip>
          ) : null}
        </Box>

        <Typography
          variant="caption"
          style={{ color: `${state ? '#2D95EF' : '#52C22C'}` }}
        >
          {state ? props.firstState : props.secondState}
        </Typography>
      </Box>

      <Switch
        disabled={disabled}
        color="primary"
        required
        checked={state}
        onChange={handleChange}
        value="switch"
      />
    </Box>
  );
}

export function InputSwitch(props) {
  const [state, setState] = useState(false);

  const { input, label, disabled } = props;

  function handleChange(e) {
    setState(e.target.checked);
    input.onChange(e.target.checked);
  }

  useEffect(() => {
    if (input.value === '' || input.value === undefined) {
      setState(props.default || false);
      input.onChange(props.default || false);
    } else {
      setState(input.value);
    }
  }, [input.value]);

  return (
    <FormControlLabel
      control={
        <Switch
          disabled={disabled}
          color="primary"
          required
          checked={state}
          onChange={handleChange}
          value="switch"
        />
      }
      label={label}
    />
  );
}
const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#E34848',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      disabled={props.disabled}
      checked={props.checked}
      onChange={props.onChange}
    />
  );
});

export function InputSwitchLarge(props) {
  const [state, setState] = useState(false);

  const { input, label, disabled } = props;

  function handleChange(e) {
    setState(e.target.checked);
    input.onChange(e.target.checked);
  }

  useEffect(() => {
    if (input.value === '' || input.value === undefined) {
      setState(false);
      input.onChange(false);
    } else {
      setState(input.value);
    }
  }, [input.value]);

  return (
    <Box
      style={{ ...props.boxStyle, marginTop: 10 }}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box display="flex" flexDirection="column">
        <Typography
          style={{ fontSize: 16, fontWeight: '400' }}
          variant="subtitle2"
        >
          {label}
        </Typography>
        <Typography
          variant="caption"
          style={{ color: `${state ? '#2D95EF' : '#52C22C'}` }}
        >
          {state ? props.firstState : props.secondState}
        </Typography>
      </Box>

      <IOSSwitch
        disabled={disabled}
        color
        checked={state}
        onChange={handleChange}
        value="switch"
      />
    </Box>
  );
}

export function MaterialCheckbox({
  input,
  label,
  type,
  style,
  meta: { touched, error, warning },
}) {
  // É necessário configurar valor para falso no uso caso usuário não mexa no checkbox

  // Exemplo de uso: <Field component={MaterialCheckbox} label={"Quer esta opção?"} name="checkbox" />
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(input.value);
  }, [input.value]);

  return (
    <div>
      <FormControlLabel
        control={<Checkbox color="primary" {...input} />}
        label={label}
        checked={state}
      />
      {touched && error ? (
        <Typography
          variant="caption"
          style={{
            color: appColors.error,
            display: 'block',
            marginTop: '-18px',
            marginLeft: 48,
            ...style,
          }}
        >
          {error}
        </Typography>
      ) : null}
    </div>
  );
}

export function CheckboxesGroup(props) {
  function handleFirstStateValue() {
    let firstState = {};
    props.options.forEach(item => {
      firstState = {
        ...firstState,
        [item.value]: false,
      };
    });

    return firstState;
  }

  const {
    meta: { touched },
    input,
  } = props;

  const [state, setState] = React.useState(handleFirstStateValue());

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  const error2 = Object.values(state).filter(v => v).length < 1;
  const helperText = 'Escolha pelo menos 1 opção';
  console.log('erro', touched, 'state', error2);

  return (
    <Box display="flex">
      <FormControl
        required={props.required}
        error={touched && error2 ? true : false}
        component="fieldset"
      >
        <FormLabel component="legend">{props.label}</FormLabel>
        <FormGroup>
          {props.options.map((item, index) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...input}
                  color="primary"
                  checked={state[`${item.value}`]}
                  onChange={handleChange(`${item.value}`)}
                  value={item.value}
                />
              }
              label={item.label}
            />
          ))}
        </FormGroup>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
}

export class EditorConvertToHTML extends React.Component {
  constructor(props) {
    super(props);
    const editorStateConst = this.initEditorState();
    this.state = {
      editorState: editorStateConst,
    };
    this.changeValue(editorStateConst);
  }

  initEditorState() {
    const html = this.props.input.value;
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
    });
    this.props.input.onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  changeValue(editorState) {
    this.setState({
      editorState,
    });
    this.props.input.onChange(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  }

  render() {
    const { editorState } = this.state;
    return (
      <>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="editor-test"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'textAlign',
              'colorPicker',
              'link',
              'embedded',
              'emoji',
              'remove',
              'history',
            ],
          }}
        />
      </>
    );
  }
}
export class EditorConvertToHTMLActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: undefined,
      currentContentLength: 0,
    };
  }

  initEditorState(props) {
    const html = props.input.value;
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    return EditorState.createWithContent(contentState);
  }

  componentDidMount() {
    if (this.props.input.value && _.isUndefined(this.state.editorState)) {
      const textLength = this.props.input.value.replace(/(<([^>]+)>)/gi, '')
        .length;
      const editorState = this.initEditorState(this.props);
      this.setState({ editorState, currentContentLength: textLength - 1 });
    }
  }

  componentWillReceiveProps(props) {
    if (props.input.value && _.isUndefined(this.state.editorState)) {
      const textLength = props.input.value.replace(/(<([^>]+)>)/gi, '').length;
      const editorState = this.initEditorState(props);
      this.setState({ editorState, currentContentLength: textLength - 1 });
    }
  }

  _getLengthOfSelectedText = () => {
    const currentSelection = this.state.editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();

    let length = 0;

    if (!isCollapsed) {
      const currentContent = this.state.editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      console.log(currentSelection);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };

  _handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (currentContentLength - selectedTextLength > this.props.maxLength - 1) {
      return 'handled';
    }
  };

  _handlePastedText = pastedText => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;
    const selectedTextLength = this._getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      this.props.maxLength
    ) {
      return 'handled';
    }
  };

  onEditorStateChange = editorState => {
    const stringHTML = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    const currentContentLength = stringHTML.replace(/(<([^>]+)>)/gi, '').length;

    this.setState(
      {
        editorState,
        currentContentLength: currentContentLength - 1,
      },
      () => {
        this.props.input.onChange(stringHTML);
      }
    );
  };

  render() {
    const { editorState } = this.state;
    const {
      meta: { touched, error },
    } = this.props;
    return (
      <>
        <Box display="flex" justifyContent="space-between">
          <Typography
            className="text-center"
            style={{
              color: this.props.disabled ? '#9E9E9E' : 'none',
              marginBottom: 10,
            }}
          >
            {this.props.label}
          </Typography>
          {this.props.maxLength && (
            <Typography
              className="text-center"
              style={{ color: this.props.disabled ? '#9E9E9E' : 'none' }}
            >
              {`${this.state.currentContentLength} de ${this.props.maxLength}`}
            </Typography>
          )}
        </Box>
        <Editor
          stripPastedStyles
          editorState={editorState}
          handleBeforeInput={this._handleBeforeInput}
          handlePastedText={this._handlePastedText}
          wrapperClassName="demo-wrapper"
          editorStyle={{
            border: !this.props.disabled
              ? '1px solid #00000099'
              : '1px solid #F0F0F0',
            borderRadius: 4,
            color: this.props.disabled ? '#9E9E9E' : 'none',
            paddingRight: '.2rem',
            paddingLeft: '.2rem',
            minHeight: 140,
            maxHeight: 520,
          }}
          editorClassName="editor-test"
          toolbarHidden={this.props.disabled}
          onEditorStateChange={this.onEditorStateChange}
          readOnly={this.props.disabled}
          toolbar={{
            options: ['inline', 'blockType', 'image', 'link', 'list'],
            list: { inDropdown: true },
            blockType: {
              inDropdown: true,
              options: ['Normal', 'Blockquote'],
            },
            inline: {
              options: ['bold', 'italic', 'underline'],
            },
            fontFamily: {
              options: ['Arial'],
            },
          }}
        />
        {touched && error ? (
          <Typography variant="caption" style={{ color: '#FF5A52' }}>
            {error}
          </Typography>
        ) : null}
      </>
    );
  }
}

export const InputFileWithUpload = ({
  input: { onBlur, onChange, value },
  meta: { touched, error },
  label,
  meta,
  input,
  squareOff,
}) => {
  const theme = useTheme();
  const [imageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    onChange(value);
  }, []);

  function onDrop(imageFiles) {
    setImageLoading(true);
    let reader = new FileReader();
    console.log(reader, '1');
    console.log(imageFiles, '2');

    reader.readAsDataURL(imageFiles[0]);
    reader.onload = async function (e) {
      console.log(e, 'evento');
      let width, height;
      var img = new Image();
      img.src = e.target.result;
      img.onload = async function () {
        console.log(img.width, img.height, '1');
        width = img.width;
        height = img.height;
        if (width !== height && !squareOff) {
          meta.dispatch({
            type: 'SNACKBAR',
            payload: {
              message:
                'Erro: Use uma imagem de proporção 1x1, ou seja, quadrada.',
              variant: 'error',
              open: true,
            },
          });
        } else {
          const url = await uploadImageToS3(reader.result);
          onBlur({ base64: url });
          onChange(url);
        }
        setImageLoading(false);
      };
    };
    reader.onerror = function (error) { };
  }

  function dropzoneProps() {
    return {
      accept: 'image/jpeg, image/png',
      multiple: false,
      onDropAccepted: onDrop,
      maxSize: 500000,
      onDropRejected: () =>
        meta.dispatch({
          type: 'SNACKBAR',
          payload: {
            message: 'Erro: Imagem maior que 500kB',
            variant: 'error',
            open: true,
          },
        }),
    };
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={`${touched && error && 'has-error'}`}
    >
      {value ? (
        <Typography
          align="left"
          variant="caption"
          style={{
            color: touched && error ? appColors.error : 'rgb(102, 102, 102)',
            textAlign: 'left',
          }}
        >
          {label}
        </Typography>
      ) : null}
      <input type="hidden" disabled {...input} />
      <Box display="flex">
        <Dropzone {...dropzoneProps()}>
          {({ getRootProps, getInputProps }) => (
            <Box
              display="flex"
              flexGrow={1}
              {...getRootProps()}
              style={{
                height: 'auto',
                width: '100%',
                borderWidth: 1,
                borderColor:
                  touched && error ? appColors.error : appColors.dark.level3,
                borderStyle: 'dashed',
                borderRadius: 5,
                cursor: 'pointer',
                backgroundColor: 'white',
              }}
            >
              {value ? (
                <>
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    alignItems={'center'}
                    justifyContent="center"
                  >
                    <img
                      src={`${BASE_S3}/${value}`}
                      className="img-fluid"
                      alt="Ícone do Troféu"
                    />
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  flexGrow={1}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  p={3}
                >
                  <input {...getInputProps()} />
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      borderRadius: '50%',
                      backgroundColor: `${appColors.light.level1}`,
                      height: 70,
                      width: 70,
                    }}
                  >
                    <CloudUploadIcon
                      style={{
                        fontSize: theme.spacing(4),
                        color:
                          touched && error
                            ? appColors.error
                            : 'rgb(102, 102, 102)',
                      }}
                      className=""
                    />
                  </Box>

                  <Typography
                    variant="caption"
                    align="left"
                    style={{
                      textAlign: 'left',
                      color:
                        touched && error
                          ? appColors.error
                          : 'rgb(102, 102, 102)',
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Dropzone>
      </Box>
      {touched && error && (
        <Box display="flex" mt={1} ml={1}>
          <Typography
            align="left"
            variant="caption"
            style={{ color: appColors.error }}
          >
            {error}
          </Typography>
        </Box>
      )}
      <Backdrop
        style={{
          zIndex: 1500 + 1,
          color: '#fff',
        }}
        open={imageLoading}
      >
        <CircularProgress color="inherit" />{' '}
        <span style={{ marginLeft: 10 }}>Fazendo upload da imagem...</span>
      </Backdrop>
    </Box>
  );
};
