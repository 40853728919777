import ContactsSurveysRoutes from 'modules/owner/pages/actions/research/researchRoutes';
import React from 'react';
import { IndexRoute, Route } from 'react-router';
import ActionDetail from './actionDetail';
import actionOffersRoutes from './actionOffers/actionOffersRoutes';
import Actions from './actions';
import actionDraftsRoutes from './actionsDrafts/actionsDraftsRoutes';
import actionHistoryRoute from './actionsHistory/actionsHistoryRoutes';
import ActionsOptions from './actionsOptions';
import actionActiveRoutes from './activeActions/activeActionsRoutes';
import cupomRoutes from './cupom/cupomRoutes';
import presentialCourseRoutes from './presentialCourse/presentialCoursesRoutes';
import productDeliveryRoutes from './productDelivery/productDeliveryRoutes';
import donationActionRoutes from './donationAction/donationActionRoutes';
import rawContentRoutes from './rawContent/rawContentRoutes';
import actionsMenuRoutes from './actionsMenu/actionsMenuRoutes';
import videoPlaylistRoutes from './videoPlaylist/videoPlaylistRoutes';

export default (
  <Route path="acoes">
    <IndexRoute component={props => <Actions {...props} />} />
    {actionsMenuRoutes}
    {presentialCourseRoutes}
    {cupomRoutes}
    {actionOffersRoutes}
    {actionDraftsRoutes}
    {actionActiveRoutes}
    {actionHistoryRoute}
    {productDeliveryRoutes}
    {donationActionRoutes}
    {rawContentRoutes}
    {videoPlaylistRoutes}
    <Route path=":id">
      <IndexRoute component={props => <ActionDetail {...props} />} />
    </Route>
    <Route path="aquisicao-clientes">
      <IndexRoute component={props => <ActionsOptions {...props} />} />
      {ContactsSurveysRoutes}
    </Route>
  </Route>
);
