import React, { Component } from 'react';
import { connect } from 'react-redux';

class Home extends Component {
  componentDidMount() {
    if (this.props.auth.user.active === 0) {
      this.props.router.push('/confimation');
    } else {
      if (
        this.props.auth.user.type_id === 1 ||
        this.props.auth.user.type_id === 4
      ) {
        this.props.router.push('/owner');
      } else if (this.props.auth.user.type_id === 2) {
        this.props.router.push('/homepage');
      } else if (this.props.auth.user.type_id === 3) {
        this.props.router.push('/manager');
      }
    }
  }
  render() {
    return <div></div>;
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Home);
