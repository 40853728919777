import React from 'react';
import { IndexRoute, Route } from 'react-router';
import Coupons from './coupons';
import CouponsForm from './couponsForm';

export default (
  <Route path="cupons">
    <IndexRoute component={props => <Coupons {...props} />} />
    <Route path="novo" component={props => <CouponsForm {...props} />} />
    <Route path=":id/:action" component={props => <CouponsForm {...props} />} />
  </Route>
);
