import { faCircle, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  makeStyles,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  TextField,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import { FullButton } from 'common/components/buttons/fullButton';
import React from 'react';
import { connect } from 'react-redux';
import { appColors } from 'styles/colors.jsx';

function ResearchInteraction(props) {
  const [question, setQuestion] = React.useState(0);

  const StyledMenuItem = styled(MenuItem)({
    display: props => (props.disabled ? 'none' : 'block'),
  });
  const useStyles = makeStyles(theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
    },

    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }));

  const CssTextField = withStyles({
    root: {
      flexGrow: 1,
      '& label': {
        color: appColors.field.normal,
        fontSize: 16,
      },
      '&? label?.Mui-focused': {
        color: appColors.field.primary,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: appColors.field.primary,
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: appColors.field.normal,
        },
        '&:hover fieldset': {
          borderColor: appColors.field.hover,
        },
        '&.Mui-focused fieldset': {
          borderColor: appColors.field.primary,
        },
      },
      '& .MuiOutlinedInput-input': {
        paddingTop: 19.5,
        paddingBottom: 19.5,
      },
      '& .MuiOutlinedInput-multiline': {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  })(TextField);

  function renderOptions() {
    return props.formValues?.questions[
      question
    ].options.map((option, index) => (
      <StyledMenuItem value={index}>{option?.label?.text}</StyledMenuItem>
    ));
  }
  const classes = useStyles();

  if (!props.formValues?.questions) {
    return null;
  } else if (
    props.formValues?.questions &&
    !props.formValues?.questions[question]
  ) {
    return (
      <div
        style={{
          height: 600,
          margin: 30,
          background: '#FFFFFF',
          borderRadius: 6,
        }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            margin: 50,
          }}
        >
          <span
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '24px',
              lineHeight: '29px',

              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              letterSpacing: '0.25px',
              flexWrap: 'wrap',
              marginBottom: 20,
              color: '#000000',
            }}
          >
            Responda o questionário
          </span>

          {/* //pergunta */}
          <span
            style={{
              marginBottom: 20,
              alignSelf: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            Pergunta {question + 1}
          </span>
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
              alignSelf: 'flex-end',
              display: 'flex',
            }}
          >
            <span>
              {question + 1} de {props.formValues?.questions?.length}
            </span>
            <ChevronLeft
              onClick={() => {
                if (question > 0) {
                  setQuestion(question - 1);
                }
              }}
              style={{
                color: question > 0 ? 'black' : 'gray',
                marginLeft: 20,
                cursor: 'pointer',
              }}
            />
            <ChevronRight
              onClick={() => {
                if (question !== props.formValues?.questions?.length - 1) {
                  setQuestion(question + 1);
                }
              }}
              style={{
                cursor: 'pointer',
                marginLeft: 20,
                color:
                  question !== props.formValues?.questions?.length - 1
                    ? 'black'
                    : 'gray',
              }}
            />
          </div>

          <FullButton
            classNameProp="station-font-medium py-3 px-3"
            disabled={question === props.formValues?.questions?.length - 1}
            background={
              question === props.formValues?.questions?.length - 1
                ? 'gray'
                : '#2D95EF'
            }
            text={'black'}
            fontSize="12px"
            type="button"
            onClick={() => {
              if (question !== props.formValues?.questions?.length - 1) {
                setQuestion(question + 1);
              }
            }}
          >
            Próximo
          </FullButton>
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        height: 600,
        margin: 30,
        background: '#FFFFFF',
        borderRadius: 6,
      }}
    >
      {props.formValues?.questions?.length > 0 ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            margin: 50,
          }}
        >
          <span
            style={{
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '24px',
              lineHeight: '29px',

              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
              letterSpacing: '0.25px',
              flexWrap: 'wrap',
              marginBottom: 20,
              color: '#000000',
            }}
          >
            Responda o questionário
          </span>

          {/* //pergunta */}
          <span
            style={{
              marginBottom: 20,
              alignSelf: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            {props.formValues?.questions[question]?.question?.text}
          </span>
          <div
            style={{
              marginBottom: 20,
              alignSelf: 'flex-start',
              display: 'flex',
              alignContent: 'flex-start',
              flexWrap: 'wrap',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {props.formValues?.questions[question]?.options?.length > 0 &&
            (props.formValues?.questions[question]?.type === 1 ||
              props.formValues?.questions[question]?.type === 2) ? (
              <Select
                placeholder="Escolha uma opção"
                style={{ width: '100%' }}
                input={<OutlinedInput id="select-label" />}
              >
                {renderOptions()}
              </Select>
            ) : null}
            {props.formValues?.questions[question]?.options?.length > 0 &&
            (props.formValues?.questions[question]?.type === 4 ||
              props.formValues?.questions[question]?.type === 5) ? (
              <>
                {props.formValues?.questions[question]?.options?.map(option => {
                  if (option) {
                    /* Multipla escolha */
                    if (props.formValues?.questions[question]?.type === 4) {
                      return (
                        <div
                          style={{
                            marginBottom: 10,
                            display: 'flex',
                            alignContent: 'flex-start',
                            flexDirection: 'row',
                          }}
                        >
                          <FontAwesomeIcon
                            style={{ margin: 'auto 8', fontSize: 16 }}
                            icon={faCircle}
                          />
                          <span>{option?.label?.text}</span>
                        </div>
                      );
                    } else if (
                      props.formValues?.questions[question]?.type === 5
                    ) {
                      return (
                        <div
                          style={{
                            marginBottom: 10,
                            display: 'flex',
                            alignContent: 'flex-start',
                            flexDirection: 'row',
                          }}
                        >
                          <FontAwesomeIcon
                            style={{ margin: 'auto 8', fontSize: 16 }}
                            icon={faSquare}
                          />
                          <span>{option?.label?.text}</span>
                        </div>
                      );
                    }
                  }
                })}
                {props.formValues?.questions[question]?.otherOption ? (
                  <div className={classes.container}>
                    <CssTextField
                      label={'Outro'}
                      variant="outlined"
                      type={'text'}
                    />
                  </div>
                ) : null}
              </>
            ) : props.formValues?.questions[question]?.type === 3 ? (
              <div className={classes.container}>
                <CssTextField
                  label={'Responda aqui'}
                  variant="outlined"
                  type={'text'}
                />
              </div>
            ) : null}
          </div>
          <div
            style={{
              marginTop: 20,
              marginBottom: 20,
              alignSelf: 'flex-end',
              display: 'flex',
            }}
          >
            <span>
              {question + 1} de {props.formValues?.questions?.length}
            </span>
            <ChevronLeft
              onClick={() => {
                if (question > 0) {
                  setQuestion(question - 1);
                }
              }}
              style={{
                color: question > 0 ? 'black' : 'gray',
                marginLeft: 20,
                cursor: 'pointer',
              }}
            />
            <ChevronRight
              onClick={() => {
                if (question !== props.formValues?.questions?.length - 1) {
                  setQuestion(question + 1);
                }
              }}
              style={{
                cursor: 'pointer',
                marginLeft: 20,
                color:
                  question !== props.formValues?.questions?.length - 1
                    ? 'black'
                    : 'gray',
              }}
            />
          </div>
          <FullButton
            classNameProp="station-font-medium py-3 px-3"
            disabled={question === props.formValues?.questions?.length - 1}
            background={
              question === props.formValues?.questions?.length - 1
                ? 'gray'
                : '#2D95EF'
            }
            text={'black'}
            fontSize="12px"
            type="button"
            onClick={() => {
              if (question !== props.formValues?.questions?.length - 1) {
                setQuestion(question + 1);
              }
            }}
          >
            Próximo
          </FullButton>
        </div>
      ) : (
        <>Adicione perguntas</>
      )}
    </div>
  );
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(ResearchInteraction);
