import React from 'react';
export default function ActiveUsers({ width, height }) {
  return (
    <svg
      width={ width }
      height={ height }
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0725 0.928467C16.5657 0.928467 16.9654 1.3442 16.9654 1.85704V4.64275C16.9654 5.15559 16.5657 5.57132 16.0725 5.57132C15.5794 5.57132 15.1797 5.15559 15.1797 4.64275V1.85704C15.1797 1.3442 15.5794 0.928467 16.0725 0.928467Z"
        fill="#5759FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2199 2.94145C10.6302 2.65698 11.1846 2.77229 11.4581 3.19899L13.2438 5.98471C13.5174 6.41141 13.4065 6.98793 12.9962 7.2724C12.5859 7.55687 12.0316 7.44157 11.758 7.01486L9.97232 4.22915C9.69879 3.80244 9.80966 3.22592 10.2199 2.94145Z"
        fill="#5759FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.0075 12.4849C18.281 12.0582 18.8353 11.9429 19.2456 12.2273L21.9242 14.0845C22.3345 14.369 22.4454 14.9455 22.1718 15.3722C21.8983 15.7989 21.344 15.9142 20.9337 15.6297L18.2551 13.7726C17.8448 13.4881 17.7339 12.9116 18.0075 12.4849Z"
        fill="#5759FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.6445 9.28551C19.6445 8.77267 20.0443 8.35693 20.5374 8.35693H23.216C23.7091 8.35693 24.1088 8.77267 24.1088 9.28551C24.1088 9.79834 23.7091 10.2141 23.216 10.2141H20.5374C20.0443 10.2141 19.6445 9.79834 19.6445 9.28551Z"
        fill="#5759FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.0602 3.05762C22.4089 3.42025 22.4089 4.00819 22.0602 4.37082L19.3816 7.15653C19.0329 7.51916 18.4676 7.51916 18.1189 7.15653C17.7703 6.7939 17.7703 6.20596 18.1189 5.84333L20.7975 3.05762C21.1462 2.69499 21.7115 2.69499 22.0602 3.05762Z"
        fill="#5759FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7013 8.42862C16.0352 8.28392 16.4202 8.36312 16.676 8.62918C16.9318 8.89524 17.008 9.29556 16.8688 9.64288L10.5563 25.4007C10.4135 25.7573 10.0733 25.9847 9.70209 25.9717C9.33088 25.9587 9.00617 25.708 8.88672 25.3423L7.10732 19.8938L2.39027 24.7995C2.04159 25.1621 1.47627 25.1621 1.12758 24.7995C0.778901 24.4369 0.778901 23.8489 1.12758 23.4863L5.84462 18.5806L0.605714 16.73C0.25402 16.6058 0.013011 16.2681 0.000509307 15.882C-0.0119923 15.496 0.206627 15.1422 0.549488 14.9936L15.7013 8.42862ZM9.8172 22.4168L14.3866 11.0101L3.41869 15.7623L7.77822 17.3022C8.04095 17.3951 8.24726 17.6096 8.3365 17.8829L9.8172 22.4168Z"
        fill="#5759FB"
      />
    </svg>
  );
}
