import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Content from 'common/components/content/content';
import React, { Component } from 'react';
import { CardText, CardTitle, CircularProgress } from 'react-md';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import Table6 from '../../../../common/components/table/table6';
import { getContactByEstablishmentId } from './contactsActions';

class Contacts extends Component {
  constructor(props) {
    super(props);

    this.props.getContactByEstablishmentId(
      this.props.auth.user.establishments[0].id
    );

    this.state = {
      columns: [
        { title: 'Nome', field: 'name' },
        { title: 'E-mail', field: 'email' },
        { title: 'Ativado', field: 'active' },
        { title: 'Convidado', field: 'invited' },
      ],
    };
  }

  componentDidMount() {}

  render() {
    const { list, loading } = this.props.contacts;
    const { columns } = this.state;
    if (loading) {
      return <CircularProgress id="contacts" />;
    }
    return (
      <section className="modules">
        <div className="container-fluid">
          <div>
            <Content>
              <CardTitle title="Contatos" />
              <Link to="/owner/contacts/import">
                <center>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ marginBottom: 20 }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Importar Contatos
                  </button>
                </center>
              </Link>
              <CardText>
                <Table6 title="Lista" columns={columns} data={list} />
              </CardText>
            </Content>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    contacts: state.contacts,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getContactByEstablishmentId }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
