import { Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import { OnboardingCard } from 'common/components/cards/onboardingCard/onboardingCard';
import React, { Component } from 'react';
import { Avatar, CircularProgress } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingProgress from '../../../../common/components/progress/loading';
import { finishTutorial } from '../../../../pages/auth/authActions';
import { getUserSteps } from './principalActions';

class Principal extends Component {
  constructor(props) {
    super(props);
    this.props.getUserSteps(this.props.auth.user.id);
  }

  renderPoints = () => {
    const { establishment } = this.props.auth.user;
    if (this.props.principal.loading) {
      return <CircularProgress id="points" />;
    } else {
      return (
        <span className="font-dosis">
          <span style={{ fontWeight: '300' }}>Seja bem vindo, </span>
          <span style={{ fontWeight: 'bold' }}>
            {establishment.name}, você possui pontos.
          </span>
        </span>
      );
    }
  };
  renderNumber = (number, condition) => {
    if (condition) {
      return (
        <Avatar style={{ background: 'gray' }}>
          <span>{number}</span>
        </Avatar>
      );
    } else
      return (
        <Avatar style={{ background: '#FF007B' }}>
          <span>{number}</span>
        </Avatar>
      );
  };

  finishTutorial = () => {
    this.props.finishTutorial(
      this.props.auth.user.id,
      this.props.router,
      this.props.auth
    );
  };

  componentWillMount() {}

  render() {
    const { establishment } = this.props.principal.obj;

    if (this.props.principal.loading) {
      return (
        <section className="d-flex justify-content-center mt-5">
          <LoadingProgress />
        </section>
      );
    }
    return (
      <section className="modules" style={{ paddingBottom: 50 }}>
        <MaterialGrid container spacing={3}>
          <MaterialGrid
            item
            xs={12}
            style={{ marginBottom: 40, marginTop: 40 }}
          >
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontWeight: 500,
                fontSize: 24,
                textAlign: 'center',
              }}
            >
              Bem vindo, {this.props.auth.user.name}
            </Typography>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <MaterialGrid item>
            <OnboardingCard
              condition={establishment}
              title="Cadastre seu CNPJ para iniciar"
              text="Ao cadastrar sua empresa, você está fazendo o primeiro passo para fazer trilhas, ou seja, ofertas promocionais, para os nossos programas
              de relacionamento"
              router={this.props.router}
              buttonLabel="Cadastrar Agora"
              onClickButton={() =>
                this.props.router.push('/owner/signup-company')
              }
            />
          </MaterialGrid>
          {establishment ? (
            <MaterialGrid item>
              <OnboardingCard
                condition={false}
                title="Pronto"
                text="Finalize esse tutorial e vá para a dashboard"
                router={this.props.router}
                buttonLabel="Finalizar"
                onClickButton={() => this.finishTutorial()}
              />
            </MaterialGrid>
          ) : null}
        </MaterialGrid>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    principal: state.principal,
    points: state.points,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getUserSteps, finishTutorial }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Principal);
