import React from 'react';
import { IndexRoute, Route } from 'react-router';
import Validations from './validations';
import ValidationsInfo from './validationsInfo';

export default (
  <Route path="validacao">
    <IndexRoute component={props => <Validations {...props} />} />
    <Route path=":code" component={props => <ValidationsInfo {...props} />} />
  </Route>
);
