import React from 'react';
import { Route, IndexRoute } from 'react-router';
import Main from './main';

import Results from '../sections/results';

export default (
  <Route path="atividade-doacao">
    <IndexRoute component={props => <Main {...props} />} />
    <Route path=":id/:action">
      <IndexRoute component={props => <Main {...props} />} />
    </Route>
    <Route path="resultados" component={Results} />
  </Route>
);
