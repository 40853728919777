import React from 'react';
import Grid from 'common/components/layout/grid/grid';
import _ from 'lodash';
import { Link } from 'react-router';
import './moduleCard.css';

export const ModuleCard = props => {
  return (
    <Link to={props.path}>
      <Grid style={{ ...props.style, marginTop: 20 }} cols={props.cols}>
        <div
          className="info-box"
          style={{ background: props.bgc, color: props.color }}
          onClick={!_.isUndefined(props.onClick) ? props.onClick : null}
        >
          <span className="info-box-icon-full">
            {!_.isUndefined(props.icon)
              ? props.icon
              : !_.isUndefined(props.mdIcon)
              ? props.mdIcon
              : null}
          </span>
          <div
            className="info-box-content"
            style={{ marginLeft: 0, textAlign: 'center' }}
          >
            <span className="info-box-number font-dosis">{props.text}</span>
          </div>
        </div>
      </Grid>
    </Link>
  );
};
