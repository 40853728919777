import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getEstablishmentPoints } from './pointsActions';
import LoadingProgress from 'common/components/progress/loading';
import { OnboardingCard } from 'common/components/cards/onboardingCard/onboardingCard';
import MaterialGrid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import { String2Currency } from 'helpers/dataTransformers';

class Points extends Component {
  constructor(props) {
    super(props);
    this.props.getEstablishmentPoints(
      this.props.auth.user.establishments[0].id
    );
  }

  renderPoints = () => {
    const { points } = this.props.points;
    const { establishment } = this.props.auth.user;
    if (this.props.points.loading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <LoadingProgress />
        </div>
      );
    } else {
      return (
        <span className="font-dosis">
          <span style={{ fontWeight: '300' }}>Seja bem vindo, </span>
          <span style={{ fontWeight: 'bold' }}>
            {establishment.name}, você possui {points} créditos.
          </span>
        </span>
      );
    }
  };

  componentWillMount() {}

  render() {
    const {
      points,
      allocated_points,
      avaliable_marketplace_points,
      unavaliable_marketplace_points,
    } = this.props.points.points;
    const avaliable_points = (
      parseFloat(points) - parseFloat(allocated_points)
    ).toFixed(2);

    if (this.props.points.loading) {
      return (
        <Box display="flex" justifyContent="center" height="100vh" mt={7}>
          <LoadingProgress />
        </Box>
      );
    }
    return (
      <section className="modules" style={{ marginTop: '5.3em' }}>
        <div className="container-fluid">
          <MaterialGrid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <MaterialGrid item>
              <OnboardingCard
                condition={false}
                multipleLines
                title={`Premiação \n Créditos disponíveis: ${String2Currency(
                  avaliable_points
                )}\nCréditos em atividades: ${String2Currency(
                  allocated_points
                )}\nCréditos totais: ${String2Currency(points)}`}
                text={
                  'Os créditos disponíveis podem ser utilizados como cashback ou recompensa em suas atividades.'
                }
                router={this.props.router}
                buttonLabel="Continuar"
                hideButton={!this.props.location.query.first_action}
                onClickButton={() =>
                  this.props.router.push('/owner/acoes/?first_action=true')
                }
              />
            </MaterialGrid>
            <MaterialGrid item>
              <OnboardingCard
                condition={false}
                multipleLines
                title={`Saldo de vendas \n  Disponível: ${String2Currency(
                  avaliable_marketplace_points
                )}\nA receber: ${String2Currency(
                  unavaliable_marketplace_points
                )}`}
                text={
                  'Esses créditos poderão ser retirados quando atingirem 28 dias do dia da compra.'
                }
                router={this.props.router}
                buttonLabel="Continuar"
                hideButton={!this.props.location.query.first_action}
                onClickButton={() =>
                  this.props.router.push('/owner/acoes/?first_action=true')
                }
              />
            </MaterialGrid>
          </MaterialGrid>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    points: state.points,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getEstablishmentPoints }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Points);
