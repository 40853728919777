import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { initialize, reset as resetForm } from 'redux-form';
import type from './types';

const URL = `${BASE_API}/cupoms/`;
const URL_COUPONS_BY_ESTABLISHMENT = `${BASE_API}/offer-by-partner`;
const URL_ASK_ESTALBIHSMENTS = `${BASE_API}/cupoms-to-establishments`;
const URL_STATIONS = `${BASE_API}/stations`;

export const getList = id => {
  return dispatch => {
    dispatch({ type: type.CUPOM_OFFERS_LOAD, payload: true });
    axios
      .get(`${URL_COUPONS_BY_ESTABLISHMENT}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        for (let i in response.data) {
          if (parseInt(response.data[i].active === 1)) {
            response.data[i]['accepted'] = 'Aceito';
          } else if (parseInt(response.data[i].active === 0)) {
            response.data[i]['accepted'] = 'Rejeitado';
          } else {
            response.data[i]['accepted'] = 'Aguardando Resposta';
          }
        }
        dispatch([
          {
            type: type.CUPOM_OFFERS_FETCHED,
            payload: response.data,
          },
        ]);
      });
  };
};

export const getStationsList = () => {
  return dispatch => {
    dispatch({ type: type.STATIONS_LOAD, payload: true });
    axios
      .get(`${URL_STATIONS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.STATIONS_FETCHED,
            payload: response.data,
          },
        ]);
      });
  };
};

export const sendValuesToEstablishment = (values, router = undefined) => {
  return dispatch => {
    axios
      .post(URL_ASK_ESTALBIHSMENTS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        if (!_.isUndefined(router)) {
          router.push('/owner');
        }
      });
  };
};

export const getListPage = page => {
  const request = axios.get(`${URL}?page=${page}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return dispatch => {
    dispatch([
      {
        type: type.COUPON_LOAD,
        payload: true,
      },
      {
        type: type.CUPOM_OFFERS_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const getListLimit = number => {
  const request = axios.get(`${URL}?limit=${number}`, {
    headers: {
      Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
    },
  });
  return dispatch => {
    dispatch([
      {
        type: type.COUPON_LOAD,
        payload: true,
      },
      {
        type: type.CUPOM_OFFERS_FETCHED,
        payload: request,
      },
    ]);
  };
};

export const remove = (id, resolve, reject, other = undefined) => {
  return dispatch => {
    dispatch({ type: type.COUPON_LOAD, payload: false });
    axios
      .delete(`${URL}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        toastr.success('Sucesso', 'Operação realizada com sucesso.');

        dispatch([
          {
            type: type.COUPON_LOAD,
            payload: false,
          },

          resolve(),
          getList(other),
        ]);
      })
      .catch(e => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              toastr.error(i, e.response.data[i][j]);
            }
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const getDetail = id => {
  return dispatch => {
    dispatch({
      type: type.COUPON_LOAD,
      payload: true,
    });
    axios
      .get(`${URL}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const initialValues = {
          ...response.data[0],
        };
        dispatch([
          {
            type: type.NEWS_DETAIL,
            payload: response.data[0],
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const setDetail = values => {
  return dispatch => {
    dispatch({
      type: type.COUPON_LOAD,
      payload: true,
    });
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: type.NEWS_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
      reset(),
      getList(),
    ]);
  };
};

export const initializeForm = values => {
  return initialize('couponsForm', values);
};

export const reset = () => {
  return resetForm('couponsForm');
};

export const create = (values, router) => {
  return submit(values, 'post', router);
};

export const update = (values, router) => {
  return submit(values, 'put', router);
};

function submit(values, method, router = undefined) {
  const id = values.id ? `${values.id}/` : '';
  return dispatch => {
    dispatch({ type: type.COUPON_LOAD, payload: true });
    axios[method](`${URL}${id}`, values, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
    })
      .then(response => {
        toastr.success('Sucesso', 'Operação realizada com sucesso.');
        /* dispatch([reset(), getList()]); */

        if (!_.isUndefined(router)) {
          router.push('/owner/cupons');
        }
      })
      .catch(e => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              toastr.error(i, e.response.data[i][j]);
            }
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
}
