import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { FullButton } from 'common/components/buttons/fullButton';
import React from 'react';
import { DomPurifyContentContainer } from '../styles';
import MultiSelect from './ItemCard/multiSelect';
import RadioSelect from './ItemCard/radioSelect';
import { EmptyProducts } from './styles';

function ActivityCoupon(props) {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));

  return (
    <Grid
      item
      container
      alignItems="center"
      xs={12}
    >
      {/* DOM PURIFY CONTENT */}
      <Grid
        item
        container
        alignItems="center"
        xs={12}
      >
        <DomPurifyContentContainer
          className={props.className}
          dangerouslySetInnerHTML={{
            __html: props.formValues.description,
          }}
        />
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        xs={12}
      >
        {props.formValues?.products?.length > 0 ? (
          props.formValues.products.map((product) => {
            if(product) {
              if (props.formValues.only_one_product) {
                return (
                  <>
                    <Grid
                      item
                      container
                      justify="center"
                      xs={12}
                    >
                      <RadioSelect data={product} />
                    </Grid>
                  </>
                );
              }
              return (
                <Grid
                  item
                  container
                  justify="center"
                  xs={12}
                >
                  <MultiSelect data={product} />
                </Grid>
              );
            } else return null;
          })
        ) : (
          <Grid
            item
            container
            justify="center"
            xs={12}
          >
            <EmptyProducts>
              <span>Nenhum produto cadastrado ainda</span>
            </EmptyProducts>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        xs={12}
        style={{
          backgroundColor: '#E9F6F6',
          padding: '1.7rem 1.5rem',
          borderRadius: (xs || sm) ? '10px' : '0 0 10px 10px',
          marginTop: '1rem',
        }}
      >
        <Grid
          item
          container
          justify={(xs || sm) ? "center" : "flex-start"}
          alignItems="flex-start"
          xs={12}
          md={6}
          style={{
            marginBottom: (xs || sm) ? '.5rem' : null,
            height: (xs || sm) ? '60px' : '',
          }}
        >
          <Typography gutterBottom={true} component="p" style={{ textAlign: 'start', maxWidth: '' }}>
            Escolha os produtos e adicione ao carrinho de compras
          </Typography>
        </Grid>
        <Grid
          item
          container
          justify="center"
          alignItems="center"
          spacing={1}
          xs={12}
          md={6}
          style={{
            marginTop: (xs || sm) ? '.3rem' : null,
          }}
        >
          <Grid item container justify="center" alignItems="center" xs={12} md={4}>
            <FullButton
              background="#fff"
              text="#000"
              classNameProp="station-font-medium py-2 px-3"
              fontSize="12px"
              type="button"
            >
              Voltar
            </FullButton>
          </Grid>
          <Grid item container justify="center" alignItems="center" xs={12} md={8}>
            <FullButton
              classNameProp="station-font-medium py-2 px-3"
              background={'#2D95EF'}
              text={'#fff'}
              fontSize="12px"
              type="button"
            >
              Adicionar ao carrinho
            </FullButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ActivityCoupon;
