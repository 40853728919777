import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import type from './types';

const URL_PARTICIPANT_INFO = `${BASE_API}/data-cupom`;
const URL_VALIDATION = `${BASE_API}/validate-cupom`;

export const getParticipantInfo = (code, router = undefined) => {
  return dispatch => {
    axios
      .post(
        `${URL_PARTICIPANT_INFO}/${code}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(response => {
        toastr.success(
          'Sucesso',
          'Participante encontrado. Verifique as informações.'
        );
        /* dispatch([reset(), getList()]); */

        dispatch({
          type: type.VALIDATION_DETAIL,
          payload: response.data,
        });
      })
      .catch(e => {
        try {
          toastr.error('Erro', 'Erro interno no servidor');
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const validateParticipant = (id, router = undefined) => {
  return dispatch => {
    axios
      .put(
        `${URL_VALIDATION}/${id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(response => {
        toastr.success('Sucesso', 'Participante validado com sucesso.');
        /* dispatch([reset(), getList()]); */

        if (!_.isUndefined(router)) {
          router.push('/owner/sales');
        }
      })

      .catch(e => {
        try {
          if (e.response.data.error) {
            toastr.error('Erro', e.response.data.error);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export function setLoading(boolean) {
  return dispatch => {
    dispatch({
      type: type.VALIDATION_LOAD,
      payload: boolean,
    });
  };
}
