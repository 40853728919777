import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import LoadingProgress from 'common/components/progress/loading';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PrimaryButton from '../../../../common/components/buttons/primaryButton';
import { getEstablishmentPoints } from '../points/pointsActions';
import { buyActionTemplate } from './actionsFile';

function BuyAction(props) {
  const theme = useTheme();

  useEffect(() => {
    props.getEstablishmentPoints(props.auth.user.establishments[0].id);
  }, []);

  if (props.points.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  const { points, allocated_points } = props.points.points;
  const avaliable_points = (
    parseFloat(points) - parseFloat(allocated_points)
  ).toFixed(2);

  function handleBuyAction() {
    const values = {
      action_template_id: parseInt(props.router.params.id),
      establishment_id: parseInt(props.auth.user.establishments[0].id),
      station_id: parseInt(
        props.auth.user.establishments[0].stations
          ? props.auth.user.establishments[0].stations[0].id
          : null
      ),
      type_action_id: parseInt(props.actions.templateDetail.type_action_id),
      sponsored: true,
      partner_id: parseInt(props.auth.user.establishments[0].id),
    };
    props.buyActionTemplate(values, props.setIsBuyActionModalVisible);
  }

  return (
    <MaterialGrid
      container
      spacing={3}
      style={{ background: theme.palette.background.main }}
      justify="center"
    >
      <MaterialGrid
        item
        xs={11}
        style={{
          background: theme.palette.background.white,
          padding: theme.spacing(5),
          marginBottom: theme.spacing(5),
        }}
      >
        <MaterialGrid
          container
          spacing={3}
          justify="space-between"
          style={{ marginBottom: theme.spacing(3) }}
          alignItems="center"
        >
          <MaterialGrid item xs={4}>
            <Typography
              style={{
                color: theme.palette.text.main,
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Revise seu pedido
            </Typography>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid
          container
          spacing={0}
          style={{ marginBottom: theme.spacing(1) }}
        >
          <MaterialGrid
            item
            xs={12}
            style={{ backgroundColor: '#707070', height: 10 }}
          ></MaterialGrid>
        </MaterialGrid>
        <MaterialGrid
          container
          spacing={3}
          style={{
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
          }}
        >
          <MaterialGrid item xs={12}>
            <Typography variant="body2" color="primary">
              Resumo do pedido
            </Typography>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid
          container
          spacing={3}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <MaterialGrid item xs={12}>
            <Typography
              style={{
                color: theme.palette.text.main,
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              {props.action.name}
            </Typography>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid
          container
          spacing={3}
          style={{ marginBottom: theme.spacing(1) }}
        >
          <MaterialGrid item xs={12}>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12}>
                <Typography variant="body2" color="primary">
                  Criado por: Fan.Projects
                </Typography>
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid container spacing={3} justify="space-between">
              <MaterialGrid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  py={3}
                  style={{
                    borderStyle: 'dashed',
                    borderWidth: '1px 0px 0px 0px',
                    borderColor: 'lightgray',
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ color: theme.palette.text.main }}
                  >
                    Valor desta
                  </Typography>
                  <Typography
                    variant="body2"
                    align="right"
                    style={{ color: theme.palette.text.main, fontWeight: 700 }}
                  >
                    R${' '}
                    {parseFloat(props.body.action_price)
                      .toFixed(2)
                      .replace('.', ',')}
                  </Typography>
                </Box>
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid container spacing={3} justify="space-between">
              <MaterialGrid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  py={3}
                  style={{
                    borderStyle: 'dashed',
                    borderWidth: '1px 0px 0px 0px',
                    borderColor: 'lightgray',
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ color: theme.palette.text.main }}
                  >
                    Créditos disponíveis
                  </Typography>
                  <Typography
                    variant="body2"
                    align="right"
                    style={{ color: theme.palette.text.main, fontWeight: 700 }}
                  >
                    R${' '}
                    {parseFloat(avaliable_points)
                      .toFixed(2)
                      .replace('.', ',')}
                  </Typography>
                </Box>
              </MaterialGrid>
            </MaterialGrid>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid container spacing={3} justify="center">
          <MaterialGrid item xs={4}>
            <PrimaryButton
              style={{ padding: '1rem 4rem' }}
              onClick={() => handleBuyAction()}
              type="button"
              disabled={props.general.buttonLoading}
              progress={props.general.submitProgress}
            >
              {props.general.buttonLoading ? 'Contratando' : 'Contratar'}
            </PrimaryButton>
          </MaterialGrid>
        </MaterialGrid>
      </MaterialGrid>
    </MaterialGrid>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    actions: state.actions,
    general: state.general,
    points: state.points,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { buyActionTemplate, getEstablishmentPoints },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyAction);
