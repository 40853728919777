import money from 'assets/img/money_cashback.png';
import { BASE_S3 } from 'config/consts';
import React from 'react';
import Lottie from 'react-lottie';

export default function GeneralFeedback(props) {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: props.lotties.detail.json,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',

        backgroundColor: '#FAFAFA',
      }}
    >
      <div
        className="d-flex justify-content-center align-items-center flex-column"
        style={{
          marginTop: 50,
          width: 662,
          backgroundColor: 'white',
          boxShadow: '0px 2.2127px 4.4254px 1.10635px rgba(0, 0, 0, 0.2)',
          overflowY: 'scroll',
          height: 'calc(100vh - 310px)',
          overflowX: 'hidden',
          paddingRight: 15,
        }}
      >
        {props.formValues.feedback_lottie_id ? (
          <Lottie options={defaultOptions} height={200} width={200} />
        ) : null}
        {props.formValues.feedback_message ? (
          <div
            style={{ maxWidth: 630 }}
            dangerouslySetInnerHTML={{
              __html: props.formValues.feedback_message,
            }}
          ></div>
        ) : null}
        <div className="d-flex justify-content-between align-items-center">
          {parseFloat(props.formValues.points) > 0 ? (
            <div
              className="d-flex justify-content-center align-items-center flex-row"
              style={{
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '16px',
                lineWeight: '160%',
                letterSpacing: '0.25px',
                color: '#000000',
                padding: 20,
                alignSelf: 'flex-start',
                flexWrap: 'wrap',
                width: props.formValues.isThereTrophy ? '50%' : '100%',
              }}
            >
              <img
                style={{ width: 30, height: 'auto', marginRight: 10 }}
                src={money}
                alt="money"
              />
              <span>
                R${' '}
                {parseFloat(props.formValues.points)
                  .toFixed(2)
                  .replace('.', ',')}{' '}
              </span>
            </div>
          ) : null}

          {props.formValues.isThereTrophy ? (
            <div
              className="d-flex justify-content-center align-items-center flex-row"
              style={{ marginLeft: 10 }}
            >
              <img
                src={`${BASE_S3}/${props.formValues.trophy_image}`}
                style={{ height: 50, width: 50 }}
                alt="money"
              ></img>
              <div
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineWeight: '160%',
                  letterSpacing: '0.25px',
                  color: '#000000',
                  padding: 20,
                  alignSelf: 'flex-start',
                }}
              >
                {props.formValues.trophy_description}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
