import React from 'react';
import { IndexRoute, Route } from 'react-router';
import Website from './website';
import WebsiteForm from './websiteForm';

export default (
  <Route path="website">
    <IndexRoute component={props => <Website />} />
    <Route path="create" component={props => <WebsiteForm {...props} />} />
  </Route>
);
