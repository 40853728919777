import Spinner from 'common/components/spinner/spinner';
import Page from 'interfaces/page';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table6 from '../../../../../common/components/table/table6';
import { getList, remove } from './cupomOffersActions';

class CupomOffer extends Page {
  constructor(props) {
    super(props);
    const { getList, auth } = this.props;
    getList(auth.user.establishments[0].id);
    this.state = {
      columns: [
        { title: 'Cupom', field: 'cupom.name' },
        { title: 'Empresa de Destino', field: 'establishment.name' },
        { title: 'Código', field: 'cupom.code' },
        { field: 'accepted', title: 'Status' },
      ],
    };
  }

  handleDelete = (id, resolve, reject) => {
    this.props.remove(
      id,
      resolve,
      reject,
      this.props.auth.user.establishments[0].id
    );
  };

  render() {
    const { content } = this.props.cupomOffer.list;
    const { columns } = this.state;
    const { router } = this.props;

    return this.props.cupomOffer.loading ? (
      <Spinner />
    ) : (
      <div className="container-fluid" style={{ marginTop: 70 }}>
        {/* <SimpleTable
                    rows={content}
                    columns={this.state.columns}
                    onRowsDelete={this.handleDelete}
                    title="Notícias"
                    actions_permissions={["can_edit", "can_remove"]}
                    router={this.props.router}
                    handleDelete={this.handleDelete}
                    tableType="no_actions"
                /> */}
        <Table6
          localization={{
            localization: { toolbar: { searchPlaceholder: 'Procurar' } },
          }}
          title="Lista"
          columns={columns}
          data={content}
          router={router}
          handleDelete={this.handleDelete}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    cupomOffer: state.cupomOffer,
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getList, remove }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(CupomOffer);
