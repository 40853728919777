import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModuleCard } from 'common/components/cards/moduleCard/moduleCard';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import { bindActionCreators } from "redux";

class OwnerUsers extends Component {
  render() {
    return (
      <section className="modules">
        <div className="container-fluid">
          <ModuleCard
            cols="12 3 3 3"
            path="/owner/owner-users/manage-users"
            text={'Gerir meus usuários'}
            bgc={'white'}
            color="#2b2d6b"
            icon={
              <FontAwesomeIcon
                style={{ fontSize: 50, margin: 20 }}
                className="history-icon"
                icon={faUser}
              />
            }
          />
          <ModuleCard
            cols="12 3 3 3"
            path="/owner/owner-users/activate"
            text={'Como ativar um usuário?'}
            bgc={'white'}
            color="#2b2d6b"
            icon={
              <FontAwesomeIcon
                style={{ fontSize: 50, margin: 20 }}
                className="history-icon"
                icon={faUser}
              />
            }
          />
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerUsers);
