import { BASE_S3 } from 'config/consts';
import { shade } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 130px;
  padding: 15px 0;
  border-bottom: 1px solid #c4c4c4;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0 15px;
  flex: 1;

  p {
    margin: 0;
  }

  svg {
    cursor: pointer;
  }

  section {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
`;

export const ItemImage = styled.div`
  cursor: pointer;
  background-image: ${props => `url(${BASE_S3}/${props.image})`};
  user-select: none;
  width: 178px;
  height: 100px;
  background-position: center;
  background-size: cover;

  ${props =>
    props.description &&
    css`
      width: 600px;
      height: 337px;
    `}
`;

export const Title = styled.p`
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  width: 100%;
  height: fit-content;
  user-select: none;
`;
export const Quantity = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  user-select: none;
  border: 1px solid ${props => '#2D95EF'};
  box-sizing: border-box;
  border-radius: 3px;

  svg {
    color: ${props => '#2D95EF'};
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: ${props => `${shade(0.2, '#2D95EF')}`};
    }
  }

  p {
    font-size: 14px;
    height: fit-content;
    color: ${props => '#2D95EF'};
    margin: 0 8px 2px 8px;
  }
`;

export const Prices = styled.div`
  user-select: none;
  display: flex;
  align-items: flex-end;
  min-width: 150px;

  span:nth-child(1) {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    height: fit-content;
    line-height: 16px;
    align-items: center;
    color: ${props => '#2D95EF'};
  }

  span:nth-child(2) {
    margin-left: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    height: fit-content;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-decoration-line: line-through;
    color: #c4c4c4;
  }
`;

export const FinalPrice = styled.div`
  display: flex;
  min-width: 150px;
  justify-content: flex-end;
  align-items: flex-end;
  color: #c4c4c4;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;

  span {
    margin-left: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #102c2d;
  }
`;

export const DescriptionContainer = styled.div`
  padding: 70px 90px;
  display: flex;
  flex-direction: column;

  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.01em;
    color: #102c2d;
  }

  span {
    margin-top: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
  }
`;

export const RadioSelect = styled.div`
  height: 50px;
  width: 25px;

  label {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover input ~ span section {
      display: block;
      background-color: #c4c4c4;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    input:checked ~ span section {
      display: block;
    }

    span {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: transparent;
      border: 2px solid ${props => '#2D95EF'};
      border-radius: 50%;

      section {
        display: none;
        height: 15px;
        width: 15px;
        background-color: #c4c4c4;
        border-radius: 50%;
      }
    }
  }
`;

export const SeeMore = styled.span`
  color: ${props => '#2D95EF'};
  font-size: 14px;
  cursor: pointer;
`;
