import GeneralButton from 'common/components/buttons/generalButton';
import Grid from 'common/components/layout/grid/grid';
import { setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  InputLabelControl,
  SelectLabel,
} from '../../../../../common/components/fields/fields';
import { getList } from '../coupons/couponsActions';
import {
  create,
  getDetail,
  getStationsList,
  initializeForm,
  sendValuesToEstablishment,
  setDetail,
  update,
} from './cupomOffersActions';

class CouponsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      selected: [],
      all: undefined,
    };

    this.props.getList(this.props.auth.user.establishments[0].id);
    this.props.getStationsList();
    const { coupons, router, location, getDetail, setDetail } = this.props;
    setFormValue(coupons, router, location, getDetail, setDetail);
  }

  onSubmit = values => {
    const { router } = this.props;
    values['all'] = true;
    values['partner'] = this.props.auth.user.establishments[0].id;
    if (this.state.step === 1) {
      values['all'] = false;
      if (this.state.selected.length > 0) {
        values['establishments'] = this.state.selected;
      } else {
        toastr.error('Atenção', 'Selecione as estações desejadas');
      }
    } else {
      toastr.error(
        'Atenção',
        'Selecione se vai querer enviar para todas as estações ou não'
      );
    }
    this.props.sendValuesToEstablishment(values, router);
  };

  componentWillReceiveProps(props) {
    if (!_.isUndefined(props.formValues)) {
      this.setState({ ...props.formValues });
    }
  }
  carTemplate(option) {
    return (
      <div className="p-clearfix">
        <span style={{ fontSize: '1em', float: 'right', marginTop: '4px' }}>
          {option.label}
        </span>
      </div>
    );
  }

  selectedCarTemplate(label) {
    if (label) {
      return (
        <div className="my-multiselected-item-token">
          <span>{label}</span>
        </div>
      );
    } else {
      return (
        <span className="my-multiselected-empty-token">
          Escolha entre as estações
        </span>
      );
    }
  }

  render() {
    const { handleSubmit, coupons, cupomOffer } = this.props;

    const stationsList = cupomOffer.stationsList.content.map(item => ({
      value: item.id,
      label: item.name,
    }));

    const couponsList = coupons.list.content.map(item => ({
      value: item.id,
      label: item.name,
    }));

    const itemsSelect = [
      { label: 'Selecionar estações', value: 1 },
      { label: 'Enviar para todas as estações', value: 2 },
    ];

    return (
      <div
        className="container-fluid"
        style={{ marginTop: 70, paddingBottom: 20 }}
      >
        <div className="row">
          <div className="col-12">
            <Form role="form" onSubmit={handleSubmit(this.onSubmit)} noValidate>
              <Grid cols="12 12 3 3" style={{ textAlign: 'center' }}></Grid>
              <center>
                <Field
                  component={SelectLabel}
                  type="text"
                  label={`Selecione um dos seus Produtos`}
                  name={`cupom`}
                  cols="12 12 6 6"
                  validate={[FORM_RULES.required]}
                  options={couponsList}
                  style={{
                    border: '1px #FF007B',
                    fontFamily: 'Roboto',
                    fontSize: 16,
                    borderRadius: 0,
                    padding: '23px 10px',
                    fontWeight: 400,
                  }}
                  borderColor="#FF007B"
                />
              </center>
              <Grid cols="12 12 3 3" style={{ textAlign: 'center' }}></Grid>
              <Grid cols="12 12 12 12">
                <Grid cols="12 12 3 3" style={{ textAlign: 'center' }}></Grid>
                <center>
                  <Field
                    component={InputLabelControl}
                    type="text"
                    label={`Escreva uma mensagem explicando o porquê do pedido.`}
                    name={'message'}
                    cols="12 12 6 6"
                    validate={[FORM_RULES.required]}
                  />
                </center>
                <Grid cols="12 12 3 3" style={{ textAlign: 'center' }}></Grid>
              </Grid>
              <center>
                <Grid cols="12 12 12 12" style={{ textAlign: 'center' }}>
                  <SelectButton
                    value={this.state.step}
                    options={itemsSelect}
                    onChange={e => this.setState({ step: e.value })}
                  ></SelectButton>
                </Grid>
              </center>
              {this.state.step === 1 ? (
                <center>
                  <Grid
                    cols="12 12 12 12"
                    style={{ textAlign: 'center', marginTop: 20 }}
                  >
                    <MultiSelect
                      value={this.state.selected}
                      options={stationsList}
                      onChange={e => this.setState({ selected: e.value })}
                      style={{ minWidth: '12em' }}
                      filter={true}
                      placeholder="Escolha as Estações"
                    />
                  </Grid>

                  <Grid cols="12 12 12 12" style={{ textAlign: 'center' }}>
                    <GeneralButton
                      style={{
                        backgroundColor: 'white',
                        color: '#FF007B',
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        fontWeight: '700',
                        letterSpacing: '0.1em',
                        marginRight: '1rem',
                        border: '1px solid #FF007B',
                      }}
                      label="Enviar"
                      type="submit"
                    />
                  </Grid>
                </center>
              ) : this.state.step === 2 ? (
                <center>
                  <Grid cols="12 12 12 12" style={{ textAlign: 'center' }}>
                    <GeneralButton
                      style={{
                        backgroundColor: 'white',
                        color: '#FF007B',
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        fontWeight: '700',
                        letterSpacing: '0.1em',
                        marginRight: '1rem',
                        border: '1px solid #FF007B',
                      }}
                      label="Enviar"
                      type="submit"
                    />
                  </Grid>
                </center>
              ) : null}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

CouponsForm = reduxForm({ form: 'couponsForm' })(CouponsForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    cupomOffer: state.cupomOffer,
    coupons: state.coupons,
    establishments: state.establishments,
    formValues: getFormValues('couponsForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStationsList,
      getDetail,
      setDetail,
      sendValuesToEstablishment,
      getList,
      initializeForm,
      create,
      update,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponsForm);
