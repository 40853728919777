import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { tableSearch } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getStationHistory } from './actionsHistoryActions';
import { onClickActionResults } from '../activeActions/activeActionsScripts';
import CardActionInfo from 'common/components/cards/cardActionInfo/cardActionInfo.jsx';
import moment from 'moment';

function ActionsHistory(props) {
  const { auth } = props;
  const content = props.actionsHistory.list;

  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  useEffect(() => {
    props.getStationHistory(auth.user.establishments[0].id);
  }, []);

  useEffect(() => {
    setData(props.actionsHistory.list.slice(0).reverse());
  }, [props.actionsHistory.list]);

  function handleChange(e) {
    setData(tableSearch(e, props.actionsHistory.list.slice(0).reverse()));
    setValue(e.target.value);
  }

  function renderEmptyScreen() {
    return (
      <section
        id="actionsHistory"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui atividades arquivadas
                  </h2>
                  <p className="">
                    Atividades são campanhas que você poderá distribuir para
                    seus associados.
                  </p>

                  <PrimaryButton
                    onClick={() =>
                      props.router.push(
                        '/owner/acoes/minhas-atividades/?to=online'
                      )
                    }
                    color="primary"
                  >
                    Ver as atividades ativas
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração Cupons"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.actionsHistory.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            justify="space-between"
            alignContent="space-between"
          >
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}
              >
                Lista de Atividades Arquivadas
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {props.actionsHistory.list.length} atividades arquivadas
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              {data.length > 0 ? (
                data.map((item, index) => (
                  <CardActionInfo
                    index={item.id}
                    data={item}
                    summary={item.summary}
                    hasResults
                    reactivate
                    condition={moment(new Date()).isBetween(
                      item.start,
                      item.end
                    )}
                    onClickResults={() =>
                      props.onClickActionResults(
                        item,
                        props.router,
                        'arquivadas'
                      )
                    }
                  />
                ))
              ) : (
                <div style={{ marginTop: 20 }}>
                  Nenhum resultado encontrado...
                </div>
              )}
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    actionsHistory: state.actionsHistory,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getStationHistory, onClickActionResults },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionsHistory);
