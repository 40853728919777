import { Box } from '@material-ui/core';
import { String2Currency } from 'helpers/dataTransformers';
import React from 'react';
import MoneyPig from '../../svg/moneyPig';
import { Container } from './styles';

export default function PriceCard(props) {
  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span style={{ color: '#707070', fontSize: 12 }}>Valor</span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            width: 60,
            height: 60,
            background: '#f7f7f7',
          }}
        >
          <MoneyPig color={props.secudaryColor} />
        </div>
      </Box>
      <Box display="flex" flexDirection="column">
        {props.price === props.newPrice ? (
          <strong style={{ fontSize: 24, color: props.secudaryColor }}>
            {String2Currency(props.newPrice)}
          </strong>
        ) : (
          <>
            <span style={{ color: '#a1a1a1', fontSize: 12 }}>
              De R${' '}
              <span
                style={{
                  fontSize: 16,
                  textDecoration: 'line-through',
                }}
              >
                {parseFloat(props.price)
                  .toFixed(2)
                  .replace('.', ',')}
              </span>{' '}
              por
            </span>
            <strong style={{ fontSize: 24, color: props.secudaryColor }}>
              {String2Currency(props.newPrice)}
            </strong>{' '}
          </>
        )}
      </Box>
      <span style={{ fontSize: 16, color: '#707070' }}>
        Este é o valor a ser pago para a empresa parceira na contratação.
      </span>
    </Container>
  );
}
