import { Box } from '@material-ui/core';
import React from 'react';
import ActivityButton from '../../buttons/activityButton';
import CashbackCard from '../secudaryActivitiesCards/cashbackCard';
import PriceCard from '../secudaryActivitiesCards/priceCard';
import ShareCard from '../secudaryActivitiesCards/shareCard';
import './moduleCard.css';

let CallToActionCard = function(props) {
  if (props.formValues.type_id === 1) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}
        >
          <CashbackCard
            type={props.formValues.type_id}
            cashback={props.formValues.points_inviter}
            invitedCashback={props.formValues.points_invited}
            secudaryColor={'#2D95EF'}
          />
        </Box>
        <ActivityButton
          color={'#2D95EF'}
          label={props.formValues.click_me_label}
        />
      </>
    );
  } else if (props.formValues.type_id === 2) {
    return null;
  } else if (props.formValues.type_id === 3) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}
        >
          {parseFloat(props.formValues.price).toFixed(2) > 0.0 ? (
            <PriceCard
              price={props.formValues.price}
              newPrice={props.formValues.newPrice}
              secudaryColor={'#2D95EF'}
            />
          ) : null}
          {(props.formValues.points > 0 && props.formValues.isPaid) ||
          props.formValues.additional_cashback ? (
            <CashbackCard
              type={props.formValues.type_id}
              cashback={
                parseFloat(props.formValues.points) +
                (props.formValues.additional_cashback
                  ? props.formValues.additional_cashback
                  : 0.0)
              }
              secudaryColor={'#2D95EF'}
            />
          ) : null}
        </Box>
        <ActivityButton
          color={'#2D95EF'}
          label={props.formValues.click_me_label}
        />
      </>
    );
  } else if (props.formValues.type_id === 4) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}
        >
          {parseFloat(props.formValues.price).toFixed(2) > 0 ? (
            <PriceCard
              price={props.formValues.price}
              newPrice={props.formValues.discount_price}
              secudaryColor={'#2D95EF'}
            />
          ) : null}
          {(props.formValues.points > 0 && props.formValues.isPaid) ||
          props.formValues.additional_cashback ? (
            <CashbackCard
              type={props.formValues.type_id}
              cashback={
                parseFloat(props.formValues.points) +
                (props.formValues.additional_cashback
                  ? props.formValues.additional_cashback
                  : 0.0)
              }
              secudaryColor={'#2D95EF'}
            />
          ) : null}
        </Box>

        <ActivityButton
          color={'#2D95EF'}
          presential_course
          label={props.formValues.click_me_label}
        />
      </>
    );
  } else if (props.formValues.type_id === 5) {
    return (
      <ShareCard
        cashback={
          parseFloat(props.formValues.points) +
          (props.formValues.additional_cashback
            ? props.formValues.additional_cashback
            : 0.0)
        }
        router={props.router}
        onClick={props.onClick}
        url={props.activities.detail.contents[0].link}
        title={props.activities.detail.contents[0].text}
        secudaryColor={'#2D95EF'}
      />
    );
  } else if (
    props.formValues.type_id === 7 ||
    props.formValues.type_id === 8 ||
    props.formValues.type_id === 6 ||
    props.formValues.type_id === 9 ||
    props.formValues.type_id === 10 ||
    props.formValues.type_id === 11 ||
    props.formValues.type_id === 12
  ) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}
        >
          {(props.formValues.points > 0 && props.formValues.isPaid) ||
          props.formValues.additional_cashback ? (
            <CashbackCard
              type={props.formValues.type_id}
              cashback={
                parseFloat(props.formValues.points) +
                (props.formValues.additional_cashback
                  ? props.formValues.additional_cashback
                  : 0.0)
              }
              secudaryColor={'#2D95EF'}
            />
          ) : null}
        </Box>
        <ActivityButton
          color={'#2D95EF'}
          label={props.formValues.click_me_label}
        />
      </>
    );
  } else if (props.formValues.type_id === 14) {
    return (
      <>
        <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', marginBottom: 40 }}
        >
          {(props.formValues.points > 0 && props.formValues.isPaid) ||
          props.formValues.additional_cashback ? (
            <CashbackCard
              type={props.formValues.type_id}
              cashback={
                parseFloat(props.formValues.points) +
                (props.formValues.additional_cashback
                  ? parseFloat(props.formValues.additional_cashback)
                  : 0.0)
              }
              secudaryColor={'#2D95EF'}
            />
          ) : null}
        </Box>
        <ActivityButton
          color={'#2D95EF'}
          label={props.formValues.click_me_label}
        />
      </>
    );
  } else return null;
};

export default CallToActionCard;
