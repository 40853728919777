import axios from 'axios';
import type from './types';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';

const URL = `${BASE_API}/partner-cupoms`;

export const getList = id => {
  return dispatch => {
    dispatch({ type: type.SUBSCRIPTIONS_LOAD, payload: true });
    axios
      .get(`${URL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        response.data.forEach(item => {
          item['used'] = item.used ? 'Sim' : 'Não';
          item['createdAt'] = moment(item.createdAt).format('DD/MM/YYYY HH:MM');
        });
        dispatch([
          {
            type: type.SUBSCRIPTIONS_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};
