import Content from 'common/components/content/content';
import Grid from 'common/components/layout/grid/grid';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React, { Component } from 'react';
import Lottie from 'react-lottie';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  DatePickerComponent,
  InputAnyFile,
  InputLabelControl,
  SelectLabel,
  TextLabel,
} from '../../../../../common/components/fields/fields';
import { getQuizzes, postAction } from './engajeReadingActions';

class EngajeReading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
    this.props.getQuizzes(this.props.auth.user.establishments[0].id);
  }
  componentWillMount() { }

  onSubmit = values => {
    values['establishment'] = this.props.auth.user.establishments[0].id;
    this.props.postAction(values, this.props.router);
  };

  renderContent = () => {
    const { formValues } = this.props;
    if (!_.isUndefined(formValues)) {
      if (!_.isUndefined(formValues.contentType)) {
        if (formValues.contentType === 'media') {
          return (
            <Field
              component={InputLabelControl}
              id="floating-center-title"
              label={'Coloque aqui o link da sua música ou vídeo'}
              name="media"
              cols="12 12 12 12"
              validate={[FORM_RULES.required]}
            />
          );
        } else if (formValues.contentType === 'file') {
          return (
            <Field
              component={InputAnyFile}
              id="floating-center-title"
              label={'Arquivo para leitura'}
              name="file"
              type="text"
              cols="12 12 12 12"
              placeholder="Arraste seu arquivo de conteúdo aqui"
              validate={[FORM_RULES.required]}
            />
          );
        }
      }
    }
  };

  render() {
    const { handleSubmit } = this.props;

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: require('assets/lottie/2420-network.json'),
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };
    if (this.props.quizzes.loading) {
      return (
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
      );
    }
    const quizzesList = this.props.quizzes.list.map(item => ({
      value: item.id,
      label: item.name,
    }));
    return (
      <Content>
        <Card>
          <CardTitle title="Gerar engajamento ao consumir conteúdo" />
          <CardText>
            <div className="container-fluid">
              <center>
                <h3>Interação</h3>
              </center>
              <p>
                Esta atividade necessita de um <b>QUIZ</b> e algum tipo de
                conteúdo.
              </p>
              <Form
                role="form"
                onSubmit={handleSubmit(this.onSubmit)}
                noValidate
              >
                {quizzesList.length < 1 ? (
                  <div>
                    <p>
                      <b>
                        Ops, parece que você não tem um quiz cadastrado! Clique
                        aqui e cadastre o seu primeiro.
                      </b>
                    </p>
                    <Link to="/owner/manage-quiz/create/engaje-reading">
                      <button className="btn btn-primary">
                        Cadastrar um Quiz
                      </button>
                    </Link>
                  </div>
                ) : (
                  <div>
                    <Field
                      component={SelectLabel}
                      className="md-cell md-cell--bottom"
                      id="floating-center-title"
                      label={'Selecione seu Quiz'}
                      options={quizzesList}
                      name="quiz"
                      cols="12 12 6 6"
                      validate={[FORM_RULES.required]}
                    />
                    <Grid cols="12 12 2 2">
                      <div className={`form-group`}>
                        <label className="control-label">
                          Caso queira outro
                        </label>
                        <Link to="/owner/manage-quiz/create/engaje-reading">
                          <button className="btn btn-primary">
                            Cadastrar um Quiz
                          </button>
                        </Link>
                      </div>
                    </Grid>
                  </div>
                )}
                <Field
                  component={SelectLabel}
                  className="md-cell md-cell--bottom"
                  id="floating-center-title"
                  label={'Selecione seu conteúdo'}
                  options={[
                    { value: 'file', label: 'Arquivo' },
                    { value: 'media', label: 'Link de música ou vídeo' },
                  ]}
                  name="contentType"
                  cols="12 12 12 12"
                  validate={[FORM_RULES.required]}
                />

                {this.renderContent()}
                <Grid cols="12 12 12 12">
                  <center>
                    <h3>Regras</h3>
                  </center>
                </Grid>
                <Field
                  component={InputLabelControl}
                  label={
                    'Quantos pontos o usuário vai ganhar ao realizar essa atividade'
                  }
                  name="points"
                  cols="12 12 6 6"
                  type="number"
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={InputLabelControl}
                  label={'Quantos usuários podem realizar essa atividade'}
                  name="limit"
                  type="number"
                  cols="12 12 6 6"
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={DatePickerComponent}
                  className="md-cell md-cell--bottom"
                  id="floating-center-title"
                  label={'Quando essa atividade expira'}
                  name="expiration"
                  cols="12 12 12 12"
                  validate={[FORM_RULES.required]}
                />
                <Grid cols="12 12 12 12">
                  <center>
                    <h3>Conteúdo</h3>
                  </center>
                </Grid>
                <Field
                  component={InputLabelControl}
                  label={'Nome dessa atividade'}
                  name="name"
                  type="text"
                  cols="12 12 6 6"
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={TextLabel}
                  label={'Descrição dessa Atividade'}
                  name="description"
                  type="text"
                  cols="12 12 6 6"
                  validate={[FORM_RULES.required]}
                />
                <Grid cols="12 12 12 12">
                  <center>
                    <button className="btn btn-primary" type="submit">
                      Enviar atividade
                    </button>
                  </center>
                </Grid>
              </Form>
            </div>
          </CardText>
        </Card>
      </Content>
    );
  }
}

EngajeReading = reduxForm({ form: 'engajeReadingForm' })(EngajeReading);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    quizzes: state.quizzes,
    formValues: getFormValues('engajeReadingForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getQuizzes, postAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EngajeReading);
