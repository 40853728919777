export const BASE_API =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'elasticbeanstalk'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'http://localhost:8080/api'
    : 'http://localhost:8080/api';

export const BASE_AUTH =
  process.env.REACT_APP_API_KEY === 'production'
    ? 'elasticbeanstalk/auth'
    : process.env.REACT_APP_API_KEY === 'test'
    ? 'http://localhost:8080/api/auth'
    : 'http://localhost:8080/api/auth';

export const BASE_S3 = 'https://s3.amazonaws.com/rilato.app.files';

export const USER_KEY = '_fermentoLabPartnerUser';
export const USER_TOKEN = '_fermentoLabPartnerUserToken';
