import { CardMedia, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { appColors } from '../../../styles/colors';
import { isMobile } from 'react-device-detect';
import { truncateString } from '../../../helpers/utils';
import img_actions from 'assets/img/galeria_de_atividades.png';

const styles = {
  card: {
    // width: 265,
    // height: 400,
    // boxShadow:
    //   '0px 16px 8px -4px rgba(0,0,0,0.05), 0px 13px 19px 2px rgba(0,0,0,0.05), 0px 5px 24px 4px rgba(0,0,0,0.05)',
    position: 'relative',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
    fontFamily: 'Roboto',
    fontWeight: '200',
    fontSize: 14,
    textOverflow: 'ellipsis',
    textAlign: 'left',
  },
  media: {
    height: 190,
  },
  type: {
    fontFamily: 'Roboto',
    letterSpacing: 3,
    fontWeight: '400',
    fontSize: 11.5,
    textAlign: 'left',
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 18,
    textAlign: 'left',
    cursor: 'pointer',
  },
  seeMore: {
    float: 'right',
    color: appColors.primary,
  },
  edit: {
    float: 'left',
    color: 'gray',
  },
};

function SimpleCard(props) {
  const {
    classes,
    cardEmpty,
    styleCard,
    horizontalCard,
    image,
    onClick,
    name,
    description,
    doneAction,
    buttonLabel,
  } = props;

  if (cardEmpty) {
    return (
      <Card
        onClick={props.onClick}
        elevation={0}
        style={{
          ...styleCard,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          cursor: 'pointer',
          width: horizontalCard ? 270 : 'default',
          height: horizontalCard ? 300 : 'default',
          border: '1px solid lightgray',
        }}
        className={classes.card}
      >
        <img
          src={img_actions}
          alt={'img loja de atividades'}
          style={{ maxHeight: 130, width: 'auto' }}
        />
        <Typography className={classes.title} style={{ marginTop: 10 }}>
          Abrir loja de atividades
        </Typography>
      </Card>
    );
  }

  return (
    <Card
      elevation={0}
      style={{
        ...props.styleCard,
        width: isMobile ? 178 : horizontalCard ? 270 : 265,
        height: isMobile ? 152 : horizontalCard ? 300 : 345,
        border: '1px solid lightgray',
      }}
      className={classes.card}
    >
      <CardMedia
        style={{ height: isMobile ? 100 : horizontalCard ? 155.81 : 149.0625 }}
        className={classes.media}
        image={image}
      />
      <CardContent
        style={{
          display: isMobile ? 'none' : undefined,
          paddingTop: 5,
        }}
      >
        <div style={{ marginBottom: 15 }}>
          <Typography className={classes.type}></Typography>
          <Typography onClick={onClick} className={classes.title}>
            {props.name}
          </Typography>
        </div>
        <Typography
          className={classes.pos}
          color="textSecondary"
          style={{ overflowWrap: 'break-word' }}
        >
          {description ? truncateString(description) : ''}
        </Typography>
      </CardContent>
      <CardActions
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: doneAction ? 'space-between' : 'flex-start',
        }}
      >
        {/* Keep disabled till edits activity is available  */}
        {/* {doneAction ? (
          <Button
            className={classes.edit}
            onClick={onClickEdit}
            size="small"
          >
            {'Editar'}
          </Button>
        ) : null} */}
        <Button
          className={classes.seeMore}
          onClick={props.onClick}
          size="small"
          style={{
            textTransform: 'none',
            color: isMobile ? '#242424' : null,
            padding: 0,
          }}
        >
          {isMobile ? name : buttonLabel}
        </Button>
      </CardActions>
    </Card>
  );
}

SimpleCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SimpleCard);
