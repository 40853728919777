import React, { Component } from 'react';
import { CircularProgress } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { activeUser } from '../authActions';

class ActivateUser extends Component {
  componentDidMount() {
    const { router } = this.props;
    console.log(router.params.hash, router.params.email);
    this.props.activeUser(
      router.params.hash,
      router.params.email,
      this.props.router
    );
  }

  render() {
    return <CircularProgress />;
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ activeUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateUser);
