import React from 'react';
import { IndexRoute, Route } from 'react-router';
import ManageUsers from './manage';

export default (
  <Route path="manage-users">
    <IndexRoute component={props => <ManageUsers {...props} />} />
    {/* 
        <Route path='create' component={(props) => <QuizCreateForm {...props} />} />
        <Route path='manage' component={(props) => <ManageQuiz {...props} />} /> */}
  </Route>
);
