import { String2Currency } from 'helpers/dataTransformers';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Container,
  InfoContainer,
  ItemImage,
  Prices,
  RadioSelect,
  SeeMore,
  Title,
} from './styles';

let ItemCard = function({ data }) {
  const price = useMemo(() => {
    return String2Currency(data.value);
  }, [data.value]);

  const discountPrice = useMemo(() => {
    return String2Currency(data.value - data.discount_value);
  }, [data.discount_value, data.value]);

  return (
    <>
      <Container>
        <ItemImage image={data.image} />
        <InfoContainer>
          <Title>{data.name}</Title>
          <SeeMore>Ver mais</SeeMore>
          <section>
            <Prices>
              <span>{discountPrice}</span>
              {data.discount_value > 0 && <span>{price}</span>}
            </Prices>
            <RadioSelect>
              <label>
                <input
                  type="radio"
                  checked={false}
                  name="product"
                  value={data.id}
                />
                <span>
                  <section />
                </span>
              </label>
            </RadioSelect>
          </section>
        </InfoContainer>
      </Container>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard);
