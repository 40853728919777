import { Box, Checkbox, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import logo from 'assets/img/fan_logo.svg';
import MaterialCard from 'common/components/cards/materialCard';
import DialogTemplates from 'common/components/dialog/dialogTemplates';
import LoadingProgress from 'common/components/progress/loading';
import { BASE_S3 } from 'config/consts';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import {
  getTemplates,
  openCupom,
  openMemberGetMember,
  openResearch,
  openSingupAndEarn,
  getTemplatesBought,
  createAction,
  getTemplatesCategories,
  getActionsCategories,
} from './actionsFile';
import ConfirmationCard from '../../../../common/components/cards/confirmationCard/confirmationCard';
import GeneralGrid from '../../../../common/components/layout/grid/GeneralGrid';

class Actions extends Component {
  constructor(props) {
    super(props);
    this.props.getTemplates(this.props.auth.user.establishments[0].id);
    this.props.getTemplatesCategories();
    this.props.getActionsCategories();
    this.state = {
      section: 0,
      open: false,
      item: null,
      actionTypes: [],
      templatesCategories: [],
      filteredTemplatesList: [],
    };
  }

  //Filtra as atividades
  componentDidUpdate(props, state) {
    if (
      props.actions.templatesList.length !==
        this.props.actions.templatesList.length ||
      this.state.actionTypes.length !== state.actionTypes.length ||
      this.state.templatesCategories.length !== state.templatesCategories.length
    ) {
      const templatesList = this.props.actions.templatesList.filter(
        item =>
          item.partners[0] === 'all' ||
          item.partners.indexOf(this.props.auth.user.establishments[0].id) >= 0
      );

      const filteredTemplatesList = templatesList.filter(
        item =>
          this.state.actionTypes.indexOf(item.type_action_id) > -1 ||
          this.state.templatesCategories.indexOf(item.category) > -1
      );
      if (_.isEmpty(filteredTemplatesList)) {
        this.setState({
          filteredTemplatesList: templatesList,
        });
      } else {
        this.setState({ filteredTemplatesList });
      }
    }
  }

  componentDidMount() {
    const templatesList = this.props.actions.templatesList.filter(
      item =>
        item.partners[0] === 'all' ||
        item.partners.indexOf(this.props.auth.user.establishments[0].id) >= 0
    );

    const filteredTemplatesList = templatesList.filter(
      item => this.state.actionTypes.indexOf(item.type_action_id) > -1
    );
    if (_.isEmpty(filteredTemplatesList)) {
      this.setState({
        filteredTemplatesList: templatesList,
      });
    } else {
      this.setState({ filteredTemplatesList });
    }
  }

  handleChangeActionType = (category, e) => {
    let newTypesArray = [];
    if (e.target.checked) {
      newTypesArray = [...this.state.actionTypes, category.id];
    } else {
      newTypesArray = [...this.state.actionTypes].filter(
        item => item !== category.id
      );
    }
    this.setState({
      actionTypes: newTypesArray,
      [category.name.split(' ').join('_')]: e.target.checked,
    });
  };

  handleChangeTemplateCategory = (category, e) => {
    let templatesCategories = [];
    if (e.target.checked) {
      templatesCategories = [...this.state.templatesCategories, category.id];
    } else {
      templatesCategories = [...this.state.templatesCategories].filter(
        item => item !== category.id
      );
    }
    this.setState({
      templatesCategories,
      [category.name.split(' ').join('_')]: e.target.checked,
    });
  };

  handleCreate = item => {
    this.setState({ open: true, item: item });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  onDialogClose = () => {
    this.props.router.push('/owner/acoes/minhas-atividades');
  };

  renderEmptyScreen = () => {
    return (
      <section
        id="actionOffers"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Nào temos novas atividades disponíveis no momento
                  </h2>
                  <p className="">
                    Assim que novas atividades forem incluidas, lhe
                    notificaremos. Enquanto isso desfrute das suas atividades
                    adiquiridas.
                  </p>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração Cupons"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  };

  renderTemplatesList = () => {
    const listTemplates = this.state.filteredTemplatesList.map(
      (item, index) => {
        return (
          <MaterialGrid item key={index}>
            <MaterialCard
              onClick={
                item.has_bought
                  ? () => this.handleCreate(item.id)
                  : () => this.props.router.push(`/owner/acoes/${item.id}`)
              }
              name={item.name}
              has_bought={item.has_bought}
              image={`${BASE_S3}/${item.owner_image || item.image}`}
              description={item.summary}
              buttonLabel={item.has_bought ? 'Ativar' : 'Veja Mais'}
              type={item.type.name}
            />
          </MaterialGrid>
        );
      }
    );

    return (
      <MaterialGrid
        container
        spacing={3}
        justify="flex-start"
        style={{ marginLeft: '0rem', background: '#f7f7f7' }}
      >
        {listTemplates.length > 0 ? listTemplates : this.renderEmptyScreen()}
      </MaterialGrid>
    );
  };

  render() {
    if (this.props.actions.templatesLoading) {
      return (
        <Box display="flex" justifyContent="center" height="100vh" mt={7}>
          <LoadingProgress />
        </Box>
      );
    }
    return (
      <>
        <DialogTemplates
          visible={true}
          height="95vh"
          active={this.handleActive}
          onClose={this.onDialogClose}
          maxWidth="lg"
          title="Escolha uma nova atividade"
        >
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={3} style={{ backgroundColor: 'white' }}>
              <div
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: '50%',
                  display: 'flex',
                  justifySelf: 'center',
                  alignItems: 'center',
                  padding: '0 8px',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={logo}
                  alt="Rilato Logo"
                  style={{ width: '100%', height: 'auto' }}
                />
              </div>
              {this.props.actions.templatesCategories.length > 0 &&
                this.props.actions.templatesCategories.map(category => (
                  <>
                    <Typography
                      style={{
                        fontFamily: 'Roboto',
                        fontWeight: 200,
                        fontSize: 14,
                        textAlign: 'left',
                        marginTop: 20,
                        color: '#2D95EF',
                      }}
                    >
                      {category.name}
                    </Typography>
                    {category.categories.map(item => (
                      <div
                        className="d-flex align-items-center"
                        style={{ marginLeft: 0 }}
                      >
                        <Checkbox
                          checkboxClass="icheckbox_square-green"
                          checked={
                            this.state[item.name.split(' ').join('_')] || false
                          }
                          onChange={e =>
                            this.handleChangeTemplateCategory(item, e)
                          }
                        />
                        <span style={{ fontSize: 16 }}>{item.name}</span>
                      </div>
                    ))}
                  </>
                ))}
              <Typography
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 200,
                  fontSize: 14,
                  textAlign: 'left',
                  marginTop: 20,
                  color: '#2D95EF',
                }}
              >
                Interações
              </Typography>
              {this.props.actions.actionTypes.length > 0 &&
                this.props.actions.actionTypes.map(actionType => (
                  <div
                    className="d-flex align-items-center"
                    style={{ marginLeft: 0 }}
                  >
                    <Checkbox
                      checkboxClass="icheckbox_square-green"
                      checked={
                        this.state[actionType.name.split(' ').join('_')] ||
                        false
                      }
                      onChange={e => this.handleChangeActionType(actionType, e)}
                    />
                    <span style={{ fontSize: 16 }}>{actionType.name}</span>
                  </div>
                ))}
            </MaterialGrid>
            <MaterialGrid
              item
              xs={9}
              style={{ background: '#f7f7f7', minHeight: '100vh' }}
            >
              {this.renderTemplatesList()}
            </MaterialGrid>
          </MaterialGrid>
        </DialogTemplates>
        <ConfirmationCard
          onClose={this.handleClose}
          open={this.state.open}
          item={this.state.item}
          station_id={null}
          partner_id={this.props.auth.user.establishments[0].id}
          sponsored={true}
          router={this.props.router}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    coupons: state.coupons,
    contactsSurveys: state.contactsSurveys,
    signupAndEarn: state.signupAndEarn,
    memberGetMember: state.memberGetMember,
    actions: state.actions,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      openCupom,
      openResearch,
      openMemberGetMember,
      openSingupAndEarn,
      getTemplates,
      getTemplatesBought,
      createAction,
      getTemplatesCategories,
      getActionsCategories,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Actions);
