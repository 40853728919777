import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import moment from 'moment';
import { initialize } from 'redux-form';
const URL_USER_ACTIONS = `${BASE_API}/user-actions`;
const URL_ACTION_PAGE = `${BASE_API}/actions`;
const URL_TEMPLATES = `${BASE_API}/action-templates`;
const URL_TEMPLATES_BOUGHT = `${BASE_API}/establishments/buy-action-transactions`;
const URL_BUY_ACTION = `${BASE_API}/buy-action`;
const URL_CREATE_ACTION = `${BASE_API}/create-owner-action`;
const UPLOAD_IMAGE = `${BASE_API}/upload-image-to-s3`;
const URL_TEMPLATE_PAGE = `${BASE_API}/templates`;

export const openCupom = (item, router = undefined, first) => {
  return dispatch => {
    let values = JSON.parse(item.body);
    if (item.type_action_id === 3) {
      if (values.price === 0 && values.newPrice === 0) {
        values.isFree = true;
      } else {
        values.isFree = false;
      }
      if (parseFloat(values.points) === 0) {
        values.isPaid = false;
      } else {
        values.isPaid = true;
      }
      values.expiration = new Date();
      router.push(`/owner/acoes/cupom`);
      dispatch([
        initializeForm('cupomForm', {
          ...values,
          image: item.image,
          model_name: item.name,
          model_description: item.description,
          type: item.type,
        }),
      ]);
    } else if (item.type_action_id === 4) {
      if (parseFloat(values.price) === 0) {
        values.isNoDiscountPriceFree = true;
      } else {
        values.isNoDiscountPriceFree = false;
      }
      if (parseFloat(values.discount_price) === 0) {
        values.isDiscountPriceFree = true;
      } else {
        values.isDiscountPriceFree = false;
      }
      if (parseFloat(values.points) === 0) {
        values.isPaid = false;
      } else {
        values.isPaid = true;
      }
      router.push(`/owner/acoes/curso-presencial`);
      dispatch([
        initializeForm('presentialCoursesForm', {
          ...values,
          image: item.image,
          model_name: item.name,
          model_description: item.description,
          type: item.type,
        }),
      ]);
    }
  };
};

export const openMemberGetMember = (item, router = undefined, first) => {
  return dispatch => {
    let values = JSON.parse(item.body);
    if (parseFloat(values.points_inviter) === 0) {
      values.isInviterPaid = true;
    } else {
      values.isInviterPaid = false;
    }
    if (parseFloat(values.points_invited) === 0) {
      values.isInvitedPaid = true;
    } else {
      values.isInvitedPaid = false;
    }
    router.push(`/owner/acoes/member-get-member`);
    dispatch([
      initializeForm('mgmForm', {
        ...values,
        image: item.image,
        type: item.type,
        model_name: item.name,
        model_description: item.description,
      }),
    ]);
  };
};

export const openSingupAndEarn = (item, router = undefined, first) => {
  return dispatch => {
    let values = JSON.parse(item.body);
    if (parseFloat(values.points) === 0) {
      values.isPaid = false;
    } else {
      values.isPaid = true;
    }
    router.push(`/owner/acoes/signup-earn`);
    dispatch([
      initializeForm('signupAndEarnForm', {
        ...values,
        image: item.image,
        type: item.type,
        model_name: item.name,
        model_description: item.description,
      }),
    ]);
  };
};

export const getUserActions = id => {
  return dispatch => {
    dispatch({ type: 'USER_ACTIONS_LOAD', payload: true });
    axios
      .get(`${URL_USER_ACTIONS}/?where[action_id]=${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        for (let i in response.data) {
          response.data[i]['createdAtDate'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:mm:ss');
        }
        dispatch([
          {
            type: 'USER_ACTIONS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getActionById = id => {
  return dispatch => {
    dispatch({ type: 'ACTION_PAGE_LOAD', payload: true });
    axios
      .get(`${URL_ACTION_PAGE}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'ACTION_PAGE_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const openResearch = (
  item,
  router = undefined,
  first,
  type_action_id
) => {
  return dispatch => {
    let values = JSON.parse(item.body);
    if (parseFloat(values.points) === 0) {
      values.isPaid = false;
    } else {
      values.isPaid = true;
    }
    let obj = {
      name: values.name,
      description: values.description,
      ...values,
      image: item.image,
      type_action_id,
      model_name: item.name,
      model_description: item.description,
      type: item.type,
    };

    dispatch([
      {
        type: 'TEMPLATE_RESEARCH_PARSED',
        payload: values,
      },

      initializeForm('contactsSurveysForm', obj),
    ]);
    router.push(`/owner/acoes/aquisicao-clientes/pesquisa/?template=true`);
  };
};

export const initializeForm = (form, values) => {
  return initialize(form, values);
};

export const getTemplates = id => {
  return dispatch => {
    dispatch({ type: 'TEMPLATES_LOAD', payload: true });
    axios
      .get(
        `${URL_TEMPLATES}/?where[establishment_id]=${id}&&where[type_action_id]=6&&where[type_action_id]=7&&where[type_action_id]=8&&where[type_action_id]=3&&where[type_action_id]=4&&where[type_action_id]=14&&where[type_action_id]=16&&where[type_action_id]=17&&where[type_action_id]=18&&where[is_draft]=${false}`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(response => {
        dispatch([
          {
            type: 'TEMPLATES_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getTemplatesBought = id => {
  return dispatch => {
    dispatch({ type: 'TEMPLATES_BOUGHT_LOAD', payload: true });
    axios
      .get(`${URL_TEMPLATES_BOUGHT}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'TEMPLATES_BOUGHT_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const boughtTemplate = ({ action_id, type_action_id }) => {
  return dispatch => {
    dispatch({
      type: 'BOUGHT_TEMPLATE',
      payload: { action_id, type_action_id },
    });
  };
};

export const handleReviewModal = status => {
  return dispatch => {
    dispatch({ type: 'REVIEW_MODAL', payload: status });
  };
};

export const getTemplateById = id => {
  return dispatch => {
    dispatch({ type: 'TEMPLATE_DETAIL_BY_ID_LOAD', payload: true });
    axios
      .get(`${URL_TEMPLATES}/${id}?where[is_draft]=${false}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'TEMPLATE_DETAIL',
            payload: response.data,
          },
        ]);
      });
  };
};

export const buyActionTemplate = (values, setIsBuyActionModalVisible) => {
  const {
    action_template_id,
    station_id,
    establishment_id,
    partner_id,
    sponsored,
  } = values;

  return dispatch => {
    dispatch([
      { type: 'BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: true },
    ]);
    axios
      .post(
        `${URL_BUY_ACTION}`,
        { action_template_id, establishment_id },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(buyActionResponse => {
        dispatch([
          {
            type: 'TRANSACTION_MADE',
            payload: buyActionResponse.data.transactionMade,
          },
        ]);
        axios
          .post(
            `${URL_CREATE_ACTION}`,
            { action_template_id, station_id, partner_id, sponsored },
            {
              headers: {
                Authorization: `${JSON.parse(
                  localStorage.getItem(USER_TOKEN)
                )}`,
              },
            }
          )
          .then(createActionResponse => {
            dispatch([
              { type: 'BUTTON_LOAD', payload: false },
              { type: 'SUBMIT_PROGRESS', payload: false },
              {
                type: 'SNACKBAR',
                payload: {
                  message: 'Operação realizada com sucesso',
                  variant: 'success',
                  open: true,
                },
              },
              setIsBuyActionModalVisible(false),
              boughtTemplate({
                action_id: createActionResponse.data.action.id,
                type_action_id: createActionResponse.data.action.type_id,
              }),
              { type: 'REVIEW_MODAL', payload: true },
            ]);
          })
          .catch(e => {
            dispatch([
              { type: 'BUTTON_LOAD', payload: false },
              { type: 'SUBMIT_PROGRESS', payload: false },
              setIsBuyActionModalVisible(false),
            ]);
            try {
              for (const i in e.response.data) {
                dispatch({
                  type: 'SNACKBAR',
                  payload: {
                    message: `${e.response.data[i]}`,
                    variant: 'error',
                    open: true,
                  },
                });
              }
            } catch (error) {
              dispatch({
                type: 'SNACKBAR',
                payload: { message: 'Erro', variant: 'error', open: true },
              });
            }
          });
      })
      .catch(e => {
        dispatch([
          { type: 'BUTTON_LOAD', payload: false },
          { type: 'SUBMIT_PROGRESS', payload: false },
          setIsBuyActionModalVisible(false),
        ]);
        try {
          for (const i in e.response.data) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${e.response.data[i]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const createAction = (
  action_template_id,
  station_id,
  partner_id,
  sponsored,
  router
) => {
  return dispatch => {
    dispatch([{ type: 'BUTTON_LOAD', payload: true }]);
    axios
      .post(
        `${URL_CREATE_ACTION}`,
        { action_template_id, station_id, partner_id, sponsored },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(response => {
        const routes = [
          'member-get-member',
          'signup-earn',
          'cupons',
          'curso-presencial',
          'compartilhe',
          'pesquisa',
          'pesquisa',
          'pesquisa',
          'checkList',
          'pollQuiz',
          'rightAnswer',
          'quiz-personalidade',
          'redirecionar-link',
          'entregar-produto',
          'agreement',
          'conteudo-s-i',
          'atividade-doacao',
          'video-playlist',
        ];
        const { id, type_id } = response.data.action;
        dispatch([{ type: 'BUTTON_LOAD', payload: false }]);
        router.push(
          `/owner/acoes/${
            type_id === 6 || type_id === 7 || type_id === 8
              ? 'aquisicao-clientes/'
              : ''
          }${routes[type_id - 1]}/${id}/rascunho`
        );
      })
      .catch(e => {
        dispatch([
          { type: 'BUTTON_LOAD', payload: false },
          {
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          },
        ]);
      });
  };
};

export function uploadImageToS3(base64) {
  return axios
    .post(
      `${UPLOAD_IMAGE}`,
      { base64 },
      {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      }
    )
    .then(response => {
      return response.data.url;
    });
}
export const getTemplatesCategories = () => {
  return dispatch => {
    axios
      .get(`${URL_TEMPLATE_PAGE}/categories-types`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'TEMPLATES_CATEGORY_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const getActionsCategories = () => {
  return dispatch => {
    axios
      .get(`${URL_ACTION_PAGE}/categories`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const filteredActionCategories = response.data.filter(
          item =>
            item.id === 1 ||
            item.id === 2 ||
            item.id === 3 ||
            item.id === 4 ||
            item.id === 6 ||
            item.id === 7 ||
            item.id === 14 ||
            item.id === 17
        );
        dispatch([
          {
            type: 'ACTIONS_CATEGORY_FETCHED',
            payload: filteredActionCategories,
          },
        ]);
      });
  };
};
