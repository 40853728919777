import React from 'react';
import { IndexRoute, Route } from 'react-router';
import QuizCreateForm from './create/quizCreateForm';
import QuizEditForm from './edit/quizEditForm';
import ManageQuiz from './manage/manage';
import Quiz from './quiz';

export default (
  <Route path="manage-quiz">
    <IndexRoute component={props => <Quiz />} />
    <Route
      path="create/:method"
      component={props => <QuizCreateForm {...props} />}
    />
    <Route path="create" component={props => <QuizCreateForm {...props} />} />
    <Route path=":id/editar" component={props => <QuizEditForm {...props} />} />
    <Route path="manage" component={props => <ManageQuiz {...props} />} />
  </Route>
);
