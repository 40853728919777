import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { initialize, reset as resetForm } from 'redux-form';
import type from './types';
import _ from 'lodash';

const URL_UPDATE_PROFILE = `${BASE_API}/user-establishment-profile`;
const URL_SIGNUP_PAGARME = `${BASE_API}/gateway-digital-accounts`;

export const setDetail = values => {
  return dispatch => {
    dispatch({
      type: type.LIST_LOAD,
      payload: true,
    });
    const initialValues = {
      ...values,
    };
    dispatch([
      {
        type: type.ITEM_DETAIL,
        payload: { data: values },
      },
      initializeForm(initialValues),
      reset(),
    ]);
  };
};

export const getPagarmeDetail = id => {
  return dispatch => {
    dispatch({
      type: type.PAGARME_DATA_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_SIGNUP_PAGARME}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        if (!_.isArray(response.data)) {
          const create_acc = response.data;
          const formObject = {
            create_account: {
              bankAccount: {
                accountNumber: create_acc.bank_account.conta,
                accountDv: create_acc.bank_account.conta_dv,
                accountType: create_acc.bank_account.type,
                agencyNumber: create_acc.bank_account.agencia,
                agencyDv: create_acc.bank_account.agencia_dv,
                bankNumber: create_acc.bank_account.bank_code,
                ownerName: create_acc.bank_account.legal_name,
                cpf: create_acc.bank_account.document_number,
              },
              businessUrl: create_acc.register_information.site_url,
              companyType: create_acc.company_type,
              phone: {
                number: create_acc.register_information.phone_numbers[0].number,
                ddd: create_acc.register_information.phone_numbers[0].ddd,
              },
              bankAccountType:
                create_acc.bank_account.document_type === 'cnpj'
                  ? 'pj'
                  : create_acc.bank_account.document_type === 'cpf'
                  ? 'pf'
                  : '',
            },
            managingPartners: create_acc.register_information.managing_partners?.map(
              item => ({
                documentNumber: item.document_number,
                name: item.name,
                email: item.email,
              })
            ),
          };
          dispatch([
            {
              type: type.PAGARME_DATA_FETCHED,
              payload: { data: formObject },
            },
            initialize('GatewayForm', formObject),
          ]);
        } else {
          dispatch({
            type: type.PAGARME_DATA_LOAD,
            payload: false,
          });
        }
      })
      .catch(e => {
        console.error(e);
        try {
          dispatch({
            type: type.PAGARME_DATA_LOAD,
            payload: false,
          });
          if (e.response.data.message) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${e.response.data.message}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro interno do sistema`,
              variant: 'error',
              open: true,
            },
          });
        }
      });
  };
};

export const initializeForm = values => {
  return initialize('ProfileForm', values);
};

export const reset = () => {
  return resetForm('ProfileForm');
};

export const updateProfile = values => {
  return dispatch => {
    axios
      .post(URL_UPDATE_PROFILE, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Você atualizou a seu perfil com sucesso`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        window.location.reload();
      })
      .catch(e => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};

export const saveGatewayInfo = values => {
  return dispatch => {
    axios
      .post(URL_SIGNUP_PAGARME, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Você atualizou a seu perfil com sucesso`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        window.location.reload();
      })
      .catch(e => {
        try {
          if (e.response.data.message) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${e.response.data.message}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro interno do sistema`,
              variant: 'error',
              open: true,
            },
          });
        }
      });
  };
};

export const putGatewayInfo = (values, id) => {
  return dispatch => {
    axios
      .put(`${URL_SIGNUP_PAGARME}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Você atualizou a seu perfil com sucesso`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        window.location.reload();
      })
      .catch(e => {
        try {
          if (e.response.data.message) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `${e.response.data.message}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro interno do sistema`,
              variant: 'error',
              open: true,
            },
          });
        }
      });
  };
};
