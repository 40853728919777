import AppBar from '@material-ui/core/AppBar';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';

function GeneralTabs(props) {
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > div': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#2D95EF',
      },
    },
  })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

  const StyledTab = withStyles(theme => {
    return {
      root: {
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(15),
        marginRight: theme.spacing(1),
        '&:focus': {
          opacity: 1,
        },
        outline: '0 !important',
        padding: '0 !important',
      },
    };
  })(props => <Tab disableRipple {...props} />);

  const useStyles = makeStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      backgroundColor: '#ffffff',
      boxShadow: 'none',
    },
  });

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const primary = '#2D95EF';
  const secundary = '#505050';

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      {props.hiddenTabs ? (
        <div>{props.start}</div>
      ) : (
        <>
          <AppBar className={classes.appBar} position="static">
            <StyledTabs
              className={classes.tabs}
              centered
              value={value}
              onChange={handleChange}
              aria-label="Tabs"
            >
              <StyledTab
                style={{ color: value === 0 ? primary : secundary }}
                label="Geral"
                {...a11yProps(0)}
              />
              <StyledTab
                style={{ color: value === 1 ? primary : secundary }}
                label="Regulamento"
                {...a11yProps(1)}
              />
            </StyledTabs>
          </AppBar>
          <div>
            {value === 0 ? props.start : value === 1 ? props.rules : null}
          </div>
        </>
      )}
    </div>
  );
}

export default GeneralTabs;
