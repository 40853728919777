import React from 'react';
import { IndexRoute, Route } from 'react-router';
import CupomOffers from './cupomOffers';
import CupomOffersForm from './cupomOffersForm';

export default (
  <Route path="status-cupom">
    <IndexRoute component={props => <CupomOffers {...props} />} />
    <Route path="novo" component={props => <CupomOffersForm {...props} />} />
    <Route
      path=":id/:action"
      component={props => <CupomOffersForm {...props} />}
    />
  </Route>
);
