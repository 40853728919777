import React, { Component } from 'react';
import { hashHistory, IndexRoute, Route, Router } from 'react-router';
import ActionsRoutes from '../modules/owner/pages/actions/actionsRoutes';
import CouponsRoutes from '../modules/owner/pages/actions/coupons/couponsRoutes';
import cupomOffersRoutes from '../modules/owner/pages/actions/cupomOffers/cupomOffersRoutes';
import EngajeReadingRoutes from '../modules/owner/pages/actions/engajeReading/engajeReadingRoutes';
import EngajeSharingRoutes from '../modules/owner/pages/actions/engajeSharing/engajeSharingRoutes';
import InviteContactsRoutes from '../modules/owner/pages/actions/inviteContacts/inviteContactsRoutes';
import ContactsRoutes from '../modules/owner/pages/contacts/contactsRoutes';
import DashboardOwner from '../modules/owner/pages/dashboard/dashboardOwner';
import IdentityRouter from '../modules/owner/pages/identity/identityRouter';
import MailRoutes from '../modules/owner/pages/mail/mailRoutes';
import OwnerUsersRoutes from '../modules/owner/pages/ownerUsers/ownerUsersRoutes';
import partnerCuponsRoutes from '../modules/owner/pages/partnerCupons/partnerCuponsRoutes';
import PointsRoutes from '../modules/owner/pages/points/pointsRoutes';
import principalRoutes from '../modules/owner/pages/principal/principalRoutes';
import QuizRoutes from '../modules/owner/pages/quiz/quizRoutes';
import signupCompanyRoutes from '../modules/owner/pages/signupCompany/signupCompanyRoutes';
import subscriptionsRoutes from '../modules/owner/pages/subscriptions/subscriptionsRoutes';
import validationsRoutes from '../modules/owner/pages/validations/validationsRoutes';
import WebsiteRoutes from '../modules/owner/pages/website/websiteRoutes';
import ActivateUser from '../pages/auth/activateUser/activateUser';
import ForgotPassword from '../pages/auth/forgotPassword/forgotPassword';
import NewPassword from '../pages/auth/forgotPassword/newPassword';
import Login from '../pages/auth/login/login';
import Logout from '../pages/auth/logout/logout';
import EmailConfirmation from '../pages/auth/signup/emailConfirmation';
import SignupStepForm from '../pages/auth/signup/signupStepForm';
import Home from '../pages/home/home';
import Terms from '../pages/terms/terms';
import ProfileRoutes from '../modules/owner/pages/profile/profileRoutes';
import authOrApp from './authOrApp';
import validationsCupomRoutes from '../modules/owner/pages/validationsCupom/validationsCupomRoutes';
import salesRoutes from '../modules/owner/pages/sales/salesRoutes';
import donationsRoutes from '../modules/owner/pages/donations/donationsRoutes';
import actionOfferMessagesRoutes from '../modules/owner/pages/actions/actionOfferMessages/actionOfferMessagesRoutes';
import qrCodeValidator from '../pages/auth/qrCodeValidator/qrCodeValidator';
import validatorsRouter from '../modules/owner/pages/validators/validatorsRouter';

export default class Routes extends Component {
  render() {
    return (
      <Router history={hashHistory}>
        <Route path="/login" component={Login} />
        <Route
          path="/qr-code-validator/:type/:code"
          component={qrCodeValidator}
        />
        <Route path="/qr-code-validator/:type" component={qrCodeValidator} />
        <Route path="/ativar/:hash/:email" component={ActivateUser} />
        <Route path="/cadastrar" component={SignupStepForm} />
        <Route path="/confirmacao" component={EmailConfirmation} />
        <Route path="/esqueci-minha-senha" component={ForgotPassword} />
        <Route
          path="/redefinir-senha/:userHash/:userEmail"
          component={NewPassword}
        />
        <Route path="/termos" component={Terms} />
        <Route path="/logout" component={Logout} />
        <Route path="/" component={authOrApp}>
          <IndexRoute component={Home} />
          <Route path="/owner">
            <IndexRoute component={DashboardOwner} />
            {ProfileRoutes}
            {QuizRoutes}
            {EngajeReadingRoutes}
            {OwnerUsersRoutes}
            {PointsRoutes}
            {MailRoutes}
            {ContactsRoutes}
            {IdentityRouter}
            {InviteContactsRoutes}
            {WebsiteRoutes}
            {EngajeSharingRoutes}
            {ActionsRoutes}
            {principalRoutes}
            {signupCompanyRoutes}
            {cupomOffersRoutes}
            {CouponsRoutes}
            {validationsRoutes}
            {validationsCupomRoutes}
            {subscriptionsRoutes}
            {partnerCuponsRoutes}
            {salesRoutes}
            {donationsRoutes}
            {actionOfferMessagesRoutes}
            {validatorsRouter}
          </Route>
        </Route>
      </Router>
    );
  }
}
