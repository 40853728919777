import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { initialize, reset as resetForm, destroy } from 'redux-form';
import type from './types';

const URL_ACTION = `${BASE_API}/actions`;
const URL_PARTNERS = `${BASE_API}/establishments`;
const URL_SUBSCRIPTIONS = `${BASE_API}/subscriptions-action`;
const URL_REWARD = `${BASE_API}/reward-presential-course`;
const URL_ACTIONS_TO_STATIONS = `${BASE_API}/actions-to-stations`;

export const submitAction = (
  actionValues,
  router = undefined,
  create_or_update,
  id = undefined,
  publishAction
) => {
  return dispatch => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    if (create_or_update === 'create') {
      axios
        .post(`${URL_ACTION}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then(actionResponse => {
          const newValues = {
            ...actionValues,
            action: actionResponse.data.response.action.id,
          };
          axios
            .post(`${URL_ACTIONS_TO_STATIONS}`, newValues, {
              headers: {
                Authorization: `${JSON.parse(
                  localStorage.getItem(USER_TOKEN)
                )}`,
              },
              onUploadProgress: progressEvent =>
                dispatch([
                  {
                    type: 'SUBMIT_PROGRESS',
                    payload: `${Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )} %`,
                  },
                ]),
            })
            .then(actionsToStationsResponse => {
              dispatch([
                { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
                {
                  type: 'SUBMIT_PROGRESS',
                  payload: 0,
                },
                {
                  type: 'SNACKBAR',
                  payload: {
                    message: 'Operação realizada com sucesso',
                    variant: 'success',
                    open: true,
                  },
                },
              ]);
              router.push('/owner');
            })
            .catch(e => {
              dispatch([
                { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
                {
                  type: 'SUBMIT_PROGRESS',
                  payload: 0,
                },
              ]);
              try {
                for (const i in e.response.data) {
                  dispatch({
                    type: 'SNACKBAR',
                    payload: {
                      message: `${e.response.data[i]}`,
                      variant: 'error',
                      open: true,
                    },
                  });
                }
              } catch (error) {
                dispatch({
                  type: 'SNACKBAR',
                  payload: { message: 'Erro', variant: 'error', open: true },
                });
              }
            });
        })
        .catch(e => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    } else if (create_or_update === 'update') {
      axios
        .put(`${URL_ACTION}/${id}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then(actionResponse => {
          if (publishAction) {
            publishAction();
          } else {
            dispatch([
              { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
              {
                type: 'SUBMIT_PROGRESS',
                payload: 0,
              },
            ]);
            if (router)
              router.push('/owner/acoes/minhas-atividades/?to=online');
          }
        })
        .catch(e => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    }
  };
};

export const getDetail = id => {
  return dispatch => {
    dispatch({
      type: type.RAW_CONTENT_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_ACTION}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const categories = response.data.category_actions.map(
          item => item.category.id
        );
        const target = response.data.target;
        const initialValues = {
          ...response.data,
          videoIdField:
            response.data.action_videos.length > 0
              ? response.data.action_videos[0].video_id
              : null,
          categories,
          from: target.age.from,
          to: target.age.to,
          states: target.states,
          associated: target.associated,
          rules: response.data.rules,
          internal: target.internal,
          has_giveaway:
            response.data.giveaway_number_rules.length > 0 ? true : false,
          giveaway_quantity:
            response.data.giveaway_number_rules.length > 0
              ? response.data.giveaway_number_rules[0].quantity
              : null,
          giveaway_id:
            response.data.giveaway_number_rules.length > 0
              ? response.data.giveaway_number_rules[0].giveaway_id
              : null,
          isThereTrophy: response.data.trophy_description ? true : false,
        };

        dispatch([
          {
            type: type.RAW_CONTENT_DETAIL,
            payload: response.data[0],
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const getPartnersList = id => {
  return dispatch => {
    dispatch({
      type: 'PARTNERS_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_PARTNERS}/?where[type_id]=4`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'PARTNERS_FETCHED',
            payload: response,
          },
        ]);
      });
  };
};

export const giveReward = (id, action) => {
  return dispatch => {
    axios
      .put(
        `${URL_REWARD}/${id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(response => {
        dispatch(getSubscriptions(action));
      });
  };
};

export const getSubscriptions = id => {
  return dispatch => {
    dispatch({
      type: 'SUBSCRIPTIONS_LOAD',
      payload: true,
    });
    axios
      .get(`${URL_SUBSCRIPTIONS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        response.data.forEach(item => {
          item['paidLabel'] = item.paid ? 'Sim' : 'Não';
          item['click'] = item.paid
            ? 'Já foi pago'
            : 'Clique para distribuir a premiação';
        });
        dispatch([
          {
            type: 'SUBSCRIPTIONS_FETCHED',
            payload: response,
          },
        ]);
      });
  };
};

export const initializeForm = values => {
  return initialize('rawContentForm', values);
};

export const reset = () => {
  return resetForm('rawContentForm');
};

export const dispatchFormReset = () => {
  return dispatch => {
    dispatch(destroy('rawContentForm'));
  };
};

export const savePartialy = (values, router, setValue, value) => {
  const id = router.params.id;
  return dispatch => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .put(`${URL_ACTION}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: progressEvent =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then(response => {
        dispatch([
          getDetail(id),
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          setValue(value + 1),
        ]);
      })
      .catch(e => {
        dispatch([
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
