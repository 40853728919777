import { FanApi } from '../api';
import { USER_TOKEN } from 'config/consts';
import { toastr } from 'react-redux-toastr';

export function sendPostVideo(
  selectedFile,
  setSelectedFile,
  establishment_id,
  handleChangeTab
) {
  return dispatch => {
    const data = new FormData();
    data.append('file', selectedFile);
    FanApi.post(`/upload-video/${establishment_id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
      onUploadProgress: progressEvent =>
        dispatch([
          {
            type: 'UPLOAD_VIDEO_PROGRESS',
            payload: `${parseInt(
              (progressEvent.loaded / progressEvent.total) * 100
            )} %`,
          },
        ]),
    }).then(response => {
      dispatch([
        {
          type: 'UPLOAD_VIDEO_PROGRESS',
          payload: '',
        },
        dispatchLoading(false),
        setSelectedFile(null),
        handleChangeTab(1),
      ]);
      toastr.success('Sucesso!', 'Upload feito com sucesso!');
    });
  };
}

export const dispatchLoading = bool => {
  return dispatch => {
    dispatch({
      type: 'LIST_LOAD',
      payload: bool,
    });
  };
};
