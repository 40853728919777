import { Box, IconButton } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { createTheme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { GetApp } from '@material-ui/icons';
import TuneIcon from '@material-ui/icons/Tune';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ThemeProvider } from 'styled-components';
import { searchTableValue } from '../../../helpers/utils.js';
import { MenuFiltersOptions, SearchBar } from './tableComponents';

const themeSec = createTheme({
  overrides: {
    MuiSelect: {
      root: {
        width: 95,
        height: 60,
        borderRadius: 10,
        border: '1px solid #DFDFDF',
        padding: 10,
        margin: 0,
      },
      outlined: {
        height: 60,
        // borderRadius: 6,
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: 6,
        border: '1px solid #DFDFDF',
        padding: 0,
        marginRight: 5,
        marginLeft: 15,
        transform: `rotate(90deg)`,
      },
    },
  },
});

const themeSecMobile = createTheme({
  overrides: {
    MuiSelect: {
      input: {
        border: '0px',
      },
      root: {
        width: 135,
        height: 60,
        borderRadius: 10,
        padding: 10,
        margin: 0,
        border: '0px',
        fontWeight: 'bold',
      },
    },

    MuiIconButton: {
      root: {
        borderRadius: 6,
        padding: 0,
        marginRight: 5,
        border: '1px solid #DFDFDF',
        transform: `rotate(90deg)`,
        // color: 'black',
      },
    },
  },
});

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  const headCells = props.columns;
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.field}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.field}
              direction={orderBy === headCell.field ? order : 'asc'}
              onClick={createSortHandler(headCell.field)}
            >
              {headCell.title}
              {orderBy === headCell.field ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  toolbar: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  container: {
    overflow: 'none',
    padding: theme.spacing(4),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  title: {
    marginRight: theme.spacing(2),
  },
}));

export default function EnhancedTable({
  rows: defaultData,
  columns,
  title,
  onRowClick,
  toolbarSearch,
  searchPlaceholder,
  smallSearch,
  dataType,
  filters,
  mobileDropdown,
  onChangeOption,
  mobileTable,
  defaultSelected,
  selectOptions,
  csvData,
  buttonCSV,
}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchValue, setSearchValue] = useState('');
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [optionSelected, setOptionSelected] = React.useState(
    defaultSelected ? defaultSelected : ''
  );

  const buttonRef = React.useRef();
  useEffect(() => {
    const filteredByName = searchTableValue(searchValue, defaultData, dataType);
    const filteredBySelectors = filterTableData(appliedFilters, filteredByName);
    setFilteredData(filteredBySelectors);
  }, [searchValue, appliedFilters]);
  useEffect(() => {
    setFilteredData(defaultData);
  }, [defaultData]);

  useEffect(() => {
    if (onChangeOption) onChangeOption(optionSelected);
  }, [optionSelected]);

  const handleSelectChange = event => {
    setOptionSelected(event.target.value);
  };
  const filterTableData = (appliedFilters, backupData) => {
    let currentData = [];
    let filtered = [];
    if (appliedFilters.length > 0) {
      currentData = backupData;
      filtered = currentData.filter((item, index) => {
        const array = [];
        for (let i = 0; i < appliedFilters.length; i++) {
          if (appliedFilters[i].field === 'betweenDates') {
            if (appliedFilters[i].filterFunction === 0) {
              if (
                moment(item['participationStart']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                ) &&
                moment(item['participationEnd']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            } else if (appliedFilters[i].filterFunction === 1) {
              if (
                moment(item['action']['participationStart']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                ) &&
                moment(item['action']['participationEnd']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            } else if (appliedFilters[i].filterFunction === 2) {
              if (
                moment(item['start_date']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            } else if (appliedFilters[i].filterFunction === 3) {
              if (
                moment(item['createdAt']).isBetween(
                  appliedFilters[i].startDate,
                  appliedFilters[i].endDate,
                  undefined,
                  []
                )
              ) {
                array.push(true);
              }
            }
          } else {
            if (
              appliedFilters[i].value[0] <= item[appliedFilters[i].field] &&
              item[appliedFilters[i].field] <= appliedFilters[i].value[1]
            ) {
              array.push(true);
            }
          }
        }
        if (array.length === appliedFilters.length) return true;
        return false;
      });
    } else {
      filtered = backupData;
    }

    return filtered;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleClick = () => {
    setAnchorEl(true);
  };
  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={clsx(classes.toolbar)}>
          <Box display="flex" justifyContent="start" alignItems="center">
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {title}
            </Typography>
            {selectOptions ? (
              selectOptions.length > 0 ? (
                <ThemeProvider
                  theme={
                    mobileTable && mobileDropdown ? themeSecMobile : themeSec
                  }
                >
                  <Select
                    disableUnderline={mobileDropdown && mobileTable}
                    variant={
                      mobileDropdown && mobileTable ? 'standard' : 'outlined'
                    }
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={optionSelected}
                    onChange={handleSelectChange}
                  >
                    {selectOptions.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </ThemeProvider>
              ) : null
            ) : null}
          </Box>
          <Box display="flex" justifyContent="end" alignItems="center">
            {buttonCSV && csvData ? (
              <Box
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                height={'100%'}
              >
                <CSVLink data={csvData} filename="fan-projects-vendas.csv">
                  <GetApp
                    style={{
                      fontSize: 25,
                      marginRight: 10,
                    }}
                  />
                </CSVLink>
              </Box>
            ) : null}
            {toolbarSearch ? (
              <SearchBar
                setShowSearchBar={setShowSearchBar}
                showSearchBar={showSearchBar}
                placeholder={searchPlaceholder || 'Pesquisar'}
                small={smallSearch}
                setSearchValue={setSearchValue}
                defaultValue={searchValue}
              />
            ) : null}
            {filters && filters.length > 0 ? (
              <ThemeProvider
                theme={
                  mobileDropdown && mobileTable ? themeSecMobile : themeSec
                }
              >
                <IconButton
                  ref={buttonRef}
                  disableFocusRipple
                  disableRipple
                  onClick={handleClick}
                >
                  <TuneIcon />
                </IconButton>
                <MenuFiltersOptions
                  filterOptions={filters}
                  appliedFilters={appliedFilters}
                  setAppliedFilters={setAppliedFilters}
                  open={Boolean(anchorEl)}
                  buttonRef={() => buttonRef.current}
                  setAnchorEl={setAnchorEl}
                />
              </ThemeProvider>
            ) : null}
          </Box>
        </Toolbar>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              columns={columns}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredData?.length}
            />
            <TableBody>
              {stableSort(filteredData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                    >
                      {columns.map(item => (
                        <TableCell
                          onClick={e =>
                            !item.hasOnClick && onRowClick
                              ? onRowClick(e, row)
                              : null
                          }
                          padding="normal"
                          component="th"
                          id={labelId}
                          scope="row"
                        >
                          {!item.render && row[item.field]
                            ? row[item.field]
                            : null}
                          {item.render ? item?.render(row) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              {/* 
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelRowsPerPage="Linhas por página"
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
