import type from './type';

const INITIAL_STATE = {
  loading: false,
  list: [],
  total: 0,
  campaignsLoading: false,
  campaigns: [],
  dataLoading: true,
  data: {},
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.CAMPAIGNS_FETCHED:
      return { ...state, campaigns: action.payload, campaignsLoading: false };
    case type.CAMPAIGNS_LOAD:
      return { ...state, campaignsLoading: action.payload };
    case 'PARTNER_DATA_FETCHED':
      return { ...state, data: action.payload, dataLoading: false };
    case 'PARTNER_DATA_LOAD':
      return { ...state, dataLoading: action.payload };
    default:
      return state;
  }
};
