import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import { initialize } from 'redux-form';
import type from './type';
import { parseISO, format } from 'date-fns';

const PRODUCT_ORDERS = `${BASE_API}/product-orders/?where[partner_id]=`;
const PRODUCT_ORDERS_DETAIL = `${BASE_API}/product-orders`;
const VALIDATE_PRODUCT_ORDER = `${BASE_API}/validate-product-order`;
const CANCEL_PRODUCT_ORDER = `${BASE_API}/cancel-product-order`;
const PRODUCT_ORDER = `${BASE_API}/product-orders/?where[id]=`;
const DELIVERY_ORDERS = `${BASE_API}/product-delivery/?where[partner_id]=`;
const DELIVERY_STATUSES = `${BASE_API}/delivery-statuses`;
const PRODUCT_ORDERS_GENERAL = `${BASE_API}/product-orders`;

export const getOrdersWithId = id => {
  return dispatch => {
    dispatch([
      {
        type: type.PRODUCT_ORDERS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${PRODUCT_ORDERS}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const newResponseData = response.data
          .filter(item => item.single_product_orders.length > 0)
          .map(item => ({
            ...item,
            createdAt: format(parseISO(item.createdAt), 'dd-MM-yyyy HH:mm'),
          }));
        dispatch([
          {
            type: type.PRODUCT_ORDERS_FETCHED,
            payload: newResponseData,
          },
        ]);
      })
      .catch(e => {
        dispatch([
          {
            type: type.PRODUCT_ORDERS_LOAD,
            payload: false,
          },
        ]);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getProductOrderDetail = id => {
  return dispatch => {
    dispatch([
      {
        type: type.PRODUCT_ORDERS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${PRODUCT_ORDERS_DETAIL}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.PRODUCT_ORDERS_DETAIL,
            payload: response.data,
          },
        ]);
      })
      .catch(e => {
        console.log(e);
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getDeliverysWithId = id => {
  return dispatch => {
    dispatch([
      {
        type: type.PRODUCT_ORDERS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${DELIVERY_ORDERS}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.PRODUCT_ORDERS_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch(e => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getOrderAndInitialize = id => {
  return dispatch => {
    dispatch([
      {
        type: type.PRODUCT_DETAIL_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${PRODUCT_ORDER}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch(initialize('ProductOrderForm', response.data[0]));
      })
      .catch(e => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: ` Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const validateProductOrder = (values, router = undefined, id) => {
  return dispatch => {
    dispatch([
      {
        type: type.PRODUCT_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .put(`${VALIDATE_PRODUCT_ORDER}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: progressEvent =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then(response => {
        dispatch([
          {
            type: type.PRODUCT_BUTTON_LOADING,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
        router.push('/owner/produtos-pedidos');
        window.location.reload();
      })
      .catch(e => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const cancelProductOrder = (id, partner_id) => {
  return dispatch => {
    dispatch([
      {
        type: type.PRODUCT_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .put(
        `${CANCEL_PRODUCT_ORDER}/${id}`,
        {},
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: progressEvent =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )} %`,
              },
            ]),
        }
      )
      .then(response => {
        dispatch([
          getOrdersWithId(partner_id),
          {
            type: type.PRODUCT_BUTTON_LOADING,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
      })
      .catch(e => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const updateObservationsAndDeliveryStatus = (
  id,
  { observations, delivery_status_id }
) => {
  return dispatch => {
    dispatch([
      {
        type: type.PRODUCT_BUTTON_LOADING,
        payload: true,
      },
    ]);
    axios
      .put(
        `${PRODUCT_ORDERS_GENERAL}/${id}`,
        { observations, delivery_status_id },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: progressEvent =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )} %`,
              },
            ]),
        }
      )
      .then(response => {
        dispatch([
          getProductOrderDetail(id),
          {
            type: type.PRODUCT_BUTTON_LOADING,
            payload: false,
          },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);
      })
      .catch(e => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: `Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getOrder = id => {
  return dispatch => {
    dispatch([
      {
        type: type.PRODUCT_DETAIL_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${PRODUCT_ORDER}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.PRODUCT_DETAIL_FETCHED,
            payload: response.data[0],
          },
        ]);
      })
      .catch(e => {
        try {
          if (e.response.data[0]) {
            dispatch({
              type: 'SNACKBAR',
              payload: {
                message: ` Erro: ${e.response.data[0]}`,
                variant: 'error',
                open: true,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getDeliveryStatuses = () => {
  return dispatch => {
    dispatch([
      {
        type: type.DELIVERY_STATUSES_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${DELIVERY_STATUSES}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.DELIVERY_STATUSES_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch(e => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: ` Erro: ${e.response.message}`,
              variant: 'error',
              open: true,
            },
          });
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
