import moment from 'moment';
import { default as React } from 'react';
import MultiSelectDisplayCard from './components/displayMultiSelect';
import {
  Container,
  Header,
  InteractionContainer,
  ProductsContainer,
  QRCodeContainer,
  Subtitle,
  Title,
  Titlecontainer,
} from './styles';
const QRCode = require('qrcode.react');

export default function CupomFeedback(props) {
  if (props.formValues.products) {
    if (props.formValues.products.length > 0) {
      const product = props.formValues.products[0];
      if (product) {
        return (
          <div
            style={{ backgroundColor: '#EEEEEE', padding: 30, height: '100%' }}
          >
            <Container
              style={{
                overflowY: 'scroll',
                height: 'calc(100vh - 260px)',
                overflowX: 'hidden',
                paddingRight: 15,
              }}
            >
              <Header>
                <Titlecontainer>
                  <Title>Pedido realizado com sucesso!</Title>
                  <Subtitle>{moment().format('LLLL')}</Subtitle>
                  <Subtitle>{`Id da compra: #1111111`}</Subtitle>
                </Titlecontainer>
                <QRCodeContainer>
                  <section></section>
                  <footer
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <QRCode value={'qualquer string'} />
                    <span>CODIGOCUPOM123</span>
                  </footer>
                </QRCodeContainer>
              </Header>
              <ProductsContainer>
                <MultiSelectDisplayCard
                  key={product.id}
                  data={product}
                  items={[]}
                />
              </ProductsContainer>

              <InteractionContainer>
                <button type="button">Imprimir</button>
                <a href="#/homepage">Pagina inicial</a>
              </InteractionContainer>
            </Container>
          </div>
        );
      } else return null;
    } else
      return (
        <div className="d-flex justify-content-center align-items-center h-50">
          Adicione produtos para ver como vai ficar o comprovante
        </div>
      );
  } else
    return (
      <div className="d-flex justify-content-center align-items-center h-50">
        Adicione produtos para ver como vai ficar o comprovante
      </div>
    );
}
