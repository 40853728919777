import CallToActionCard from '../cards/moduleCard/callToActionCard';
import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { DomPurifyContentContainer } from './styles';

function ActivityShare(props) {
  return (
    <Grid
      item
      container
      alignItems="center"
      xs={12}
    >
      {/* DOM PURIFY CONTENT */}
      <Grid
        item
        container
        alignItems="center"
        xs={12}
      >
        <DomPurifyContentContainer
          className={props.className}
          dangerouslySetInnerHTML={{
            __html: props.formValues.description,
          }}
        />
      </Grid>
      <Grid
        item
        container
        justify="center"
        xs={12}
      >
        <CallToActionCard formValues={props.formValues} />
      </Grid>
    </Grid>
  );
}

const StyledActivitySurvey = styled(ActivityShare)`
  p {
    font-size: 16px;
  }
`;

export default StyledActivitySurvey;
