import React from 'react';
import { IndexRoute, Route } from 'react-router';
import OwnerUsers from './ownerUsers';
import activateRoutes from './pages/activate/activateRoutes';
import manageRoutes from './pages/manage/manageRoutes';

export default (
  <Route path="owner-users">
    <IndexRoute component={props => <OwnerUsers {...props} />} />

    {activateRoutes}
    {manageRoutes}
  </Route>
);
