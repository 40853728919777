import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { destroy, initialize } from 'redux-form';
import type from './types';

const URL_RESEARCH = `${BASE_API}/research`;
const URL_ACTION = `${BASE_API}/actions`;
const URL_ACTIONS_TO_STATIONS = `${BASE_API}/actions-to-stations`;

export const submitAction = (
  actionValues,
  router = undefined,
  create_or_update,
  id = undefined,
  publishAction
) => {
  return (dispatch) => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    if (create_or_update === 'create') {
      axios
        .post(`${URL_ACTION}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
          onUploadProgress: (progressEvent) =>
            dispatch([
              {
                type: 'SUBMIT_PROGRESS',
                payload: `${Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                )} %`,
              },
            ]),
        })
        .then((actionResponse) => {
          const newValues = {
            ...actionValues,
            action: actionResponse.data.response.response.action_id,
          };
          axios
            .post(`${URL_ACTIONS_TO_STATIONS}`, newValues, {
              headers: {
                Authorization: `${JSON.parse(
                  localStorage.getItem(USER_TOKEN)
                )}`,
              },
              onUploadProgress: (progressEvent) =>
                dispatch([
                  {
                    type: 'SUBMIT_PROGRESS',
                    payload: `${Math.round(
                      (progressEvent.loaded * 100) / progressEvent.total
                    )} %`,
                  },
                ]),
            })
            .then((actionsToStationsResponse) => {
              dispatch([
                { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
                {
                  type: 'SUBMIT_PROGRESS',
                  payload: 0,
                },
                {
                  type: 'SNACKBAR',
                  payload: {
                    message: 'Operação realizada com sucesso',
                    variant: 'success',
                    open: true,
                  },
                },
              ]);
              router.push('/owner');
            })
            .catch((e) => {
              dispatch([
                { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
                {
                  type: 'SUBMIT_PROGRESS',
                  payload: 0,
                },
              ]);
              try {
                for (const i in e.response.data) {
                  dispatch({
                    type: 'SNACKBAR',
                    payload: {
                      message: `${e.response.data[i]}`,
                      variant: 'error',
                      open: true,
                    },
                  });
                }
              } catch (error) {
                dispatch({
                  type: 'SNACKBAR',
                  payload: { message: 'Erro', variant: 'error', open: true },
                });
              }
            });
        })
        .catch((e) => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    } else if (create_or_update === 'update') {
      axios
        .put(`${URL_ACTION}/${id}`, actionValues, {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        })
        .then((actionResponse) => {
          if (publishAction) {
            publishAction();
          } else {
            dispatch([
              { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
              {
                type: 'SUBMIT_PROGRESS',
                payload: 0,
              },
            ]);
            if (router)
              router.push('/owner/acoes/minhas-atividades/?to=online');
          }
        })
        .catch((e) => {
          dispatch([
            { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
            {
              type: 'SUBMIT_PROGRESS',
              payload: 0,
            },
          ]);
          try {
            for (const i in e.response.data) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${e.response.data[i]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          } catch (error) {
            dispatch({
              type: 'SNACKBAR',
              payload: { message: 'Erro', variant: 'error', open: true },
            });
          }
        });
    }
  };
};

export const getDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: type.CONTACTS_SURVEY_LOAD,
      payload: true,
    });
    axios
      .get(`${URL_ACTION}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const target = response.data.target;
        const categories = response.data.category_actions?.map(
          (item) => item.category?.id
        );
        let obj = response.data;

        const error_count_as_participation =
          obj.researches[0]?.error_count_as_participation;

        const newQuestionsList = obj.researches[0]?.questions?.map(
          (question, index) => ({
            ...question,
            options: question.options.map((item) => ({
              id: item.id,
              label: { text: item.label },
              correct: item.correct,
            })),
            question: { id: question.id, text: question.question },
            type: question.type_id,
          })
        );

        const initialValues = {
          ...obj,
          categories,
          from: target.age.from,
          error_count_as_participation,
          videoIdField:
            response.data.action_videos.length > 0
              ? response.data.action_videos[0].video_id
              : null,
          to: target.age.to,
          states: target.states,
          associated: target.associated,
          internal: target.internal,
          questions: newQuestionsList,
          isThereTrophy: response.data.trophy_description ? true : false,
        };
        if (parseFloat(initialValues.points) === 0) {
          initialValues.isPaid = false;
        } else {
          initialValues.isPaid = true;
        }
        dispatch([
          {
            type: type.CONTACTS_SURVEY_DETAIL,
            payload: obj,
          },
          initializeForm(initialValues),
        ]);
      });
  };
};

export const initializeForm = (values) => {
  return initialize('contactsSurveysForm', values);
};

export const submitResearch = (
  researchValues,
  router = undefined,
  typeAction
) => {
  return (dispatch) => {
    dispatch({ type: type.CONTACTS_SURVEY_LOAD, payload: true });
    axios
      .post(`${URL_RESEARCH}`, researchValues, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((researchResponse) => {
        if (!_.isUndefined(router)) {
          router.push({
            pathname: `/owner/acoes/aquisicao-clientes/pesquisa/form/acao/${researchResponse.data.ok[0].research_id}`,
            search: `?type=${typeAction}`,
          });
        }
      })
      .catch((e) => {
        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};

export const getResearchemplates = () => {
  return (dispatch) => {
    dispatch({ type: type.RESEARCH_TEMPLATES_LOAD, payload: true });
    axios
      .get(
        `${BASE_API}/action-templates/?where[type_action_id]=7&&where[type_action_id]=8`,
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        dispatch([
          {
            type: type.RESEARCH_TEMPLATES_FETCHED,
            payload: response,
          },
        ]);
      });
  };
};

export const reset = () => {
  return destroy('contactsSurveysForm');
};

export const dispatchFormReset = () => {
  return (dispatch) => {
    dispatch(destroy('contactsSurveysForm'));
  };
};

export const savePartialy = (values, router, setValue, value) => {
  const id = router.params.id;
  return (dispatch) => {
    dispatch([
      { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);
    axios
      .put(`${URL_ACTION}/${id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
        onUploadProgress: (progressEvent) =>
          dispatch([
            {
              type: 'SUBMIT_PROGRESS',
              payload: `${Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )} %`,
            },
          ]),
      })
      .then((response) => {
        dispatch([
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          setValue(value + 1),
        ]);
      })
      .catch((e) => {
        dispatch([
          { type: 'ACTIVE_ACTIONS_BUTTON_LOAD', payload: false },
          {
            type: 'SUBMIT_PROGRESS',
            payload: 0,
          },
        ]);

        try {
          for (const i in e.response.data) {
            for (const j in e.response.data[i]) {
              dispatch({
                type: 'SNACKBAR',
                payload: {
                  message: `${i}: ${e.response.data.errors[i][j]}`,
                  variant: 'error',
                  open: true,
                },
              });
            }
          }
        } catch (error) {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: 'Erro', variant: 'error', open: true },
          });
        }
      });
  };
};
