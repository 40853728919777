import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import _ from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { BASE_S3 } from 'config/consts';

// const urlFan =
//   process.env.REACT_APP_API_KEY === 'production'
//     ? `https://rilato.com.br/#/login-token`
//     : process.env.REACT_APP_API_KEY === 'test'
//     ? `http://fan.fantest.site/#/login-token`
//     : 'http://localhost:3001/#/login-token';

function OwnerNavigation(props) {
  const split = window.location.hash.split('#')[1];
  let route = split;

  function handleFirstStateActive() {
    for (let i in props.navBarItems) {
      if (props.navBarItems[i].link === route) {
        return props.navBarItems[i].id;
      } else {
        if (props.navBarItems[i].children) {
          for (let item in props.navBarItems[i].children) {
            if (props.navBarItems[i].children[item].link === route) {
              return props.navBarItems[i].id;
            }
          }
        }
      }
    }
  }
  //Escolha de empresa da plataforma
  function handleEstablishmentChange(establishmentId) {
    let newEstablishmentsList = [...props.auth.user.establishments];
    for (
      let index = 0;
      index < props.auth.user.establishments.length;
      index++
    ) {
      if (props.auth.user.establishments[index].id === establishmentId) {
        [newEstablishmentsList[0], newEstablishmentsList[index]] = [
          newEstablishmentsList[index],
          newEstablishmentsList[0],
        ];
      }
    }
    return props.changeEstablishment(newEstablishmentsList);
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(handleFirstStateActive());
  const openAnchor = Boolean(anchorEl);
  function handleMenu(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <div>
      <div
        style={{
          width: '100%',
          background: '#FFFFFF',
          height: 94,
          boxShadow: '0px 5px 3px -5px #111',
          position: 'relative',
          zIndex: 100,
        }}
      >
        {/* Container do primeiro menu */}
        <div className="container h-100" style={{ maxWidth: 1180 }}>
          <div className="col-12 d-flex justify-content-between align-items-center h-100 flex-wrap">
            {/* Flex da imagem + Botões */}
            <div className="d-flex align-items-center">
              {/* Imagem da estação */}

              <img
                src={`${BASE_S3}/${props.auth?.user?.fanData?.objectNewItemsBody?.navbar_station_secondary_logo}`}
                style={{ height: 50, marginRight: 27, cursor: 'pointer' }}
                className="img-fluid"
                alt="station_img"
                onClick={() => props.router.push('/owner')}
              />
              {/* Botões */}
              {/* <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  border: '1px solid #282828',
                  width: 98,
                  height: 32,
                  borderRadius: 18,
                  marginRight: 27,
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  lineHeight: '160%',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.location.href = `${urlFan}/${
                    JSON.parse(localStorage.getItem(USER_TOKEN)).split(' ')[1]
                  }`;
                  return null;
                }}
              >
                Descobrir
              </div> */}
              {/* <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  width: 98,
                  height: 32,
                  borderRadius: 18,
                  background: '#E5E5E5',
                  cursor: 'pointer',
                }}
              >
                Gerenciar
              </div> */}
            </div>
            {/* Flex do "ver site" + notificacoes + imagem de perfil */}
            <div className="d-flex align-items-center justify-content-end">
              {/* Seleção de estação */}
              <button
                className={`btn btn-secondary dropdown-toggle`}
                type="button"
                data-toggle="dropdown"
                id={'dropdownMenuStation'}
                aria-haspopup="true"
                style={{
                  background: 'transparent',
                  color: '#000',
                  border: 'none',
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: '16px',
                  marginRight: 20,
                }}
              >
                {!_.isEmpty(props.auth.user.establishments)
                  ? props.auth.user.establishments[0]?.name
                  : 'Não há empresa'}
              </button>
              <div
                className="dropdown-menu"
                style={{ maxHeight: 220, overflow: 'auto' }}
                aria-labelledby={'dropdownMenuStation'}
              >
                {props.auth.user.establishments.map((item, index) => (
                  <button
                    key={item.id + index}
                    className="dropdown-item"
                    type="button"
                    style={{
                      marginBottom: 10,
                    }}
                    onClick={() => handleEstablishmentChange(item.id)}
                  >
                    {item.name}
                  </button>
                ))}
                <button
                  className="dropdown-item"
                  type="button"
                  style={{
                    marginBottom: 10,
                  }}
                  onClick={() => props.router.push('/owner/create-new-station')}
                >
                  + Adicionar nova estação
                </button>
              </div>
              {/* Botão de ver o site */}
              {/* Icone de Notificações */}
              {/*  <NotificationsOutlined
                style={{
                  marginRight: 16,
                }}
              /> */}
              {/* Foto de perfil */}
              <div>
                <IconButton
                  aria-label="Account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={openAnchor}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => props.router.push('/owner/profile')}>
                    Perfil
                  </MenuItem>
                  <MenuItem onClick={() => props.router.push('/logout')}>
                    Sair
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          background: '#F7F7F7',
          height: 56,
          boxShadow: '0px 2px 4px -4px rgba(0, 0, 0, 0.2)',
        }}
      >
        <div className="container h-100" style={{ maxWidth: 1180 }}>
          <div className="col-12 d-flex justify-content-start align-items-center flex-wrap h-100">
            {props.navBarItems.map((item, index) => (
              <div className="dropdown">
                {item.children?.length > 0 ? (
                  <>
                    <button
                      key={item.id + index}
                      className={`btn btn-secondary dropdown-toggle`}
                      type="button"
                      data-toggle="dropdown"
                      id={'dropdownMenu' + index}
                      aria-haspopup="true"
                      style={{
                        background: 'transparent',
                        color: active === item.id ? '#5759FB' : '#000',
                        border: 'none',
                        fontFamily: 'Roboto',
                        fontStyle: 'normal',
                        fontWeight: active === item.id ? 'bold' : 'normal',
                        fontSize: '16px',
                      }}
                    >
                      {item.name}
                    </button>
                    {item.children?.length > 0 ? (
                      <div
                        className="dropdown-menu"
                        aria-labelledby={'dropdownMenu' + index}
                      >
                        {item.children.map((subitem, index) => (
                          <button
                            key={subitem.id + index}
                            className="dropdown-item"
                            type="button"
                            style={{
                              marginBottom: 10,
                            }}
                            onClick={() => {
                              props.router.push(subitem.link);
                              setActive(item.id);
                            }}
                          >
                            {subitem.name}
                          </button>
                        ))}
                      </div>
                    ) : null}
                  </>
                ) : (
                  <button
                    className={`btn btn-secondary`}
                    type="button"
                    onClick={() => {
                      props.router.push(item.link);
                      setActive(item.id);
                    }}
                    style={{
                      background: 'transparent',
                      color: active === item.id ? '#5759FB' : '#000',
                      border: 'none',
                      fontFamily: 'Roboto',
                      fontStyle: 'normal',
                      fontWeight: active === item.id ? 'bold' : 'normal',
                      fontSize: '16px',
                    }}
                  >
                    {item.name}
                  </button>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    website: state.website,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(OwnerNavigation);
