import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { cnpjMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import { requestPasswordResetLogged } from 'pages/auth/authActions';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { handleWhiteSpaces } from 'helpers/dataTransformers';
import AddressesForm from '../addresses/addressesForm';
import {
  initializeForm,
  setDetail,
  updateProfile,
  getPagarmeDetail,
} from './profileActions';
import GatewayForm from './gateway/gatewayForm';

const maxL = max => value =>
  value && value.length > max
    ? `Aceitamos ${max} caracteres ou menos. Seu atual: ${value.length}`
    : undefined;
const maxLength = maxL(30);
const onlyLetters = value =>
  value && /[^a-zA-Z ]/i.test(value) ? 'Apenas letras' : undefined;
let ProfileForm = function(props) {
  const [visible, setVisible] = React.useState(false);

  useEffect(() => {
    let values = {
      name: props.auth.user.name,
      email: props.auth.user.email,
      cnpj: props.auth.user.establishments[0].cnpj,
      establishment_name: props.auth.user.establishments[0].name,
      social_name: props.auth.user.establishments[0].social_name,
    };

    props.setDetail(values);
  }, [props.auth]);

  useEffect(() => {
    props.getPagarmeDetail(props.auth.user.establishments[0].id);
  }, []);

  function onSubmit(values) {
    const values_perfil = {
      user: {
        id: props.auth.user.id,
        name: values.name,
      },
      establishment: {
        id: props.auth.user.establishments[0].id,
        name: values.establishment_name,
        social_name: values.social_name,
        cnpj: values.cnpj,
      },
    };

    return props.updateProfile(values_perfil);
  }
  const buttonClick = () => {
    const values = {
      email: props.auth.user.email,
    };
    const { router } = props;
    props.requestPasswordResetLogged(values, router);
    setVisible(true);
  };
  return props.profile.pagarmeLoading ? (
    <Box display="flex" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section id="coupons-form" style={{ marginTop: '2em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Perfil</p>
              <Form
                role="form"
                onSubmit={props.handleSubmit(onSubmit)}
                noValidate
              >
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="E-mail"
                      name="email"
                      validate={[FORM_RULES.required]}
                      required
                      disabled
                      margin="normal"
                      normalize={handleWhiteSpaces}
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6} lg={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome"
                      name="name"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Nome Fantasia"
                      name="establishment_name"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Razão social resumida"
                      name="social_name"
                      margin="normal"
                      validate={[FORM_RULES.required, maxLength, onlyLetters]}
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="Razão social completa (para os Termos de Uso)"
                      name="full_social_name"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                  <GeneralGrid item xs={12} md={6}>
                    <Field
                      component={InputLabelOutlined}
                      label="CNPJ"
                      name="cnpj"
                      validate={[FORM_RULES.required]}
                      required
                      margin="normal"
                      {...cnpjMask}
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <div className="row justify-content-around mb-3 mt-3">
                  <PrimaryButton
                    type="button"
                    onClick={() => buttonClick()}
                    disabled={props.coupons.buttonLoading}
                    progress={parseInt(props.general.submitProgress)}
                  >
                    {props.coupons.buttonLoading
                      ? 'Enviando'
                      : 'Mudar minha senha'}
                  </PrimaryButton>
                  {visible ? (
                    <p>Você receberá um e-mail com as instruções. Obrigado!</p>
                  ) : null}
                  <PrimaryButton
                    router={props.router}
                    type="submit"
                    color="primary"
                  >
                    Enviar
                  </PrimaryButton>
                </div>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Endereço</p>
              <AddressesForm />
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">
                Informações para vendas
              </p>
              <GatewayForm />
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

ProfileForm = reduxForm({ form: 'ProfileForm' })(ProfileForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    coupons: state.coupons,
    profile: state.profile,
    formValues: getFormValues('ProfileForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setDetail,
      initializeForm,
      requestPasswordResetLogged,
      getPagarmeDetail,
      updateProfile,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
