import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { appColors } from '../../../styles/colors';

const useStyles = makeStyles(theme => ({
  progress: {
    color: appColors.primary,
  },
}));

export default function LoadingProgress() {
  const classes = useStyles();

  return (
    <div>
      <CircularProgress className={classes.progress} />
    </div>
  );
}
