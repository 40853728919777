import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import LoadingProgress from 'common/components/progress/loading';
import TableGeneral from 'common/components/table/tableGeneral';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { roundMoney } from '../../../../../helpers/utils';
import {
  cancelProductOrder,
  getDeliveryStatuses,
  getProductOrderDetail,
  updateObservationsAndDeliveryStatus,
} from './productOrdersActions';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  defaultSpacing: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  bold: {
    fontWeight: '700',
  },
  italic: {
    fontStyle: 'italic',
  },
}));

function ProductOrdersDetail({
  router,
  productOrders: { detail, loading, deliveryStatusesList, buttonLoading },
  getProductOrderDetail,
  auth,
  updateObservationsAndDeliveryStatus,
  getDeliveryStatuses,
  cancelProductOrder,
}) {
  const classes = useStyles();
  const { id } = router.params;

  const [open, setOpen] = useState(false);
  const [idCancel, setIdCancel] = useState(undefined);
  const [observations] = useState(null);
  const [deliveryStatusId, setDeliveryStatusId] = useState(null);
  const [deliveryStatusLookup, setDeliveryStatusLookup] = useState([]);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getProductOrderDetail(id);
    getDeliveryStatuses();
  }, []);

  useEffect(() => {
    console.log(deliveryStatusesList);
    const deliveryStatusLookup = deliveryStatusesList.map(item => ({
      value: item.id,
      label: item.status,
    }));
    /* for (let i = 0; i < deliveryStatusesList.length; i += 1) {
      deliveryStatusLookup[`${deliveryStatusesList[i].id}`] =
        deliveryStatusesList[i].status;
    } */
    setDeliveryStatusLookup(deliveryStatusLookup);
  }, [deliveryStatusesList]);

  useEffect(() => {
    if (detail) {
      setDeliveryStatusId(detail.delivery_status_id);
    }
  }, [detail]);

  useEffect(() => {
    if (detail) {
      const notRepeatedProducts = [];
      detail.single_product_orders.forEach(item => {
        const indexFound = notRepeatedProducts.findIndex(
          secondItem => secondItem.id === item.id
        );
        if (indexFound >= 0) {
          notRepeatedProducts[indexFound] = {
            ...notRepeatedProducts[indexFound],
            quantity: notRepeatedProducts[indexFound].quantity + 1,
          };
        } else {
          notRepeatedProducts.push({
            id: item.product_id,
            name: item.product.name,
            quantity: 1,
            amount: roundMoney(
              item.product.value - item.product.discount_value
            ),
          });
        }
      });
      setProducts(notRepeatedProducts);
    }

    return () => {
      setProducts([]);
    };
  }, [detail]);

  function handleUpdate() {
    updateObservationsAndDeliveryStatus(id, {
      observations,
      delivery_status_id: deliveryStatusId,
    });
  }

  if (loading || !detail) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  const walletFutureTransaction = detail.future_transactions.find(
    item => !item.gateway_or_wallet
  );

  const gatewayFutureTransaction = detail.future_transactions.find(
    item => item.gateway_or_wallet
  );

  return (
    <Container maxWidth="lg">
      <Grid container>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                ID:{' '}
                <Typography component="span">#{detail.purchase_id}</Typography>
              </Typography>
            </Grid>
            {detail.gateway_charge && (
              <Grid item xs={12}>
                <Typography className={classes.bold}>
                  ID Pagar.me:{' '}
                  <Typography component="span">
                    {detail.gateway_charge.id}
                  </Typography>
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Data:{' '}
                <Typography component="span">
                  {detail
                    ? format(parseISO(detail.createdAt), 'dd/MM/yyyy HH:mm')
                    : 'Erro'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Status do pedido:{' '}
                <Typography component="span">{detail.status}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Nome:{' '}
                <Typography component="span">{detail.user.name}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                CPF:{' '}
                <Typography component="span">
                  {detail.user?.cpf || ''}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Endereço:{' '}
                <Typography component="span">{detail.address}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Complemento:{' '}
                <Typography component="span">{detail.reference}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Local:{' '}
                <Typography component="span">{detail.user.name}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.bold}>
                Atividade:{' '}
                <Typography component="span">{detail.action.name}</Typography>
              </Typography>
            </Grid>
            {detail.action.partner && (
              <Grid item xs={12}>
                <Typography className={classes.bold}>
                  Parceiro:{' '}
                  <Typography component="span">
                    {detail.action.partner.name}
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Grid container>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => router.push(`/owner/validacao-cupom/`)}
              >
                Validar QR Code
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                className={`${classes.formControl} ${classes.defaultSpacing}`}
              >
                <InputLabel htmlFor="outlined-age-native-simple">
                  Status do delivery
                </InputLabel>
                <Select
                  native
                  value={deliveryStatusId}
                  onChange={event => {
                    setDeliveryStatusId(event.target.value);
                  }}
                  inputProps={{
                    name: 'age',
                    id: 'outlined-age-native-simple',
                  }}
                >
                  {deliveryStatusLookup.map(item => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </Select>
              </FormControl>
              <Button
                color="primary"
                variant="contained"
                onClick={handleUpdate}
                className={classes.defaultSpacing}
              >
                Atualizar status
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setIdCancel(id);
                  setOpen(true);
                }}
                className={classes.defaultSpacing}
              >
                Cancelar pedido
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box mt={3}>
            <TableGeneral
              hasToolbar={true}
              searchPlaceholder={'Pesquisar por nome'}
              toolbarSearch
              title={'Produtos'}
              dataType={'productOrders'}
              columns={[
                {
                  field: 'name',
                  title: 'Nome',
                  editable: 'never',
                },
                {
                  field: 'quantity',
                  title: 'Quantidade',
                  editable: 'never',
                },
                {
                  field: 'amount',
                  title: 'Valor (R$)',
                  editable: 'never',
                  render: rowData => roundMoney(rowData.amount, true),
                },
              ]}
              data={products}
            />
          </Box>
        </Grid>
        <Grid container spacing={1} className={classes.defaultSpacing}>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Soma dos produtos:{' '}
              <Typography component="span">
                {roundMoney(detail.product_items_sum_value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Soma dos descontos:{' '}
              <Typography component="span">
                {roundMoney(detail.additional_discount_value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Entrega:{' '}
              <Typography component="span">
                {roundMoney(detail.delivery_tax_value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor final:{' '}
              <Typography component="span">
                {roundMoney(detail.final_value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor pago da carteira:{' '}
              <Typography component="span">
                {roundMoney(detail.wallet_value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor líquido recebido da carteira:{' '}
              <Typography component="span">
                {roundMoney(walletFutureTransaction.value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor pago do cartão/PIX:{' '}
              <Typography component="span">
                {roundMoney(detail.credit_card_value, true)}
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.bold}>
              Valor líquido recebido do cartão/PIX:{' '}
              <Typography component="span">
                {gatewayFutureTransaction
                  ? roundMoney(gatewayFutureTransaction.value, true)
                  : 'R$ 0,00'}
                *
              </Typography>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.italic}>
              *Cobramos uma taxa de R$ 1,50 de antifraude (para compras no
              cartão de crédito) por transação. Esta taxa é dividida igualmente
              entre todos os parceiros caso o cliente tenha comprado de mais de
              1 parceiro em uma única compra:{' '}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {open ? (
        <AlertDialogSlide
          maxWidth="sm"
          title="Tem certeza?"
          onClose={() => setOpen(false)}
          visible={open}
        >
          <div>
            <Typography>
              Você tem certeza que deseja cancelar esse pedido?
            </Typography>

            <PrimaryButton
              noMargin
              onClick={() => {
                setOpen(false);
                cancelProductOrder(idCancel, auth.user.establishments[0].id);
              }}
              disabled={buttonLoading}
            >
              Sim, quero cancelar
            </PrimaryButton>
            <SecondaryButton onClick={() => setOpen(false)}>
              Cancelar
            </SecondaryButton>
          </div>
        </AlertDialogSlide>
      ) : null}
    </Container>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    productOrders: state.productOrders,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getProductOrderDetail,
      updateObservationsAndDeliveryStatus,
      getDeliveryStatuses,
      cancelProductOrder,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductOrdersDetail);
