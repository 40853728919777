import type from './type';

const INITIAL_STATE = {
  loading: false,
  list: [],
  total: 0,
  transactions: [],
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.TRANSFER_REQUESTS_LOAD:
      return { ...state, loading: action.payload };
    case type.TRANSFER_REQUESTS_FETCHED:
      return { ...state, loading: false, transactions: action.payload };
    default:
      return state;
  }
};
