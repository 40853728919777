import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AlertDialogSlide from 'common/components/dialog/dialog';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserActions } from '../actionsFile';

class Results extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    };

    this.props.getUserActions(this.props.location.query.action);
  }

  onDialogClose = () => {
    this.setState({ visible: false });
    this.props.router.push(
      `/owner/acoes/minhas-atividades/?to=${this.props.location.query.from}`
    );
  };

  renderEmptyScreen() {
    return (
      <AlertDialogSlide
        title="Resultados"
        visible={this.state.visible}
        height="95vh"
        onClose={() => this.onDialogClose()}
        maxWidth={isMobile ? 'xl' : 'sm'}
      >
        <section
          id="statement-buy-points"
          style={{ marginTop: '4.3em', height: '100vh' }}
        >
          <GeneralGrid container spacing={3} alignItems="center">
            <GeneralGrid item xs={12} md={6}>
              <GeneralGrid container spacing={3}>
                <GeneralGrid item xs={12}>
                  <h2 className="signup-text">
                    Ninguém participou dessa atividade até o momento
                  </h2>
                  <p>Aqui você poderá distribuir os prêmios</p>
                </GeneralGrid>
              </GeneralGrid>
            </GeneralGrid>
            <GeneralGrid item xs={12} md={6}>
              <img
                alt=""
                src={placeholder_illustration}
                className="img-fluid"
              />
            </GeneralGrid>
          </GeneralGrid>
        </section>
      </AlertDialogSlide>
    );
  }
  renderBackground = () => {
    return '#3f51b5';
  };

  render() {
    const { list, loading } = this.props.actions;
    if (loading) {
      return (
        <AlertDialogSlide
          title="Resultados"
          visible={this.state.visible}
          height="95vh"
          onClose={() => this.onDialogClose()}
          maxWidth={isMobile ? 'xl' : 'sm'}
        >
          <div className="d-flex justify-content-center mt-5">
            <LoadingProgress />
          </div>
        </AlertDialogSlide>
      );
    }

    if (list.length < 1) {
      return this.renderEmptyScreen();
    }

    return (
      <AlertDialogSlide
        title="Resultados"
        visible={true}
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'sm'}
      >
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <Table
              title="Extrato"
              columns={[
                {
                  field: 'user.name',
                  title: 'Usuário',
                  render: rowData => rowData.user.name,
                },
                { field: 'createdAt', title: 'Data da Participação' },
              ]}
              rows={list}
              router={this.props.router}
            />
          </GeneralGrid>
        </GeneralGrid>
      </AlertDialogSlide>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    actions: state.actions,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getUserActions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
