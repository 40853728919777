import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import moment from 'moment';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import LoadingProgress from '../../../../common/components/progress/loading';
import {
  getParticipantInfo,
  validateParticipant,
} from './validationsCupomActions';

function ValidationsInfo(props) {
  useEffect(() => {
    props.getParticipantInfo(props.router.params.code, props.router);
  }, []);

  function validate() {
    props.validateParticipant(props.validationsCupom.detail.id, props.router);
  }

  if (props.validationsCupom.loading) {
    return (
      <Box display="flex" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  function renderList() {
    const itemsId = props.validationsCupom.detail.single_product_orders.map(
      i => i.product_id
    );
    const uniqueItemsId = Array.from(new Set(itemsId));
    const newArray = [];
    for (let productId of uniqueItemsId) {
      const product = props.validationsCupom.detail.single_product_orders.filter(
        i => i.product_id === productId
      );
      newArray.push({
        product: product[0].product.name,
        quantity: product.length,
        delivery_location: props.validationsCupom.detail.delivery_location
          ? props.validationsCupom.detail.delivery_location.name
          : null,
        date: moment(product.createdAt).format('DD-MM-YYYY HH:mm'),
      });
    }
    return (
      <DataTable value={newArray} style={{ width: 600 }}>
        <Column
          style={{ textAlign: 'center' }}
          field="quantity"
          header="Quantidade"
        />
        <Column
          style={{ textAlign: 'center' }}
          field="product"
          header="Produto"
        />
        {props.validationsCupom.detail.delivery_location && (
          <Column
            style={{ textAlign: 'center' }}
            field="delivery_location"
            header="Local de entrega"
          />
        )}
        <Column
          style={{ textAlign: 'center' }}
          field="date"
          header="Data da compra"
        />
      </DataTable>
    );
  }

  function renderFullPrice() {
    if (
      props.validationsCupom.detail.single_product_orders &&
      props.validationsCupom.detail.delivery_location
    ) {
      const valuesArray = props.validationsCupom.detail.single_product_orders.map(
        item => ({
          value: item.product.value,
          discount_value: item.product.discount_value,
        })
      );
      let finalPrice = 0;
      for (let price of valuesArray) {
        finalPrice +=
          parseFloat(price.value) - parseFloat(price.discount_value);
      }
      finalPrice =
        finalPrice +
        parseFloat(props.validationsCupom.detail.delivery_location.price);
      return (
        <div className="row justify-content-center mt-3">
          <div className="col-md-8 col-11">
            <h6 className="text-muted text-center">
              Preço final: R$ {finalPrice.toFixed(2).replace('.', ',')}
            </h6>
          </div>
        </div>
      );
    } else return null;
  }

  return (
    <Box mt={10}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div className="container-fluid d-flex-column justify-content-space-around h-100">
              <div className="row justify-content-center mt-5"></div>
              <div className="row justify-content-center mt-5">
                <div className="col-auto text-center">
                  <h4>{props.validationsCupom.detail.action.name}</h4>
                </div>
              </div>
              <div className="row justify-content-center mt-3">
                <div className="col-md-8 col-11">
                  <h6 className="text-muted text-center">{`Pedido gerado em: ${moment(
                    props.validationsCupom.detail.createdAt
                  ).format('LLLL')}`}</h6>
                </div>
              </div>

              <div className="row justify-content-center mt-3">
                <div className="col-auto">
                  <h5 className="text-muted">
                    Pedido #{props.validationsCupom.detail.id}
                  </h5>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mt-3">
              {renderList()}
            </div>
            <div className="row justify-content-center mt-3">
              {renderFullPrice()}
            </div>
          </GeneralGrid>
          <GeneralGrid container spacing={3} justify="center">
            <GeneralGrid item xs={12} md={4}>
              <Box display="flex" justifyContent="center">
                <PrimaryButton type="button" onClick={validate} color="primary">
                  Validar
                </PrimaryButton>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    validationsCupom: state.validationsCupom,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getParticipantInfo,
      validateParticipant,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidationsInfo);
