import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';

const URL_USER_STEPS = `${BASE_API}/user-steps`;

export const getUserSteps = id => {
  return dispatch => {
    dispatch([
      {
        type: 'USER_STEPS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_USER_STEPS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'USER_STEPS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};
