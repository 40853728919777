import { Box } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  create,
  getDetail,
  initializeForm,
  setDetail,
  setLoading,
  update,
} from './validatorsActions';

let ValidatorCodeForm = function(props) {
  const { validatorCode, router, location, getDetail, setDetail } = props;

  useEffect(() => {
    if (!router.params.id) {
      props.setLoading(false);
    } else {
      setFormValue(validatorCode, router, location, getDetail, setDetail);
    }
  }, []);

  function onSubmit(values) {
    const { create, update } = props;

    const newValues = {
      ...values,
      partner_id: props.auth.user.establishments?.[0]?.id,
    };

    create_or_update(router, newValues, create, update);
  }

  return props.validatorCode.loading ? (
    <Box display="flex" height="100vh" justifyContent="center" mt={10}>
      <LoadingProgress />
    </Box>
  ) : (
    <section
      id="validatorCode-form"
      style={{ paddingBottom: isMobileOnly ? 100 : 0 }}
    >
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <div className="card">
            <div className="card-body">
              <p className=" h4 card-title text-muted">Validadores</p>
              <Form
                role="form"
                onSubmit={props.handleSubmit(onSubmit)}
                noValidate
              >
                <GeneralGrid container spacing={3}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Identificação (nome, matrícula, ID)"
                      name="identification"
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>
                <GeneralGrid item xs={12} md={6}>
                  <GeneralGrid item xs={12}>
                    <Field
                      component={InputLabelOutlined}
                      label="Código"
                      name="code"
                      required
                      margin="normal"
                    />
                  </GeneralGrid>
                </GeneralGrid>

                <GeneralGrid container>
                  <GeneralGrid
                    item
                    xs={8}
                    md={4}
                    lg={4}
                    style={{ marginLeft: -8 }}
                  >
                    <PrimaryButton
                      type="submit"
                      disabled={props.validatorCode.buttonLoading}
                      progress={parseInt(props.general.submitProgress)}
                    >
                      {props.validatorCode.buttonLoading
                        ? 'Enviando'
                        : 'Enviar'}
                    </PrimaryButton>
                  </GeneralGrid>
                </GeneralGrid>
              </Form>
            </div>
          </div>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
};

ValidatorCodeForm = reduxForm({ form: 'validatorCodeForm' })(ValidatorCodeForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    validatorCode: state.validatorCode,
    formValues: getFormValues('validatorCodeForm')(state),
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ValidatorCodeForm);
