import React from 'react';

export default function UsersIcon({ width, height }) {
  return (
    <svg
      width={ width }
      height={ height }
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.54057 16.8261C2.50621 15.8218 3.82446 15.25 5.20768 15.25H13.541C14.9242 15.25 16.2425 15.8218 17.2081 16.8261C18.1725 17.829 18.7077 19.1812 18.7077 20.5833V22.75C18.7077 23.3023 18.26 23.75 17.7077 23.75C17.1554 23.75 16.7077 23.3023 16.7077 22.75V20.5833C16.7077 19.6869 16.3649 18.8347 15.7665 18.2123C15.1693 17.5913 14.3679 17.25 13.541 17.25H5.20768C4.38077 17.25 3.5794 17.5913 2.98224 18.2123C2.38379 18.8347 2.04102 19.6869 2.04102 20.5833V22.75C2.04102 23.3023 1.5933 23.75 1.04102 23.75C0.488731 23.75 0.0410156 23.3023 0.0410156 22.75V20.5833C0.0410156 19.1812 0.576211 17.829 1.54057 16.8261Z"
        fill="#5759FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.37565 4.25C7.66335 4.25 6.20898 5.70506 6.20898 7.58333C6.20898 9.46161 7.66335 10.9167 9.37565 10.9167C11.0879 10.9167 12.5423 9.46161 12.5423 7.58333C12.5423 5.70506 11.0879 4.25 9.37565 4.25ZM4.20898 7.58333C4.20898 4.67514 6.48558 2.25 9.37565 2.25C12.2657 2.25 14.5423 4.67514 14.5423 7.58333C14.5423 10.4915 12.2657 12.9167 9.37565 12.9167C6.48558 12.9167 4.20898 10.4915 4.20898 7.58333Z"
        fill="#5759FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.8684 16.1315C20.0117 15.5981 20.5602 15.2819 21.0936 15.4251C22.2097 15.7248 23.1908 16.3995 23.8874 17.3341C24.5836 18.2681 24.9584 19.4108 24.9592 20.5827L24.9592 22.75C24.9592 23.3023 24.5115 23.75 23.9592 23.75C23.4069 23.75 22.9592 23.3023 22.9592 22.75L22.9592 20.5841C22.9592 20.584 22.9592 20.5842 22.9592 20.5841C22.9586 19.8358 22.7189 19.1129 22.2839 18.5293C21.8493 17.9463 21.2465 17.537 20.5749 17.3567C20.0415 17.2134 19.7252 16.6649 19.8684 16.1315Z"
        fill="#5759FB"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.6999 3.13356C15.842 2.59987 16.3899 2.28243 16.9236 2.42454C18.0428 2.72256 19.0269 3.39741 19.7256 4.33358C20.4238 5.26909 20.7994 6.4144 20.7994 7.58879C20.7994 8.76318 20.4238 9.90849 19.7256 10.844C19.0269 11.7802 18.0428 12.455 16.9236 12.753C16.3899 12.8951 15.842 12.5777 15.6999 12.044C15.5578 11.5103 15.8753 10.9625 16.4089 10.8204C17.0823 10.6411 17.687 10.2317 18.1228 9.64774C18.5592 9.0631 18.7994 8.33877 18.7994 7.58879C18.7994 6.83881 18.5592 6.11448 18.1228 5.52984C17.687 4.94585 17.0823 4.5365 16.4089 4.3572C15.8753 4.21509 15.5578 3.66724 15.6999 3.13356Z"
        fill="#5759FB"
      />
    </svg>
  );
}