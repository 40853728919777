import { Box } from '@material-ui/core';
import { String2Currency } from 'helpers/dataTransformers';
import React from 'react';
import { ContainerShare } from './styles';
import Wallet from '../../svg/wallet';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

export default function ShareCard(props) {
  return (
    <ContainerShare>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span style={{ color: '#707070', fontSize: 12 }}>Cashback</span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            width: 60,
            height: 60,
            background: '#f7f7f7',
          }}
        >
          <Wallet color={props.secudaryColor} />
        </div>
      </Box>
      <Box display="flex" flexDirection="column">
        <strong
          style={{ fontSize: 24, color: props.secudaryColor, margin: '20px 0' }}
        >
          {String2Currency(props.cashback)}
        </strong>
      </Box>
      <span style={{ fontSize: 16, color: '#707070' }}>
        Compartilhe clicando em um dos botões abaixo para resgatar sua
        recompensa!
      </span>
      <div className="row justify-content-center pt-4">
        <div
          style={{ cursor: 'pointer' }}
          className="col-3 d-flex justify-content-center"
        >
          <FacebookShareButton quote={props.title}>
            <FacebookIcon round />
          </FacebookShareButton>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          className="col-3 d-flex justify-content-center"
        >
          <TwitterShareButton title={props.title}>
            <TwitterIcon round />
          </TwitterShareButton>
        </div>
        <div
          style={{ cursor: 'pointer' }}
          className="col-3 d-flex justify-content-center"
        >
          <WhatsappShareButton separator=" ">
            <WhatsappIcon round />
          </WhatsappShareButton>
        </div>
      </div>
    </ContainerShare>
  );
}
