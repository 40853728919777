import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import React from 'react';

export class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      data: '',
      title: '',
    };
  }

  renderDynamic = () => {
    const dynamic = this.props.columns.map((col, i) => {
      return <Column key={col.field} field={col.field} header={col.header} />;
    });
    if (this.props.permissions.indexOf('can_edit') > -1) {
      dynamic.push(<Column header="Editar" body={this.edit} />);
    }
    if (this.props.permissions.indexOf('can_delete') > -1) {
      dynamic.push(<Column header="Deletar" body={this.delete} />);
    }

    return dynamic;
  };

  edit = value => {
    return (
      <center>
        <button
          type="button"
          onClick={() =>
            this.props.router.push(`${this.props.path}/${value.id}/editar`)
          }
          className="btn btn-secondary"
        >
          <FontAwesomeIcon icon={faPen} />
        </button>
      </center>
    );
  };

  delete = item => {
    const newData = (
      <div>
        <button
          type="button"
          onClick={e => this.setState({ visible: false })}
          className="btn btn-secondary"
        >
          Agora não
        </button>
        <button
          type="button"
          onClick={async () => {
            await this.props.remove(item.id, this.props.router);
            this.setState({ visible: false });
          }}
          className="btn btn-danger"
        >
          Deletar
        </button>
      </div>
    );
    return (
      <center>
        <button
          onClick={e =>
            this.setState({
              visible: true,
              title: 'Deseja mesmo deletar?',
              data: newData,
            })
          }
          className="btn btn-danger"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </center>
    );
  };

  render() {
    return (
      <div>
        <DataTable
          footer={null}
          value={this.props.values}
          responsive={true}
          tableStyle={{ borderRadius: 20, textAlign: 'center' }}
        >
          {this.renderDynamic()}
        </DataTable>
        <Dialog
          header={this.state.title}
          visible={this.state.visible}
          style={{ width: '50vw' }}
          modal={true}
          onHide={e => this.setState({ visible: false })}
        >
          {this.state.data}
        </Dialog>
      </div>
    );
  }
}
