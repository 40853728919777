import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import {
  InputLabelOutlined,
  InputSwitchLarge,
  SelectLabel,
  SelectMultiple,
} from 'common/components/fields/fields';
import states from 'helpers/states.json';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { getList as getCategoriesList } from '../../categories/categoriesActions';
import { getStationsList } from '../cupomOffers/cupomOffersActions';
import { GenericConfirmationCard } from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
// import { GeneralConfirmationCard } from 'common/components/cards/confirmationCard/generalConfirmationCard';
import { requestAdminTax } from 'pages/auth/authActions';
import { percentageMask } from 'helpers/masks';

class Publish extends Component {
  constructor(props) {
    super(props);
    this.props.getCategoriesList();
    this.state = {
      open: false,
      step: 0,
      values: null,
      selected: [],
      all: undefined,
      states: states,
      openConfirmation: false,
      tax: 0,
    };
    this.props.getStationsList();
    this.props.requestAdminTax();
  }

  componentDidMount() {
    let orderedStates = [...states];
    orderedStates.sort(function (a, b) {
      if (a.id === 10) {
        return -1;
      }
      if (b.id === 10) {
        return 1;
      }
      if (a.sigla < b.sigla) {
        return -1;
      }
      if (a.sigla > b.sigla) {
        return 1;
      }
      return 0;
    });

    this.setState({ states: orderedStates });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.formValues?.stationId !== prevProps.formValues?.stationId &&
      this.props.router.params.action === 'rascunho'
    ) {
      const selectedTax = this.props.cupomOffer.stationsList.content.find(
        (item) => item.id === this.props.formValues.stationId
      ).administration_tax;
      this.setState({ tax: parseFloat(selectedTax).toFixed(2) });
    }
  }

  onSubmit = (values) => {
    const newValues = { ...values, submitFromButton: true };
    this.props.onClickProgress(newValues);
  };

  onSubmitSec = (values) => {
    const newValues = { ...values, submitFromButton: true };
    this.props.onClickProgress(newValues);
    this.props.closeModal();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  render() {
    const statesList = this.state.states.map((item, index) => ({
      value: item.sigla,
      label: item.nome,
    }));

    const stationsList = this.props.cupomOffer.stationsList.content.map(
      (item) => ({
        value: item.id,
        label: item.name,
      })
    );
    const ageError = "A idade 'De' está menor que 'Para'";

    return (
      <>
        <Form
          role="form"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          noValidate
        >
          <div
            style={{
              overflowY: 'scroll',
              height: 'calc(100vh - 310px)',
              overflowX: 'hidden',
              paddingRight: 15,
            }}
          >
            <Typography
              style={{ fontSize: 16, fontWeight: '400' }}
              variant="subtitle2"
            >
              Agendamento da publicação
            </Typography>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12} md={6}>
                <Field
                  component={InputLabelOutlined}
                  label={'Início'}
                  name="start"
                  disabled={
                    this.props.disabled ||
                    this.props.router.params.action === 'proposta'
                  }
                  type="datetime-local"
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} sm={12} md={6}>
                <Field
                  component={InputLabelOutlined}
                  label={'Fim'}
                  name="end"
                  type="datetime-local"
                  disabled={
                    this.props.disabled ||
                    this.props.router.params.action === 'proposta'
                  }
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
            </MaterialGrid>
            <Typography
              style={{ fontSize: 16, fontWeight: '400' }}
              variant="subtitle2"
            >
              Agendamento da participação
            </Typography>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12} md={6}>
                <Field
                  component={InputLabelOutlined}
                  label={'Início'}
                  name="participationStart"
                  type="datetime-local"
                  disabled={
                    this.props.disabled ||
                    this.props.router.params.action === 'proposta'
                  }
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} sm={12} md={6}>
                <Field
                  component={InputLabelOutlined}
                  label={'Fim'}
                  name="participationEnd"
                  type="datetime-local"
                  disabled={
                    this.props.disabled ||
                    this.props.router.params.action === 'proposta'
                  }
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
            </MaterialGrid>
            {/**********************Se vai ou não ter segmentação *********************/}
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Limitar atividade por público alvo'}
                name="has_target"
                disabled={this.props.router.params.action === 'proposta'}
                required
                margin="normal"
              />
            </MaterialGrid>{' '}
            {this.props.formValues?.has_target ? (
              <>
                <Typography
                  style={{ fontSize: 16, fontWeight: '400' }}
                  variant="subtitle2"
                >
                  Definir público alvo
                </Typography>
                <>
                  {/*
                <MaterialGrid container spacing={3}>
                  <MaterialGrid item xs={12} sm={12} md={6}>
                    <Field
                      component={SelectLabel}
                      type="text"
                      label={`Associados`}
                      name={`associated`}
                      disabled={this.props.disabled}
                      margin="normal"
                      options={[
                        { value: 'all', label: 'Todos' },
                        { value: true, label: 'Associados' },
                        { value: false, label: 'Não associados' },
                      ]}
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                </MaterialGrid>
                <MaterialGrid container spacing={3}>
                  <MaterialGrid item xs={12} sm={12}>
                    <Field
                      component={SelectLabel}
                      type="text"
                      label={`Empregados`}
                      name={`internal`}
                      disabled={this.props.disabled}
                      options={[
                        { value: 'all', label: 'Todos' },
                        { value: true, label: 'Empregados' },
                        { value: false, label: 'Não empregados' },
                      ]}
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                </MaterialGrid> */}
                  <MaterialGrid container spacing={3}>
                    <MaterialGrid item xs={12} sm={12} md={6} lg={3}>
                      <Field
                        component={InputLabelOutlined}
                        label="De (anos)"
                        name="from"
                        disabled={this.props.disabled}
                        type="number"
                        validate={[FORM_RULES.required]}
                        required
                        margin="normal"
                        helperText={
                          this.props.formValues
                            ? parseInt(this.props.formValues.from) >
                              parseInt(this.props.formValues.to)
                              ? ageError
                              : null
                            : null
                        }
                      />
                    </MaterialGrid>
                    <MaterialGrid item xs={12} sm={12} md={6} lg={3}>
                      <Field
                        component={InputLabelOutlined}
                        label="Até (anos)"
                        name="to"
                        disabled={this.props.disabled}
                        type="number"
                        validate={[FORM_RULES.required]}
                        required
                        helperText={
                          this.props.formValues
                            ? parseInt(this.props.formValues.from) >
                              parseInt(this.props.formValues.to)
                              ? ageError
                              : null
                            : null
                        }
                        margin="normal"
                      />
                    </MaterialGrid>
                  </MaterialGrid>
                </>
                <Typography
                  style={{ fontSize: 16, fontWeight: '400' }}
                  variant="subtitle2"
                >
                  Local de divulgação
                </Typography>
                <MaterialGrid container spacing={3}>
                  <MaterialGrid item xs={12} sm={12} md={6}>
                    <Field
                      component={SelectMultiple}
                      label={'Estados que poderão participar'}
                      options={statesList}
                      multiple
                      name="states"
                      disabled={this.props.disabled}
                      required
                      validate={[FORM_RULES.required]}
                      margin="normal"
                    />
                  </MaterialGrid>
                </MaterialGrid>
              </>
            ) : null}{' '}
            {this.props.router.params.action === 'rascunho' && (
              <>
                <Typography
                  style={{ fontSize: 16, fontWeight: '400' }}
                  variant="subtitle2"
                >
                  Estação que receberá a proposta
                </Typography>
                <MaterialGrid item xs={12} sm={12} md={6}>
                  <Box display="flex" alignItems="center">
                    <Field
                      component={SelectLabel}
                      type="text"
                      label={`Estação`}
                      name={`stationId`}
                      disabled={this.props.disabled}
                      margin="normal"
                      options={stationsList}
                      validate={[FORM_RULES.required]}
                    />
                    <Tooltip
                      style={{ marginLeft: 10 }}
                      title="Aqui você selecionara a plataforma que enviará a prospota de atividade"
                      placement="top-start"
                    >
                      <Icon color="default">help</Icon>
                    </Tooltip>
                  </Box>
                </MaterialGrid>
              </>
            )}
            {this.props.router.params.action === 'rascunho' &&
            this.props.formValues?.type_id === 3 ? (
              <>
                <Typography
                  style={{ fontSize: 16, fontWeight: '400', marginTop: 8 }}
                  variant="subtitle2"
                >
                  Taxas
                </Typography>
                <Typography
                  style={{ margin: '15px 0', fontSize: 12 }}
                  variant="p"
                >
                  Ao enviar essa atividade para um de nossos clientes, você terá
                  que pagar uma taxa referente ao gateway + serviços a cada
                  compra efetuado. Uma parte dessa taxa é fixa e a outra você
                  propõe para o cliente que vai hospedar seu produto. A parte
                  fixa depende da estação escolhida.
                </Typography>
                <MaterialGrid container style={{ marginTop: 10 }}>
                  <MaterialGrid xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <div
                        style={{ marginRight: 10, fontSize: 25 }}
                      >{`1,50 + ${this.state.tax}% +`}</div>
                      <Field
                        component={InputLabelOutlined}
                        label="% para a estação hospedeira"
                        name="comission_owner_percentage"
                        validate={[FORM_RULES.required]}
                        disabled={
                          this.props.router.params.action === 'proposta'
                        }
                        required
                        margin="normal"
                        {...percentageMask}
                      />
                    </Box>
                  </MaterialGrid>
                  <MaterialGrid xs={12}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {this.props.formValues ? (
                        this.props.formValues.comission_owner_percentage ? (
                          <Typography>
                            Você receberá{' '}
                            {100 -
                              parseFloat(this.state.tax) -
                              parseFloat(
                                this.props.formValues.comission_owner_percentage
                              )}
                            % - 1,50 da venda dos produtos
                          </Typography>
                        ) : null
                      ) : null}
                    </Box>
                  </MaterialGrid>
                </MaterialGrid>
              </>
            ) : null}
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label="Mensagem para o gestor"
                name="ownerMessage"
              />
            </MaterialGrid>
            {this.props.formValues ? (
              this.props.formValues.ownerMessage ? (
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputLabelOutlined}
                    label="Mensagem para o gestor"
                    name="message_to_owner"
                    multiline
                    rowsMax={2}
                    disabled={this.props.disabled}
                    required
                    margin="normal"
                  />
                </MaterialGrid>
              ) : null
            ) : null}
            <GenericConfirmationCard
              open={this.props.openConfirmation}
              labelLeftButton={'Continuar aqui'}
              labelRightButton={'Sim, sair da edição'}
              general={this.props.general}
              title="Deseja sair da edição?"
              confirmButtonColor={'primary'}
              text="Lembre-se, as informações que não foram salvas serão perdidas."
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={this.props.closeModal}
              cancelClose={this.props.handleCancelClose}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justify="space-between"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: 29,
            }}
          >
            <SecondaryButton
              type="button"
              color="secondary"
              onClick={this.props.prevClick}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}
            >
              Voltar
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={this.props.activeActions.buttonLoading}
              progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}
            >
              {this.props.activeActions.buttonLoading
                ? 'Publicando'
                : 'Publicar'}
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Publish = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Publish);

const mapStateToProps = (state, { form }) => {
  return {
    auth: state.auth,
    formValues: getFormValues(form)(state),
    form: form,
    categories: state.categories,
    general: state.general,
    cupomOffer: state.cupomOffer,
    activeActions: state.activeActions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { getCategoriesList, getStationsList, requestAdminTax },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Publish);
