import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  button {
    align-self: center;
    z-index: 99;
    height: 50px;
    width: 230px;
    border-radius: 6px;
    background: ${props => (props.state ? '#505050' : props.color)};
    cursor: pointer;
    padding: 10px 40px;
    color: white;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: ${props => (!props.state ? 0.9 : null)};
    }
  }

  div {
    height: 100px;
    width: 426px;
    color: white;
  }
`;
export const ContainerBehind = styled.div`
  display: flex;
  justify-content: center;
  height: 170px;
  width: 450px;
  border-radius: 14px;
`;

export const ContainerButton = styled.div`
  button {
    align-self: center;
    z-index: 99;
    height: 50px;
    width: 230px;
    border-radius: 6px;
    background: ${props => props.color};
    cursor: pointer;
    padding: 10px 40px;
    color: white;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.9;
    }
  }
`;
