import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';

const URL_ESTABLISHMENT_POINTS = `${BASE_API}/establishments/points`;

export const getEstablishmentPoints = id => {
  return dispatch => {
    dispatch([
      {
        type: 'OWNER_POINTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_ESTABLISHMENT_POINTS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'OWNER_POINTS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};
