import AccountCircle from '@material-ui/icons/AccountCircle';
import Contacts from '@material-ui/icons/Contacts';
import Forum from '@material-ui/icons/Forum';
import Grain from '@material-ui/icons/Grain';
import Home from '@material-ui/icons/Home';
// Imports de Ícones do Material
import LocalGroceryStore from '@material-ui/icons/LocalGroceryStore';
import Loyalty from '@material-ui/icons/Loyalty';
import Score from '@material-ui/icons/Score';
import Subject from '@material-ui/icons/Subject';
import ViewList from '@material-ui/icons/ViewList';
import axios from 'axios';
import { USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomizedSnackbar from '../common/components/snackbar/snackbar';
import Owner from '../modules/owner/main/owner';
import { NotAuthorized } from '../pages/401/401';
import { validateToken } from '../pages/auth/authActions';
// import App from './app';
import Login from '../pages/auth/login/login';
import QrCodeValidator from '../pages/auth/qrCodeValidator/qrCodeValidator';

const navBarItems = [
  {
    id: 0,
    name: 'Painel',
    action: '',
    icon: <Home style={{ fontSize: 24 }} />,
    activeName: 'trl',
    link: '/owner',
  },
  {
    id: 4,
    name: 'Pedidos',
    action: '',
    icon: <Score style={{ fontSize: 24 }} />,
    activeName: 'ptp',
    link: '/owner/inscricoes',
    children: [
      {
        name: 'Vouchers',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/inscricoes',
        id: 0,
      },
      {
        name: 'Vendas',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/sales',
        id: 1,
      },
      {
        name: 'Doações',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/donations',
        id: 2,
      },
    ],
  },
  {
    id: 1,
    name: 'Engajamento',
    action: '',
    icon: <Forum style={{ fontSize: 24 }} />,
    activeName: 'trl',
    link: '/owner/acoes/minhas-atividades',
    children: [
      {
        name: 'Atividades',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/acoes/minhas-atividades',
        id: 0,
      },
      // {
      //   name: 'Atividades',
      //   action: '',
      //   icon: <ViewList style={{ fontSize: 24 }} />,
      //   link: '/owner/acoes/ativas',
      //   id: 1,
      // },
      // {
      //   name: 'Enviadas',
      //   action: '',
      //   icon: <ViewList style={{ fontSize: 24 }} />,
      //   link: '/owner/acoes/ativas',
      //   id: 2,
      // },
      // {
      //   name: 'Em construção',
      //   action: '',
      //   icon: <ViewList style={{ fontSize: 24 }} />,
      //   link: '/owner/acoes/rascunhos',
      //   id: 3,
      // },
      // {
      //   name: 'Finalizadas',
      //   action: '',
      //   icon: <ViewList style={{ fontSize: 24 }} />,
      //   link: '/owner/acoes/arquivadas',
      //   id: 4,
      // },

      // {
      //   name: 'Loja',
      //   action: '',
      //   icon: <ViewList style={{ fontSize: 24 }} />,
      //   link: '/owner/acoes',
      //   id: 5,
      // },
    ],
  },
  {
    id: 2,
    name: 'Gestores',
    action: '',
    icon: <Contacts style={{ fontSize: 24 }} />,
    activeName: 'ptp',
    link: '/owner/contacts/gestor',
    children: [
      {
        name: 'Novo gestor',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/contacts/gestor',
        id: 0,
      },
      {
        name: 'Validadores de código',
        action: '',
        icon: <ViewList style={{ fontSize: 24 }} />,
        link: '/owner/validadores',
        id: 0,
      },
    ],
  },
  {
    id: 3,
    name: 'Recompensas',
    action: '',
    icon: <Loyalty style={{ fontSize: 24 }} />,
    activeName: 'ptn',
    link: '/owner/points/buy',
    children: [
      {
        name: 'Comprar',
        action: '',
        icon: <LocalGroceryStore style={{ fontSize: 24 }} />,
        link: '/owner/points/buy',
        id: 0,
      },
      {
        name: 'Pedidos de transferência',
        action: '',
        icon: <Subject style={{ fontSize: 24 }} />,
        link: '/owner/points/transferencias',
        id: 1,
      },
      {
        name: 'Histórico de Compra de créditos',
        action: '',
        icon: <Subject style={{ fontSize: 24 }} />,
        link: '/owner/points/statement-buy-points',
        id: 2,
      },
      {
        name: 'Histórico de saída de créditos',
        action: '',
        icon: <Subject style={{ fontSize: 24 }} />,
        link: '/owner/points/user-earned-points',
        id: 3,
      },
      {
        name: 'Meus Créditos',
        action: '',
        icon: <Grain style={{ fontSize: 24 }} />,
        link: '/owner/points',
        id: 4,
      },
    ],
  },

  {
    id: 5,
    name: 'Configurações',
    link: '/owner/profile',
    icon: <AccountCircle style={{ fontSize: 24 }} />,
    activeName: 'pnl',
  },
];

class AuthOrApp extends Component {
  constructor(props) {
    super(props);
    console.disableYellowBox = true;
    axios.defaults.headers.common['Accept'] = 'application/json';
  }

  componentWillMount() {
    const { user } = this.props.auth;
    if (user) {
      this.props.validateToken(
        JSON.parse(localStorage.getItem(USER_TOKEN)),
        user.id
      );
    }
  }

  isAuthenticated() {}

  render() {
    const { user, validToken } = this.props.auth;
    let prefix = this.props.router.location.pathname.split('/');
    if (prefix?.[1].indexOf('qr-code-validator') > -1) {
      return (
        <QrCodeValidator>
          {this.props.children}
          <CustomizedSnackbar />
        </QrCodeValidator>
      );
    }
    if (!user && !validToken) {
      return (
        <Login>
          {this.props.children}
          <CustomizedSnackbar />
        </Login>
      );
    } else if (_.isNull(user.establishment_user)) {
      return <NotAuthorized />;
    }

    if (prefix.length > 1) {
      prefix = prefix[1];
    } else {
      prefix = prefix[0];
    }
    if (prefix === '') {
      return this.props.children;
    } else if (prefix === 'owner') {
      return (
        <Owner
          router={this.props.router}
          routes={this.props.routes}
          navBarItems={navBarItems}
        >
          {this.props.children}
          {/* <CustomizedSnackbar /> */}
        </Owner>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ validateToken }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthOrApp);
