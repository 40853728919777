import React from 'react';
import { Route, IndexRoute } from 'react-router';
import ValidatorCode from './validators';
import ValidatorCodeForm from './validatorsForm';

export default (
  <Route path="validadores">
    <IndexRoute component={props => <ValidatorCode {...props} />} />
    <Route path="novo" component={props => <ValidatorCodeForm {...props} />} />
    <Route
      path=":id/:action"
      component={props => <ValidatorCodeForm {...props} />}
    />
  </Route>
);
