import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import React from 'react';
import PrimaryButton from '../../buttons/primaryButton';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { isMobile } from 'react-device-detect';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function GenericConfirmationCard(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        onClose={props.cancelClose}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '98%',
          }}
        >
          <IconButton style={{ paddingBottom: 10 }} onClick={props.onClose}>
            <CloseIcon style={{ fontSize: 28 }} />
          </IconButton>
        </div>
        <DialogContent style={{ padding: isMobile ? '0px 20px 20px' : '0px 40px 20px' }}>
          <DialogTitle
            id="alert-dialog-slide-title"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: 0,
            }}
          >
            {props.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                textAlign: 'center',
                maxWidth: props.maxWidthFixed ? 350 : null,
                fontFamily: 'roboto',
                color: 'black',
              }}
            >
              {props.text}
              <br />
              {props.textSec || ''}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column-reverse' : 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              onClick={props.onClose}
              color="primary"
              style={{
                textTransform: 'none'
              }}
            >
              { props.labelLeftButton || 'Cancelar' }
            </Button>
            <PrimaryButton
              type="button"
              onClick={props.onClick}
              disabled={props.loading}
              progress={parseInt(props.general.submitProgress)}
            >
              {props.labelRightButton
                ? props.labelRightButton
                : props.loading
                ? 'Excluindo'
                : 'Excluir'}
            </PrimaryButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
}
