import { getListTransform } from 'helpers/transformResponse';
import type from './types';

const INITIAL_STATE = {
  loading: false,
  list: [],
  modal: false,
  website: {},
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.WEBSITE_LOAD:
      return { ...state, loading: action.payload };
    case type.WEBSITE_FETCHED:
      return { ...state, list: action.payload.data, loading: false };

    case type.WEBSITE_CREATED:
      return { ...state, modal: true, website: action.payload, loading: false };
    case type.WEBSITES_FETCHED:
      return {
        ...state,
        list: getListTransform(action.payload) || INITIAL_STATE.list,
        loadingStations: false,
      };
    default:
      return state;
  }
};
