import React, { Component } from 'react';
import './generalButton.css';

class GeneralButton extends Component {
  render() {
    const { onClick, type, style, disabled, className } = this.props;
    if (onClick) {
      if (disabled) {
        return (
          <button
            disabled
            type={type}
            className={`general-button ${className}`}
            style={style}
            onClick={onClick}
          >
            {this.props.children} {this.props.label}
          </button>
        );
      }
      return (
        <button
          type={type}
          className={`general-button ${className}`}
          style={style}
          onClick={onClick}
        >
          {this.props.children} {this.props.label}
        </button>
      );
    } else {
      if (disabled) {
        return (
          <button
            disabled
            type={type}
            className={`general-button ${className}`}
            style={style}
          >
            {this.props.children} {this.props.label}
          </button>
        );
      }
      return (
        <button
          type={type}
          className={`general-button ${className}`}
          style={style}
        >
          {this.props.children} {this.props.label}
        </button>
      );
    }
  }
}

export default GeneralButton;
