import { Box, Container, Link, Typography } from '@material-ui/core';
import logo from 'assets/img/fan_logo.svg';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import GeneralGrid from '../../../common/components/layout/grid/GeneralGrid';
import CustomizedSnackbar from '../../../common/components/snackbar/snackbar';
import { appColors } from '../../../styles/colors';

class EmailConfirmation extends Component {
  render() {
    return (
      <>
        <Container maxWidth="xl" style={{ height: '100%' }}>
          <GeneralGrid
            container
            spacing={8}
            justify="center"
            style={{ background: 'white' }}
          >
            <GeneralGrid item xs={8} md={5}>
              <Box display="flex" justifyContent="center" mt={5}>
                <Link to="/owner">
                  <div
                    style={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 8px',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={logo}
                      alt="Rilato Logo"
                      style={{ width: '100%', height: 'auto' }}
                    />
                  </div>
                </Link>
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                textAlign="center"
                mt={5}
              >
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 400,
                    fontSize: 32,
                  }}
                >
                  Confirme sua inscrição
                </Typography>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid
            container
            spacing={8}
            justify="center"
            style={{ height: '30%' }}
          >
            <GeneralGrid item xs={12} md={6} lg={5}>
              <Box textAlign="center" mt={10}>
                <Typography
                  variant="body1"
                  style={{ marginBottom: 8, color: appColors.primary }}
                >
                  Nós te enviamos um link para o endereço de e-mail que você nos
                  informou. Favor verifque suas mensagens e siga o link recebido
                  para verificar oseu endereço de e-mail
                </Typography>
                <Typography variant="body1">
                  Se necessário, verifique sua pasta de SPAM e adicione o nosso
                  endereço como confiável.
                </Typography>
              </Box>
            </GeneralGrid>
          </GeneralGrid>
          <CustomizedSnackbar />
        </Container>

        <div
          style={{
            position: 'absolute',
            bottom: 0,
            background: 'white',
            textAlign: 'center',
            width: '100%',
            padding: 24,
          }}
        >
          <Typography variant="body1" style={{ color: appColors.dark.level4 }}>
            2019 Todos os direitos reservados.{' '}
            <a
              href="#/confirmacao"
              style={{ color: '#2D95EF', textDecoration: 'underline' }}
            >
              Preferências de cookies
            </a>
            ,{' '}
            <a
              href="#/confirmacao"
              style={{ color: '#2D95EF', textDecoration: 'underline' }}
            >
              privacidade
            </a>{' '}
            e{' '}
            <a
              href="#/confirmacao"
              style={{ color: '#2D95EF', textDecoration: 'underline' }}
            >
              termos de uso.
            </a>
          </Typography>
        </div>
      </>
    );
  }
}

EmailConfirmation = reduxForm({ form: 'authForm' })(EmailConfirmation);
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailConfirmation);
