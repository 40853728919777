import type from './type';

const INITIAL_STATE = {
  loading: false,
  list: [],
  loadingDetail: false,
  item: {},
  groceryList: [],
  loadingList: false,
  deliveryStatusesList: [],
  deliveryStatusLoading: false,
  detail: null,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.PRODUCT_ORDERS_LOAD:
      return { ...state, loading: action.payload };
    case type.PRODUCT_ORDERS_FETCHED:
      return { ...state, loading: false, list: action.payload };
    case type.PRODUCT_ORDERS_DETAIL:
      return { ...state, loading: false, detail: action.payload };
    case type.PRODUCT_BUTTON_LOADING:
      return { ...state, loading: action.payload };
    case type.PRODUCT_DETAIL_LOAD:
      return { ...state, loadingDetail: action.payload };
    case type.PRODUCT_DETAIL_FETCHED:
      return { ...state, loadingDetail: false, item: action.payload };
    case type.PRODUCT_GROCERY_LIST_LOAD:
      return { ...state, loadingList: action.payload };
    case type.PRODUCT_GROCERY_LIST_FETCHED:
      return { ...state, loadingList: false, groceryList: action.payload };
    case type.DELIVERY_STATUSES_FETCHED:
      return {
        ...state,
        deliveryStatusLoading: false,
        deliveryStatusesList: action.payload,
      };
    case type.DELIVERY_STATUSES_LOAD:
      return {
        ...state,
        deliveryStatusLoading: action.payload,
      };
    default:
      return state;
  }
};
