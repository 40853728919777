import { reducer as toastrReducer } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import ownerReducers from '../modules/owner/ownerReducers';
import authReducer from '../pages/auth/authReducer';
import uploadReducer from '../common/components/upload/UploadFiles/uploadReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  toastr: toastrReducer,
  upload: uploadReducer,

  //Reducers from Owners module
  ...ownerReducers,
});

export default rootReducer;
