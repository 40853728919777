import Grid from '@material-ui/core/Grid';
import MaterialGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { GenericConfirmationCard } from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import {
  EditorConvertToHTMLActivity,
  InputFile,
  InputImageWithProcessing,
  InputLabelOutlined,
} from 'common/components/fields/fields';
import UploadVideos from 'common/components/upload';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { getList as getCategoriesList } from '../../categories/categoriesActions';

const maxL = max => value =>
  value && value.length > max
    ? `Aceitamos ${max} caracteres ou menos. Seu atual: ${value.length}`
    : undefined;
const maxLength = maxL(60);

class Content extends Component {
  constructor(props) {
    super(props);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.state = {
      openConfirmation: false,
      activityTitleValue: '',
      titleDisplay: 'none',
    };
  }

  onSubmit = values => {
    values['submitFromButton'] = true;
    this.props.onClickProgress(values);
  };
  onSubmitSec = values => {
    values['submitFromButton'] = true;
    this.props.onClickProgress(values);
    this.props.closeModal();
  };

  handleTitleChange(previousValue, newValue) {
    const titleLength = newValue.length;
    this.setState((prevState) => ({
      activityTitleValue: titleLength,
      titleDisplay: ''
    }))
    // console.log(newValue.length)
  }

  render() {
    return (
      <>
        <Form
          role="form"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          noValidate
        >
          <MaterialGrid
            container
            alignItems="center"
            xs={12}
            style={{
              overflowY: 'scroll',
              height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 310px)',
              overflowX: 'hidden',
              padding: '0 8px',
            }}
          >
            <MaterialGrid item xs={12}>
              <Typography
                style={{ fontSize: 16, fontWeight: '400' }}
                variant="subtitle2"
              >
                {'Título'}
              </Typography>
              {/* <MaterialGrid container direction="row" spacing={4} justify="center"> */}
              <Grid item container justify="flex-end" alignItems="center" xs={12}>
                <span
                  style={{
                    textAlign: 'flex-end',
                    display: this.state.titleDisplay,
                    fontFamily: 'Roboto',
                  }}
                >
                  {`${this.state.activityTitleValue}/60`}
                </span>
              </Grid>
              <Field
                component={InputLabelOutlined}
                label="Nome dessa atividade"
                name="name"
                validate={[FORM_RULES.required, maxLength]}
                required
                margin="normal"
                onChange={(previousValue, newValue) => this.handleTitleChange(previousValue, newValue)}
              />
            </MaterialGrid>
            {/* <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Resumo da atividade para o participante"
                name="summary"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
            </MaterialGrid> */}
            {/* <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label="Label do card (Ex: Participe)"
                name="click_me_label"
                disabled={this.props.disabled}
                required
                margin="normal"
              />
            </MaterialGrid> */}
            {/* <Typography variant="subtitle1" align="start">
            Foto para a Atividade
            </Typography> */}
            <MaterialGrid
              item
              xs={12}
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Typography
                style={{ fontSize: 16, fontWeight: '400' }}
                variant="subtitle2"
              >
                {parseInt(this.props.formValues?.type_id) === 18
                  ? 'Capa da atividade'
                  : 'Imagens'}
              </Typography>
              {/* <MaterialGrid container direction="row" justify="space-evenly"> */}

              <Field
                component={InputImageWithProcessing}
                label={'Imagem .png ou .jpg'}
                cols="12 12 12 12"
                name="image"
                aspect={16 / 9}
                aspectLabel={'16:9'}
                validate={[FORM_RULES.required]}
              />
            </MaterialGrid>
            {/* <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Destaque da área não logada'}
                name="featuredPublic"
                required
                margin="normal"
              />
            </MaterialGrid> */}
            {/* <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Destaque da área logada'}
                name="featuredPrivate"
                required
                margin="normal"
              />
            </MaterialGrid> */}
            {this.props.formValues ? (
              this.props.formValues.featuredPrivate ? (
                <MaterialGrid container direction="row" justify="space-evenly">
                  <MaterialGrid item xs={12}>
                    <Field
                      component={InputFile}
                      label={'Imagem .png ou .jpg para destaque'}
                      cols="12 12 12 12"
                      name="featuredImage"
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                </MaterialGrid>
              ) : null
            ) : null}
            {this.props.formValues ? (
              parseInt(this.props.formValues?.type_id) !== 18 ? (
                <MaterialGrid
                  item
                  xs={12}
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  <Field
                    component={EditorConvertToHTMLActivity}
                    label={'Descrição dessa atividade'}
                    name="description"
                    border
                    validate={[FORM_RULES.required]}
                  />
                </MaterialGrid>
              ) : null
            ) : null}
            {this.props.formValues ? (
              parseInt(this.props.formValues?.type_id) !== 18 ? (
                <MaterialGrid item xs={12} style={{ margin: '25px 0px' }}>
                  <Field
                    component={UploadVideos}
                    indexVideo={0}
                    multipleVideos={false}
                    formValues={
                      this.props.formValues ? this.props.formValues : null
                    }
                    name={`videoIdField`}
                  />
                </MaterialGrid>
              ) : null
            ) : null}
            <GenericConfirmationCard
              open={this.props.openConfirmation}
              labelLeftButton={'Continuar aqui'}
              labelRightButton={'Sim, sair da edição'}
              general={this.props.general}
              title="Deseja sair da edição?"
              confirmButtonColor={'primary'}
              text="Lembre-se, as informações que não foram salvas serão perdidas."
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={this.props.closeModal}
              cancelClose={this.props.handleCancelClose}
              maxWidthFixed
            />
          </MaterialGrid>
          <MaterialGrid
            container
            justify="space-evenly"
            alignItems="center"
            xs={12}
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
            }}
          >
            <PrimaryButton
              type="submit"
              color="secondary"
              disabled={true}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}
            >
              Voltar
            </PrimaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={this.props.activeActions.buttonLoading}
              // proress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
                fontSize: isMobile ? 12 : undefined,
              }}
            >
              Salvar e continuar
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Content = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Content);
const mapStateToProps = (state, { form }) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    categories: state.categories,
    general: state.general,
    formValues: getFormValues(form)(state),
    form: form,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getCategoriesList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
