import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import LoadingProgress from 'common/components/progress/loading';
import moment from 'moment';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PrimaryButton from '../../../../common/components/buttons/primaryButton';
import { getEstablishmentPoints } from '../points/pointsActions';
import { handleReviewModal } from './actionsFile';

function ActionReview(props) {
  const theme = useTheme();

  useEffect(() => {
    return () => {
      props.handleReviewModal(false);
    };
  }, []);

  if (props.points.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  function handleRouting() {
    if (
      props.actions.boughtTemplate.type_action_id === 7 ||
      props.actions.boughtTemplate.type_action_id === 8 ||
      props.actions.boughtTemplate.type_action_id === 6
    ) {
      props.router.push(
        `/owner/acoes/aquisicao-clientes/pesquisa/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    }
    if (props.actions.boughtTemplate.type_action_id === 4) {
      props.router.push(
        `/owner/acoes/curso-presencial/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    } else if (props.actions.boughtTemplate.type_action_id === 3) {
      props.router.push(
        `/owner/acoes/cupons/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    } else if (props.actions.boughtTemplate.type_action_id === 2) {
      props.router.push(
        `/owner/acoes/signup-earn/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    } else if (props.actions.boughtTemplate.type_action_id === 1) {
      props.router.push(
        `/owner/acoes/member-get-member/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    } else if (props.actions.boughtTemplate.type_action_id === 5) {
      props.router.push(
        `/owner/acoes/compartilhe/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    } else if (props.actions.boughtTemplate.type_action_id === 14) {
      props.router.push(
        `/owner/acoes/entregar-produto/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    } else if (props.actions.boughtTemplate.type_action_id === 16) {
      props.router.push(
        `/owner/acoes/conteudo-s-i/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    } else if (props.actions.boughtTemplate.type_action_id === 17) {
      props.router.push(
        `/owner/acoes/atividade-doacao/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    } else if (props.actions.boughtTemplate.type_action_id === 18) {
      props.router.push(
        `/owner/acoes/video-playlist/${props.actions.boughtTemplate.action_id}/rascunho`
      );
    }
  }
  return (
    <MaterialGrid
      container
      spacing={3}
      style={{ background: theme.palette.background.main }}
      justify="center"
    >
      <MaterialGrid
        item
        xs={11}
        style={{
          background: theme.palette.background.white,
          padding: theme.spacing(5),
          marginBottom: theme.spacing(5),
        }}
      >
        <MaterialGrid
          container
          spacing={3}
          justify="space-between"
          style={{ marginBottom: theme.spacing(3) }}
          alignItems="center"
        >
          <MaterialGrid item xs={4}>
            <Typography
              style={{
                color: theme.palette.text.main,
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              Contratação efetuada com sucesso!
            </Typography>
          </MaterialGrid>
          <MaterialGrid item xs={4}>
            <Typography
              align="right"
              style={{
                color: theme.palette.text.main,
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              #{props.actions.transactionMade.id}
            </Typography>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid
          container
          spacing={0}
          style={{ marginBottom: theme.spacing(1) }}
        >
          <MaterialGrid
            item
            xs={12}
            style={{ backgroundColor: '#169F53', height: 10 }}
          ></MaterialGrid>
        </MaterialGrid>
        <MaterialGrid
          container
          spacing={3}
          style={{
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
          }}
        >
          <MaterialGrid item xs={12}>
            <Typography variant="body2" color="primary">
              Resumo do pedido
            </Typography>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid
          container
          spacing={3}
          style={{ marginBottom: theme.spacing(3) }}
        >
          <MaterialGrid item xs={12}>
            <Typography
              style={{
                color: theme.palette.text.main,
                fontSize: '1.5rem',
                fontWeight: 700,
              }}
            >
              {props.action.name}
            </Typography>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid
          container
          spacing={3}
          style={{ marginBottom: theme.spacing(1) }}
        >
          <MaterialGrid item xs={12}>
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12}>
                <Typography variant="body2" color="primary">
                  Criado por: Fan.Projects
                </Typography>
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid container spacing={3} justify="space-between">
              <MaterialGrid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  py={3}
                  style={{
                    borderStyle: 'dashed',
                    borderWidth: '1px 0px 0px 0px',
                    borderColor: 'lightgray',
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ color: theme.palette.text.main }}
                  >
                    Valor desta
                  </Typography>
                  <Typography
                    variant="body2"
                    align="right"
                    style={{ color: theme.palette.text.main, fontWeight: 700 }}
                  >
                    R${' '}
                    {parseFloat(props.body.action_price)
                      .toFixed(2)
                      .replace('.', ',')}
                  </Typography>
                </Box>
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid container spacing={3} justify="space-between">
              <MaterialGrid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  py={3}
                  style={{
                    borderStyle: 'dashed',
                    borderWidth: '1px 0px 0px 0px',
                    borderColor: 'lightgray',
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ color: theme.palette.text.main }}
                  >
                    Data de contratação
                  </Typography>
                  <Typography
                    variant="body2"
                    align="right"
                    style={{ color: theme.palette.text.main, fontWeight: 700 }}
                  >
                    {moment().format('DD/MM/YYYY HH:MM:ss')}
                  </Typography>
                </Box>
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid container spacing={3} justify="space-between">
              <MaterialGrid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  py={3}
                  style={{
                    borderStyle: 'dashed',
                    borderWidth: '1px 0px 0px 0px',
                    borderColor: 'lightgray',
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ color: theme.palette.text.main }}
                  >
                    Responsável pela contratação
                  </Typography>
                  <Typography
                    variant="body2"
                    align="right"
                    style={{ color: theme.palette.text.main, fontWeight: 700 }}
                  >
                    {props.auth.user.name}
                  </Typography>
                </Box>
              </MaterialGrid>
            </MaterialGrid>
          </MaterialGrid>
        </MaterialGrid>
        <MaterialGrid container spacing={3} justify="center">
          <MaterialGrid item xs={4}>
            <PrimaryButton
              style={{ padding: '1rem 4rem' }}
              onClick={() => handleRouting()}
              progress={props.general.submitProgress}
            >
              ACESSAR
            </PrimaryButton>
          </MaterialGrid>
        </MaterialGrid>
      </MaterialGrid>
    </MaterialGrid>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    actions: state.actions,
    points: state.points,
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getEstablishmentPoints,
      handleReviewModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionReview);
