import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova/theme.css';
import React from 'react';
import ReactDOM from 'react-dom';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'react-typist/dist/Typist.css';
import { applyMiddleware, createStore } from 'redux';
import multi from 'redux-multi';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import reducers from './main/reducers';
import Routes from './main/routes';
import { appColors } from './styles/colors';
import './styles/base/base.css';
import './styles/pages/login/login.css';

const {
  v2: { blue },
} = appColors;

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const store = applyMiddleware(multi, thunk, promise)(createStore)(
  reducers,
  devTools
);

export const theme = createTheme({
  palette: {
    primary: { main: blue },
    secondary: { main: '#ef2db3' },
    error: { main: '#FF5A52' },
    success: { main: '#52C22C' },
    text: {
      main: '#000000',
      lightgray: '#707070',
    },
    background: {
      main: '#F7F7F7',
      white: '#FFF',
      lightgray: '#707070',
    },
  },
  spacing: 8,
});

ReactDOM.render(
  <Provider store={store}>
    <>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Routes />
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </MuiThemeProvider>
    </>
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.

// Learn more about service workers: http://bit.ly/CRA-PWA
//registerServiceWorker();
