import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(theme => {
  return {
    root: {
      flexGrow: 1,
    },
  };
});

export default function GeneralGrid(props) {
  const classes = useStyles();
  return (
    <Grid className={clsx(classes.root, props.className)} {...props}>
      {props.children}
    </Grid>
  );
}
