import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import type from './type';

const URL_GET_CAMPAIGNS = `${BASE_API}/actions/?where[partner_id]=`;
const URL_ESTABLISHMENTS = `${BASE_API}/establishments`;
const PARTNER_DATA = `${BASE_API}/partner-data`;

export const getCampaignsByEstablishmentId = id => {
  return dispatch => {
    dispatch([
      {
        type: type.CAMPAIGNS_LOAD,
        payload: true,
      },
    ]);
    axios
      .get(`${URL_GET_CAMPAIGNS}${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: type.CAMPAIGNS_FETCHED,
            payload: response.data,
          },
        ]);
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const getEstablishmentsList = () => {
  return dispatch => {
    dispatch([
      {
        type: 'ESTABLISHMENTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_ESTABLISHMENTS}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'ESTABLISHMENTS_FETCHED',
            payload: response,
          },
        ]);
      });
  };
};

export const getPartnerData = id => {
  return dispatch => {
    dispatch([
      {
        type: 'PARTNER_DATA_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${PARTNER_DATA}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'PARTNER_DATA_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch(e => {

      });
  };
};
