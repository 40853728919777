import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { InputLabelOutlined } from '../../../../../common/components/fields/fields';
import GeneralGrid from '../../../../../common/components/layout/grid/GeneralGrid';
import { tableSearch } from '../../../../../helpers/utils';
import { getList, remove } from './couponsActions';

function Coupons(props) {
  const { auth } = props;
  const { content } = props.coupons.list;
  const { router } = props;
  const [columns] = useState([
    { title: 'Nome', field: 'name' },
    { title: 'Código', field: 'code' },
    { title: 'Expiração', field: 'expiration' },
  ]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    props.getList(auth.user.establishments[0].id);
  }, []);

  useEffect(() => {
    setData(props.coupons.list.content);
  }, [props.coupons.list.content]);

  function handleDelete(id, resolve, reject) {
    props.remove(id, resolve, reject, props.auth.user.establishments[0].id);
  }

  function handleChange(e) {
    setData(tableSearch(e, props.coupons.list.content));
    setValue(e.target.value);
  }

  function renderEmptyScreen() {
    return (
      <section id="coupons" style={{ marginTop: '4.3em' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui produtos resgatáveis
                  </h2>
                  <p className="">
                    Pprodutos resgatáveis são códigos promocionais que você
                    poderá distribuir para seus associados.
                  </p>

                  <PrimaryButton
                    onClick={() => props.router.push('/owner/cupons/novo')}
                    color="primary"
                  >
                    Criar produtos resgatáveis
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.coupons.loading) {
    return (
      <Box display="flex" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            justify="space-between"
            alignContent="space-between"
          >
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}
              >
                Lista de Produtos
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {props.coupons.list.content.length} produtos
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6
                title="Lista de produtos resgatáveis"
                columns={columns}
                data={data}
                router={router}
                handleDelete={handleDelete}
                remove
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    coupons: state.coupons,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getList, remove }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Coupons);
