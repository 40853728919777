import _ from 'lodash';
import axios from 'axios';
import { BASE_API } from 'config/consts';

export const searchTableValue = (value, defaultData, typeData = 'default') => {
  let currentData = [];
  let filtered = [];
  if (value !== '') {
    currentData = defaultData;
    filtered = currentData.filter(item => {
      let lc;
      let lc2;
      if (typeData === 'partners') {
        lc = item['partner'].name.toLowerCase();
      } else if (typeData === 'cpf') {
        lc = item.cpf ? item.cpf.toLowerCase() : '';
      } else if (typeData === 'default') {
        lc = item.name ? item.name.toLowerCase() : '';
      } else if (
        typeData === 'owners' ||
        typeData === 'subscriptions' ||
        typeData === 'donations'
      ) {
        lc = item['user'].name.toLowerCase();
      } else if (typeData === 'news') {
        lc = item.title ? item.title.toLowerCase() : '';
      } else if (typeData === 'duplicateSearch') {
        lc = item.name ? item.name.toLowerCase() : '';
        lc2 = item.email ? item.email.toLowerCase() : '';
      } else if (typeData === 'planUser') {
        // lc = item.name ? item.name.toLowerCase() : '';
        lc = item['plan'].name.toLowerCase();
      } else if (typeData === 'productOrders' || typeData === 'actionsMenu') {
        // lc = item.name ? item.name.toLowerCase() : '';
        lc = item['action'].name.toLowerCase();
      }
      const filter = value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

      if (typeData === 'duplicateSearch') {
        return (
          lc
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(filter) ||
          lc2
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(filter)
        );
      } else {
        return lc
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(filter);
      }
    });
  } else {
    filtered = defaultData;
  }
  return filtered;
};

export const hasKeys = (obj, attrs) => {
  let tmp = obj;

  for (let i = 0; i < attrs.length; ++i) {
    if (_.isUndefined(tmp[attrs[i]])) return false;
    // tmp = tmp[attrs[i]];
  }
  return true;
};

export const tableSearch = (e, list) => {
  const newList = list.filter((item, index) => {
    let isFiltered = Object.values(item).find((item2, index2) => {
      if (!_.isNull(item2)) {
        if (
          item2
            .toString()
            .toUpperCase()
            .search(e.target.value.toUpperCase()) > -1
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    if (!_.isUndefined(isFiltered)) {
      return true;
    } else {
      return false;
    }
  });
  return newList;
};

export const imgToBase64 = image => {
  return image.base64;
};

export const imgToImgType = image => {
  let a = image.base64.indexOf('/');
  let b = image.base64.indexOf(';');
  image.base64.slice(a + 1, b);
  return image.base64.slice(a + 1, b);
};

export const validateCNPJ = maskedCNPJ => {
  const unmaskedCNPJ = maskedCNPJ
    .replace('.', '')
    .replace('.', '')
    .replace('/', '')
    .replace('-', '');
  const firstValidationDigits = [
    parseInt(unmaskedCNPJ[0]),
    parseInt(unmaskedCNPJ[1]),
    parseInt(unmaskedCNPJ[2]),
    parseInt(unmaskedCNPJ[3]),
    parseInt(unmaskedCNPJ[4]),
    parseInt(unmaskedCNPJ[5]),
    parseInt(unmaskedCNPJ[6]),
    parseInt(unmaskedCNPJ[7]),
    parseInt(unmaskedCNPJ[8]),
    parseInt(unmaskedCNPJ[9]),
    parseInt(unmaskedCNPJ[10]),
    parseInt(unmaskedCNPJ[11]),
  ];

  const validationArray = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  let sumOfResultsArray = 0;

  for (let i = 0; i < firstValidationDigits.length; i += 1) {
    sumOfResultsArray += firstValidationDigits[i] * validationArray[i];
  }

  const divisionValidation = parseInt(sumOfResultsArray % 11);

  if (divisionValidation < 2) {
    if (parseInt(unmaskedCNPJ[12]) !== 0) {
      return false;
    }
  } else {
    if (parseInt(unmaskedCNPJ[12]) !== 11 - divisionValidation) {
      return false;
    }
  }

  const secondValidationDigits = [
    parseInt(unmaskedCNPJ[0]),
    parseInt(unmaskedCNPJ[1]),
    parseInt(unmaskedCNPJ[2]),
    parseInt(unmaskedCNPJ[3]),
    parseInt(unmaskedCNPJ[4]),
    parseInt(unmaskedCNPJ[5]),
    parseInt(unmaskedCNPJ[6]),
    parseInt(unmaskedCNPJ[7]),
    parseInt(unmaskedCNPJ[8]),
    parseInt(unmaskedCNPJ[9]),
    parseInt(unmaskedCNPJ[10]),
    parseInt(unmaskedCNPJ[11]),
    parseInt(unmaskedCNPJ[12]),
  ];

  const secondValidationArray = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

  let secondSumOfResults = 0;

  for (let i = 0; i < secondValidationDigits.length; i += 1) {
    secondSumOfResults += secondValidationDigits[i] * secondValidationArray[i];
  }

  const secondDivisionValidation = parseInt(secondSumOfResults % 11);
  if (secondDivisionValidation < 2) {
    if (parseInt(unmaskedCNPJ[13]) !== 0) {
      return false;
    }
  } else {
    if (parseInt(unmaskedCNPJ[13]) !== 11 - secondDivisionValidation) {
      return false;
    }
  }
  return true;
};

export const api = axios.create({
  baseURL: BASE_API,
});

export const truncateString = (string, size = 50) => {
  const ellipsis = '...';
  return string.length > size
    ? string.substring(0, size).concat(ellipsis)
    : string;
};

export const formatReal = value => {
  const totalReal = value.toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

  return totalReal;
};

export const calculateRemainingCash = (value, limit, points) => {
  const totalCash = parseFloat(points) * parseFloat(limit);
  let totalCost = 0;
  let remainCash = 0;
  if (totalCash > 0) {
    totalCost = value * parseFloat(points);
    remainCash = totalCash - totalCost;
  }

  return remainCash;
};

export const limitStringSize = (string, MAX = 50) => {
  let str = string;
  if (str.length > MAX) str = `${str.substring(0, MAX)} ...`;
  return str;
};

export const roundMoney = function(value, format) {
  if (isNaN(value)) {
    return 'Erro';
  }
  let newValue = _.round(value, 2);
  if (format) {
    return `R$ ${newValue.toString().replace('.', ',')}`;
  }
  return newValue;
};
