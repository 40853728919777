import styled from "styled-components";

export const GeneralTitle = styled.h1 `
display: flex;
flex-wrap: wrap;
overflow-wrap: break-word;
font-family: 'Lato', sans-serif;
font-size: ${(props) =>
  (props.xs || props.sm)
  ? '1.5rem'
  : '2rem'
};
font-weight: 700;
line-height: ${(props) =>
  (props.isPreview) ? (
    '2.5rem'
  ) : (
    (props.xs || props.sm)
    ? '1.7rem'
    : '2.5rem'
  )
};
/* margin: 0 0 .5rem 0; */
margin-bottom: ${(props) => props.marginBottom
  ? props.marginBottom
  : '.5rem'
};
color: #525252;
`;
