import React, { Component } from 'react';

export default class Spinner extends Component {
  render() {
    return (
      <div
        class="d-flex justify-content-center"
        style={{ marginTop: 5, color: '#ff007b' }}
      >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p className="px-2">Aguarde um momento...</p>
      </div>
    );
  }
}
