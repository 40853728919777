import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, reduxForm } from 'redux-form';
import { GenericConfirmationCard } from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';

class Interaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
  }

  renderBackground = () => {
    return '#3f51b5';
  };
  onSubmit = values => {
    values['submitFromButton'] = true;
    this.props.onClickProgress(values);
  };
  onSubmitSec = values => {
    values['submitFromButton'] = true;
    this.props.onClickProgress(values);
    this.props.closeModal();
  };

  render() {
    return (
      <>
        <Form
          role="form"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          noValidate
        >
          <div
            style={{
              overflowY: 'scroll',
              height: 'calc(100vh - 310px)',
              overflowX: 'hidden',
              paddingRight: 15,
            }}
          >
            <Box
              style={{ height: 300 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography color="primary" style={{ fontSize: 20 }}>
                Essa mecânica não possui interação configurável. O valor da
                doação é escollhido pelo participante.
              </Typography>
            </Box>
            <GenericConfirmationCard
              open={this.props.openConfirmation}
              labelLeftButton={'Continuar aqui'}
              labelRightButton={'Sim, sair da edição'}
              general={this.props.general}
              title="Deseja sair da edição?"
              confirmButtonColor={'primary'}
              text="Lembre-se, as informações que não foram salvas serão perdidas."
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={this.props.closeModal}
              cancelClose={this.props.handleCancelClose}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justify="space-around"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: 29,
            }}
          >
            <SecondaryButton
              type="button"
              color="secondary"
              onClick={this.props.prevClick}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}
            >
              Voltar
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={this.props.activeActions.buttonLoading}
              // proress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}
            >
              Salvar e continuar
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Interaction = reduxForm({
  form: 'donationActionForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Interaction);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    donationAction: state.donationAction,
    activeActions: state.activeActions,
    general: state.general,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Interaction);
