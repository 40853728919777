import { Box, makeStyles, Typography } from '@material-ui/core';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import React from 'react';
import { Card, CardText } from 'react-md';
import PrimaryButton from '../../../../common/components/buttons/primaryButton';
import GeneralGrid from '../../layout/grid/GeneralGrid';

const useStyles = makeStyles(theme => ({
  gutters: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}));

export function OnboardingCard(props) {
  const style = {
    width: props.width || 290,
    height: props.height || 580,
    marginBottom: 20,
    borderRadius: 6,
    position: 'relative',
    boxShadow:
      '0px 16px 8px -4px rgba(0,0,0,0.05), 0px 13px 19px 2px rgba(0,0,0,0.05), 0px 5px 24px 4px rgba(0,0,0,0.05)',
  };
  const { condition, title, text, buttonLabel, router, onClickButton } = props;
  const classes = useStyles();

  return (
    <Card style={style} className="md-block-centered">
      <GeneralGrid container spacing={3} style={{ marginBottom: 50 }}>
        <GeneralGrid item xs={12}>
          <Box display="flex" justifyContent="center" mt={6}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                borderRadius: '50%',
                height: 60,
                width: 60,
                background: condition ? '#ababab' : '#2D95EF',
              }}
            >
              <BookmarkIcon
                style={{
                  color: 'white',
                }}
              />
            </Box>
          </Box>
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid container style={{ marginBottom: 10 }} spacing={3}>
        <GeneralGrid item xs={12}>
          {props.multipleLines ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <textarea
                style={{
                  outline: 'none',
                  border: 'none',
                  width: '100%',
                  height: '120px',
                  resize: 'none',
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 16,
                  textAlign: 'center',
                  marginLeft: style.width / 15,
                  marginRight: style.width / 15,
                }}
                spellcheck="false"
                value={title}
              />
            </Box>
          ) : (
            <Typography
              style={{
                fontFamily: 'Roboto',
                fontWeight: 200,
                fontSize: 16,
                textAlign: 'center',
                marginLeft: style.width / 15,
                marginRight: style.width / 15,
              }}
            >
              {title}
            </Typography>
          )}
        </GeneralGrid>
      </GeneralGrid>
      <CardText>
        <GeneralGrid
          container
          spacing={3}
          style={{ marginBottom: 20 }}
          className={classes.gutters}
        >
          <GeneralGrid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Typography
                variant="caption"
                style={{
                  textAlign: 'center',
                  color: '#ABABAB',
                  marginLeft: props.textMargin ? style.width / 15 : 0,
                  marginRight: props.textMargin ? style.width / 15 : 0,
                }}
              >
                {text}
              </Typography>
            </Box>
          </GeneralGrid>
          <GeneralGrid item xs={12}>
            {props.aditionalContent}
          </GeneralGrid>
          <GeneralGrid
            item
            xs={12}
            style={{
              padding: 0,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {props.aditionalContent2}
          </GeneralGrid>
        </GeneralGrid>
      </CardText>
      {!props.hideButton ? (
        <GeneralGrid
          container
          spacing={3}
          style={{ position: 'absolute', bottom: props.height * 0.015 || 45 }}
        >
          <GeneralGrid item xs={12}>
            <Box display="flex" justifyContent="center">
              {props.alternativeButton || (
                <PrimaryButton
                  router={router}
                  onClick={onClickButton}
                  disabled={condition ? true : false}
                >
                  {buttonLabel}
                </PrimaryButton>
              )}
            </Box>
          </GeneralGrid>
        </GeneralGrid>
      ) : null}
    </Card>
  );
}
