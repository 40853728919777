import type from './types';
import {
  getListTransform,
  getDetailTransform,
} from 'helpers/transformResponse';

const INITIAL_STATE = {
  list: {
    content: [],
  },
  detail: {},
  loading: true,
  buttonLoading: false,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.LIST_FETCHED:
      return {
        ...state,
        list: getListTransform(action.payload.data) || INITIAL_STATE.list,
        loading: false,
      };
    case type.ITEM_DETAIL:
      return {
        ...state,
        detail: getDetailTransform(action.payload.data) || INITIAL_STATE.detail,
        loading: false,
      };
    case type.LIST_LOAD:
      return {
        ...state,
        loading: action.payload,
      };
    case type.BUTTON_LOAD:
      return {
        ...state,
        buttonLoading: action.payload,
      };
    default:
      return state;
  }
};
