import moment from 'moment';
import React from 'react';
const QRCode = require('qrcode.react');

export default function VoucherModal({ item, colors, formValues }) {
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',

        backgroundColor: '#FAFAFA',
      }}
    >
      <div
        className="container-fluid d-flex-column justify-content-space-around"
        style={{
          backgroundColor: '#fff',
          width: 497,
          overflowY: 'scroll',
          height: 'calc(100vh - 310px)',
          overflowX: 'hidden',
          paddingRight: 15,
        }}
      >
        {/*   <div className="row justify-content-center mt-5">
          <div className="col-8 col-md-4">
            <img
              src={`${BASE_S3}/${COMPANY_COLORS(colors).homepage.sidebar.logo}`}
              alt="LOGO-EMPRESA"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        </div> */}
        <div className="row justify-content-center mt-5">
          <div className="col-auto text-center">
            <h4>{formValues.name}</h4>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-md-8 col-11">
            <h6 className="text-muted text-center">{`Voucher gerado em: ${moment().format(
              'LLLL'
            )}`}</h6>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-auto">
            <QRCode
              value={`https://parceiro.rilato.app/#/owner/validacao/CODEIGO123`}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-auto">
            <h5 className="text-muted">CODIGO123</h5>
          </div>
        </div>
        {formValues.local ? (
          <div className="row justify-content-center mt-4">
            <div className="col-md-auto col-11">
              <p className="text-muted">{formValues.local}</p>
            </div>
          </div>
        ) : null}

        <div className="row justify-content-center mt-3">
          <div className="col-md-auto col-11">
            <h6 className="text-muted mb-0 text-center">
              Este voucher deverá ser utilizado até:
            </h6>
          </div>
        </div>
        <div className="row justify-content-center mt-0">
          <div className="col-md-auto col-11">
            <h6 className="text-muted text-center">
              {moment().format('LLLL')}
            </h6>
          </div>
        </div>
        <div className="row justify-content-center mt-5">
          <div className="col-md-auto col-11">
            <div
              className={'py-3 station-font-medium'}
              style={{
                background: '#2D95EF',
                color: '#2D95EF',
                border: `1px solid #2D95EF`,
                borderRadius: 8,
                paddingLeft: 140,
                paddingRight: 140,
                cursor: 'pointer',
                fontSize: '1rem',
                textAlign: 'center',
              }}
            >
              {'Imprimir'}
            </div>
          </div>
        </div>
        <div className="row justify-content-center mt-2 mb-5">
          <div className="col-auto">
            <a
              href="#/homepage"
              style={{ textDecoration: 'underline', color: '#505050' }}
            >
              Página inicial
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
