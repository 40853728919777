import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import {
  InputLabelOutlined,
  SelectLabel,
} from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { cpfMask, DDDMask, phoneWithoutDDDMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import { getAdditionalData } from 'modules/owner/pages/signupCompany/signupCompanyActions.js';
import React, { useEffect } from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, Form, getFormValues, reduxForm } from 'redux-form';
import { putGatewayInfo, saveGatewayInfo } from '../profileActions';
import { isMobile } from 'react-device-detect';

const renderPartners = props => {
  const {
    meta: { submitFailed, error },
  } = props;
  return (
    <>
      <Box>
        <PrimaryButton onClick={() => props.fields.push()}>
          Adicionar Sócio
        </PrimaryButton>
      </Box>
      {props.fields.map((partner, index) => (
        <ExpansionPanel
          key={index}
          label={`Sócio ${index + 1}`}
          footer={
            <Box ml={2}>
              <SecondaryButton
                onClick={() => props.fields.remove(index)}
                color="primary"
              >
                Excluir Sócio
              </SecondaryButton>
            </Box>
          }
          style={{
            marginBottom: 10,
            width: '100%',
            borderRadius: 4,
            boxShadow: 'none',
            backgroundColor: '#f7f7f7',
          }}
        >
          <MaterialGrid container spacing={3} style={{ background: '#f7f7f7' }}>
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label={`Nome do Sócio ${index + 1}`}
                name={`${partner}.name`}
                disabled={props.disabled}
                validate={[FORM_RULES.required]}
                required
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label={`CPF do Sócio ${index + 1}`}
                name={`${partner}.documentNumber`}
                disabled={props.disabled}
                validate={[FORM_RULES.required, FORM_RULES.cpf]}
                required
                margin="normal"
                {...cpfMask}
              />
            </MaterialGrid>
            <MaterialGrid item xs={12}>
              <Field
                component={InputLabelOutlined}
                label={`E-mail do Sócio ${index + 1}`}
                name={`${partner}.email`}
                disabled={props.disabled}
                validate={[FORM_RULES.required, FORM_RULES.email]}
                required
                margin="normal"
              />
            </MaterialGrid>
          </MaterialGrid>
        </ExpansionPanel>
      ))}
      {submitFailed && error && (
        <span style={{ color: 'red' }} className="help-block">
          {error}
        </span>
      )}
    </>
  );
};
let GatewayForm = function(props) {
  const { formValues } = props;
  const [isDisabled, setDisabled] = React.useState(false);

  useEffect(() => {
    if (props.auth.user.establishments[0].digital_account) {
      setDisabled(true);
    }
    props.getAdditionalData();
  }, []);

  function onSubmit(values) {
    const establishment = props.auth.user.establishments[0];
    const values_pagarme = {
      managingPartners: values.managingPartners,
      type: 'RECEIVING',
      name: establishment.name,
      document: establishment.cnpj,
      tradingName: establishment.name,
      email: props.auth.user.email,
      ...values.create_account,
      establishment_id: establishment.id,
    };

    if (!isDisabled) {
      props.saveGatewayInfo(values_pagarme);
    } else {
      props.putGatewayInfo(
        values_pagarme,
        props.auth.user.establishments[0].id
      );
    }
  }

  if (
    props.signupCompany.additionalDataLoading ||
    props.profile.pagarmeLoading
  ) {
    return <LoadingProgress />;
  }
  const banks = props.signupCompany.additionalData.banks;
  const sortedBanks = banks.sort(function(a, b) {
    if (a.number < b.number) {
      return -1;
    }
    if (a.number > b.number) {
      return 1;
    }
    return 0;
  });
  return (
    <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
      <MaterialGrid item container xs={12} style={{ padding: '12px 0' }}>
        <Typography variant="body1">
          Dados da empresa para o cadastro no Pagar.me
        </Typography>
      </MaterialGrid>
      <MaterialGrid container spacing={3}>
        <MaterialGrid item xs={4} md={3}>
          <Field
            component={InputLabelOutlined}
            label="DDD"
            name="create_account.phone.ddd"
            validate={[FORM_RULES.required]}
            required
            disabled={isDisabled}
            margin="normal"
            {...DDDMask}
          />
        </MaterialGrid>
        <MaterialGrid item xs={8} md={3}>
          <Field
            component={InputLabelOutlined}
            label="Número"
            name="create_account.phone.number"
            validate={[FORM_RULES.required]}
            required
            disabled={isDisabled}
            margin="normal"
            {...phoneWithoutDDDMask}
          />
        </MaterialGrid>
        {isMobile ? (
          <MaterialGrid item container xs={12} style={{ paddingBottom: 0 }}>
            <Typography variant="body2">
              Sua empresa na rede (site, linkedIn, Instagram, Facebook):
            </Typography>
          </MaterialGrid>
        ) : null}
        <MaterialGrid item xs={12} md={6}>
          <Field
            component={InputLabelOutlined}
            label={`URL${
              isMobile ? '' : ' (site, linkedin, instagram, facebook)'
            }`}
            name="create_account.businessUrl"
            validate={[FORM_RULES.required, FORM_RULES.url]}
            required
            margin="normal"
          />
        </MaterialGrid>
      </MaterialGrid>
      <MaterialGrid item container xs={12} style={{ paddingTop: '12px' }}>
        <Typography variant="body2">Selecione o tipo de conta</Typography>
      </MaterialGrid>
      <MaterialGrid container spacing={3}>
        <MaterialGrid item xs={12} md={6}>
          <Field
            component={SelectLabel}
            label="Tipo de conta"
            name="create_account.bankAccountType"
            validate={[FORM_RULES.required]}
            options={[
              {
                value: 'pf',
                label: 'Pessoa Física',
              },
              {
                value: 'pj',
                label: 'Pessoa Jurídica',
              },
            ]}
            required
            margin="normal"
            disabled={isDisabled}
          />
        </MaterialGrid>
      </MaterialGrid>
      {formValues?.create_account.bankAccountType === 'pj' ? (
        <MaterialGrid item xs={12}>
          <MaterialGrid item xs={12} style={{ padding: '12px 0' }}>
            <Typography variant="body2">
              Informe abaixo os dados dos sócios listados neste CNPJ
            </Typography>
          </MaterialGrid>
          <ExpansionList>
            <FieldArray
              name="managingPartners"
              disabled={isDisabled}
              component={renderPartners}
              validate={[FORM_RULES.partnersArray]}
              {...props}
            />
          </ExpansionList>
        </MaterialGrid>
      ) : formValues?.create_account.bankAccountType === 'pf' ? (
        <MaterialGrid item xs={12}>
          <Typography variant="subtitle1">Dados do titular da conta</Typography>
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={12} md={6}>
              <Field
                component={InputLabelOutlined}
                label={`Nome completo`}
                name="create_account.bankAccount.ownerName"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={12} md={6}>
              <Field
                component={InputLabelOutlined}
                label={`CPF`}
                name="create_account.bankAccount.cpf"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
                {...cpfMask}
                disabled={isDisabled}
              />
            </MaterialGrid>
          </MaterialGrid>
        </MaterialGrid>
      ) : null}
      {formValues?.create_account.bankAccountType ? (
        <>
          <MaterialGrid item container xs={12} style={{ padding: '12px 0' }}>
            <Typography variant="body1">
              Dados bancários para recebimento de vendas
            </Typography>
            {isMobile && formValues?.create_account.bankAccountType === 'pj' ? (
              <Typography variant="caption">
                Atenção: Preencha os campos com as informações relacionadas à
                sua conta PJ
              </Typography>
            ) : null}
          </MaterialGrid>
          <MaterialGrid container spacing={3}>
            <MaterialGrid item xs={12} md={6}>
              <Field
                component={SelectLabel}
                label={`Banco${
                  isMobile
                    ? ''
                    : formValues?.create_account.bankAccountType === 'pj'
                    ? ' (PJ)'
                    : ''
                }`}
                name="create_account.bankAccount.bankNumber"
                validate={[FORM_RULES.required]}
                options={sortedBanks.map(item => ({
                  label: `${item.number} ${item.name}`,
                  value: item.number,
                }))}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item container xs={12} style={{ paddingBottom: 0 }}>
              <Typography variant="body2">Dados da agência:</Typography>
            </MaterialGrid>
            <MaterialGrid item xs={7} md={6}>
              <Field
                component={InputLabelOutlined}
                label={`Número${
                  isMobile
                    ? ''
                    : formValues?.create_account.bankAccountType === 'pj'
                    ? ' (PJ)'
                    : ''
                }*`}
                type="number"
                name="create_account.bankAccount.agencyNumber"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={5} md={6}>
              <Field
                component={InputLabelOutlined}
                label={`Dígito${
                  isMobile
                    ? ''
                    : formValues?.create_account.bankAccountType === 'pj'
                    ? ' (PJ)'
                    : ''
                }`}
                type="number"
                name="create_account.bankAccount.agencyDv"
                margin="normal"
              />
            </MaterialGrid>

            <MaterialGrid item container xs={12} style={{ paddingBottom: 0 }}>
              <Typography variant="body2">Dados da conta:</Typography>
            </MaterialGrid>
            <MaterialGrid item xs={12} md={6}>
              <Field
                component={SelectLabel}
                label="Tipo"
                name="create_account.bankAccount.accountType"
                validate={[FORM_RULES.required]}
                options={[
                  { label: 'Conta Corrente', value: 'conta_corrente' },
                  { label: 'Conta Poupança', value: 'conta_poupanca' },
                  {
                    label: 'Conta Corrente Conjunta',
                    value: 'conta_corrente_conjunta',
                  },
                  {
                    label: 'Conta Poupança Conjunta',
                    value: 'conta_poupanca_conjunta',
                  },
                ]}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={7} md={6}>
              <Field
                component={InputLabelOutlined}
                label={`Número${
                  isMobile
                    ? ''
                    : formValues?.create_account.bankAccountType === 'pj'
                    ? ' (PJ)'
                    : ''
                }*`}
                type="number"
                name="create_account.bankAccount.accountNumber"
                validate={[FORM_RULES.required]}
                required
                margin="normal"
              />
            </MaterialGrid>
            <MaterialGrid item xs={5} md={6}>
              <Field
                component={InputLabelOutlined}
                label={`Dígito${
                  isMobile
                    ? ''
                    : formValues?.create_account.bankAccountType === 'pj'
                    ? ' (PJ)'
                    : ''
                }*`}
                type="number"
                name="create_account.bankAccount.accountDv"
                margin="normal"
                required
                validate={[FORM_RULES.required]}
              />
            </MaterialGrid>
          </MaterialGrid>
        </>
      ) : null}
      <div className="row justify-content-around mb-3 mt-3">
        <PrimaryButton
          progress={parseInt(props.general.submitProgress)}
          router={props.router}
          type="submit"
          color="primary"
        >
          {'Enviar'}
        </PrimaryButton>
      </div>
    </Form>
  );
};

GatewayForm = reduxForm({
  form: 'GatewayForm',
})(GatewayForm);

const mapStateToProps = state => {
  return {
    formValues: getFormValues('GatewayForm')(state),
    general: state.general,
    auth: state.auth,
    profile: state.profile,
    signupCompany: state.signupCompany,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getAdditionalData, saveGatewayInfo, putGatewayInfo },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GatewayForm);
