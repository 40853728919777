import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePickerComponent } from 'common/components/fields/fields';
import Grid from 'common/components/layout/grid/grid';
import { setFormValue } from 'helpers/formComponent';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { TextField } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import { stylesheets } from 'styles/stylesheets';
import { getDetail, initializeForm, setDetail } from '../manage/manageActions';
import validate from './validate';
class QuizForm extends Component {
  constructor(props) {
    super(props);
    const { quizzes, router, location, getDetail, setDetail } = this.props;
    setFormValue(quizzes, router, location, getDetail, setDetail);
  }

  renderInputField = ({
    name,
    input,
    label,
    type,
    meta: { touched, error },
  }) => (
    <div>
      <div className={`form-group ${touched && error && 'has-error'}`}>
        {touched && error ? (
          <label className="control-label">
            <i className="fa fa-times-circle-o" />
            &nbsp;
            {label}
          </label>
        ) : (
          <label htmlFor={name}>{label}</label>
        )}
        <TextField {...input} type={type} placeholder={label} />
        {touched && error && <span className="help-block">{error}</span>}
      </div>
    </div>
  );

  renderTextareaField = ({
    name,
    input,
    label,
    type,
    meta: { touched, error },
  }) => (
    <div>
      <div className={`form-group ${touched && error && 'has-error'}`}>
        {touched && error ? (
          <label className="control-label">
            <i className="fa fa-times-circle-o" />
            &nbsp;
            {label}
          </label>
        ) : (
          <label htmlFor={name}>{label}</label>
        )}
        <textarea
          className="form-control"
          {...input}
          type={type}
          placeholder={label}
        />
        {touched && error && <span className="help-block">{error}</span>}
      </div>
    </div>
  );

  renderSelectField = ({
    name,
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <div>
      <div className={`form-group ${touched && error && 'has-error'}`}>
        {touched && error ? (
          <label className="control-label">
            <i className="fa fa-times-circle-o" />
            &nbsp;
            {label}
          </label>
        ) : (
          <label htmlFor={name}>{label}</label>
        )}
        <select className="form-control" {...input}>
          {children}
        </select>
        {touched && error && <span className="help-block">{error}</span>}
      </div>
    </div>
  );

  renderSelectQuestionTypeField = ({
    input,
    label,
    type,
    meta: { touched, error },
    children,
  }) => (
    <div>
      <label>{label}</label>
      <div>
        <select {...input}>{children}</select>
        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );

  renderTextAnswers = ({ fields, question, meta: { error } }) => (
    <div>
      {fields.map((answer, index) => (
        <div key={index} className="row">
          <Grid cols="10 10 10 10">
            <Field
              name={answer}
              type="text"
              component={this.renderInputField}
              label={`Resposta ${index + 1}`}
            />
          </Grid>
          <Grid cols="2 2 2 2">
            <button
              className="btn btn-danger"
              type="button"
              onClick={() => fields.remove(index)}
            >
              <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </button>
          </Grid>
        </div>
      ))}
      <div>
        <Field
          name={`${question}.correctAnswer`}
          component={this.renderSelectField}
          label="Resposta Correta"
        >
          <option value="">Selecione a Resposta Correta</option>
          {fields.map((answer, index) => (
            <option key={index + 1} value={index + 1}>{`Resposta ${index +
              1}`}</option>
          ))}
        </Field>
      </div>

      {error && <div className="error">{error}</div>}
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => fields.push()}
      >
        Adicionar Resposta
      </button>
    </div>
  );

  renderQuestions = ({ fields, meta: { touched, error, submitFailed } }) => (
    <div>
      {fields.map((question, index) => (
        <div
          key={index}
          className="container"
          style={{
            background: 'rgb(239, 239, 239)',
            padding: 25,
            borderRadius: 50,
            marginTop: 20,
          }}
        >
          <div className="row">
            <Grid cols="10 10 10 10">
              <h4>Questão {index + 1}</h4>
            </Grid>
            <Grid cols="2 2 2 2">
              <button
                style={{ marginRight: '0', position: 'relative' }}
                className="btn btn-danger"
                type="button"
                onClick={() => fields.remove(index)}
              >
                {<FontAwesomeIcon className="history-icon" icon={faTrash} />}
              </button>
            </Grid>
          </div>
          <Field
            name={`${question}.question`}
            type="text"
            component={this.renderInputField}
            label="Pergunta"
          />
          <FieldArray
            name={`${question}.answers`}
            component={this.renderTextAnswers}
            question={question}
          />
          <Field
            name={`${question}.messageForCorrectAnswer`}
            type="text"
            component={this.renderTextareaField}
            label="Mensagem caso a pessoa acerte essa resposta"
          />
          <Field
            name={`${question}.messageForInorrectAnswer`}
            type="text"
            component={this.renderTextareaField}
            label="Mensagem caso a pessoa erre essa resposta"
          />
          <Field
            name={`${question}.explanation`}
            type="text"
            component={this.renderTextareaField}
            label="Explicação da resposta"
          />
        </div>
      ))}
      <div style={stylesheets.flexboxParent}>
        <button
          className="btn btn-primary"
          style={{
            ...stylesheets.flexboxChild,
            minWidth: '0',
            minHeight: '0',
            height: '3em',
            maxWidth: '12em',
            marginTop: 20,
          }}
          type="button"
          onClick={() => fields.push({})}
        >
          <FontAwesomeIcon icon={faPlus} /> Adicionar Questão
        </button>
        {(touched || submitFailed) && error && <span>{error}</span>}
      </div>
    </div>
  );

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

    return (
      <div className="QuizForm">
        <form name="quiz-form" onSubmit={handleSubmit}>
          <Field
            name="name"
            type="text"
            component={this.renderInputField}
            label="Título do Quiz"
            validate={[FORM_RULES.required]}
          />
          <Field
            name="description"
            type="text"
            component={this.renderInputField}
            label="Descrição do Quiz"
            validate={[FORM_RULES.required]}
          />
          <Field
            name="expiration"
            type="tel"
            component={DatePickerComponent}
            label="Expiração do Quiz"
            validate={[FORM_RULES.required]}
          />
          <Field
            name="points"
            type="number"
            component={this.renderInputField}
            label="Quantos pontos o usuário vai receber por participar do Quiz"
            validate={[FORM_RULES.required]}
          />
          <Field
            name="limit"
            type="number"
            component={this.renderInputField}
            label="Quanto usuários, no máximo, podem fazer o Quiz."
            validate={[FORM_RULES.required]}
          />
          <FieldArray name="questions" component={this.renderQuestions} />
          <div className="box-footer" style={{ marginTop: 20 }}>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={submitting}
            >
              Enviar
            </button>
            <button
              className="btn btn-danger"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Resetar Tudo
            </button>
          </div>
        </form>
      </div>
    );
  }
}

QuizForm = reduxForm({
  form: 'quizForm',
  validate,
})(QuizForm);

const selector = formValueSelector('quizForm');

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
    },
    dispatch
  );
};

QuizForm = connect(state => {
  const questions = selector(state, 'questions');
  const questionType =
    questions && questions.map(question => question.questionType);

  return { questionType: questionType };
}, mapDispatchToProps)(QuizForm);

export default QuizForm;
