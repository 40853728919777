import { USER_KEY, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reduxForm } from 'redux-form';
import {
  signupCompanyOnly,
  changeEstablishment,
} from '../../../pages/auth/authActions.js';
import { getListById as getSectionsListById } from '../pages/sections/sectionsActions';
import './owner.css';
import { isMobileOnly } from 'react-device-detect';
import ResponsiveDrawer from 'common/components/navigation/responsiveDrawer';
import OwnerNavigation from '../../../common/components/navigation/ownerNavigation/ownerNavigation';
import CustomizedSnackbar from 'common/components/snackbar/snackbar';

class Owner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: !_.isEmpty(props.auth.user.establishments) ? false : true,
      step: 0,
    };
  }

  renderNav = () => {
    return (
      <span className="font-dosis">
        <span style={{ fontWeight: 'bold', color: '#2b2d6b' }}>
          {this.props.auth.user.name}
        </span>
      </span>
    );
  };

  onHide = () => {
    this.setState({ visible: false });
  };

  handleRemove = () => {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(USER_TOKEN);
  };

  onSubmit = values => {
    const { router, auth } = this.props;
    values['type'] = 1;
    values['owner'] = this.props.auth.user.id;
    this.props.signupCompanyOnly(values, router, auth);
  };

  render() {
    const currentRoute = this.props.routes[this.props.routes.length - 2]?.path;
    if (isMobileOnly) {
      return (
        <ResponsiveDrawer
          router={this.props.router}
          routes={this.props.routes}
          navItems={this.props.navBarItems}
          user={this.props.auth.user}
        >
          {this.props.children}
        </ResponsiveDrawer>
      );
    }
    return (
      <>
        <OwnerNavigation
          router={this.props.router}
          navBarItems={this.props.navBarItems}
          changeEstablishment={this.props.changeEstablishment}
          establishments={this.props.establishments}
        />
        <div
          style={{
            background: '#f7f7f7',
            width: '100%',
            minHeight: currentRoute === 'website' ? 0 : '110vh',
          }}
        >
          <div className="container" style={{ maxWidth: 1180 }}>
            <div className="col-12">{this.props.children}</div>
          </div>

          <CustomizedSnackbar />
        </div>
      </>
    );
  }
}

Owner = reduxForm({ form: 'ownerForm' })(Owner);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    points: state.points,
    establishments: state.establishments,
    sections: state.sections,
    principal: state.principal,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { signupCompanyOnly, getSectionsListById, changeEstablishment },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Owner);
