import Content from 'common/components/content/content';
import { ColorPickerInput, InputFile } from 'common/components/fields/fields';
import Grid from 'common/components/layout/grid/grid';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  getDetail,
  initializeForm,
  setDetail,
  updateIdentity,
} from './identityActions';

class Identity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
    this.props.setDetail(
      this.props.auth.user.establishments[0].visualIdentityId
    );
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleSecondColorChange = this.handleSecondColorChange.bind(this);
  }

  handleColorChange(color) {
    this.props.change('mainColor', color);
  }
  handleSecondColorChange(color) {
    this.props.change('secondColor', color);
  }
  componentWillMount() {}

  onSubmit = values => {
    const { router } = this.props;
    values['id'] = this.props.auth.user.establishments[0].visualIdentityId.id;
    values.base64 = values.cleanLogo.base64;
    let a = values.base64.indexOf('/');
    let b = values.base64.indexOf(';');
    values.imageType = values.base64.slice(a + 1, b);
    delete values['cleanLogo'];
    this.props.updateIdentity(values, router);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Content>
        <Card>
          <CardTitle title="Minha Identidade Visual" />
          <CardText>
            <div className="container-fluid">
              <div className="row">
                <Form
                  role="form"
                  onSubmit={handleSubmit(this.onSubmit)}
                  noValidate
                >
                  <Field
                    component={ColorPickerInput}
                    label={'Cor Principal'}
                    name="mainColor"
                    onColorChange={this.handleColorChange}
                    cols="12 12 6 6"
                    validate={[FORM_RULES.required]}
                  />
                  {/* <Field
									component={ColorPickerInput}
									label={"Cor secundária"}
									name="secondColor"
									onColorChange={this.handleSecondColorChange}
									cols="12 12 4 4"
									validate={[FORM_RULES.required]}
								/> */}
                  <Field
                    component={InputFile}
                    label={'Logo com fundo transparente'}
                    name="cleanLogo"
                    cols="12 12 6 6"
                    validate={[FORM_RULES.required]}
                  />
                  <Grid cols="12 12 12 12">
                    <center>
                      <button className="btn btn-primary" type="submit">
                        Salvar
                      </button>
                    </center>
                  </Grid>
                </Form>
              </div>
            </div>
          </CardText>
        </Card>
      </Content>
    );
  }
}

Identity = reduxForm({ form: 'IdentityForm' })(Identity);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    formValues: getFormValues('IdentityForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      updateIdentity,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Identity);
