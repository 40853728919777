import _ from 'lodash';
import { validate } from './cpf';

export const FORM_RULES = {
  required: value => {
    return !_.isEmpty(value) || _.isNumber(value) || _.isBoolean(value)
      ? undefined
      : 'Esse campo é obrigatório';
  },
  max: (max, current) => value =>
    value && value.length > max
      ? `Esse campo deve possuir no máximo ${max} caracteres. Atualmente: ${current}`
      : undefined,
  min: min => value =>
    value && value.length < min
      ? `Esse campo deve possuir no mínimo ${min} caracteres`
      : undefined,
  number: value =>
    value && isNaN(Number(value)) ? 'Este campo só aceita números' : undefined,
  minValue: min => value =>
    value && value < min ? `O valor deve ser maior que ${min}` : undefined,
  maxValue: max => value =>
    value && value < max ? `O valor deve ser menor que ${max}` : undefined,
  password: value =>
    value && !/((?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,20})/i.test(value)
      ? 'Senha precisar ter: uma letra maiúscula, uma letra minúscula, um número e tamanho entre 8 - 20.'
      : undefined,
  email: value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? 'Email inválido'
      : undefined,
  tooOld: value => value =>
    value && value > 65 ? 'You might be too old for this' : undefined,
  lowerCase: value =>
    value && /[^a-z]/i.test(value)
      ? 'Apenas letras minúsculas sem acento'
      : undefined,
  requiredCheckbox: value => {
    return value ? undefined : 'Esse campo é obrigatório';
  },
  actions: value => {
    let has_required = false;
    if (!value) {
      return undefined;
    }
    for (let item of value) {
      if (!item) {
        return undefined;
      }
      if (item.required) {
        has_required = true;
      }
    }
    return has_required
      ? undefined
      : 'É necessário que tenha ao menos 1 atividade obrigatória por nível';
  },
  url: value => {
    var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(expression);
    var t = value;

    if (t.match(regex)) {
      return undefined;
    } else {
      return MESSAGES.url;
    }
  },
  minValueProduct: value => {
    const vFloat = parseFloat(value);
    if (vFloat < 1.5) {
      return 'Valor mínimo requerido de R$ 1,50';
    } else return undefined;
  },
  cpf: value => {
    if (!validate(value)) {
      return 'CPF inválido';
    } else return undefined;
  },
  partnersArray: value => {
    if (!value) {
      return 'Ao menos um sócio deve ser cadastrado';
    } else return undefined;
  },
};

export const MESSAGES = {
  required: 'Este campo é obrigatório',
  whitespace: 'Este campo não pode ser vazio',
  mail: 'Digite um email válido',
  number: 'Este campo deve conter apenas números',
  url: 'Digite uma url válida',
};

export const FORMRULES = {
  required: { required: true, message: MESSAGES.required },
  whitespace: { whitespace: true, message: MESSAGES.whitespace },
  number: { pattern: /\d+/, message: MESSAGES.number },
  mail: {
    pattern: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: MESSAGES.mail,
  },
  url: {
    pattern: /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/,
    message: MESSAGES.url,
  },
};
