import { Box, Icon, Tooltip, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { GenericConfirmationCard } from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import {
  EditorConvertToHTMLActivity,
  InputImageWithProcessing,
  InputLabelOutlined,
  InputSwitchLarge,
  SelectLabel,
} from 'common/components/fields/fields';
import LottieControl from 'common/components/lottie/lottie';
import LoadingProgress from 'common/components/progress/loading';
import { currencyMask, thousandSeparatorMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  getList as getLottiesList,
  getListById as getLottiesListById,
} from '../../lotties/lottiesActions';

class Prize extends Component {
  constructor(props) {
    super(props);
    this.props.getLottiesList();
    this.state = {
      openConfirmation: false,
    };
  }
  onSubmit = values => {
    if (!values.isPaid) {
      values.points = 0.0;
    }
    if (values.feedback_lottie_id === 'none') {
      values.feedback_lottie_id = null;
    }
    values['submitFromButton'] = true;
    this.props.onClickProgress(values);
  };
  onSubmitSec = values => {
    if (!values.isPaid) {
      values.points = 0.0;
    }
    if (values.feedback_lottie_id === 'none') {
      values.feedback_lottie_id = null;
    }
    values['submitFromButton'] = true;
    this.props.onClickProgress(values);
    this.props.closeModal();
  };

  componentDidMount() {
    if (this.props.formValues) {
      if (this.props.formValues.feedback_lottie_id) {
        this.props.getLottiesListById(this.props.formValues.feedback_lottie_id);
      }
    }
  }
  componentDidUpdate(props) {
    if (props.formValues) {
      if (
        props.formValues.feedback_lottie_id !==
        this.props.formValues.feedback_lottie_id
      ) {
        if (this.props.formValues.feedback_lottie_id !== 'none') {
          this.props.getLottiesListById(
            this.props.formValues.feedback_lottie_id
          );
        }
      }
    } else {
      if (this.props.formValues) {
        if (this.props.formValues.feedback_lottie_id !== 'none') {
          this.props.getLottiesListById(
            this.props.formValues.feedback_lottie_id
          );
        }
      }
    }
  }
  render() {
    let lottiesList = this.props.lotties.list.content.map((item, index) => {
      return {
        label: item.name,
        value: item.id,
        url: item.url,
      };
    });
    lottiesList.unshift({
      label: 'Nenhuma Animação',
      value: 'none',
      url: 'none',
    });

    return (
      <>
        <Form
          role="form"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          noValidate
        >
          <div
            style={{
              overflowY: 'scroll',
              height: 'calc(100vh - 310px)',
              overflowX: 'hidden',
              paddingRight: 15,
            }}
          >
            <MaterialGrid container direction="row" justify="space-evenly">
              {this.props.formValues ? (
                this.props.formValues.type_id === 6 ? null : (
                  <MaterialGrid
                    item
                    xs={12}
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    <Field
                      component={EditorConvertToHTMLActivity}
                      label={'Mensagem ao concluir atividade'}
                      name="feedback_message"
                      disabled={this.props.disabled}
                      border
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                )
              ) : null}
              <MaterialGrid item xs={12} sm={12}>
                <Typography
                  style={{ fontSize: 16, fontWeight: '400' }}
                  variant="subtitle2"
                >
                  {'Limite de participação'}
                </Typography>
                <Field
                  component={InputLabelOutlined}
                  label="Limite de participações na atividade"
                  name="limit"
                  validate={[FORM_RULES.required]}
                  required
                  disabled={this.props.disabled}
                  margin="normal"
                  {...thousandSeparatorMask}
                />
              </MaterialGrid>
              {!this.props.formValues.type_id === 16 ? (
                <MaterialGrid item xs={12} sm={12}>
                  <Field
                    component={InputLabelOutlined}
                    label="Limite de participações por usuário"
                    name="limitPerUser"
                    validate={[FORM_RULES.required]}
                    required
                    disabled={this.props.disabled}
                    margin="normal"
                    {...thousandSeparatorMask}
                  />
                </MaterialGrid>
              ) : null}
              <MaterialGrid item xs={12} sm={12}>
                <Field
                  component={InputSwitchLarge}
                  label="O participante ganhará recompensa?"
                  disabled={this.props.disabled}
                  name="isPaid"
                />
              </MaterialGrid>
              {this.props.formValues.isPaid ? (
                <MaterialGrid item xs={12} sm={12}>
                  <Field
                    component={InputLabelOutlined}
                    label="Quanto ganha a pessoa que vai participar? (R$)"
                    name="points"
                    type="tel"
                    disabled={this.props.disabled}
                    validate={[FORM_RULES.required]}
                    required
                    margin="normal"
                    {...currencyMask}
                  />
                </MaterialGrid>
              ) : null}

              {!this.props.formValues.type_id === 16 ? (
                <MaterialGrid
                  item
                  xs={12}
                  style={{ minHeight: 100, marginTop: 50, marginBottom: 50 }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MaterialGrid item xs={6}>
                      <Field
                        component={SelectLabel}
                        options={lottiesList}
                        name="feedback_lottie_id"
                        disabled={this.props.disabled}
                        label="Escolha uma animação para feedback"
                      />
                    </MaterialGrid>
                    <MaterialGrid item xs={6}>
                      <div style={{ height: 100 }}>
                        {this.props.formValues ? (
                          this.props.formValues.feedback_lottie_id !==
                          'none' ? (
                            this.props.lotties.loading ? (
                              <Box display="flex" justifyContent="center">
                                <LoadingProgress />
                              </Box>
                            ) : (
                              <Box display="flex" justifyContent="center">
                                <LottieControl
                                  width={100}
                                  height={100}
                                  loop={false}
                                  animation={this.props.lotties.detail.json}
                                  hasReplayButton
                                />
                              </Box>
                            )
                          ) : null
                        ) : null}
                      </div>
                    </MaterialGrid>
                  </Box>
                </MaterialGrid>
              ) : null}
            </MaterialGrid>
            {parseInt(
              this.props.formValues ? this.props.formValues.type_id : 0
            ) !== 16 ? (
              <>
                <MaterialGrid item xs={12}>
                  <Field
                    component={InputSwitchLarge}
                    label={'Regulamento'}
                    name="has_rules"
                    default={true}
                    disabled={this.props.disabled}
                  />
                </MaterialGrid>
                {this.props.formValues && this.props.formValues.has_rules && (
                  <MaterialGrid item xs={12}>
                    <Field
                      component={EditorConvertToHTMLActivity}
                      label={''}
                      name="rules"
                      border
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                )}
              </>
            ) : null}
            <MaterialGrid item xs={12}>
              <Field
                component={InputSwitchLarge}
                label={'Receber troféu ao concluir'}
                disabled={this.props.disabled}
                name="isThereTrophy"
                margin="normal"
              />
            </MaterialGrid>
            {this.props.formValues ? (
              this.props.formValues.isThereTrophy ? (
                <>
                  <MaterialGrid item xs={12}>
                    <Typography
                      style={{ marginBottom: 5 }}
                      variant="subtitle2"
                      align="left"
                    >
                      Ícone do Troféu
                    </Typography>
                    <Field
                      component={InputImageWithProcessing}
                      label={'Imagem .png ou .jpg'}
                      cols="12 12 12 12"
                      name="trophy_image"
                      aspect={1 / 1}
                      aspectLabel={'1:1'}
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                  <MaterialGrid item xs={12} style={{ marginTop: 8 }}>
                    <Typography
                      style={{ marginBottom: 5, display: 'inline' }}
                      variant="subtitle2"
                      align="left"
                    >
                      Mensagem de exibição do troféu
                    </Typography>
                    <div style={{ cursor: 'pointer', display: 'inline' }}>
                      <Tooltip
                        style={{ marginLeft: 10 }}
                        title="É a mensagem que aparecerá para o usuário ao passar o mouse no ícone do troféu."
                        placement="top-start"
                      >
                        <Icon color="default">help</Icon>
                      </Tooltip>
                    </div>
                    <Field
                      component={InputLabelOutlined}
                      label="Texto do troféu"
                      name="trophy_description"
                      margin="normal"
                      validate={[FORM_RULES.required]}
                    />
                  </MaterialGrid>
                </>
              ) : null
            ) : null}

            <GenericConfirmationCard
              open={this.props.openConfirmation}
              labelLeftButton={'Continuar aqui'}
              labelRightButton={'Sim, sair da edição'}
              general={this.props.general}
              title="Deseja sair da edição?"
              confirmButtonColor={'primary'}
              text="Lembre-se, as informações que não foram salvas serão perdidas."
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={this.props.closeModal}
              cancelClose={this.props.handleCancelClose}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justify="space-between"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: 29,
            }}
          >
            <SecondaryButton
              type="button"
              color="secondary"
              onClick={this.props.prevClick}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}
            >
              Voltar
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              disabled={this.props.activeActions.buttonLoading}
              // proress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}
            >
              Salvar e continuar
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Prize = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Prize);
const mapStateToProps = (state, { form }) => {
  return {
    auth: state.auth,
    activeActions: state.activeActions,
    lotties: state.lotties,
    general: state.general,
    formValues: getFormValues(form)(state),
    form: form,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getLottiesListById,
      getLottiesList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Prize);
