import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import moment from 'moment';
import { initialize, reset as resetForm } from 'redux-form';

const URL_ACTION_PAGE = `${BASE_API}/offer-by-partner`;
const URL_ACTION_ARCHIVE = `${BASE_API}/archive-action`;

export const getStationActionOffers = id => {
  return dispatch => {
    dispatch({ type: 'ACTION_OFFERS_LOAD', payload: true });
    axios
      .get(`${URL_ACTION_PAGE}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        for (let i in response.data) {
          response.data[i]['createdAtDate'] = moment(
            response.data[i].createdAt
          ).format('DD/MM/YYYY HH:mm:ss');
          response.data[i]['active'] =
            parseInt(response.data[i].active) === 1 ? 'Sim' : 'Não';
        }
        dispatch([
          {
            type: 'ACTION_OFFERS_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const reset = form => {
  return resetForm(form);
};

export const initializeForm = (form, values) => {
  return initialize(form, values);
};

export const remove = (id, resolve, reject, station_id) => {
  return dispatch => {
    axios
      .put(
        `${URL_ACTION_ARCHIVE}/${id}`,
        { active: 0 },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then(response => {
        dispatch([getStationActionOffers(station_id), resolve()]);
      });
  };
};

export const onClickAction = (offer, router = undefined) => {
  const action = offer.action;
  return dispatch => {
    if (action.type_id === 7 || action.type_id === 8) {
      if (!_.isUndefined(router)) {
        router.push(
          `/owner/acoes/aquisicao-clientes/pesquisa/${action.id}/editar`
        );
      }
    }
    if (action.type_id === 4) {
      if (!_.isUndefined(router)) {
        router.push(`/owner/acoes/curso-presencial/${action.id}/editar`);
      }
    } else if (action.type_id === 14) {
      if (!_.isUndefined(router)) {
        router.push(`/owner/acoes/entregar-produto/${action.id}/editar`);
      }
    } else if (action.type_id === 3) {
      router.push(`/owner/acoes/cupom/${action.id}/editar`);
    } else if (action.type_id === 2) {
      router.push(`/owner/acoes/signup-earn/${action.id}/editar`);
    } else if (action.type_id === 1) {
      router.push(`/owner/acoes/member-get-member/${action.id}/editar`);
    } else if (action.type_id === 17) {
      router.push(`/owner/acoes/atividade-doacao/${action.id}/editar`);
    } else if (action.type_id === 18) {
      router.push(`/owner/acoes/video-playlist/${action.id}/editar`);
    }
  };
};
