import { USER_KEY, USER_TOKEN } from 'config/consts';
import type from './type';

const INITIAL_STATE = {
  user: JSON.parse(localStorage.getItem(USER_KEY)) || undefined,
  admin_tax: 0,
  validToken: false,
  permissions: [],
  loadingRegister: false,
  wasActived: null,
  loadingLogin: false,
  wasRecovered: null,
  loadingPoints: false,
  establishmentActivation: null,
  activationBegan: false,
  modal: false,
  signupCompanyStatus: false,
  skipStatus: false,
  sectionsStatus: false,
  contactsStatus: false,
  identityStatus: false,
  tokenLoading: true,
};

/**
 * @param {Object} state - Default application state
 * @param {Object} action - Action from action creator
 * @returns {Object} New state
 */
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case type.TOKEN_VALIDATED:
      if (action.payload) {
        return { ...state, validToken: true };
      } else {
        localStorage.removeItem(USER_KEY);
        return { ...state, validToken: false, user: null };
      }
    case type.USER_FETCHED:
      localStorage.setItem(
        USER_KEY,
        JSON.stringify(action.payload.data.content)
      );
      localStorage.setItem(
        USER_TOKEN,
        JSON.stringify(action.payload.data.token)
      );
      return {
        ...state,
        user: action.payload.data.content,
        points: action.payload.data.points,
        validToken: true,
        loadingLogin: false,
      };
    case type.PERMISSIONS_FETCHED:
      return { ...state, permissions: action.payload };
    case type.LOADING_REGISTER:
      return { ...state, loadingRegister: action.payload };
    case type.LOADING_LOGIN:
      return { ...state, loadingLogin: action.payload };
    case type.USER_REGISTRED:
      return { ...state };
    case 'USER_FETCHED_AGAIN':
      localStorage.setItem(USER_KEY, JSON.stringify(action.payload));
      return { ...state, user: action.payload, tokenLoading: false };
    case 'ESTABLISHMENT_DONE':
      return {
        ...state,
        user: { ...state.user, establishment: action.payload },
      };
    case type.USER_ACTIVED:
      return { ...state, wasActived: action.payload };
    case type.USER_RECOVERED:
      return { ...state, wasRecovered: action.payload };
    case 'USER_POINTS_FETCHED':
      return { ...state, points: action.payload, loadingPoints: false };
    case 'USER_POINTS_LOAD':
      return { ...state, loadingPoints: action.payload };
    case 'ACTIVATION_CODE_FETCHED':
      return { ...state, establishmentActivation: action.payload };
    case 'VALIDATION_BEGINS':
      return { ...state, activationBegan: action.payload };
    case 'UPDATE_IDENTITY':
      return {
        ...state,
        user: {
          ...state.user,
          establishment: {
            ...state.user.establishments[0],
            VisualIdentity: action.payload,
          },
        },
      };
    case 'SHOW_MODAL':
      return { ...state, modal: action.payload };
    case 'SIGNUP_COMPANY':
      return {
        ...state,
        signupCompanyStatus: action.payload,
      };
    case 'SECTIONS_STATUS':
      return {
        ...state,
        sectionsStatus: action.payload,
        contactsStatus: !action.payload,
        identityStatus: !action.payload,
      };
    case 'CONTACTS_STATUS':
      return {
        ...state,
        contactsStatus: action.payload,
        sectionsStatus: !action.payload,
        identityStatus: !action.payload,
      };
    case 'IDENTITY_STATUS':
      return {
        ...state,
        identityStatus: action.payload,
        contactsStatus: !action.payload,
        sectionsStatus: !action.payload,
      };

    case 'SKIP_QUESTIONS':
      return {
        ...state,
        skipStatus: action.payload,
      };
    case 'TOKEN_LOADING':
      return {
        ...state,
        loadingToken: action.payload,
      };
    case 'CHANGE_ESTABLISHMENT':
      document.location.reload();
      return {
        ...state,
        user: { ...state.user, establishments: action.payload },
      };
    case 'GET_ADMIN_TAX':
      return {
        ...state,
        admin_tax: action.payload,
      };
    default:
      return state;
  }
};
