import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { GenericConfirmationCard } from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import LoadingProgress from 'common/components/progress/loading';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CardActionInfo from 'common/components/cards/cardActionInfo/cardActionInfo';
import {
  getStationActionsDrafts,
  onClickDraftAction,
  deleteActionDraft,
  onClickDraftShare,
} from './actionsDraftsScripts';

function ActiveActions(props) {
  const { auth } = props;
  const content = props.actionsDrafts.list;

  useEffect(() => {
    props.getStationActionsDrafts(auth.user.establishments[0].id);
  }, []);

  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [item, setItem] = React.useState(undefined);

  function renderEmptyScreen() {
    return (
      <section
        id="activeActions"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você não possui atividades em construção.
                  </h2>
                  <p className="">
                    Atividades são campanhas que você poderá divulgar para
                    plataformas associadas a rilato.
                  </p>

                  <PrimaryButton
                    onClick={() => props.router.push('/owner/acoes')}
                    color="primary"
                  >
                    Criar Atividade
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração Cupons"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.actionsDrafts.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }

  if (content.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <section style={{ marginTop: '4.3em' }}>
      <GenericConfirmationCard
        open={openConfirmation}
        state={props.actionsDrafts}
        general={props.general}
        title="Deletar rascunho"
        confirmButtonColor={'primary'}
        text="Tem certeza que deseja deletar esse rascunho? Essa ação não poderá ser desfeita"
        onClose={() => setOpenConfirmation(false)}
        onClick={() => {
          if (item) props.deleteActionDraft(item);
        }}
      />
      <GeneralGrid container spacing={3}>
        <GeneralGrid item sm={12} style={{ padding: '0 2em' }}>
          <GeneralGrid
            container
            spacing={3}
            justify={'space-between'}
            style={{ padding: '2.6em 0 2.6em 0' }}
          >
            <GeneralGrid
              item
              sm={8}
              md={6}
              lg={4}
              style={{ padding: '12px 12px 12px 5px' }}
            >
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: 24,
                    textAlign: 'left',
                    textJustify: 'center',
                    backgroundColor: '#f7f7f7',
                  }}
                >
                  Estas são as atividades que estão em construção.
                </Typography>
              </Box>
            </GeneralGrid>
            <GeneralGrid
              item
              sm={4}
              md={6}
              lg={8}
              style={{ padding: '12px 0 12px 12px' }}
            ></GeneralGrid>
          </GeneralGrid>
          {content
            .slice(0)
            .reverse()
            .map((item, index) => (
              <CardActionInfo
                index={item.id}
                data={item}
                router={props.router}
                condition={false}
                draft
                onClickEdit={() => props.onClickDraftAction(item, props.router)}
                onClickDelete={() => {
                  setOpenConfirmation(true);
                  setItem(item.id);
                }}
                onClickShare={() => {
                  props.onClickDraftShare(item, props.router);
                }}
              />
            ))}
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    actionsDrafts: state.actionsDrafts,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getStationActionsDrafts,
      onClickDraftAction,
      deleteActionDraft,
      onClickDraftShare,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveActions);
