import axios from 'axios';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { cepMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { SelectLabel } from 'common/components/fields/fields';
import _ from 'lodash';
import {
  getCitiesList,
  getStatesList,
  update as updateAddress,
  create as createAddress,
  changeField,
  getAddressByEstablishment,
} from './addressesActions';
import LoadingProgress from 'common/components/progress/loading';

let AddressesForm = function(props) {
  const [citiesList, setCitiesList] = React.useState([]);
  const [statesList, setStatesList] = React.useState([]);

  useEffect(() => {
    props.getAddressByEstablishment(props.auth.user.establishments[0].id);
  }, []);

  useEffect(() => {
    if (props.formValues) {
      if (props.formValues.state) {
        props.getCitiesList(
          props.addresses.statesList.find(
            item => props.formValues.state === item.sigla
          ).id
        );
      }
    }
  }, [props.formValues ? props.formValues.state : props.formValues]);

  useEffect(() => {
    async function loadZipcode() {
      const response = await axios.get(
        `https://viacep.com.br/ws/${props.formValues.zip_code}/json/`
      );

      props.changeField('AddressesForm', 'line_1', response.data.logradouro);

      props.changeField(
        'AddressesForm',
        'state',
        props.addresses.statesList.find(async (item) => await response.data.uf === item.sigla).sigla
      );
    }
    if (props.formValues) {
      if (props.formValues.zip_code) {
        if (props.formValues.zip_code.length === 8) {
          loadZipcode();
        }
      }
    }
  }, [props.formValues ? props.formValues.zip_code : props.formValues]);

  useEffect(() => {
    setStatesList(
      props.addresses.statesList
        .map(state => ({
          value: state.sigla,
          label: state.nome,
        }))
        .sort((a, b) => {
          if (a.label.toUpperCase() > b.label.toUpperCase()) {
            return 1;
          } else {
            return -1;
          }
        })
    );
    setCitiesList(
      props.addresses.citiesList.map(city => ({
        value: city.nome,
        label: city.nome,
      }))
    );
  }, [props.addresses.citiesList, props.addresses.statesList]);

  function onSubmit(values) {
    if (!_.isEmpty(props.addresses.detail)) {
      const newValues = {
        ...values,
      };
      return props.updateAddress(newValues, props.addresses.detail.id);
    } else {
      const newValues = {
        ...values,
        establishment_id: props.auth.user.establishments[0].id,
      };
      return props.createAddress(newValues);
    }
  }

  if (props.addresses.loading) {
    return <LoadingProgress />;
  }

  return (
    <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} md={6} lg={6}>
          <Field
            component={InputLabelOutlined}
            label="CEP"
            name="zip_code"
            validate={[FORM_RULES.required]}
            required
            margin="normal"
            {...cepMask}
          />
        </GeneralGrid>
        <GeneralGrid item xs={12} md={6} lg={6}>
          <Field
            component={InputLabelOutlined}
            label="Linha 1"
            name="line_1"
            validate={[FORM_RULES.required]}
            required
            margin="normal"
          />
        </GeneralGrid>
      </GeneralGrid>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12} md={6}>
          <Field
            component={InputLabelOutlined}
            label="Número/Complemento/Referência"
            name="line_2"
            validate={[FORM_RULES.required]}
            required
            margin="normal"
          />
        </GeneralGrid>
        <GeneralGrid item xs={12} md={6}>
          <Field
            component={SelectLabel}
            label="Estado"
            name="state"
            validate={[FORM_RULES.required]}
            required
            margin="normal"
            options={statesList}
          />
        </GeneralGrid>
        <GeneralGrid item xs={12} md={6}>
          <Field
            component={SelectLabel}
            label="Cidade"
            name="city"
            validate={[FORM_RULES.required]}
            required
            margin="normal"
            options={citiesList}
          />
        </GeneralGrid>
      </GeneralGrid>
      <div className="row justify-content-around mb-3 mt-3">
        <PrimaryButton
          disabled={props.addresses.buttonLoading}
          progress={parseInt(props.general.submitProgress)}
          router={props.router}
          type="submit"
          color="primary"
        >
          {props.addresses.buttonLoading ? 'Enviando' : 'Enviar'}
        </PrimaryButton>
      </div>
    </Form>
  );
};

AddressesForm = reduxForm({
  form: 'AddressesForm',
})(AddressesForm);

const mapStateToProps = state => {
  return {
    formValues: getFormValues('AddressesForm')(state),
    general: state.general,
    addresses: state.addresses,
    auth: state.auth,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCitiesList,
      getStatesList,
      updateAddress,
      changeField,
      getAddressByEstablishment,
      createAddress,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressesForm);
