import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';

export default function ProductOrders(props) {
  const { id } = props.auth.user.establishments[0];
  // const { router } = props;
  const { list, loading, deliveryStatusesList } = props.productOrders;

  const [columns, setColumns] = useState([]);
  const [csvData, setCSVData] = useState(null);

  function renderFullPrice(item) {
    return (
      <div>
        R${' '}
        {parseFloat(item)
          .toFixed(2)
          .replace('.', ',')}
      </div>
    );
  }

  function handleOpenDetailModal(_, rowData) {
    props.router.push(`/owner/sales/${rowData.id}`);
  }

  useEffect(() => {
    props.getOrdersWithId(id);
    props.getDeliveryStatuses();
  }, []);

  useEffect(() => {
    const deliveryStatusLookup = {};
    for (let i = 0; i < deliveryStatusesList.length; i += 1) {
      deliveryStatusLookup[`${deliveryStatusesList[i].id}`] =
        deliveryStatusesList[i].status;
    }

    if (deliveryStatusesList.length > 0) {
      setColumns([
        {
          field: 'purchase_id',
          title: 'ID',
          editable: 'never',
        },
        {
          field: 'gateway_charge.external_id',
          title: 'ID Pagar.me',
          editable: 'never',
          render: rowData =>
            rowData.gateway_charge?.external_id
              ? rowData.gateway_charge?.external_id
              : '-',
        },
        {
          field: 'createdAt',
          title: 'Data',
          editable: 'never',
        },
        {
          field: 'user.name',
          title: 'Nome',
          editable: 'never',
          render: rowData => (rowData.user.name ? rowData.user.name : '-'),
        },
        {
          field: 'user.cpf',
          title: 'CPF',
          editable: 'never',
          render: rowData => (rowData.user?.cpf ? rowData.user?.cpf : '-'),
        },
        {
          field: 'fullAddresses',
          title: 'Endereços de Entrega',
          editable: 'never',
        },
        {
          title: 'Valor pago da carteira',
          field: 'wallet_value',
          render: rowData => {
            return renderFullPrice(rowData.wallet_value);
          },
          editable: 'never',
          export: true,
        },
        {
          title: 'Valor líquido da carteira',
          render: rowData => {
            if (rowData.status === 'Pagamento recusado') {
              return '0,00';
            }
            if (rowData.totalWalletNetValue) {
              return renderFullPrice(rowData.totalWalletNetValue);
            }
            return '0,00';
          },
          editable: 'never',
        },
        {
          title: 'Valor pago no cartão',
          field: 'total_credit_card_value',
          render: rowData => {
            return rowData.total_credit_card_value
              ? renderFullPrice(rowData.total_credit_card_value)
              : '';
          },
          editable: 'never',
        },
        {
          title: 'Valor líquido do cartão',
          render: rowData => {
            if (rowData.status === 'Pagamento recusado') {
              return '0,00';
            }
            if (rowData.totalCardNetValue) {
              return renderFullPrice(rowData.totalCardNetValue);
            }
            return '0,00';
          },
          editable: 'never',
        },
        {
          title: 'Valor Final',
          field: 'final_value',
          render: rowData => {
            return renderFullPrice(rowData.final_value);
          },
          editable: 'never',
        },
        {
          title: 'Status',
          field: 'status',
          editable: 'never',
        },
      ]);
    }
  }, [deliveryStatusesList.length]);

  function handleDeliveryLocation(productOrder) {
    const {
      address,
      reference,
      zipcode,
      action,
      delivery_location,
    } = productOrder;
    if (action.digital_product) {
      return 'Produto digital';
    } else if (delivery_location.delivery) {
      return `${address}, ${reference}, ${zipcode}`;
    } else {
      const {
        zipcode: deliveryLocationZipcode,
        street,
        street_number,
      } = delivery_location;
      return `${street}, ${street_number}, ${deliveryLocationZipcode}`;
    }
  }

  useEffect(() => {
    const samePurchaseArray = [];
    const formattedCSVData = [
      [
        'ID',
        'ID Pagar.me',
        'Data',
        'Nome',
        'CPF',
        'Endereços de entrega',
        'Valor pago da carteira',
        'Valor pago no cartão',
        'Valor final',
      ],
    ];
    for (let i = 0; i < props.productOrders.list.length; i += 1) {
      const item = props.productOrders.list[i];
      const index = samePurchaseArray.findIndex(secondItem => {
        return secondItem.purchase_id === item.purchase_id;
      });
      const futureTransactionCard = item.future_transactions.find(
        item => item.gateway_or_wallet
      );
      const futureTransactionWallet = item.future_transactions.find(
        item => !item.gateway_or_wallet
      );
      let totalCardNetValue = 0;
      let totalWalletNetValue = 0;
      if (futureTransactionCard) {
        totalCardNetValue = parseFloat(futureTransactionCard.value);
      }
      if (futureTransactionWallet) {
        totalWalletNetValue = parseFloat(futureTransactionWallet.value);
      }
      if (index === -1) {
        samePurchaseArray.push({
          ...item,
          fullAddresses: handleDeliveryLocation(item),
          totalCardNetValue,
          totalWalletNetValue,
        });
        formattedCSVData.push([
          item.purchase_id,
          item.gateway_charge ? item.gateway_charge.external_id : '',
          item.createdAt,
          item.user?.name,
          item.user?.cpf,
          handleDeliveryLocation(item),
          item.wallet_value,
          item.total_credit_card_value,
          item.final_value,
        ]);
      } else {
        samePurchaseArray[index] = {
          ...samePurchaseArray[index],
          fullAddresses: `${
            samePurchaseArray[index].fullAddresses
          },${handleDeliveryLocation(item)}`,
          wallet_value: parseFloat(
            (
              parseFloat(samePurchaseArray[index].wallet_value) +
              parseFloat(item.wallet_value)
            ).toFixed(2)
          ),
          final_value: parseFloat(
            (
              parseFloat(samePurchaseArray[index].final_value) +
              parseFloat(item.final_value)
            ).toFixed(2)
          ),
          totalCardNetValue:
            samePurchaseArray[index].totalCardNetValue + totalCardNetValue,
          totalWalletNetValue:
            samePurchaseArray[index].totalWalletNetValue + totalWalletNetValue,
        };
        formattedCSVData.push([
          samePurchaseArray[index].purchase_id,
          samePurchaseArray[index].gateway_charge
            ? samePurchaseArray[index].gateway_charge?.external_id
            : '',
          samePurchaseArray[index].createdAt,
          samePurchaseArray[index].user.name,
          samePurchaseArray[index].user.cpf,
          `${samePurchaseArray[index].fullAddresses},${handleDeliveryLocation(
            item
          )}`,
          parseFloat(
            (
              parseFloat(samePurchaseArray[index].wallet_value) +
              parseFloat(item.wallet_value)
            ).toFixed(2)
          ),
          samePurchaseArray[index].total_credit_card_value,
          parseFloat(
            (
              parseFloat(samePurchaseArray[index].final_value) +
              parseFloat(item.final_value)
            ).toFixed(2)
          ),
        ]);
      }
    }
    setCSVData(formattedCSVData);
  }, [props.productOrders.list]);

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não tem nenhum pedido
                </h2>
                <p>
                  Os pedidos serão feitos pelos participantes e aparecerão aqui.
                </p>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (
    loading ||
    !columns.find(item => item.field === 'gateway_charge.external_id')
  ) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="statement-buy-points">
      <GeneralGrid container spacing={3} mt={2}>
        <GeneralGrid
          item
          xs={12}
          style={{ marginTop: 20, width: isMobile ? '90vw' : undefined }}
        >
          <Table
            buttonCSV
            csvData={csvData}
            searchPlaceholder={'Procurar'}
            hasToolbar={true}
            title="Vendas"
            columns={columns}
            rows={props.productOrders.list}
            toolbarSearch
            onRowClick={handleOpenDetailModal}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}
