import { Box, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { makeStyles } from '@material-ui/core/styles';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList, remove } from './donationsActions';

const useStyles = makeStyles(styles);

let Donations = function(props) {
  const classes = useStyles();
  const { router } = props;
  const { list, loading } = props.donations;

  const [columns] = useState([
    { title: 'Nome', field: 'user.name' },
    { title: 'CPF', field: 'user.cpf' },
    { title: 'Atividade', field: 'action.name' },
    {
      title: 'Valor',
      render: rowData => {
        return (
          'R$' +
          parseFloat(rowData.value)
            .toFixed(2)
            .replace('.', ',')
        );
      },
    },
    { title: 'Data da doação', field: 'createdAt', type: 'datetime' },
  ]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');

  useEffect(() => {
    props.getList(props.auth.user.establishments[0].id);
  }, []);

  useEffect(() => {
    setData(props.donations.list.content);
  }, [props.donations.list.content]);

  function handleChange(e) {
    setData(tableSearch(e, props.donations.list.content));
    setValue(e.target.value);
  }

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">Você ainda não possui doações!</h2>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração: não há dados"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <>
      <section id="donations" style={{ marginTop: '4.3em' }}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="primary" stats icon>
                <CardIcon color="white">
                  <Icon>accessibility</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Doações</p>
                <h3 className={classes.cardTitle}>
                  {props.donations.list.content.length}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="primary" stats icon>
                <CardIcon color="white">
                  <Icon>dashboard</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Atividades de doação</p>
                <h3 className={classes.cardTitle}>
                  {' '}
                  {props.donations.list.donationActions.count}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="primary" stats icon>
                <CardIcon color="white">
                  <Icon>attach_money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Saldo disponível</p>
                <h3 className={classes.cardTitle}>
                  R${' '}
                  {parseFloat(
                    props.donations.list.establishmentData
                      .avaliable_marketplace_points
                  )
                    .toFixed(2)
                    .replace('.', ',')}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="primary" stats icon>
                <CardIcon color="white">
                  <Icon>money_off</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Saldo a ser liberado</p>
                <h3 className={classes.cardTitle}>
                  R${' '}
                  {parseFloat(
                    props.donations.list.establishmentData
                      .unavaliable_marketplace_points
                  )
                    .toFixed(2)
                    .replace('.', ',')}
                </h3>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            <GeneralGrid
              container
              spacing={3}
              justify="space-between"
              alignContent="space-between"
            >
              <GeneralGrid item xs={3}>
                <span
                  style={{
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                    fontSize: 24,
                  }}
                >
                  Doações
                </span>
                <Typography variant="body1" style={{ marginTop: 20 }}>
                  {props.donations.list.content.length} doações
                </Typography>
              </GeneralGrid>
              <GeneralGrid item xs={3}>
                <InputLabelOutlined
                  input={{ onChange: handleChange, value: value }}
                  meta={{}}
                  onChange={handleChange}
                  value={value}
                  label="Procurar"
                  name="search"
                  margin="normal"
                />
              </GeneralGrid>
            </GeneralGrid>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <Table6
                  title="Lista de doações"
                  columns={columns}
                  data={data}
                  router={router}
                />
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </section>
    </>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    donations: state.donations,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getList,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Donations);
