import Content from 'common/components/content/content';
import Grid from 'common/components/layout/grid/grid';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import {
  DatePickerComponent,
  InputFile,
  InputLabelControl,
} from '../../../../../common/components/fields/fields';
import { getQuizzes, postAction } from './engajeSharingActions';

class EngajeSharing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
    this.props.getQuizzes(this.props.auth.user.establishments[0].id);
  }

  onSubmit = values => {
    values['establishment'] = this.props.auth.user.establishments[0][0].id;
    values['base64'] = values.image.base64;
    values['imageType'] = values.image.base64
      .split('data:')[1]
      .split(';base64')[0];
    delete values['image'];
    this.props.postAction(values, this.props.router);
  };

  render() {
    const { handleSubmit } = this.props;
    return (
      <Content>
        <Card>
          <CardTitle title="Gerar engajamento ao compartilhar conteúdo" />
          <CardText>
            <div className="container-fluid">
              <center>
                <h3>Interação</h3>
              </center>
              <Form
                role="form"
                onSubmit={handleSubmit(this.onSubmit)}
                noValidate
              >
                <Field
                  component={InputFile}
                  multiple={false}
                  name="image"
                  label="Foto da Atividade"
                  cols="12 12 12 12"
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={InputLabelControl}
                  label={'Digite Aqui o link para compartilhamento'}
                  name="link"
                  type="text"
                  cols="12 12 12 12"
                  validate={[FORM_RULES.required]}
                />
                <Grid cols="12 12 12 12">
                  <center>
                    <h3>Regras</h3>
                  </center>
                </Grid>
                <Field
                  component={InputLabelControl}
                  label={'Nome dessa atividade'}
                  name="name"
                  cols="12 12 6 6"
                  type="text"
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={InputLabelControl}
                  label={'Descrição que o usuário verá'}
                  name="description"
                  cols="12 12 6 6"
                  type="text"
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={InputLabelControl}
                  label={
                    'Quantos pontos o usuário vai ganhar ao realizar essa atividade'
                  }
                  name="points"
                  cols="12 12 6 6"
                  type="number"
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={InputLabelControl}
                  label={'Quantos usuários podem realizar essa atividade'}
                  name="limit"
                  type="number"
                  cols="12 12 6 6"
                  validate={[FORM_RULES.required]}
                />
                <Field
                  component={DatePickerComponent}
                  className="md-cell md-cell--bottom"
                  id="floating-center-title"
                  label={'Quando essa atividade expira'}
                  name="expiration"
                  cols="12 12 12 12"
                  validate={[FORM_RULES.required]}
                />
                <Grid cols="12 12 12 12">
                  <center>
                    <button className="btn btn-primary" type="submit">
                      Enviar atividade
                    </button>
                  </center>
                </Grid>
              </Form>
            </div>
          </CardText>
        </Card>
      </Content>
    );
  }
}

EngajeSharing = reduxForm({ form: 'engajeSharingForm' })(EngajeSharing);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    quizzes: state.quizzes,
    formValues: getFormValues('engajeSharingForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getQuizzes, postAction }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EngajeSharing);
