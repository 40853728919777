import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { OnboardingCard } from 'common/components/cards/onboardingCard/onboardingCard';
import { InputLabelOutlined } from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { currencyMask } from 'helpers/masks';
import { String2Currency } from 'helpers/dataTransformers';
import { FORM_RULES } from 'helpers/validations';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, reduxForm, getFormValues } from 'redux-form';
import { getEstablishmentPoints } from '../../pointsActions';
import { buyPoints } from './buyPointsActions';

class BuyPoints extends Component {
  constructor(props) {
    super(props);

    if (this.props.location.query.first_action) {
      this.props.getEstablishmentPoints(
        this.props.auth.user.establishments[0].id
      );
    }
  }

  handleChange = e => {
    this.setState({
      valueToPay: String2Currency(e.target.value),
    });
  };

  componentWillReceiveProps(props) {
    if (props.points.points > 0) {
      if (props.location.query.first_action) {
        this.props.router.push('/owner/acoes/?first_action=true');
      }
    }
  }

  onSubmit = values => {
    const { id, establishments } = this.props.auth.user;
    const { router } = this.props;
    let first_Action = false;
    if (this.props.location.query.first_action) {
      first_Action = true;
    }
    values['establishment_id'] = establishments[0].id;
    this.props.buyPoints(values, id, router, first_Action);
  };

  renderPrimaryButton = () => {
    return (
      <PrimaryButton
        type="submit"
        disabled={this.props.points.buttonLoading}
        progress={parseInt(this.props.general.submitProgress)}
      >
        {this.props.points.buttonLoading
          ? 'Processando pedido'
          : 'Fazer pedido'}
      </PrimaryButton>
    );
  }

  renderField = () => {
    return (
      <Field
        component={InputLabelOutlined}
        type="tel"
        label={'Quantidade em BRL'}
        name="points"
        required
        validate={[FORM_RULES.required]}
        margin="normal"
        {...currencyMask}
      />
    );
  }

  renderFeedback = () => {
    return (
      <>
        <span>Você tera que depositar o valor de:</span>
        <strong>
          {this.props.formValues
            ? String2Currency(
                this.props.formValues.points / (1 - 0.08)
              )
            : 'R$ 0,00'}
        </strong>
      </>
    );
  }

  render() {
    const { handleSubmit } = this.props;
    const { renderPrimaryButton, renderFeedback, renderField } = this;

    if (this.props.points.loading) {
      return (
        <div className="d-flex justify-content-center mt-5">
          <LoadingProgress />
        </div>
      );
    }

    return (
      <section className="modules" style={{ marginTop: '5.3em' }}>
        <div className="container-fluid">
          {this.props.location.query.first_action ? (
            <p>
              Para fazer sua primeira atividade, você deve comprar créditos, é
              bem fácil. Preencha a quantidade de créditos abaixo e continue
            </p>
          ) : null}
          <Form role="form" onSubmit={handleSubmit(this.onSubmit)} noValidate>
            <MaterialGrid
              container
              spacing={3}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <MaterialGrid item>
                <OnboardingCard
                  condition={false}
                  title="Transferência bancária"
                  height={600}
                  width={290}
                  text={
                    <div style={{ textAlign: 'left' }}>
                      <span>
                        Banco: 756 - BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB
                      </span>
                      <br />
                      <span>CNPJ: 35.622.254/0001-26</span>
                      <br />
                      <span>Ag: 4259</span>
                      <br />
                      <span>CC: 3250-6</span>
                      <br />
                      <span>Nome: FAN PROJECTS S/A</span>
                    </div>
                  }
                  router={this.props.router}
                  hideButton
                  buttonLabel="Contato"
                  onClickButton={() =>
                    this.props.router.push(
                      '/owner/website/create/?first_station=true'
                    )
                  }
                />
              </MaterialGrid>
              <MaterialGrid item>
                <OnboardingCard
                  condition={false}
                  title="Começar"
                  height={600}
                  width={290}
                  text={
                    'Digite abaixo o valor que irá transferir. Efetue a transferência bancária utilizando os dados ao lado. Quando verificarmos a transferência, liberaremos o crédito para uso em suas atividades.'
                  }
                  alternativeButton={ renderPrimaryButton() }
                  aditionalContent={ renderField() }
                  aditionalContent2={ renderFeedback() }
                  router={this.props.router}
                  buttonLabel="Cadastrar Agora"
                  onClickButton={() =>
                    this.props.router.push('/owner/signup-company')
                  }
                />
              </MaterialGrid>
            </MaterialGrid>
            <MaterialGrid container spacing={3} />
          </Form>
        </div>
      </section>
    );
  }
}

BuyPoints = reduxForm({ form: 'buyPoints' })(BuyPoints);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    points: state.points,
    general: state.general,
    formValues: getFormValues('buyPoints')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ buyPoints, getEstablishmentPoints }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyPoints);
