import { Box, Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import AlertDialogSlide from 'common/components/dialog/dialog';
import LoadingProgress from 'common/components/progress/loading';
import { BASE_S3 } from 'config/consts';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { appColors } from 'styles/colors';
import PrimaryButton from '../../../../common/components/buttons/primaryButton';
import ActionReview from './actionReview';
import { getTemplateById, handleReviewModal } from './actionsFile';
import BuyAction from './buyAction';

function ActionDetail(props) {
  const [isBuyActionModalVisible, setIsBuyActionModalVisible] = useState(false);

  useEffect(() => {
    props.getTemplateById(props.router.params.id);
  }, []);

  const theme = useTheme();

  if (props.actions.templatesDetailLoading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  } else {
    return (
      <>
        <AlertDialogSlide
          visible={true}
          height="95vh"
          maxWidth="md"
          title={
            <Typography
              style={{
                paddingLeft: 20,
                paddingTop: 20,
                color: appColors.primary,
                cursor: 'pointer',
                display: 'inline',
              }}
              onClick={() => props.router.push('/owner/acoes')}
            >
              VOLTAR
            </Typography>
          }
          titleBackground="#f7f7f7"
          contentBackground="#f7f7f7"
          onClose={() => props.router.push('/owner/acoes')}
        >
          {!isBuyActionModalVisible && !props.actions.reviewModal ? (
            <MaterialGrid
              container
              spacing={3}
              justify="flex-end"
              style={{ padding: theme.spacing(3) }}
            >
              <MaterialGrid item xs={12}>
                <MaterialGrid container spacing={3}>
                  <Box pb={3}>
                    <Typography
                      style={{
                        color: theme.palette.text.main,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                      }}
                    >
                      {props.actions.templateDetail.name}
                    </Typography>
                  </Box>
                </MaterialGrid>
                <MaterialGrid container spacing={3}>
                  <Box pb={3}>
                    <Typography
                      variant="caption"
                      style={{ color: theme.palette.primary.main }}
                    >
                      Criado por: Fan.Projects
                    </Typography>
                  </Box>
                </MaterialGrid>
                <MaterialGrid container spacing={3}>
                  <img
                    src={`${BASE_S3}/${props.actions.templateDetail
                      .owner_image || props.actions.templateDetail.image}`}
                    style={{ width: '100%', height: 'auto' }}
                    alt="Imagem Detalhe da Atividade"
                  />
                </MaterialGrid>
                <MaterialGrid container spacing={3} justify="space-between">
                  <MaterialGrid item xs={6}>
                    <Box py={4}>
                      <Typography>
                        {props.actions.templateDetail.summary}
                      </Typography>
                    </Box>

                    <Box>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: props.actions.templateDetail.description,
                        }}
                      ></div>
                    </Box>
                  </MaterialGrid>

                  <MaterialGrid item xs={5}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        style={{
                          backgroundColor: theme.palette.background.white,
                        }}
                        pt={3}
                        pb={5}
                        px={3}
                        mt={4}
                      >
                        {parseFloat(props.actions.templateBody.action_price) ===
                        0 ? (
                          <Box pb={3}>
                            <Typography
                              variant="caption"
                              style={{
                                color: theme.palette.success.main,
                                fontSize: '0.75rem',
                              }}
                            >
                              <Typography
                                component="span"
                                style={{ fontSize: '1.5rem' }}
                              >
                                GRÁTIS
                              </Typography>
                            </Typography>
                          </Box>
                        ) : null}
                        {parseFloat(props.actions.templateBody.action_price) !==
                        0 ? (
                          <>
                            <Box pt={3} pb={1}>
                              <Typography
                                style={{
                                  color: '#707070',
                                  fontSize: '0.75rem',
                                }}
                              >
                                DE:{' '}
                                <Typography
                                  component="span"
                                  style={{
                                    fontSize: '1.5rem',
                                    textDecoration: 'line-through',
                                  }}
                                >
                                  R${' '}
                                  {parseFloat(
                                    props.actions.templateBody
                                      .action_price_before
                                  )
                                    .toFixed(2)
                                    .replace('.', ',')}
                                </Typography>
                              </Typography>
                            </Box>

                            <Box pb={3}>
                              <Typography
                                variant="caption"
                                style={{
                                  color: theme.palette.success.main,
                                  fontSize: '0.75rem',
                                }}
                              >
                                POR:{' '}
                                <Typography
                                  component="span"
                                  style={{ fontSize: '1.5rem' }}
                                >
                                  R${' '}
                                  {parseFloat(
                                    props.actions.templateBody.action_price
                                  )
                                    .toFixed(2)
                                    .replace('.', ',')}
                                </Typography>
                              </Typography>
                            </Box>
                          </>
                        ) : null}

                        <Box pb={1} mb={2} display="flex">
                          <Typography
                            variant="caption"
                            align="center"
                            style={{ color: theme.palette.text.main }}
                          >
                            Este é o custo de ativação dessa atividade que será
                            debitado do seu saldo.
                          </Typography>
                        </Box>
                        <PrimaryButton
                          style={{ padding: '1rem 4rem' }}
                          onClick={() => setIsBuyActionModalVisible(true)}
                        >
                          CONTRATAR
                        </PrimaryButton>
                        <Box pt={3} pb={3} display="flex">
                          <Typography
                            variant="caption"
                            align="center"
                            style={{ color: theme.palette.text.main }}
                          >
                            Você poderá alterar dados dessa atividade após a
                            contratação.
                          </Typography>
                        </Box>
                      </Box>
                      {props.actions.templateDetail.type_action_id === 1 ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column"
                          mt={4}
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            height: 194,
                            width: 194,
                          }}
                        >
                          <Typography
                            variant="h4"
                            align="center"
                            style={{
                              color: theme.palette.success.main,
                              fontSize: '1.875rem',
                              padding: '1rem 0',
                              fontWeight: 700,
                            }}
                          >
                            R${' '}
                            {props.actions.templateBody.isInviterPaid &&
                            props.actions.templateBody.isInvitedPaid
                              ? (
                                  parseFloat(
                                    props.actions.templateBody.points_invited
                                  ) +
                                  parseFloat(
                                    props.actions.templateBody.points_inviter
                                  )
                                )
                                  .toFixed(2)
                                  .replace('.', ',')
                              : !props.actions.templateBody.isInviterPaid &&
                                props.actions.templateBody.isInvitedPaid
                              ? parseFloat(
                                  props.actions.templateBody.points_invited
                                )
                                  .toFixed(2)
                                  .replace('.', ',')
                              : props.actions.templateBody.isInviterPaid &&
                                !props.actions.templateBody.isInvitedPaid
                              ? parseFloat(
                                  props.actions.templateBody.points_inviter
                                )
                                  .toFixed(2)
                                  .replace('.', ',')
                              : parseFloat(0)
                                  .toFixed(2)
                                  .replace('.', ',')}
                          </Typography>
                          <Typography variant="caption" align="center">
                            Recompensa sugerida por participação
                          </Typography>
                        </Box>
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column"
                          mt={4}
                          style={{
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            height: 194,
                            width: 194,
                          }}
                        >
                          <Typography
                            variant="h4"
                            align="center"
                            style={{
                              color: theme.palette.success.main,
                              fontSize: '1.875rem',
                              padding: '1rem 0',
                              fontWeight: 700,
                            }}
                          >
                            R$ {props.actions.templateBody.points}
                          </Typography>
                          <Typography variant="caption" align="center">
                            Recompensa sugerida por participação
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </MaterialGrid>
                </MaterialGrid>
              </MaterialGrid>
            </MaterialGrid>
          ) : isBuyActionModalVisible && !props.actions.reviewModal ? (
            <BuyAction
              setIsBuyActionModalVisible={setIsBuyActionModalVisible}
              router={props.router}
              action={props.actions.templateDetail}
              body={props.actions.templateBody}
            />
          ) : (
            <ActionReview
              router={props.router}
              action={props.actions.templateDetail}
              body={props.actions.templateBody}
            />
          )}
        </AlertDialogSlide>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
    auth: state.auth,
    actions: state.actions,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTemplateById,
      handleReviewModal,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionDetail);
