import { USER_TOKEN } from 'config/consts';
import React, { useEffect, useState } from 'react';
import PrimaryButton from '../buttons/primaryButton';
import { FanApi } from './api';
import AlertDialogSlide from './Dialog';
import { VideoButton, VideosContainer } from './styles';
import GeneralTabs from './tabs';
import VideoCard from './videoCard';
import UploadFile from './UploadFiles';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import RadioGroup from '@material-ui/core/RadioGroup';

import UploadLink from './UploadLink';
import { connect } from 'react-redux';
import Divider from '@material-ui/core/Divider';

let Upload = function({
  input: { onChange, value },
  formValues,
  indexVideo,
  multipleVideos,
  ...otherProps
}) {
  function ActionHasVideos() {
    if (formValues) {
      if (formValues.action_videos) {
        if (formValues.action_videos.length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  let selectedVideoOK = formValues
    ? multipleVideos
      ? formValues?.videosAdd
        ? formValues.videosAdd[indexVideo]
          ? formValues.videosAdd[indexVideo].id
            ? true
            : false
          : false
        : false
      : formValues?.videoIdField
      ? true
      : false
    : false;

  const [visible, setVisible2] = useState(false);
  const [videosID, setVideosID] = useState([]);
  // const [videoSent, setVideoSent] = useState([]);
  const [videoConcat, setVideoConcat] = useState([]);
  const [tab, setTab] = useState(0);
  const [valueSelected, setValueSelected] = useState(null);
  const establishment_id = otherProps.auth.user.establishments[0].id;

  async function getVideosID() {
    const response = await FanApi.get(`/videos/${establishment_id}`, {
      headers: {
        Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
      },
    });
    setVideosID(response.data);
  }

  function onClose() {
    setVisible2(false);
    setTab(0);
  }

  function onSelectionFinish() {
    const id = parseInt(valueSelected);
    if (valueSelected) {
      onChange(id);
    } else {
      onChange(null);
    }
    setVisible2(false);
  }

  function handleTab(tab) {
    setTab(tab);
  }

  function handleChange(event) {
    setValueSelected(event.target.value);
  }

  useEffect(() => {
    getVideosID();
  }, [tab]);

  // useEffect(() => {
  //   if (checkVideosFunction()) {
  //     setVideoSent([
  //       {
  //         custom: checkVideos.action_videos[indexVideo].video.custom,
  //         url: checkVideos.action_videos[indexVideo].video.url,
  //         id: checkVideos.action_videos[indexVideo].video.id,
  //       },
  //     ]);
  //   }
  // }, [checkVideos]);

  useEffect(() => {
    if (ActionHasVideos()) {
      if (
        formValues.action_videos[indexVideo] &&
        formValues.action_videos[indexVideo].video.id
      ) {
        setValueSelected(
          formValues.action_videos[indexVideo].video.id.toString()
        );
      }
    }
  }, []);

  useEffect(() => {
    // if (videoSent.length > 0) {
    //   if (videosID.some(video => video.id === videoSent[indexVideo].id)) {
    //     setVideoConcat(videosID);
    //   } else {
    //     setVideoConcat(videoSent.concat(videosID));
    //   }
    // } else {
    if (videosID) {
      setVideoConcat(videosID);
    } else {
      return;
    }
    // }
  }, [videosID]);

  const labelButton = () => {
    const defaultLabel = 'SELECIONAR/UPLOAD VÍDEO(S)';

    const label = multipleVideos
      ? formValues?.videosAdd
        ? formValues.videosAdd[indexVideo]
          ? formValues.videosAdd[indexVideo].id
            ? `VÍDEO JÁ SELECIONADO! (video - ${formValues.videosAdd[indexVideo].id})`
            : defaultLabel
          : defaultLabel
        : defaultLabel
      : formValues?.videoIdField
      ? `VÍDEO JÁ SELECIONADO! (video - ${formValues.videoIdField})`
      : defaultLabel;

    return label;
  };

  function renderEmptyScreen() {
    return (
      <section id="activeActions" style={{ overflow: 'hidden' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui vídeos online
                  </h2>
                  <p className="">
                    Para anexar videos a sua atividade volte a aba anterior e
                    faça o upload de um vídeo. Esse vídeo pode demorar até 15
                    minutos para estar disponivel na plataforma.
                  </p>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração Cupons"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  function RenderUpload(props) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%',
          }}
        >
          <span style={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: 530 }}>
            Cole o link do vídeo (Youtube ou Vimeo)
          </span>
          <UploadLink
            handleChangeTab={props.handleChangeTab}
            establishment_id={establishment_id}
          />
        </div>
        <Divider orientation={'vertical'} flexItem />
        <div
          style={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%',
          }}
        >
          <span
            style={{
              fontFamily: 'Roboto',
              fontSize: 16,
              fontWeight: 530,
            }}
          >
            Deseja fazer o upload de um arquivo?
          </span>
          <UploadFile
            handleChangeTab={props.handleChangeTab}
            establishment_id={establishment_id}
          />
        </div>
      </div>
    );
  }

  function RenderVideos() {
    return (
      <>
        <div style={{ marginTop: 20, marginLeft: 25 }}>
          <span style={{ fontFamily: 'Roboto', fontSize: 16, fontWeight: 530 }}>
            Caso alguns vídeos não tenham sido carregados ainda, recarregue a
            página.
          </span>
        </div>

        {videoConcat.length > 0 ? (
          <RadioGroup value={valueSelected} onChange={handleChange}>
            <VideosContainer>
              {videoConcat.map((item, index) => (
                <VideoCard
                  key={index}
                  onChange={onChange}
                  valueSelected={valueSelected}
                  indexVideo={indexVideo}
                  custom={item.custom ? item.custom : null}
                  name={`video - ${item.id}`}
                  url={item.url ? item.url : null}
                  id={item.id ? item.id : null}
                  checkVideos={formValues ? formValues : null}
                  setTab={setTab}
                  tab={tab}
                  setValueSelected={setValueSelected}
                />
              ))}
            </VideosContainer>
          </RadioGroup>
        ) : (
          renderEmptyScreen()
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <PrimaryButton onClick={onClose} type="button">
            Cancelar
          </PrimaryButton>
          <PrimaryButton onClick={onSelectionFinish} type="button">
            Ok
          </PrimaryButton>
        </div>
      </>
    );
  }

  return (
    <>
      <VideoButton
        selected={selectedVideoOK}
        type="button"
        onClick={() => setVisible2(true)}
      >
        {labelButton()}
      </VideoButton>
      <AlertDialogSlide
        visible={visible}
        onClose={onClose}
        onEnter={() => {
          selectedVideoOK
            ? setValueSelected(
                multipleVideos
                  ? formValues.videosAdd[indexVideo].id.toString()
                  : formValues.videoIdField.toString()
              )
            : setValueSelected(null);
        }}
      >
        <GeneralTabs
          handleTab={handleTab}
          upload={<RenderUpload />}
          videos={<RenderVideos />}
        />
      </AlertDialogSlide>
    </>
  );
};

// export default Upload;

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Upload);
