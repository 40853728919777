import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { initialize } from 'redux-form';

export const getDetail = id => {
  return dispatch => {
    axios
      .get(`${BASE_API}/quiz/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        const initialValues = {
          ...response.data[0],
          questions: JSON.parse(response.data[0].questions),
        };
        dispatch([initializeForm(initialValues)]);
      });
  };
};

export const setDetail = values => {
  return dispatch => {
    const initialValues = {
      ...values,
    };
    dispatch([initializeForm(initialValues)]);
  };
};

export const updateIdentity = (values, router = undefined) => {
  return dispatch => {
    axios
      .put(`${BASE_API}/identity/${values.id}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'UPDATE_IDENTITY',
            payload: values,
          },
          {
            type: 'IDENTITY_STATUS',
            payload: true,
          },
        ]);
        if (!_.isUndefined(router)) {
          router.push('/owner');
        }
      });
  };
};

export const initializeForm = values => {
  return initialize('IdentityForm', values);
};
