import axios from 'axios';
import { BASE_API, BASE_AUTH, USER_KEY, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { initialize, reset as resetForm } from 'redux-form';
import types from './type';

const URL_ESTABLISHMENT = `${BASE_API}/establishments`;
const URL_ESTABLISHMENT_OWNER = `${BASE_API}/establishments/owner`;
const URL_ESTABLISHMENT_USER = `${BASE_API}/establishment-users`;
const URL_SIGNUP_ADMIN = `${BASE_AUTH}/signup-admin`;
const URL_LOGIN_HASH = `${BASE_AUTH}/login-hash`;
const URL_FINISH_BRIEFING = `${BASE_API}/users-finish-tutorial`;
const URL_ANSWER_QUESTIONS = `${BASE_API}/establishment-questions`;
const URL_ADDRESS = `${BASE_API}/addresses`;
const URL_ADMIN_TAX = `${BASE_API}/admin-data`;

export const login = (values, router = undefined) => {
  return (dispatch) => {
    dispatch(setLoadingLogin(true));
    axios
      .post(`${BASE_AUTH}/login-partner`, values)
      .then((response) => {
        if (
          response.data.content.type_id === 1 ||
          response.data.content.type_id === 4
        ) {
          axios
            .get(`${URL_ESTABLISHMENT_OWNER}/${response.data.content.id}`, {
              headers: { Authorization: response.data.token },
            })
            .then((responseOwner) => {
              response.data.content['establishments'] = responseOwner.data;
              dispatch([
                {
                  type: 'SNACKBAR',
                  payload: {
                    message: 'Sucesso, aproveite.',
                    variant: 'success',
                    open: true,
                  },
                },
                { type: 'USER_FETCHED', payload: response },
                dispatch(setLoadingLogin(false)),
              ]);
              if (!_.isUndefined(router)) {
                router.push('/');
              }
            })
            .catch((e) => {
              try {
                if (e.response.data[0]) {
                  dispatch({
                    type: 'SNACKBAR',
                    payload: {
                      message: `Erro: ${e.response.data[0]}`,
                      variant: 'error',
                      open: true,
                    },
                  });
                }
              } catch (error) {
                dispatch({
                  type: 'SNACKBAR',
                  payload: { message: 'Erro', variant: 'error', open: true },
                });
              }
            });
        } else if (response.data.content.type_id === 2) {
          axios
            .get(`${URL_ESTABLISHMENT_USER}/user/${response.data.content.id}`, {
              headers: { Authorization: response.data.token },
            })
            .then((responseParticipant) => {
              response.data.content['establishment_participant'] =
                responseParticipant.data[0];
              dispatch([
                { type: 'USER_FETCHED', payload: response },
                dispatch(setLoadingLogin(false)),
              ]);
              if (!_.isUndefined(router)) {
                router.push('/');
              }
            })
            .catch((e) => {
              try {
                if (e.response.data[0]) {
                  toastr.error('Erro', e.response.data[0]);
                }
              } catch (error) {
                toastr.error('Erro', 'Erro interno no servidor');
              }
            });
        } else if (response.data.content.type_id === 3) {
          dispatch([
            { type: 'USER_FETCHED', payload: response },
            dispatch(setLoadingLogin(false)),
          ]);
          if (!_.isUndefined(router)) {
            router.push('/');
          }
        }
      })
      .catch((e) => {
        try {
          if (e.response.data[0]) {
            toastr.error('Erro', e.response.data[0]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
        }
      });
  };
};

export const finishTutorial = (id, router, auth) => {
  return (dispatch) => {
    dispatch([
      {
        type: 'USER_STEPS_LOAD',
        payload: true,
      },
    ]);
    axios
      .put(
        `${URL_FINISH_BRIEFING}/${id}`,
        { type_user: 4, cnpj: auth.user.establishments[0].cnpj },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      )
      .then((response) => {
        axios
          .post(URL_LOGIN_HASH, {
            hash: auth.user.hash,
            email: auth.user.email,
          })
          .then((response) => {
            if (
              response.data.content.type_id === 1 ||
              response.data.content.type_id === 4
            ) {
              axios
                .get(`${URL_ESTABLISHMENT_OWNER}/${response.data.content.id}`, {
                  headers: { Authorization: response.data.token },
                })
                .then((responseOwner) => {
                  toastr.success('Sucesso, aproveite.');
                  response.data.content['establishments'] = responseOwner.data;
                  dispatch([
                    { type: 'USER_FETCHED', payload: response },
                    dispatch(setLoadingLogin(false)),
                  ]);
                  if (!_.isUndefined(router)) {
                    router.push('/owner');
                  }
                })
                .catch((e) => {
                  try {
                    if (e.response.data[0]) {
                      toastr.error('Erro', e.response.data[0]);
                    }
                  } catch (error) {
                    toastr.error('Erro', 'Erro interno no servidor');
                  }
                });
            } else if (response.data.content.type_id === 2) {
              axios
                .get(
                  `${URL_ESTABLISHMENT_USER}/user/${response.data.content.id}`,
                  {
                    headers: { Authorization: response.data.token },
                  }
                )
                .then((responseParticipant) => {
                  toastr.success('Sucesso, aproveite.');
                  response.data.content['establishment_participant'] =
                    responseParticipant.data[0];
                  dispatch([
                    { type: 'USER_FETCHED', payload: response },
                    dispatch(setLoadingLogin(false)),
                  ]);
                  if (!_.isUndefined(router)) {
                    router.push('/owner');
                  }
                })
                .catch((e) => {
                  try {
                    if (e.response.data[0]) {
                      toastr.error('Erro', e.response.data[0]);
                    }
                  } catch (error) {
                    toastr.error('Erro', 'Erro interno no servidor');
                  }
                });
            } else if (response.data.content.type_id === 3) {
              dispatch([
                { type: 'USER_FETCHED', payload: response },
                dispatch(setLoadingLogin(false)),
              ]);
              toastr.success('Sucesso, aproveite.');
              if (!_.isUndefined(router)) {
                router.push('/owner');
              }
            }
          })
          .catch((e) => {
            try {
              if (e.response.data[0]) {
                toastr.error('Erro', e.response.data[0]);
              }
            } catch (error) {
              toastr.error('Erro', 'Erro interno no servidor');
            }
          });
      });
  };
};

export const logout = () => {
  return { type: 'TOKEN_VALIDATED', payload: false };
};

export const codeValidate = (code, token) => {
  return (dispatch) => {
    axios.get(`${URL_ESTABLISHMENT}/activation/${code}`).then((response) => {
      if (response.data.length > 0) {
        dispatch([
          {
            type: 'ACTIVATION_CODE_FETCHED',
            payload: response.data[0],
          },
          !_.isNull(token) && token === 'activate'
            ? initializeForm({ hasCompany: '1' })
            : null,
        ]);
      } else {
        toastr.error('Não existe empresa cadastrada com esse código');
      }
    });
  };
};

export const activeUser = (hash, email, router = undefined) => {
  return (dispatch) => {
    const body = {
      hash,
      email,
    };
    axios
      .post(`${BASE_AUTH}/signup/activate/`, body)
      .then((responseActive) => {
        dispatch([
          {
            type: types.USER_ACTIVED,
            payload: true,
          },
        ]);
        axios
          .post(URL_LOGIN_HASH, { hash, email })
          .then((response) => {
            if (
              response.data.content.type_id === 1 ||
              response.data.content.type_id === 4
            ) {
              axios
                .get(`${URL_ESTABLISHMENT_OWNER}/${response.data.content.id}`, {
                  headers: { Authorization: response.data.token },
                })
                .then((responseOwner) => {
                  toastr.success('Sucesso, aproveite.');
                  response.data.content['establishments'] = responseOwner.data;
                  dispatch([
                    { type: 'USER_FETCHED', payload: response },
                    dispatch(setLoadingLogin(false)),
                  ]);
                  if (!_.isUndefined(router)) {
                    router.push('/');
                  }
                })
                .catch((e) => {
                  try {
                    if (e.response.data[0]) {
                      toastr.error('Erro', e.response.data[0]);
                    }
                  } catch (error) {
                    toastr.error('Erro', 'Erro interno no servidor');
                  }
                });
            } else if (response.data.content.type_id === 2) {
              axios
                .get(
                  `${URL_ESTABLISHMENT_USER}/user/${response.data.content.id}`,
                  {
                    headers: { Authorization: response.data.token },
                  }
                )
                .then((responseParticipant) => {
                  toastr.success('Sucesso, aproveite.');
                  response.data.content['establishment_participant'] =
                    responseParticipant.data[0];
                  dispatch([
                    { type: 'USER_FETCHED', payload: response },
                    dispatch(setLoadingLogin(false)),
                  ]);
                  if (!_.isUndefined(router)) {
                    router.push('/');
                  }
                })
                .catch((e) => {
                  try {
                    if (e.response.data[0]) {
                      toastr.error('Erro', e.response.data[0]);
                    }
                  } catch (error) {
                    toastr.error('Erro', 'Erro interno no servidor');
                  }
                });
            } else if (response.data.content.type_id === 3) {
              dispatch([
                { type: 'USER_FETCHED', payload: response },
                dispatch(setLoadingLogin(false)),
              ]);
              toastr.success('Sucesso, aproveite.');
              if (!_.isUndefined(router)) {
                router.push('/');
              }
            }
          })
          .catch((e) => {
            try {
              if (e.response.data[0]) {
                toastr.error('Erro', e.response.data[0]);
              }
            } catch (error) {
              toastr.error('Erro', 'Erro interno no servidor');
            }
          });
      })
      .catch((err) => {
        toastr.error('Erro ao ativar usuário', 'Token de ativação invalido.');
        router.push('/login');
        dispatch([
          {
            type: types.USER_ACTIVED,
            payload: false,
          },
        ]);
      });
  };
};

export const signup = (values, router) => {
  return submit(values, `${BASE_AUTH}/signup`, 'signup', router);
};

export const signupCompany = (values, router) => {
  const method = 'signup';
  const url = `${BASE_AUTH}/signup`;
  values['type'] = 1;
  return (dispatch) => {
    dispatch([{ type: 'LOADING_REGISTER', payload: true }]);

    axios
      .post(url, values)
      .then((response) => {
        const bodyEstablishment = {
          name: values.establishmentName,
          description: values.establishmentDescription,
          type: values.establishmentType,
          owner: response.data.id,
          cnpj: values.cnpj,
          base64: values.image.base64,
          imageName: values.image.name,
        };
        axios
          .post(URL_ESTABLISHMENT, bodyEstablishment)
          .then((responseEstablishment) => {
            if (method === 'signup') {
              toastr.success(
                'Sucesso, por favor, cheque o email que usou para o cadastro'
              );
              dispatch([
                { type: 'USER_REGISTRED', payload: true },
                { type: 'VALIDATION_BEGINS', payload: true },
                /* login({ email: values.email, password: values.password }, router) */
              ]);
            }
            dispatch([{ type: 'USER_FETCHED', payload: response.data }]);
          })
          .catch((e) => {});
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error('Erro', e.response.data.messages[0]);
            dispatch([{ type: 'LOADING_REGISTER', payload: false }]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
          dispatch([{ type: 'LOADING_REGISTER', payload: false }]);
        }
      });
  };
};

export const validateToken = (token, userId) => {
  return (dispatch) => {
    delete axios.defaults.headers.common['authorization'];
    dispatch([{ type: 'TOKEN_LOADING', payload: true }]);
    axios
      .get(`${BASE_API}/users/${userId}`, {
        headers: { Authorization: `${token}` },
      })
      .then((response) => {
        if (response.data.length > 0) {
          const newUserData = {
            ...response.data[0],
          };

          const parsedUserKey = JSON.parse(localStorage.getItem(USER_KEY));
          //Troca a posição do estabelecimento escolhido para a primeira posição do array
          for (
            let index = 0;
            index < newUserData.establishments.length;
            index++
          ) {
            if (
              newUserData.establishments[index].id ===
              parsedUserKey.establishments[0].id
            ) {
              [
                newUserData.establishments[0],
                newUserData.establishments[index],
              ] = [
                newUserData.establishments[index],
                newUserData.establishments[0],
              ];
            }
          }

          dispatch([
            { type: 'TOKEN_VALIDATED', payload: true },
            { type: 'USER_FETCHED_AGAIN', payload: newUserData },
          ]);
        } else {
          dispatch({ type: 'TOKEN_VALIDATED', payload: false });
        }
      })
      .catch((e) => {
        dispatch({ type: 'TOKEN_VALIDATED', payload: false });
      });
  };
};

export const sendPasswordReset = (values, router = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/password/email`, values)
      .then((response) => {
        dispatch(setLoading(false));
        router.push('/login');
        toastr.success('Sucesso', response.data.response.content.message);
      })
      .catch((e) => {
        dispatch(setLoading(false));
        toastr.error('Erro', e.response.data.response.content.message);
      });
  };
};

export const initializeForm = (values) => {
  return initialize('authForm', values);
};

export const resetPassword = (values, router = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/password/reset/${values.token}`, values)
      .then((response) => {
        dispatch(setLoading(false));
        toastr.success('Sucesso', response.data.response.content.message);
        dispatch({
          type: types.USER_RECOVERED,
          payload: true,
        });
      })
      .catch((e) => {
        dispatch(setLoading(false));
        toastr.error('Erro', e.response.data.response.content.message);
        dispatch({
          type: types.USER_RECOVERED,
          payload: false,
        });
      });
  };
};

export const setUser = (id) => {
  return (dispatch) => {
    axios
      .get(`${BASE_AUTH}/user/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        const user = {
          cpf: response.data.content.cpf,
          profile: response.data.content.profile,
          name: response.data.content.name,
          phone: response.data.content.phone,
          cnh: response.data.content.cnh,
        };
        user['token'] = JSON.parse(localStorage.getItem(USER_TOKEN));
        dispatch({ type: 'USER_FETCHED', payload: { ...user } });
      });
  };
};

//Solicitar reset de senha logado

export const requestPasswordResetLogged = (values, router = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/validate-email-forgot-pass`, values)
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Sucesso! Verifique sua caixa de e-mails`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        router.push('/owner');
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};

//Atualizar a senha

export const updatePassword = (values, router = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/update-password`, values)
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Sucesso! Você já pode logar com a nova senha`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        router.push('/login');
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};

export const setLoading = (status) => {
  return { type: 'LOADING_REGISTER', payload: status };
};
export const setLoadingLogin = (status) => {
  return { type: 'LOADING_LOGIN', payload: status };
};
const submit = (values, url, method, router = undefined) => {
  return (dispatch) => {
    if (method === 'signup') {
      dispatch([{ type: 'LOADING_REGISTER', payload: true }]);
    }
    values['type'] = 2;

    axios
      .post(url, values)
      .then((response) => {
        const postEstablishmentUserBody = {
          user: response.data.id,
          establishment: parseInt(values.establishmentId),
        };
        axios
          .post(URL_ESTABLISHMENT_USER, postEstablishmentUserBody)
          .then((responseEstablishmentUser) => {
            if (method === 'signup') {
              toastr.success(
                'Sucesso, estamos te logando no sistema',
                response.message
              );
              dispatch([
                { type: 'USER_REGISTRED', payload: true },
                { type: 'VALIDATION_BEGINS', payload: true },
                login(
                  { email: values.email, password: values.password },
                  router
                ),
              ]);
            }
            dispatch([{ type: 'USER_FETCHED', payload: response.data }]);
          })
          .catch((e) => {
            try {
              if (e.response.data) {
                toastr.error('Erro', e.response.data.messages[0]);
                dispatch([{ type: 'LOADING_REGISTER', payload: false }]);
              }
            } catch (error) {
              toastr.error('Erro', 'Erro interno no servidor');
              dispatch([{ type: 'LOADING_REGISTER', payload: false }]);
            }
          });
      })
      .catch((e) => {
        try {
          if (e.response.data) {
            toastr.error('Erro', e.response.data.messages[0]);
            dispatch([{ type: 'LOADING_REGISTER', payload: false }]);
          }
        } catch (error) {
          toastr.error('Erro', 'Erro interno no servidor');
          dispatch([{ type: 'LOADING_REGISTER', payload: false }]);
        }
      });
  };
};

export const reset = () => {
  return resetForm('signupStepForm');
};

export const signupAdmin = (values, router) => {
  return (dispatch) => {
    values.type = 4;
    axios
      .post(URL_SIGNUP_ADMIN, values)
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Sucesso! Verifique sua caixa de e-mails.',
              variant: 'success',
              open: true,
            },
          },
          reset(),
          router.push('/confirmacao'),
        ]);
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};

export const signupCompanyOnly = (values, router, auth) => {
  return (dispatch) => {
    dispatch({
      type: 'USER_STEPS_LOAD',
      payload: true,
    });
    axios
      .post(URL_ESTABLISHMENT, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((establishmentResponse) => {
        axios
          .post(URL_LOGIN_HASH, {
            hash: auth.user.hash,
            email: auth.user.email,
          })
          .then((response) => {
            axios
              .get(`${URL_ESTABLISHMENT_OWNER}/${response.data.content.id}`, {
                headers: { Authorization: response.data.token },
              })
              .then((responseOwner) => {
                axios
                  .post(
                    URL_ADDRESS,
                    {
                      ...values,
                      establishment_id: establishmentResponse.data.id,
                    },
                    {
                      headers: { Authorization: response.data.token },
                    }
                  )
                  .then((addressResponse) => {
                    response.data.content.establishments = responseOwner.data;
                    dispatch([
                      { type: 'USER_FETCHED', payload: response },
                      dispatch(setLoadingLogin(false)),
                      {
                        type: 'SIGNUP_COMPANY',
                        payload: true,
                      },
                    ]);
                    dispatch([
                      {
                        type: 'SNACKBAR',
                        payload: {
                          message: 'Sucesso! Aproveite.',
                          variant: 'success',
                          open: true,
                        },
                      },
                      {
                        type: 'USER_STEPS_LOAD',
                        payload: false,
                      },
                      { type: 'USER_FETCHED', payload: response },
                      {
                        type: 'SIGNUP_COMPANY',
                        payload: true,
                      },
                      setLoadingLogin(false),
                    ]);
                    if (!_.isUndefined(router)) {
                      router.push('/owner');
                    }
                  })

                  .catch((e) => {
                    try {
                      if (e.response.data[0]) {
                        dispatch([
                          {
                            type: 'SNACKBAR',
                            payload: {
                              message: `Erro: ${e.response.data[0]}`,
                              variant: 'error',
                              open: true,
                            },
                          },
                          {
                            type: 'USER_STEPS_LOAD',
                            payload: false,
                          },
                        ]);
                      }
                    } catch (error) {
                      dispatch([
                        {
                          type: 'SNACKBAR',
                          payload: {
                            message: 'Erro',
                            variant: 'error',
                            open: true,
                          },
                        },
                        {
                          type: 'USER_STEPS_LOAD',
                          payload: false,
                        },
                      ]);
                    }
                  });
              });
          })
          .catch((e) => {
            console.log(e);
            try {
              if (e.response.data) {
                dispatch([
                  {
                    type: 'SIGNUP_COMPANY',
                    payload: false,
                  },
                  {
                    type: 'SNACKBAR',
                    payload: {
                      message: `Erro: ${e.response.data.error}`,
                      variant: 'error',
                      open: true,
                    },
                  },
                  {
                    type: 'USER_STEPS_LOAD',
                    payload: false,
                  },
                ]);
              }
            } catch (error) {
              console.log(error);
              dispatch({
                type: 'SIGNUP_COMPANY',
                payload: false,
              });
              dispatch({
                type: 'SNACKBAR',
                payload: { message: 'Erro', variant: 'error', open: true },
              });
            }
          })
          .catch((e) => {
            console.log(e);
            try {
              if (e.response.data[0]) {
                dispatch([
                  {
                    type: 'SNACKBAR',
                    payload: {
                      message: `Erro: ${e.response.data[0]}`,
                      variant: 'error',
                      open: true,
                    },
                  },
                  {
                    type: 'USER_STEPS_LOAD',
                    payload: false,
                  },
                ]);
              }
            } catch (error) {
              console.log(error);
              dispatch([
                {
                  type: 'SNACKBAR',
                  payload: {
                    message: 'Erro',
                    variant: 'error',
                    open: true,
                  },
                },
                {
                  type: 'USER_STEPS_LOAD',
                  payload: false,
                },
              ]);
            }
          });
      });
  };
};

export const showModal = () => {
  return (dispatch) => {
    dispatch({ type: 'SHOW_MODAL', payload: false });
  };
};

export const answerQuestions = (values, router = undefined) => {
  return (dispatch) => {
    axios
      .post(URL_ANSWER_QUESTIONS, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        dispatch([
          { type: 'SECTIONS_STATUS', payload: true },
          {
            type: 'SIGNUP_COMPANY',
            payload: false,
          },
        ]);
      });
  };
};

export const skipOnboarding = () => {
  return (dispatch) => {
    dispatch({
      type: 'SKIP_QUESTIONS',
      payload: true,
    });
  };
};

//Solicitar reset de senha

export const requestPasswordReset = (values, router = undefined) => {
  return (dispatch) => {
    axios
      .post(`${BASE_AUTH}/validate-email-forgot-pass`, values)
      .then((response) => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: `Sucesso! Verifique sua caixa de e-mails`,
              variant: 'success',
              open: true,
            },
          },
        ]);
        router.push('/login');
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};

export const changeEstablishment = (newEstablishmentsList) => {
  return (dispatch) => {
    /* const a = localStorage.setItem(USER_KEY, JSON.stringify(action.payload.data.content)); */
    const b = JSON.parse(localStorage.getItem(USER_KEY));
    const a = { ...b, establishments: newEstablishmentsList };
    localStorage.setItem(USER_KEY, JSON.stringify(a));
    console.log(newEstablishmentsList);
    console.log(JSON.stringify(a));
    dispatch({
      type: 'CHANGE_ESTABLISHMENT',
      payload: newEstablishmentsList,
    });
  };
};

export const requestAdminTax = () => {
  return (dispatch) => {
    axios
      .get(`${URL_ADMIN_TAX}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then((response) => {
        console.log({ response });
        dispatch([
          {
            type: 'GET_ADMIN_TAX',
            payload: response.data.adm_tax,
          },
        ]);
      })
      .catch((e) => {
        dispatch({
          type: 'SNACKBAR',
          payload: {
            message: `Erro: ${e.response.data.messages[0]}`,
            variant: 'error',
            open: true,
          },
        });
      });
  };
};
