import { Box } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import { getList as getCategoriesList } from 'modules/owner/pages/categories/categoriesActions';
import React from 'react';
import { ExpansionList, ExpansionPanel } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Field,
  FieldArray,
  Form,
  getFormValues,
  initialize,
  reduxForm,
} from 'redux-form';
import { InputSwitch } from 'common/components/fields/fields';
import { GenericConfirmationCard } from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import UploadVideos from 'common/components/upload';
import { isMobile } from 'react-device-detect';

const changeFieldValue = (form, value) => {
  return dispatch => {
    dispatch(initialize(form, value));
  };
};

const renderVideosAdd = props => {
  function array_move(fromIndex, toIndex) {
    var element = props.formValues?.videosAdd[fromIndex];
    props.fields.splice(fromIndex, 1);
    props.fields.splice(toIndex, 0, element);
  }
  function goUp(index) {
    return array_move(index, index - 1);
  }
  function goDown(index) {
    return array_move(index, index + 1);
  }

  return (
    <ul style={{ listStyleType: 'none' }}>
      {props.fields.map((video, index) => (
        <Box
          display="flex"
          key={index}
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center"
          style={{
            borderTop: '2px solid #E5E5E5',
            paddingTop: 5,
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            style={{ marginRight: 20, zIndex: 100 }}
          >
            {index !== 0 && props.fields.length > 1 ? (
              <ArrowDropUp
                style={{ cursor: 'pointer' }}
                onClick={() => goUp(index)}
              />
            ) : null}
            {index !== props.fields.length - 1 && props.fields.length > 1 ? (
              <ArrowDropDown
                style={{ cursor: 'pointer' }}
                onClick={() => goDown(index)}
              />
            ) : null}
          </Box>
          <ExpansionPanel
            key={index}
            label={`Vídeo ${index + 1}`}
            footer={null}
            style={{
              marginBottom: 10,
              width: '100%',
              borderRadius: 4,
              boxShadow: 'none',
              backgroundColor: '#ffffff',
            }}
          >
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12} sm={12}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  style={{ marginBottom: 10 }}
                >
                  <Field
                    component={InputSwitch}
                    label={'Vídeo obrigatório'}
                    name={`${video}.required`}
                  />
                  <Typography
                    style={{
                      cursor: 'pointer',
                      color: '#E34848',
                      fontSize: 16,
                    }}
                    onClick={() => props.fields.remove(index)}
                  >
                    Apagar
                  </Typography>
                </Box>
                <Field
                  component={InputLabelOutlined}
                  label={`Nome do vídeo`}
                  name={`${video}.name`}
                  validate={[FORM_RULES.required]}
                  required
                  margin="normal"
                  disabled={
                    props.disabled || props.router.params.action === 'proposta'
                  }
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} style={{ margin: '25px 0px' }}>
                <Field
                  component={UploadVideos}
                  multipleVideos={true}
                  indexVideo={index}
                  formValues={props.formValues ? props.formValues : null}
                  name={`${video}.id`}
                />
              </MaterialGrid>
              <MaterialGrid item xs={12} sm={12}>
                <Field
                  component={InputLabelOutlined}
                  maxLength={600}
                  rows={5}
                  multiline
                  type="text"
                  label={`Descrição do vídeo`}
                  name={`${video}.description`}
                  margin="normal"
                  validate={[FORM_RULES.required]}
                  disabled={
                    props.disabled || props.router.params.action === 'proposta'
                  }
                />
              </MaterialGrid>
            </MaterialGrid>
          </ExpansionPanel>
        </Box>
      ))}
      <Box
        onClick={() => props.fields.push()}
        style={{
          width: '100%',
          borderTop: '2px solid #E5E5E5',
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: 26,
        }}
      >
        <Typography
          style={{
            color: '#5759FB',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
          }}
        >
          + Adicionar Vídeo
        </Typography>
      </Box>
    </ul>
  );
};
let VideoActionInteraction = function(props) {
  // const [openConfirmation, setOpenConfirmation] = useState(false);
  function onSubmit(values) {
    values['submitFromButton'] = true;
    props.onClickProgress(values);
  }
  // function onSubmitSec(values) {
  //   values['submitFromButton'] = true;
  //   props
  //     .onClickProgress(values)
  //     .then(() => {
  //       props.closeModal();
  //     })
  //     .catch(() => {});
  // }

  function renderVideos() {
    return (
      <>
        {_.isEmpty(props.formValues?.videosAdd) && (
          <p style={{ textAlign: 'center' }}>
            Ainda não há vídeos. Adicione algum!
          </p>
        )}
        <ExpansionList>
          <FieldArray name="videosAdd" component={renderVideosAdd} {...props} />
        </ExpansionList>
        <GenericConfirmationCard
          open={props.openConfirmation}
          cancelClose={props.handleCancelClose}
          maxWidthFixed
          labelLeftButton={'Continuar aqui'}
          labelRightButton={props.buttonConfirmationLabel}
          general={props.general}
          confirmButtonColor={'primary'}
          title={props.confirmationTitle}
          text={props.confirmationSubtitle}
          onClose={() => props.setOpenConfirmation(false)}
          onClick={props.closeModal}
        />
      </>
    );
  }

  return props.contactsSurveys.loading ? (
    <div className="d-flex justify-content-center mt-5">
      <LoadingProgress />
    </div>
  ) : (
    <>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <div
          style={{
            overflowY: 'scroll',
            height: isMobile ? 'calc(100vh - 250px)' : 'calc(100vh - 310px)',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 15,
          }}
        >
          {renderVideos()}
          <GenericConfirmationCard
            open={props.openConfirmation}
            cancelClose={props.handleCancelClose}
            maxWidthFixed
            labelLeftButton={'Continuar aqui'}
            labelRightButton={props.buttonConfirmationLabel}
            general={props.general}
            confirmButtonColor={'primary'}
            title={props.confirmationTitle}
            text={props.confirmationSubtitle}
            onClose={() => props.setOpenConfirmation(false)}
            onClick={() =>
              props.router.push('/owner/acoes/minhas-atividades/?to=drafts')
            }
          />
        </div>
        <MaterialGrid
          container
          display="flex"
          justify="space-around"
          alignItems="center"
          style={{
            margin: '25px 0px',
            overflowX: 'hidden',
            paddingRight: isMobile ? 0 : 29,
          }}
        >
          <SecondaryButton
            type="button"
            color="secondary"
            onClick={props.prevClick}
            // progress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: '10px 20px',
              textTransform: 'none',
            }}
          >
            Voltar
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            color="primary"
            disabled={props.activeActions.buttonLoading}
            // proress={parseInt(this.props.general.submitProgress)}
            style={{
              margin: 0,
              width: '48%',
              padding: isMobile ? '12px 20px' : '10px 20px',
              textTransform: 'none',
              fontSize: isMobile ? 12 : undefined,
            }}
          >
            Salvar e continuar
          </PrimaryButton>
        </MaterialGrid>
      </Form>
    </>
  );
};

VideoActionInteraction = reduxForm({
  form: 'videoPlaylistForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(VideoActionInteraction);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    contactsSurveys: state.contactsSurveys,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('videoPlaylistForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ changeFieldValue, getCategoriesList }, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoActionInteraction);
