import React from 'react';
import { IndexRoute, Route } from 'react-router';
import ActionOfferMessages from './actionOfferMessages';

export default (
  <Route path="mensagens-proposta">
    <Route path=":id">
      <IndexRoute component={props => <ActionOfferMessages {...props} />} />
    </Route>
  </Route>
);
