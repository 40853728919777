import Content from 'common/components/content/content';
import React from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getFormValues, reduxForm } from 'redux-form';
import { putQuiz } from '../quizActions';
import QuizForm from './quizForm';

class QuizEditForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      result: null,
    };
  }
  onSubmit = values => {
    const { router } = this.props;
    const { establishment } = this.props.auth.user;
    const newValues = {
      ...values,
      establishment: establishment.id,
    };
    this.props.putQuiz(newValues, router);
  };
  render() {
    return (
      <div>
        <Content>
          <Card>
            <CardTitle title="Criar Quiz" />
            <CardText>
              <div className="App">
                <QuizForm
                  quizzes={this.props.quizzes}
                  router={this.props.router}
                  onSubmit={this.onSubmit}
                />
              </div>
            </CardText>
          </Card>
        </Content>
      </div>
    );
  }
}

QuizEditForm = reduxForm({ form: 'quizEditForm' })(QuizEditForm);

const mapStateToProps = state => {
  return {
    formValues: getFormValues('quizCreateForm')(state),
    auth: state.auth,
    quizzes: state.quizzes,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      putQuiz,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizEditForm);
