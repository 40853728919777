import React from 'react';
import { Route, IndexRoute } from 'react-router';
import ProductOrdersDetail from './productOrders/productOrdersDetail';
import Sales from './sales';

export default (
  <Route path="sales">
    <IndexRoute component={props => <Sales {...props} />} />
    <Route path=":id" component={props => <ProductOrdersDetail {...props} />} />
  </Route>
);
