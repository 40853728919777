import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';

const URL_INVITE = `${BASE_API}/contacts/invite`;

export const inviteContacts = (values, router = undefined) => {
  return dispatch => {
    axios
      .get(`${URL_INVITE}/${values.establishment}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        if (!_.isUndefined(router)) {
          router.push('/owner/contacts');
        }
      });
  };
};
