import { Box, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import AlertDialogSlide from 'common/components/dialog/dialog';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';

export default function ProductOrders(props) {
  const { id } = props.auth.user.establishments[0];
  const { router } = props;
  const { list, loading } = props.productOrders;

  function renderList(item) {
    const itemsId = item.single_product_orders.map(i => i.product_id);
    const uniqueItemsId = Array.from(new Set(itemsId));
    const newArray = [];
    for (let productId of uniqueItemsId) {
      const product = item.single_product_orders.filter(
        i => i.product_id === productId
      );
      newArray.push({
        product: product[0].product.name,
        quantity: product.length,
      });
    }
    return (
      <DataTable value={newArray} style={{ width: 300 }}>
        <Column field="quantity" header="Quantidade" />
        <Column field="product" header="Produto" />
      </DataTable>
    );
  }

  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [idCancel, setIdCancel] = useState(undefined);

  function renderFullPrice(item) {
    if (item.single_product_orders && item.delivery_location) {
      const pricesArray = item.single_product_orders.map(
        item => item.product.value
      );
      pricesArray.push(item.delivery_location.price);
      let finalPrice = 0;
      for (let price of pricesArray) {
        finalPrice += parseFloat(price);
      }
      finalPrice = finalPrice - parseFloat(item.action.discount_delivery);
      return <div>R$ {finalPrice.toFixed(2).replace('.', ',')}</div>;
    } else return null;
  }

  const [columns] = useState([
    { field: 'name', title: 'Nome', editable: 'never' },
    { field: 'cpf', title: 'CPF', editable: 'never' },
    { field: 'phone', title: 'Telefone', editable: 'never' },
    {
      field: 'address',
      title: 'Endereço',
      editable: 'never',
    },
    {
      field: 'reference',
      title: 'Referência',
      editable: 'never',
    },
    {
      title: 'Produtos',
      render: rowData => {
        return renderList(rowData);
      },
      editable: 'never',
    },
    {
      title: 'Valor Final',
      render: rowData => {
        return renderFullPrice(rowData);
      },
      editable: 'never',
    },
    {
      field: 'delivery_location.name',
      title: 'Local',
      editable: 'never',
    },
    {
      field: 'action.name',
      title: 'Atividade',
      editable: 'never',
    },
    {
      title: 'X',
      render: rowData => {
        if (!rowData.cancelled) {
          return (
            <div>
              <IconButton
                onClick={() => {
                  setIdCancel(rowData.id);
                  setOpen(true);
                }}
                style={{ color: 'red' }}
                aria-label="Rejeitar"
              >
                <ClearIcon />
              </IconButton>
            </div>
          );
        } else {
          return <span>Cancelado</span>;
        }
      },
      editable: 'never',
    },
    {
      title: 'Observações',
      field: 'observations',
    },
  ]);

  useEffect(() => {
    props.getDeliverysWithId(id);
  }, []);

  useEffect(() => {
    setData(props.productOrders.list);
  }, [props.productOrders.list]);

  function handleChange(e) {
    setData(tableSearch(e, props.productOrders.list));
    setValue(e.target.value);
  }
  function handleUpdate(values, resolve, reject) {
    const { id } = props.auth.user.establishments[0];
    props.updateObservations(
      values.id,
      values.observations,
      id,
      resolve,
      reject
    );
  }

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não tem nenhum pedido
                </h2>
                <p>
                  Os pedidos serão feitos pelos participantes e aparecerão aqui.
                </p>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (list.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="statement-buy-points">
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            justify="space-between"
            alignContent="space-between"
          >
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}
              >
                Pedidos
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {list.length} {list.length > 1 ? 'pedidos' : 'pedido'}
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6
                title="Pedidos"
                columns={columns}
                data={data}
                router={router}
                edit
                actions={'Editar Obs.'}
                handleUpdate={handleUpdate}
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
      {open ? (
        <AlertDialogSlide
          maxWidth="sm"
          title="Tem certeza?"
          onClose={() => setOpen(false)}
          visible={open}
        >
          <div>
            <Typography>
              Você tem certeza que deseja cancelar esse pedido?
            </Typography>

            <PrimaryButton
              noMargin
              onClick={() => {
                setOpen(false);
                props.cancelProductOrder(
                  idCancel,
                  props.auth.user.establishments[0].id
                );
              }}
              disabled={props.productOrders.buttonLoading}
            >
              Sim, quero cancelar
            </PrimaryButton>
            <SecondaryButton onClick={() => setOpen(false)}>
              Cancelar
            </SecondaryButton>
          </div>
        </AlertDialogSlide>
      ) : null}
    </section>
  );
}
