import { Typography } from '@material-ui/core';
import MaterialGrid from '@material-ui/core/Grid';
import PrimaryButton from 'common/components/buttons/primaryButton';
import SecondaryButton from 'common/components/buttons/secondaryButton';
import {
  InputLabelOutlined,
  InputSwitchGrey,
} from 'common/components/fields/fields';
import LoadingProgress from 'common/components/progress/loading';
import { currencyMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import { getPartnersList } from '../presentialCoursesActions';
import { GenericConfirmationCard } from 'common/components/cards/genericConfirmationModel/genericConfirmationModel';

class Interaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openConfirmation: false,
    };
    this.props.getPartnersList();
  }
  componentDidUpdate(props) {
    if (this.props.formValues !== props.formValues) {
      if (!this.props.formValues.prices) {
        this.props.formValues['price'] = 0;
        this.props.formValues['discount_price'] = 0;
        this.props.formValues['discountPrice'] = 0;
      }
      if (
        !_.isUndefined(this.props.formValues.price) &&
        !_.isUndefined(this.props.formValues.discountPrice)
      ) {
        this.props.formValues['discount_price'] =
          this.props.formValues.price - this.props.formValues.discountPrice;
      } else if (
        !_.isUndefined(this.props.formValues.price) &&
        _.isUndefined(this.props.formValues.discountPrice)
      ) {
        this.props.formValues['discount_price'] = this.props.formValues.price;
      }
    }
  }

  onSubmit = values => {
    values['submitFromButton'] = true;
    this.props.onClickProgress(values);
  };
  onSubmitSec = values => {
    values['submitFromButton'] = true;
    this.props.onClickProgress(values);
    this.props.closeModal();
  };

  render() {
    // const partnersList = this.props.presentialCourses.listPartners.content.map(
    //   item => ({
    //     value: item.id,
    //     label: item.name,
    //   })
    // );
    return this.props.presentialCourses.loading ? (
      <div className="d-flex justify-content-center mt-5">
        <LoadingProgress />
      </div>
    ) : (
      <>
        <Form
          role="form"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
          noValidate
        >
          <div
            style={{
              overflowY: 'scroll',
              height: 'calc(100vh - 310px)',
              overflowX: 'hidden',
              paddingRight: 15,
            }}
          >
            <MaterialGrid container spacing={3}>
              <MaterialGrid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  label={'Data de Expiração do Voucher'}
                  name="date"
                  type="datetime-local"
                  disabled={this.props.router.params.action === 'proposta'}
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                />
              </MaterialGrid>
              <MaterialGrid
                item
                xs={12}
                style={{
                  backgroundColor: '#f7f7f7',
                  padding: 16,
                }}
              >
                <Field
                  component={InputSwitchGrey}
                  label={'Preços'}
                  boxStyle={{ backgroundColor: '#f7f7f7' }}
                  firstState="Defina o preço original e o desconto"
                  secondState="Ative para mostrar preços"
                  name="prices"
                  // required
                  margin="normal"
                />
                {this.props.formValues.prices ? (
                  <MaterialGrid container spacing={3}>
                    <MaterialGrid item xs={4}>
                      <Field
                        component={InputLabelOutlined}
                        type="tel"
                        label={'Valor em reais'}
                        name="price"
                        required
                        validate={[FORM_RULES.required]}
                        margin="normal"
                        {...currencyMask}
                      />
                    </MaterialGrid>
                    <MaterialGrid item xs={4}>
                      <Field
                        component={InputLabelOutlined}
                        type="tel"
                        label={'Desconto em reais'}
                        name="discountPrice"
                        required
                        validate={[FORM_RULES.required]}
                        margin="normal"
                        {...currencyMask}
                      />
                    </MaterialGrid>
                    <MaterialGrid
                      item
                      xs={4}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 10,
                      }}
                    >
                      <Typography color="primary" style={{ fontSize: 12 }}>
                        Valor com desconto
                      </Typography>
                      <Typography style={{ color: 'black', marginTop: 5 }}>
                        {this.props.formValues
                          ? 'R$ ' +
                            parseFloat(
                              this.props.formValues.discount_price || 0
                            )
                              .toFixed(2)
                              .replace('.', ',')
                          : null}
                      </Typography>
                    </MaterialGrid>
                  </MaterialGrid>
                ) : null}
              </MaterialGrid>
              <MaterialGrid item xs={12}>
                <Field
                  component={InputLabelOutlined}
                  type="text"
                  label={'Regulamento do Voucher'}
                  name="local"
                  required
                  validate={[FORM_RULES.required]}
                  margin="normal"
                  disabled={this.props.router.params.action === 'proposta'}
                />
              </MaterialGrid>
            </MaterialGrid>
            <GenericConfirmationCard
              open={this.props.openConfirmation}
              labelLeftButton={'Continuar aqui'}
              labelRightButton={'Sim, sair da edição'}
              general={this.props.general}
              title="Deseja sair da edição?"
              confirmButtonColor={'primary'}
              text="Lembre-se, as informações que não foram salvas serão perdidas."
              onClose={() => this.props.setOpenConfirmation(false)}
              onClick={this.props.closeModal}
              cancelClose={this.props.handleCancelClose}
            />
          </div>
          <MaterialGrid
            container
            display="flex"
            justify="space-around"
            alignItems="center"
            style={{
              margin: '25px 0px',
              overflowX: 'hidden',
              paddingRight: 29,
            }}
          >
            <SecondaryButton
              type="button"
              color="secondary"
              onClick={this.props.prevClick}
              // progress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}
            >
              Voltar
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              color="primary"
              // proress={parseInt(this.props.general.submitProgress)}
              style={{
                margin: 0,
                width: '48%',
                padding: '10px 20px',
                textTransform: 'none',
              }}
            >
              Salvar e continuar
            </PrimaryButton>
          </MaterialGrid>
        </Form>
      </>
    );
  }
}

Interaction = reduxForm({
  form: 'presentialCoursesForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Interaction);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    presentialCourses: state.presentialCourses,
    activeActions: state.activeActions,
    general: state.general,
    formValues: getFormValues('presentialCoursesForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getPartnersList,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Interaction);
