import { InputFile } from 'common/components/fields/fields';
// import { bindActionCreators } from "redux";
/* import _ from "lodash"; */
import Grid from 'common/components/layout/grid/grid';
import Spinner from 'common/components/spinner/spinner';
import { create_or_update, setFormValue } from 'helpers/formComponent';
import { currencyMask } from 'helpers/masks';
import { FORM_RULES } from 'helpers/validations';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, getFormValues, reduxForm } from 'redux-form';
import PrimaryButton from '../../../../../common/components/buttons/primaryButton';
import { ImageCard } from '../../../../../common/components/cards/imgCard';
import { InputLabelOutlined } from '../../../../../common/components/fields/fields';
import GeneralGrid from '../../../../../common/components/layout/grid/GeneralGrid';
import {
  create,
  getDetail,
  initializeForm,
  setDetail,
  update,
} from './couponsActions';

class CouponsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      name: 'Título',
      description: 'Descrição',
      oldPrice: '2,00',
      newPrice: '1,00',
      image: {
        base64: '',
      },
      step: 1,
    };

    const { coupons, router, location, getDetail, setDetail } = this.props;
    setFormValue(coupons, router, location, getDetail, setDetail);
  }

  onSubmit = values => {
    const { router, create, update } = this.props;
    values.base64 = values.image.base64;
    values['establishment'] = this.props.auth.user.establishments[0].id;
    values['sponsored'] = 1;
    let a = values.base64.indexOf('/');
    let b = values.base64.indexOf(';');
    values.imageType = values.base64.slice(a + 1, b);
    delete values['image'];
    create_or_update(router, values, create, update);
  };

  componentWillReceiveProps(props) {
    if (!_.isUndefined(props.formValues)) {
      this.setState({ ...props.formValues });
    }
  }
  render() {
    const { handleSubmit } = this.props;

    return this.props.coupons.loading ? (
      <Spinner />
    ) : (
      <div
        className="container-fluid"
        style={{ marginTop: 70, paddingBottom: 20 }}
      >
        <div className="row">
          <div className="col-12">
            <Form role="form" onSubmit={handleSubmit(this.onSubmit)} noValidate>
              <Grid cols="12 12 6 6">
                <div className="card">
                  <div className="card-body">
                    <p className="h4 card-title text-muted">
                      Configure seu cupom e veja como ele ficará ao lado
                    </p>
                    <div
                      style={{
                        display: this.state.step === 1 ? 'block' : 'none',
                      }}
                    >
                      <GeneralGrid container spacing={3}>
                        <GeneralGrid item xs={12} md={6}>
                          <Field
                            component={InputLabelOutlined}
                            label="Nome do Cupom"
                            name="name"
                            validate={[FORM_RULES.required]}
                            required
                            margin="normal"
                          />
                        </GeneralGrid>
                        <GeneralGrid item xs={12} md={6}>
                          <Field
                            component={InputLabelOutlined}
                            type="tel"
                            label={'Preço Antigo'}
                            name="price"
                            required
                            validate={[FORM_RULES.required]}
                            margin="normal"
                            {...currencyMask}
                          />
                        </GeneralGrid>
                        <GeneralGrid item xs={4} lg={6}>
                          <Field
                            component={InputLabelOutlined}
                            type="tel"
                            label={'Novo preço'}
                            name="newPrice"
                            cols="12 12 4 4"
                            required
                            validate={[FORM_RULES.required]}
                            margin="normal"
                            {...currencyMask}
                          />
                        </GeneralGrid>
                        <GeneralGrid item xs={4} lg={12}>
                          <Field
                            component={InputLabelOutlined}
                            multiline
                            label={'Descrição'}
                            name="description"
                            cols="12 12 12 12"
                            rowsMax={2}
                            required
                            validate={[FORM_RULES.required]}
                            margin="normal"
                          />
                        </GeneralGrid>
                        <GeneralGrid item xs={12} md={6}>
                          <Field
                            component={InputFile}
                            label={'Imagem .png ou .jpg'}
                            name="image"
                            validate={[FORM_RULES.required]}
                            margin="normal"
                          />
                        </GeneralGrid>
                      </GeneralGrid>

                      <Grid cols="12 12 12 12" style={{ textAlign: 'center' }}>
                        <PrimaryButton
                          onClick={() => this.setState({ step: 2 })}
                        >
                          PRÓXIMO
                        </PrimaryButton>
                      </Grid>
                    </div>
                    <div
                      style={{
                        display: this.state.step === 2 ? 'block' : 'none',
                      }}
                    >
                      <GeneralGrid item xs={12} md={6}>
                        <Field
                          component={InputLabelOutlined}
                          type="number"
                          label={'Limite'}
                          name="limit"
                          required
                          validate={[FORM_RULES.required]}
                          margin="normal"
                        />
                      </GeneralGrid>
                      <GeneralGrid item xs={12} md={6}>
                        <Field
                          component={InputLabelOutlined}
                          label={'Expiração do Cupom'}
                          defaultValue={'2017-05-24T10:30'}
                          name="expiration"
                          type="datetime-local"
                          required
                          validate={[FORM_RULES.required]}
                          margin="normal"
                        />
                      </GeneralGrid>

                      <GeneralGrid item xs={4} lg={6}>
                        <Field
                          component={InputLabelOutlined}
                          label={'Código'}
                          name="code"
                          cols="12 12 4 4"
                          required
                          validate={[FORM_RULES.required]}
                          margin="normal"
                        />
                      </GeneralGrid>
                      <Grid cols="12 12 6 6" style={{ textAlign: 'center' }}>
                        <PrimaryButton
                          onClick={() => this.setState({ step: 1 })}
                        >
                          Voltar
                        </PrimaryButton>
                      </Grid>
                      <Grid cols="12 12 6 6" style={{ textAlign: 'center' }}>
                        <PrimaryButton type="submit">Enviar</PrimaryButton>
                      </Grid>
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid cols="12 12 6 6">
                <center>
                  <ImageCard
                    title={this.state.name}
                    imgSrc={this.state.image.base64}
                    text={
                      this.props.formValues
                        ? `${100 -
                            (
                              (parseFloat(this.props.formValues.newPrice) *
                                100) /
                              parseFloat(this.props.formValues.price)
                            ).toFixed(2)}% de desconto`
                        : null
                    }
                    text2={`${this.state.description.substring(0, 30)}`}
                  />
                </center>
              </Grid>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

CouponsForm = reduxForm({ form: 'couponsForm' })(CouponsForm);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    coupons: state.coupons,
    formValues: getFormValues('couponsForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDetail,
      setDetail,
      initializeForm,
      create,
      update,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CouponsForm);
