import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getStationActionOffers,
  onClickAction,
  remove,
} from './actionOffersActions';

function ActionOffers(props) {
  const { auth } = props;
  const content = props.actionOffers.list;
  const [columns] = useState([
    { title: 'Estação', field: 'station.name' },
    { title: 'Atividade', field: 'action.name' },
    { title: 'Aceito', field: 'active' },
    { title: 'Data de Criação', field: 'createdAtDate' },
  ]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  useEffect(() => {
    props.getStationActionOffers(auth.user.establishments[0].id);
  }, []);

  useEffect(() => {
    setData(props.actionOffers.list);
  }, [props.actionOffers.list]);

  function handleChange(e) {
    setData(tableSearch(e, props.actionOffers.list));
    setValue(e.target.value);
  }

  function onRowClick(e, rowData) {
    props.onClickAction(rowData, props.router);
  }

  function renderEmptyScreen() {
    return (
      <section
        id="actionOffers"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não fez propostas de atividades para estações.
                  </h2>
                  <p className="">
                    Atividades são campanhas que você poderá distribuir para o
                    público das estações.
                  </p>

                  <PrimaryButton
                    onClick={() => props.router.push('/owner/acoes')}
                    color="primary"
                  >
                    Criar atividade
                  </PrimaryButton>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img
              src={placeholder_illustration}
              className="img-fluid"
              alt="Ilustração Cupons"
            />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.actionOffers.loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }

  return (
    <section style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            justify="space-between"
            alignContent="space-between"
          >
            <GeneralGrid item xs={3}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 200,
                  fontSize: 24,
                }}
              >
                Atividades
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {props.actionOffers.list.length} Atividades
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6
                title="Atividades"
                columns={columns}
                data={data}
                add
                deleteText="Se confirmar, você estará arquivando essa Atividade, quer continuar?"
                onRowClick={onRowClick}
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    actionOffers: state.actionOffers,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    { getStationActionOffers, remove, onClickAction },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionOffers);
