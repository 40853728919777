import React from 'react';
import { IndexRoute, Route } from 'react-router';
import buyPointsRoutes from './pages/buyPoints/buyPointsRoutes';
import statementBuyPointsRoutes from './pages/statementBuyPoints/statementBuyPointsRoutes';
import transferRequestsRoutes from './pages/transferRequests/transferRequestsRoutes';
import userEarnedPointsRoutes from './pages/userEarnedPoints/userEarnedPointsRoutes';
import Points from './points';

export default (
  <Route path="points">
    <IndexRoute component={props => <Points {...props} />} />
    {buyPointsRoutes}
    {statementBuyPointsRoutes}
    {userEarnedPointsRoutes}
    {transferRequestsRoutes}
  </Route>
);
