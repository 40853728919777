import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MaterialGrid from '@material-ui/core/Grid';
import LoadingProgress from 'common/components/progress/loading';
import _ from 'lodash';
import AlertDialogSlide from 'common/components/dialog/dialogActivities';
import Interaction from './sections/interaction';
import Prize from '../sections/prize';
import Informations from '../sections/information';
import Publish from '../sections/publish';
import {
  submitAction,
  getDetail,
  dispatchFormReset,
  savePartialy,
} from './productDeliveryActions';
import { imgToBase64, imgToImgType } from 'helpers/utils';
import { getList as getLottiesList } from '../../lotties/lottiesActions';
import { publishAction } from 'modules/owner/pages/actions/activeActions/activeActionsScripts';
import { isMobile } from 'react-device-detect';
import { getFormValues } from 'redux-form';
import ActionsTabs from 'common/components/navigation/actionsTab/activityTabs';

function Main(props) {
  const [value, setValue] = React.useState(0);
  const [form, setForm] = React.useState(props.formValues);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  useEffect(() => {
    if (props.formValues) {
      setForm(props.formValues);
    }
  }, [props.formValues]);

  useEffect(() => {
    props.getLottiesList();
    if (props.location.query.share) {
      setValue(3);
    }
  }, []);

  async function handleChange(values, router) {
    values.base64 = values.image.base64;
    if (!_.isUndefined(values.image.base64)) {
      values.imageType = imgToImgType(values.image);
    }

    if (values.featuredImage) {
      if (!_.isUndefined(values.featuredImage.base64)) {
        values.featuredImage.imageType = imgToImgType(values.featuredImage);
      }
    }

    values.body = {
      ...values,
      name: values.body_name,
      description: values.body_description,
    };
    values.type_action = values.type_id;
    delete values.body['image'];
    delete values.body['base64'];
    delete values.body['imageType'];
    values.body = JSON.stringify(values.body);

    let selectedVideos = [];

    selectedVideos.push({
      video_id: values.videoIdField,
      name: '',
      description: '',
      required: false,
    });
    values.videos = [...selectedVideos];

    props.savePartialy(values, router, setValue, value);
  }

  function goBack() {
    setValue(value - 1);
  }

  function handleOpenConfirmation() {
    setOpenConfirmation(true);
  }
  function handleCancelClose() {
    setOpenConfirmation(false);
  }

  function handleNativeChange(e, newValue) {
    if (
      props.router.params.action === 'editar' ||
      props.router.params.action === 'proposta' ||
      props.router.params.action === 'rascunho'
    ) {
      setValue(newValue);
    } else {
      if (newValue < value) {
        setValue(newValue);
      }
    }
  }

  useEffect(() => {
    if (
      props.router.params.action === 'editar' ||
      props.router.params.action === 'proposta' ||
      props.router.params.action === 'rascunho'
    ) {
      props.getDetail(props.router.params.id);
    }
  }, []);

  const onSubmit = async values => {
    if (!_.isUndefined(values.image.base64)) {
      values.imageType = imgToImgType(values.image);
      values.base64 = imgToBase64(values.image);
    }

    if (values.featuredPrivate) {
      if (!_.isUndefined(values.featuredImage.base64)) {
        values.featuredImage.imageType = imgToImgType(values.featuredImage);
      }
    }

    const target = {
      age: {
        from: values.from,
        to: values.to,
      },
      states: values.states,
      associated: values.associated,
      internal: values.internal,
    };
    values['stations'] = [values.stationId];
    values['partner'] = props.auth.user.establishments[0].id;
    values['type_action'] = 14;
    values['sponsored'] = true;

    values['target'] = target;
    const create_or_update =
      props.router.params.action === 'editar' ||
        props.router.params.action === 'rascunho'
        ? 'update'
        : 'create';
    const id_action = props.router.params.id;
    let publish = null;
    if (props.router.params.action === 'rascunho') {
      publish = () => {
        return props.publishAction(
          props.router.params.id,
          values.stationId,
          'productDeliveryForm',
          props.router,
          values.message_to_owner,
          'PRODUCT_DELIVERIES_BUTTON_LOAD'
        );
      };
    }

    props.submitAction(
      values,
      props.router,
      create_or_update,
      id_action,
      publish
    );
  };

  function onDialogClose() {
    props.dispatchFormReset();
    props.router.push(
      props.router.params.action === 'editar'
        ? '/owner/acoes/minhas-atividades/?to=online'
        : props.router.params.action === 'rascunho'
          ? '/owner/acoes/minhas-atividades/?to=drafts'
          : '/owner'
    );
  }

  return (
    <AlertDialogSlide
      value={value}
      test={true}
      visible={true}
      height="95vh"
      form={form}
      onClose={handleOpenConfirmation}
      maxWidth={isMobile ? 'xl' : 'sm'}
    >
      {props.productDelivery.loading ? (
        <div className="d-flex justify-content-center mt-5">
          <LoadingProgress />
        </div>
      ) : (
        <MaterialGrid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          sm={12}
          md={12}
          lg={12}
        >
          <ActionsTabs
            styles={{}}
            form="productDeliveryForm"
            handleChange={handleNativeChange}
            onClickProgress={values => {
              if (values.submitFromButton) {
                return;
              }
              handleChange(values, props.router);
            }}
            goBack={goBack}
            activeTab={value}
            tabs={[
              {
                content: (
                  <Informations
                    handleOpenConfirmation={handleOpenConfirmation}
                    handleCancelClose={handleCancelClose}
                    openConfirmation={openConfirmation}
                    setOpenConfirmation={setOpenConfirmation}
                    closeModal={onDialogClose}
                    template={props.location.query.template}
                    type={props.location.query.type}
                    disabled={props.router.params.action === 'editar'}
                    router={props.router}
                    form="productDeliveryForm"
                    onClickProgress={values =>
                      handleChange(values, props.router)
                    }
                  />
                ),
                icon: 'subject',
                label: 'Informações',
              },
              {
                content: (
                  <Interaction
                    handleOpenConfirmation={handleOpenConfirmation}
                    handleCancelClose={handleCancelClose}
                    openConfirmation={openConfirmation}
                    setOpenConfirmation={setOpenConfirmation}
                    closeModal={onDialogClose}
                    onClickProgress={values =>
                      handleChange(values, props.router)
                    }
                    prevClick={goBack}
                    form="productDeliveryForm"
                    router={props.router}
                    disabled={props.router.params.action === 'editar'}
                  />
                ),
                icon: 'forum',
                label: 'Interações',
              },
              {
                content: (
                  <Prize
                    handleOpenConfirmation={handleOpenConfirmation}
                    handleCancelClose={handleCancelClose}
                    openConfirmation={openConfirmation}
                    setOpenConfirmation={setOpenConfirmation}
                    closeModal={onDialogClose}
                    onClickProgress={values =>
                      handleChange(values, props.router)
                    }
                    prevClick={goBack}
                    form="productDeliveryForm"
                    router={props.router}
                    disabled={props.router.params.action === 'editar'}
                  />
                ),
                label: 'Recompensas',
                icon: 'loyalty',
              },
              {
                content: (
                  <Publish
                    handleOpenConfirmation={handleOpenConfirmation}
                    handleCancelClose={handleCancelClose}
                    openConfirmation={openConfirmation}
                    setOpenConfirmation={setOpenConfirmation}
                    closeModal={onDialogClose}
                    onClickProgress={values => onSubmit(values)}
                    prevClick={goBack}
                    router={props.router}
                    form="productDeliveryForm"
                    disabled={props.router.params.action === 'editar'}
                  />
                ),
                label: 'Publicação',
                icon: 'settings',
              },
            ]}
          ></ActionsTabs>
        </MaterialGrid>
      )}
    </AlertDialogSlide>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    contacts: state.contacts,
    productDelivery: state.productDelivery,
    formValues: getFormValues('productDeliveryForm')(state),
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      submitAction,
      getDetail,
      dispatchFormReset,
      getLottiesList,
      savePartialy,
      publishAction,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
