import React from 'react';
import { BASE_S3 } from 'config/consts';
import { truncateString } from '../../../helpers/utils';
import PropTypes from 'prop-types';

// HAS ON GESTOR
const DefaultCard = props => {
  const {
    onClick,
    imgCloudSource = undefined,
    actionName,
    labelClick,
    key,
  } = props;
  let { imgSource } = props;
  const cloudTo = () => {
    imgSource = `${BASE_S3}/${imgCloudSource}`;
  };

  return (
    <>
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          margin: '0 auto',
        }}
        onClick={onClick}
        key={key}
      >
        {imgCloudSource ? (
          <>
            {cloudTo()}
            <img
              alt=""
              className="img-fluid"
              style={{
                borderRadius: 6,
                marginBottom: 16,
                maxWidth: 267.02,
                maxHeight: 150.31,
                width: 'auto',
                height: 'auto',
                display: 'block',
                objectFit: 'cover',
                objectPosition: '50% 50%',
              }}
              src={imgSource}
            ></img>
          </>
        ) : (
          <img
            alt=""
            className="img-fluid"
            style={{
              borderRadius: 6,
              marginBottom: 16,
              maxWidth: 267.02,
              maxHeight: 150.31,
              width: 'auto',
              height: 'auto',
              display: 'block',
              objectFit: 'cover',
              objectPosition: '50% 50%',
            }}
            src={imgSource}
          ></img>
        )}

        <span
          style={{
            fontFamily: 'Lato !important',
            fontStyle: 'normal !important',
            fontWeight: 'normal',
            fontSize: '16px',
            maxWidth: 267.02,
            lineHeight: '24px',
            height: 48,
            letterSpacing: '0.25px',
            marginBottom: 8,
            color: '#242424',
          }}
        >
          {truncateString(actionName, 62)}
        </span>
        <span
          style={{
            fontFamily: 'Lato !important',
            fontStyle: 'normal !important',
            fontWeight: 'normal',
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.25px',
            color: '#6200EE',
            maxWidth: 267.02,
          }}
        >
          {truncateString(labelClick, 35)}
        </span>
      </div>
    </>
  );
};

DefaultCard.propTypes = {
  onClick: PropTypes.func,
  imgCloudSource: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  labelClick: PropTypes.string.isRequired,
}

export default DefaultCard;
