import React, { useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import PrimaryButton from '../../buttons/primaryButton';
import { FanApi } from '../api';
import { USER_TOKEN } from 'config/consts';
import { toastr } from 'react-redux-toastr';

export default function UploadLink({ handleChangeTab, establishment_id }) {
  const ref = useRef();
  const [value, setValue] = useState('');

  async function handleLink(value) {
    try {
      await FanApi.post(
        `embed-video`,
        { url: value, establishment_id },
        {
          headers: {
            Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
          },
        }
      );
      toastr.success('Sucesso!', 'Link enviado com sucesso!');
      handleChangeTab(1);
    } catch (e) {
      try {
        if (e.response.data.error) {
          toastr.error('Erro', e.response.data.error);
        }
      } catch (error) {
        toastr.error('Erro', 'Erro interno no servidor');
      }
    }
  }

  return (
    <div
      style={{
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        // className="col-8"
      >
        <TextField
          ref={ref}
          value={value}
          onChange={e => setValue(e.target.value)}
          style={{ width: '80%' }}
          id="outlined-basic"
          label="Link-externo"
          variant="outlined"
        />
        <PrimaryButton
          onClick={() => handleLink(value)}
          type="button"
          style={{ margin: '20px 0 0 0' }}
        >
          Enviar
        </PrimaryButton>
      </div>
    </div>
  );
}
