import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { bindActionCreators } from 'redux';
import CupomFeedback from './feedback/cupomFeedback';
import GeneralFeedback from './feedback/generalFeedback';
import PresentialCourseFeedback from './feedback/presentialCourseFeedback';
import ResearchInteraction from './interaction/researchInteraction';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ padding: 0 }} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '90%',
    backgroundColor: '#ffffff',
  },
  appBar: {
    margin: '0px 0 47px 0',
    borderRadius: '5px',
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '1px solid lightgray',
  },
}));

function ActivityTabs(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };
  useEffect(() => {
    setValue(0);
  }, [props.step]);
  return (
    <>
      {props.step !== 2 ? (
        props.step === 1 &&
          (props.formValues.type_id === 8 || props.formValues.type_id === 6) ? ( // se for pesquisa a interação é diferente
          <div className={classes.root}>
            {/*  <AppBar
              className={classes.appBar}
              position="static"
              color="default"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Preview" {...a11yProps(0)} />
              </Tabs>
            </AppBar> */}
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                style={{
                  overflowY: 'scroll',
                  height: 'calc(100vh - 320px)',
                  overflowX: 'hidden',
                  paddingRight: 15,
                }}
              >
                <ResearchInteraction {...props} />
              </TabPanel>
            </SwipeableViews>
          </div>
        ) : (
          //se for qualquer outra, interação é igual
          <div className={classes.root}>
            <AppBar
              className={classes.appBar}
              position="static"
              color="default"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Atividade" {...a11yProps(0)} />
                <Tab label="Card" {...a11yProps(1)} />
                {(props.formValues.type_id === 9 ||
                  props.formValues.type_id === 10 ||
                  props.formValues.type_id === 11 ||
                  props.formValues.type_id === 12) && (
                    <Tab label="Interação" {...a11yProps(2)} />
                  )}
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel
                value={value}
                index={0}
                dir={theme.direction}
                style={{
                  overflowY: 'scroll',
                  height: 'calc(100vh - 320px)',
                  overflowX: 'hidden',
                  paddingRight: 15,
                }}
              >
                {props.activity}
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                dir={theme.direction}
                style={{
                  overflowY: 'scroll',
                  height: 'calc(100vh - 320px)',
                  overflowX: 'hidden',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {props.card}
              </TabPanel>
              {props.formValues.type_id === 9 ||
                props.formValues.type_id === 10 ||
                props.formValues.type_id === 11 ||
                props.formValues.type_id === 12 ? (
                <TabPanel
                  value={value}
                  index={2}
                  dir={theme.direction}
                  style={{
                    overflowY: 'scroll',
                    height: 'calc(100vh - 260px)',
                    overflowX: 'hidden',
                    paddingRight: 15,
                  }}
                >
                  {props.interaction}
                </TabPanel>
              ) : (
                <div />
              )}
            </SwipeableViews>
          </div>
        )
      ) : props.step === 2 && //Se for a etapa de recompensa
        props.formValues.type_id !== 3 && //Se não for Compre e ganhe *
        props.formValues.type_id !== 4 && //Se não for Compre e ganhe *
        props.formValues.type_id !== 16 ? ( //Se não for Post sinmples
        <GeneralFeedback {...props} />
      ) : props.formValues.type_id === 3 ? ( //Cupom
        <CupomFeedback {...props} />
      ) : props.formValues.type_id === 4 ? ( //Atividade presencial
        <PresentialCourseFeedback {...props} />
      ) : null}
    </>
  );
}

const mapStateToProps = state => {
  return {
    lotties: state.lotties,
    auth: state.auth,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityTabs);
