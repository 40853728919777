import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';
import { reset as resetForm } from 'redux-form';

const URL_POST_CSV = `${BASE_API}/contacts/csv`;
const URL_GET_CONTACTS = `${BASE_API}/contacts/establishment`;
const URL_INVITE_OWNER = `${BASE_API}/invite-to-establishment`;

export const postCsvFileToContacts = (
  file,
  establishment,
  router = undefined
) => {
  return dispatch => {
    var formData = new FormData();
    formData.append('file', file);
    axios
      .post(`${URL_POST_CSV}/${establishment}`, formData, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch({
          type: 'CONTACTS_STATUS',
          payload: true,
        });
        if (!_.isUndefined(router)) {
          router.push('/owner/contacts');
        }
      });
  };
};

export const getContactByEstablishmentId = id => {
  return dispatch => {
    dispatch([
      {
        type: 'CONTACTS_LOAD',
        payload: true,
      },
    ]);
    axios
      .get(`${URL_GET_CONTACTS}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'CONTACTS_FETCHED',
            payload: response.data,
          },
        ]);
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const inviteOwner = values => {
  return dispatch => {
    dispatch([
      { type: 'BUTTON_LOAD', payload: true },
      { type: 'SUBMIT_PROGRESS', payload: 1 },
    ]);

    axios
      .post(`${URL_INVITE_OWNER}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'SNACKBAR',
            payload: {
              message: 'Operação realizada com sucesso',
              variant: 'success',
              open: true,
            },
          },
          reset(),
        ]);
      })
      .catch(e => {
        try {
          dispatch({
            type: 'SNACKBAR',
            payload: {
              message: `Erro: ${e.response.data.messages[0]}`,
              variant: 'error',
              open: true,
            },
          });
        } catch {
          dispatch({
            type: 'SNACKBAR',
            payload: { message: `Erro`, variant: 'error', open: true },
          });
        }
      })
      .finally(() => {
        dispatch([
          { type: 'BUTTON_LOAD', payload: false },
          { type: 'SUBMIT_PROGRESS', payload: 0 },
        ]);
      });
  };
};

export const reset = () => {
  return resetForm('InviteOwnerForm');
};
