import Content from 'common/components/content/content';
import { Table } from 'common/components/table/table';
import React, { Component } from 'react';
import { Card, CardText, CardTitle } from 'react-md';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUsersByEstablishmentId, remove } from './manageActions';

class ManageUsers extends Component {
  constructor(props) {
    super(props);

    this.props.getUsersByEstablishmentId(
      this.props.auth.user.establishments[0].id
    );
  }

  render() {
    const { router } = this.props;
    const { users } = this.props.ownerUsers;
    return (
      <Content>
        <Card>
          <CardTitle title="Usuários" />
          <CardText>
            <Table
              path={'/owner/owner-users'}
              router={router}
              values={users}
              columns={[
                { field: 'id', header: 'Id' },
                { field: 'name', header: 'Nome' },
                { field: 'email', header: 'Descrição' },
                { field: 'createdAt', header: 'Data de entrada' },
              ]}
              remove={this.props.remove}
              permissions={[]}
            />
          </CardText>
        </Card>
      </Content>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    ownerUsers: state.ownerUsers,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getUsersByEstablishmentId,
      remove,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);
