import { Box } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTransferRequestsByEstablishmentId } from './transferRequestsActions';

export function TransferRequests(props) {
  const { establishments } = props.auth.user;
  // const { router } = props;
  const { transactions, loading } = props.transferRequests;

  const [columns] = useState([
    { field: 'createdAt', title: 'Data do Pedido' },
    { field: 'points', title: 'Créditos (R$)' },
    { field: 'type.name', title: 'Tipo', render: rowData => rowData.type.name },
    { field: 'status.name', title: 'Status' },
  ]);
  const [data, setData] = useState([]);

  useEffect(() => {
    props.getTransferRequestsByEstablishmentId(establishments[0].id);
  }, []);

  useEffect(() => {
    setData(props.transferRequests.transactions);
  }, [props.transferRequests.transactions]);

  function renderEmptyScreen() {
    return (
      <section
        id="statement-buy-points"
        style={{ marginTop: '4.3em', height: '100vh' }}
      >
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <h2 className="signup-text">
                  Você ainda não comprou créditos!
                </h2>
                <p>
                  Créditos podem ser usados pelos seus associados para trocarem
                  por benefícios.
                </p>

                <PrimaryButton
                  onClick={() => props.router.push('/owner/points/buy')}
                >
                  Comprar créditos
                </PrimaryButton>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (loading) {
    return (
      <Box display="flex" height="100vh" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (transactions.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section id="statement-buy-points" style={{ marginTop: '2.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <Table
            searchPlaceholder={'Pesquisar'}
            hasToolbar={true}
            title="Transferências"
            columns={columns}
            rows={data}
            toolbarSearch
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    transferRequests: state.transferRequests,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTransferRequestsByEstablishmentId,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TransferRequests);
