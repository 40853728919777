import { Box, Typography } from '@material-ui/core';
import PrimaryButton from 'common/components/buttons/primaryButton';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import { FORM_RULES } from 'helpers/validations';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, Form, reduxForm } from 'redux-form';
import { InputLabelOutlined } from '../../../../common/components/fields/fields';
import { setLoading } from './validationsCupomActions';

let Validations = function(props) {
  function onSubmit(values) {
    props.router.push(`/owner/validacao-cupom/${values.code}`);
    props.setLoading(true);
  }

  return (
    <Box mt={10}>
      <Typography variant="body1" align="center">
        A validação pode ser feito pela sua câmera para leitura do QR Code ou
        digitando o código do cupom abaixo
      </Typography>
      <Form role="form" onSubmit={props.handleSubmit(onSubmit)} noValidate>
        <GeneralGrid container justify="center">
          <GeneralGrid item xs={8} md={4}>
            <Field
              component={InputLabelOutlined}
              label="Código"
              name="code"
              validate={[FORM_RULES.required]}
              required
              margin="normal"
            />
          </GeneralGrid>
        </GeneralGrid>
        <GeneralGrid container justify="center">
          <GeneralGrid item xs={10} md={6}>
            <Box display="flex" justifyContent="center">
              <PrimaryButton type="submit" color="primary">
                Próximo
              </PrimaryButton>
            </Box>
          </GeneralGrid>
        </GeneralGrid>
      </Form>
    </Box>
  );
};

Validations = reduxForm({
  form: 'validationsCupomForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(Validations);

const mapStateToProps = state => {
  return {
    auth: state.auth,
    validationsCupom: state.validationsCupom,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLoading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Validations);
