import axios from 'axios';
import { BASE_API, USER_TOKEN } from 'config/consts';
import _ from 'lodash';

const URL_QUIZZES = `${BASE_API}/quizzes/establishment`;
const URL_ACTIONS_POST = `${BASE_API}/actions`;

export const getQuizzes = id => {
  return dispatch => {
    dispatch([
      {
        type: 'QUIZZES_LOAD',
        payload: true,
      },
    ]);

    axios
      .get(`${URL_QUIZZES}/${id}`, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        dispatch([
          {
            type: 'QUIZZES_FETCHED',
            payload: response.data,
          },
        ]);
      });
  };
};

export const postAction = (values, router = undefined) => {
  return dispatch => {
    values['type'] = 1;
    axios
      .post(`${URL_ACTIONS_POST}`, values, {
        headers: {
          Authorization: `${JSON.parse(localStorage.getItem(USER_TOKEN))}`,
        },
      })
      .then(response => {
        if (!_.isUndefined(router)) {
          router.push('/owner');
        }
      });
  };
};
