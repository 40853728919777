import React from 'react';
import { FiMoreHorizontal } from 'react-icons/fi';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import {
  Container,
  FinalPrice,
  InfoContainer,
  ItemImage,
  Prices,
  Quantity,
  Title,
} from './styles';

let ItemCard = function({ data }) {
  return (
    <Container>
      <ItemImage image={data.image} />
      <InfoContainer>
        <Title>{data.name}</Title>
        <FiMoreHorizontal />
        <section>
          <Prices>
            <span>
              {parseFloat(data.value)
                .toFixed(2)
                .replace('.', ',')}
            </span>
          </Prices>
          <Quantity>
            <p>1</p>
          </Quantity>
          <FinalPrice>
            R${' '}
            <span>
              {(parseFloat(data.value) - parseFloat(data.discount_value))
                .toFixed(2)
                .replace('.', ',')}
            </span>
          </FinalPrice>
        </section>
      </InfoContainer>
    </Container>
  );
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    formValues: getFormValues('shoppingForm')(state),
  };
};

export default connect(mapStateToProps)(ItemCard);
