import CallToActionCard from '../cards/moduleCard/callToActionCard';
import React from 'react';
import { Grid } from '@material-ui/core';
import { DomPurifyContentContainer } from './styles';

function ActivityVideoPlaylist(props) {
  return (
    <Grid
      item
      container
      justify="center"
      alignItems="center"
      xs={12}
    >
      <DomPurifyContentContainer
        dangerouslySetInnerHTML={{
          __html: props.formValues.description,
        }}
      />
      <Grid
        item
        container
        justify="center"
        xs={12}
      >
        <CallToActionCard formValues={props.formValues} />
      </Grid>
    </Grid>
  );
}

export default ActivityVideoPlaylist;
