import { Box, Typography } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import { InputLabelOutlined } from 'common/components/fields/fields';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Table6 from 'common/components/table/table6';
import { tableSearch } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList } from './partnerCuponsActions';

function Subscriptions(props) {
  const { auth } = props;
  const { content } = props.partnerCupons.list;
  const { router } = props;
  const [columns] = useState([
    { title: 'Nome', field: 'user.name' },
    { title: 'CPF', field: 'user.cpf' },
    { title: 'Entregue', field: 'used' },
    { title: 'Atividade', field: 'cupom.action.name' },
    { title: 'Data da inscrição', field: 'createdAt' },
  ]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState('');
  useEffect(() => {
    props.getList(auth.user.establishments[0].id);
  }, []);
  useEffect(() => {
    setData(props.partnerCupons.list.content);
  }, [props.partnerCupons.list.content]);

  function handleChange(e) {
    setData(tableSearch(e, props.partnerCupons.list.content));
    setValue(e.target.value);
  }

  function renderEmptyScreen() {
    return (
      <section id="partnerCupons" style={{ marginTop: '4.3em' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui inscrições
                  </h2>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.partnerCupons.loading) {
    return (
      <Box display="flex" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section style={{ marginTop: '4.3em' }}>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <GeneralGrid
            container
            spacing={3}
            justify="space-between"
            alignContent="space-between"
          >
            <GeneralGrid item xs={5}>
              <span
                style={{
                  fontFamily: 'Roboto',
                  fontWeight: 500,
                  fontSize: 24,
                }}
              >
                Lista de compras feitas pelos seus clientes
              </span>
              <Typography variant="body1" style={{ marginTop: 20 }}>
                {props.partnerCupons.list.content.length} compras
              </Typography>
            </GeneralGrid>
            <GeneralGrid item xs={3}>
              <InputLabelOutlined
                input={{ onChange: handleChange, value: value }}
                meta={{}}
                onChange={handleChange}
                value={value}
                label="Procurar"
                name="search"
                margin="normal"
              />
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid container spacing={3}>
            <GeneralGrid item xs={12}>
              <Table6
                title="Lista de inscrições"
                columns={columns}
                data={data}
                router={router}
              />
            </GeneralGrid>
          </GeneralGrid>
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    partnerCupons: state.partnerCupons,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
