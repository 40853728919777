/* eslint-disable react-hooks/exhaustive-deps */
// import { getActionById, setDetail, subscribe, setSubscribed } from './activitiesActions';
import GeneralTabs from 'common/components/navigation/actionsTab/tabs';
import { BASE_S3 } from 'config/consts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './activity.css';
import ActivityCoupon from './activityCoupon/index';
import ActivityDonation from './activityDonation';
import ActivityMemberGetMember from './activityMemberGetMember';
import ActivityPresentialCourse from './activityPresentialCourse';
import ActivityShare from './activityShare';
import ActivitySignUpAndEarn from './activitySignUpAndEarn';
import ActivitySurvey from './activitySurvey';
import ActivityDelivery from './activityDelivery';
import ActivityVideoPlaylist from './activityVideoPlaylist';
import Grid from '@material-ui/core/Grid';
import { ActivityImageContainer, DomPurifyContentContainer } from './styles';
import { GeneralTitle } from '../../styles/styles';

class Activity extends Component {
  renderSignupAndWin() {
    return null;
  }

  renderStart(props) {
    return (
      <>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          xs={12}
          style={{
            backgroundColor: ''
          }}
        >
          <Grid
            item
            container
            alignItems="center"
            xs={12}
          >
            {console.log(props.formValues.type_id)}
            {props.formValues.type_id === 3 && (
              <ActivityCoupon formValues={props.formValues} />
            )}
            {props.formValues.type_id === 4 && (
              <ActivityPresentialCourse formValues={props.formValues} />
            )}
            {props.formValues.type_id === 2 && (
              <ActivitySignUpAndEarn formValues={props.formValues} />
            )}
            {props.formValues.type_id === 1 && (
              <ActivityMemberGetMember formValues={props.formValues} />
            )}
            {props.formValues.type_id === 5 && (
              <ActivityShare formValues={props.formValues} />
            )}
            {props.formValues.type_id === 17 && (
              <ActivityDonation formValues={props.formValues} />
            )}
            {props.formValues.type_id === 18 && (
              <ActivityVideoPlaylist formValues={props.formValues} />
            )}
            {(props.formValues.type_id === 7 ||
              props.formValues.type_id === 8 ||
              props.formValues.type_id === 9 ||
              props.formValues.type_id === 10 ||
              props.formValues.type_id === 11 ||
              props.formValues.type_id === 12 ||
              props.formValues.type_id === 6) && (
                <ActivitySurvey formValues={props.formValues} />
              )}
            {props.formValues.type_id === 14 && (
              <ActivityDelivery formValues={props.formValues} />
            )}
          </Grid>
        </Grid>
      </>
    );
  }

  renderRules(props) {
    return (
      <>
        <Grid item container alignItems="center" xs={12}>
          <DomPurifyContentContainer
            dangerouslySetInnerHTML={{ __html: props.formValues.rules }}
          />
        </Grid>
      </>
    );
  }

  render() {
    return (
      <>
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          xs={12}
          style={{
            backgroundColor: '#ffffff'
          }}
        >
          {/* IMAGE PREVIEW */}
          <Grid
            item
            container
            xs={11}
          >
            <ActivityImageContainer
              BASE_S3={BASE_S3}
              imagePath={
                this.props.formValues?.image?.indexOf('base64') > (-1) ? (
                  this.props.formValues?.image
                ) : (
                  BASE_S3 + '/' + this.props.formValues?.image
                )
              }
            />
          </Grid>
          {/* ACTIVITY PREVIEW TITLE */}
          <Grid
            item
            container
            xs={11}
          >
            <GeneralTitle isPreview>
              {this.props.formValues.name}
            </GeneralTitle>
          </Grid>
          {/* ACTIVITY PREVIEW CONTENT */}
          <Grid
            item
            container
            xs={11}
          >
            <GeneralTabs
              start={this.renderStart(this.props)}
              rules={this.renderRules(this.props)}
              hiddenTabs={!this.props.formValues.has_rules}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    /*  auth: state.auth,
    activities: state.activities,
    website: state.website */
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      /* getActionById, setDetail, subscribe, setSubscribed */
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Activity);
