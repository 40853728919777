import { Box, Typography, makeStyles } from '@material-ui/core';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';

import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import Icon from '@material-ui/core/Icon';

import Table from 'common/components/table/table-material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getList } from './subscriptionsActions';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import { Link } from 'react-router';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(styles);
function Subscriptions(props) {
  const classes = useStyles();
  const { auth } = props;
  const { content } = props.subscriptions.list;
  const { router } = props;
  const [columns] = useState([
    {
      title: 'Nome',
      field: 'user.name',

      render: rowData => (rowData.user?.name ? rowData.user?.name : '-'),
    },
    {
      title: 'Telefone',
      field: 'user.tel',
      render: rowData => (rowData.user?.tel ? rowData.user?.tel : '-'),
    },
    {
      title: 'E-mail',
      render: rowData => (rowData.user?.email ? rowData.user?.email : '-'),
      field: 'user.email',
    },
    {
      title: 'Deseja contato',
      render: rowData => (rowData.should_contact ? 'Sim' : 'Não'),
    },
    { title: 'Validado', field: 'validated' },
    {
      title: 'Atividade',
      field: 'presential_course.action.name',

      render: rowData =>
        rowData.presential_course?.action?.name
          ? rowData.presential_course?.action?.name
          : '-',
    },
    { title: 'Data da inscrição', field: 'createdAt' },
  ]);
  const [data, setData] = useState([]);
  useEffect(() => {
    props.getList(auth.user.establishments[0].id);
  }, []);
  useEffect(() => {
    setData(props.subscriptions.list.content);
  }, [props.subscriptions.list.content]);

  function renderEmptyScreen() {
    return (
      <section id="subscriptions" style={{ marginTop: '4.3em' }}>
        <GeneralGrid container spacing={3} alignItems="center">
          <GeneralGrid item xs={12} md={6}>
            <GeneralGrid container spacing={3}>
              <GeneralGrid item xs={12}>
                <div className="pl-4">
                  <h2 className="signup-text">
                    Você ainda não possui inscrições
                  </h2>
                </div>
              </GeneralGrid>
            </GeneralGrid>
          </GeneralGrid>
          <GeneralGrid item xs={12} md={6}>
            <img alt="" src={placeholder_illustration} className="img-fluid" />
          </GeneralGrid>
        </GeneralGrid>
      </section>
    );
  }

  if (props.subscriptions.loading) {
    return (
      <Box display="flex" justifyContent="center" mt={7}>
        <LoadingProgress />
      </Box>
    );
  }
  if (content.length < 1) {
    return renderEmptyScreen();
  }
  return (
    <section style={{ marginTop: '4.3em' }}>
      {' '}
      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ position: 'relative' }} className="active-actions">
            <CardHeader color="primary" stats icon>
              <CardIcon color="white">
                <Icon color="primary">accessibility</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Resgates</p>
              <h3 className={classes.cardTitle}>
                {props.subscriptions.list.content.length}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card style={{ position: 'relative' }} className="active-actions">
            <CardHeader color="primary" stats icon>
              <CardIcon color="white">
                <Icon color="primary">dashboard</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Vouchers validados</p>
              <h3 className={classes.cardTitle}>
                {
                  props.subscriptions.list.content.filter(item => {
                    return item.validated === 'Sim';
                  }).length
                }
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Link to="/owner/validacao">
            <Card
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="active-actions"
            >
              <Typography
                color="primary"
                component="h4"
                className={classes.cardTitle}
              >
                Validar QR CODE
              </Typography>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Link to="/owner/inscricoes/validados">
            <Card
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              className="active-actions"
            >
              <Typography
                color="primary"
                component="h4"
                className={classes.cardTitle}
              >
                Premiar participante
              </Typography>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
      <GeneralGrid container spacing={3}>
        <GeneralGrid item xs={12}>
          <Table
            hasToolbar
            toolbarSearch
            title="Lista de vouchers resgatados"
            columns={columns}
            rows={data}
            dataType={'subscriptions'}
            router={router}
            mobileTable={isMobile}
            hasBorder={!isMobile}
          />
        </GeneralGrid>
      </GeneralGrid>
    </section>
  );
}

const mapStateToProps = state => {
  return {
    subscriptions: state.subscriptions,
    auth: state.auth,
    general: state.general,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getList }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
